import { FileUploadService } from 'src/app/shared/services/file-upload/file-upload.service';
import { Injectable,NgZone } from '@angular/core';
import { FirestoreService, TASK_NAME, TRIGGER_STATUS } from 'src/app/shared/services/firestore/firestore.service';
import { LogService} from '../logging/log.service';
import * as firebase from 'firebase';
import {  Observable, BehaviorSubject, Subject } from 'rxjs';
import { MigrationDataService } from '../migrationDataService/migration-data.service';
import { UserAppearancesConfig } from '../parser/datainterface';

const NOT_FOUND:any = -1;
const IPO_MENU_KEYS = 13;
const PAGE_DEV_LIMIT = 25;
const ACO_RING_COUNT_UNIT = 5;
const DEFAULT_RING_COUNT = 3;
const ACO_RING_COUNT_MAX_LIMIT = 15;
const PICKUP_MEMBER_LIMIT = 25;
const PHONE_MODEL = {
    SOFTPHONE: "softphone",
    MOBILE: "Mobile"
}
const tldjs = require('tldjs');

const LOCATION = {
    AUTOMATIC: "0",
    CLOUD: "1"
}

export const LIMITED_EXTENSION = "Limited Extension";
const EXTNTYPE = {
    USEREXTN: "User",
    HUNTGROUPEXTN: "HuntGroup",
    IVRMENUEXTN: "AutoAttendant",
    PAGINGGROUPEXTN: "PagingGroup",
    LIMITEDEXTN: "Limited",
    PARKLOCEXTN: "ParkLocation"
}

const FEATURE_NAME = {
    USERS: "Users",
    MESSAGE_ONLY: "messageOnly",
    USER_CONTACTS: "UserContacts",
    USER_BHOURS: "UserBHours",
    USER_BLF: "UserBLF",
    CALL_FORWARD: "CallForward",
    HUNT_GROUP: "HuntGroup",
    PAGING_GROUP: "PagingGroup",
    PARK_LOCATION: "ParkLocation",
    AUTO_ATTENDANT_PROMPTS: "AutoAttendantPrompts",
    AUTO_ATTENDANT: "AutoAttendant",
    COMPANY_RULES: "CompanyRules",
    USER_VM_WORKHOURS: "UserVmWorkHours",
    USER_VM_AFTERHOURS: "UserVmAfterHours",
    USER_VM_CUSTOMHOURS: "UserVmCustomHours",
    //USER_UPDATERINGONMONITOREDCALLDATA: "ringOnMonitoreCallData",
    COMPLEX_IVR_PROMPTS:"ComplexIvrPrompts",
    ZERO_DIAL: "ZeroDial",
    MAC_UPDATE: "MacUpdate",
    CALLPICKUP: "CallPickup",
    POST_MIGRATION: "PostMigration",
}

const IPOMENU_KEYACTIONS = {
    CENTREX_TRANSFER: {
        action:"CentrexTransfer",
        description:"Centrex transfer"
    },
    DIALBYNAME: {
        action:"DialByName",
        description:"Dial by name"
    },
    DIALBYNUMBER: {
        action:"DialByNumber",
        description:"Dial by number"
    },
    NORMALTRANSFER: {
        action:"NormalTransfer",
        description:"Normal transfer"
    },
    NOT_DEFINED: {
        action:"Not_defined",
        description:"Not defined"
    },
    PARK_AND_PAGE: {
        action:"ParkAndPage",
        description:"Park and page"
    },
    REPLAY_GREETING: {
        action:"ReplayGreeting",
        description:"Replay greeting"
    },
    BLINDTRANSFER: {
        action:"BlindTransfer",
        description:"Blind transfer"
    },
    TRANSFER_TO_AA: {
        action:"TransferToAutoAttendant",
        description:"Transfer to Auto-Receptionist"
    },
    FAX: {
        action:"Fax",
        description:"Not Applicable on ACO"
    },
    STAR: {
        action:"*",
        description:"Mapped to Replay Greeting on ACO"
    },
    HASH: {
        action:"#",
        description:"Mapped to Replay Greeting on ACO"
    }
} 

export const AA_ERROR_MSG = {
    PROMPTS_MISSING : "Prompts missing",
    UNMATCHED_KEY_ACTIONS : "No matching Key Press action",
    INVALID_DESTINATION : "Destination extension number no longer exists",
    COMPLEX_CALL_FLOW : "Complex Voicemail Pro call flows, only mapped IVR Menus are migrated : ",
    TIMEOUT_PRESENT: "Voicemail Pro call flows with timeout entries: ",
    NO_KEYS_TO_MATCH: "No Key Press to match",
    INVALID_PHONE_NUMBER: "Not a valid Key Press destination",
    ANS_RULE_DATE_NOT_SELECT_TIME_PROFILE: "Working Hours with invalid Date Range are not migrated : ",
    CONFERENCE_MEET_ME: "Voicemail Pro Conference meet me menu not supported in ACO: ",
    UNKNOWN_ACTION: "Unknown Key Press Action",
    ICR_AA_NOT_FOUND: "IP Office Incoming call route Auto Receptionist destinations not found or skipped as no ACO match: ",
    DIAL_BY_EXTN_WARN: "Dial by extension is supported but does not require a key press assignment",
    PROMPT_TO_BE_RERECORD: "IVR prompt needs to be re-recorded"
}

export const VM_ERROR_MSG ={
    GREETINGS_MISSING : "Voicemail greeting missing",
    PARTIAL_USERS : "Voicemail greetings partially uploaded",
    IGNORED_USERS : "Users with greetings file size greater than max limit of 20MB are ignored",
    LIM_EXTN_UNSUPPORTED : "User Voicemail greeting will not be migrated for Limited Extension user(s): "
};

export const PG_ERROR_MSG =  {
    SINGLE_EXTN_NOT_ASSIGNED :"Page capable Devices is not assigned for extension number(s) ",
    MULTIPLE_EXTNS_NOT_ASSIGNED:"Page capable devices are not assigned for extension numbers ",
    LIMIT_RCHD : "Max limit of "+PAGE_DEV_LIMIT+" paging Devices reached in the Paging Only group, not migrating Devices ",
    ALL_MEMBERS_DELETED : "Migrating empty Paging Only group : ",
    EMPTY_GROUP: "Migrating empty Paging Only group : ",
    EXTENSION_MISSING : "Extension number missing for Paging Only group, skipping creation of Paging Only group(s) for: ",
    UNCHECKED_MEM_LIST : "Unchecked members are ",
    AA_KEYS_FOUND: "Paging Only group(s) not considered for ACO IVR menu keys: ",
    LIM_EXTN_UNSUPPORTED: "Paging Only Group member(s) configured as Limited Extension will not be migrated for Paging Only Group(s): ",
    RESERVED_EXTN: "Paging group reserved extension(s): ",
    MSG_ONLY_UNSUPPORTED: " Message Only extension(s) configured as members will not be migrated for the paging only group : ",
}
export const HG_ERROR_MSG = {
    ALL_MEMBERS_DELETED : "Migrating empty Call Queues : ",
    EMPTY_GROUP: "Migrating empty Call Queues : ",
    CQ_COUNT_EXCEEDS_ACO_THRESHOLD: "Total Call Queues count exceeds ACO maximum limit of 100. Additional Call Queues exceeding the limit will not be migrated",
    ANN_MISSING : "Interrupt Audio prompts missing for  Call Queues: ",
    UNSUPPORTED_CALL_FLOW : "Unsupported Voicemail Pro call flow, Interrupt Audio prompt not migrated for Call Queues: ",
    MUL_ANN_FILES : "Multiple Interrupt Audio prompts for Voicemail Pro Call flow, migrating only the first prompt for Call Queues: ",
    ANN_BOX_UNCHECKED: "Interrupt Audio Prompt not migrated, as \"Announcements On\" configuration on IP Office is unchecked: ",
    VM_GREETING_MISSING: "Call Queues Greeting(s) missing: ",
    VM_COMPLEX_CALLFLOW: "Unsupported Voicemail Pro call flow, Greetings not migrated for Call Queues: ",
    EXTENSION_MISSING : "Call Queues not migrated since Extension Number is missing : ",
    HG_VM_USER_ACCESS: "MWI is not available for following Call Queue members (Members which has User Source Number as H configured on IP Office):  ",
    HOLD_TIME_EXCEEDED : "IP Office Call Queues(s) with \"Voicemail answer time\" exceeding ACO Call Queues \"hold time\" of 900s. Setting maximum of 900s for hold time: ",
    LIM_EXTN_UNSUPPORTED : "Call Queue member(s) configured as Limited extension will not be migrated for the following Call Queue(s) : ",
    ANN2_BOX_UNCHECKED: "Call Queues with 2nd announcement unchecked in configuration: ",
    COLLECTIVE_CALLWAIT: "Call Pickup functionality is enabled for Call Queues with ringmode set to Simultaneous/Collective Call Waiting : ",
    EMPTY_MEMBERLIST : "No pickup members set as there are no members for the call queue: ",
    PICKUP_MEMBERS_LIMIT_EXCEEDED : "Maximum of 25 pickup members can be configured per call queue group. The limit is exceeded for the call queue: ",
    PICKUP_MEMBERS_NOT_MIGRATED : "Pickup Members not migrated for the Call Queues since the member limit exceeds ACO Simultaneous CQ Member Limit : ",
    RESERVED_EXTN: "Call Queues reserved extension(s): ",
    VM_DISABLED: "Under ACO system where service edition is SERVICE_EDITION, call queue's \"Maximum caller wait time in queue\" will be configured to 15 mins since its voicemail status is disabled : ",
    //Below warning is commented out as it contradicts the enhancement implementation, now overflow groups can be configured under routing section even when voicemail is enabled.
    //VM_DISABLED_WAITTIME_UPDATE_OVERFLOW_SUPPORT: "Call Queue Voicemail status is disabled and ACO is SERVICE_EDITION licensed. \"Maximum caller wait time in queue\" is set to 15 minutes and overflow group can be configured under routing section for: ",
    VM_DISABLED_WAITTIME_UPDATE: "Call Queue Voicemail status is disabled and ACO is SERVICE_EDITION licensed. \"Maximum caller wait time in queue\" is set to 15 minutes for: ",
    MSG_ONLY_UNSUPPORTED: "Call Queue member(s) configured as Message Only extension will not be migrated for the following Call Queue(s) : ",
    //Below warning is commented out since we do not always set the holdtime of queue will not be set to 15 mins always when queue length is set to 'No Limit' after call queue overflow group configuration feature implementation.
    //HG_QUEUE_LENGTH_NO_LIMIT : "Call Queues MaxCallers will be set to 25 calls and HoldTime of Queue will be set to 15min (900sec) since Queue Length is set to ‘No Limit’ : ",
    HG_RINGGROUP_WITH_UNSUPPORTED_MEMBERS_COUNT : "Manual configuration of 'Route call to members' field has to be done for Call Queues (Collective Call Waiting or Simultaneous) with more than ",
    HG_OVERFLOW_REJECTED_GROUPS:"Only first 3 viable overflow groups will be migrated into call queue overflow section. Not migrating overflow group(s) :",
    HG_ROUTING_REJECTED_GROUPS:"Only first viable overflow group will be migrated into the routing section. Not migrating overflow group(s): ",
    HG_NOT_MIGRATING_OVERFLOW_GROUPS:"Not migrating overflow group(s): ",
    HG_OVERFLOW_REJECT_SIMULTANEOUS_RING_TYPE:"Overflow groups cannot be configured into call queue's overflow section with ring type Simultaneous: ",
    HG_OVERFLOW_WAIT_TIME_EXCEEDED: "Exceeded maximum overflow time of 15 minutes. Maximum value will be set to 15 minutes : ",
   // HG_OVERFLOW_WAIT_TIME_NOT_SET: "Call queue wait time will be set to 15 minutes (900sec) since overflow time is set to 0",
    HG_OVERFLOW_MAX_CALLERS_EXCEEDED: "Queue Length set to 'No Limit' or exceeded maximum callers value of 25 calls. Maximum callers will be set to 25 : ",
    HG_OVERFLOW_MODE_UNSUPPORTED:"Configured \'Group\' overflow mode is not supported in ACO: ",
    HG_OVERFLOW_FEATURE_NOT_SUPPORTED:"ACO is standard licensed. Call-queue overflow functionality is not supported",
    HG_OVERFLOW_PAGING_GRP_SKIPPED:"Overflow group(s) which is/are converted to paging group(s) is/are skipped: ",
    HG_OVERFLOW_RESERVED_EXTN_SKIPPED:"Overflow group(s)  which is/are reserved extension(s) is/are skipped: ",
    HG_OVERFLOW_EMPTY_GROUP:"Migrating call-queues with no viable groups in overflow groups lists: ",
    HG_OVERFLOW_TOGGLE_DISABLED: "When ring mode set to Simultaneous or voicemail is enabled and voicemail time if set to off , the overflow toggles cannot be enabled/disabled/switched for call queues configured with overflow groups: ",
    VM_ENABLED_NO_OVERFLOW: "Not migrating overflow group into routing section as voicemail time is enabled and has higher precendence for : ",
    HG_PICKUP_MEMBER_CQ_LIMIT_EXCEEDED: "Pickup member(s) for the Call Queue list is/are a part of more than 10 Call Queues : "
   // HG_OVERFLOW_TIME_DISABLED: "Not migrating overflow groups as overflow time is disabled for : ",
}
export const TWIN_CONTACT_NUMBERS = {
    STEERING_CODES_REMOVAL : "Outbound Call Prefix excluded from Contacts and ring group numbers: ",
    DID_PHONES_NOT_ASSIGNED: "Ring Group mobile number will not be configured in ACO since phones with DID is not assigned to these user extension numbers : ",
    TWIN_DATE_NOT_SELECTED_TIME_PROFILE: "Date ranges in Working Hours having no date selections will be ignored while configuring Working Hours for User Ring Group for user extension number(s): ",
    INTERNATIONAL_CALLING_DISABLED: "International calling is disabled in target ACO: User Ring Group numbers may fail to migrate",
    LIM_EXTN_NOT_SUPPORTED: "Ring group number will not be migrated for Limited extension user(s): ",
    MSG_ONLY_NOT_SUPPORTED: "Ring group number will not be migrated for Message Only extension user(s): "
}
export const USER_BUSINESS_HOURS = {
    RING_COUNT_EXCEEDS_THRESHOLD : "User  hour’s Ring For count configuration exceeded the threshold value for extension number(s), configuring to ACO maximum limit of 15 rings: ",
    LIM_EXTN_NOT_SUPPORTED : "User custom hours, Working hours, after hours will not be migrated for Limited extension number user: ",
    MSG_ONLY_NOT_SUPPORTED : "User custom hours, Working hours, after hours will not be migrated for Message Only user(s): "
}

export const TIME_PROFILE = {
    DATE_NOT_SELECTED_TIME_PROFILE: "Working Hours with invalid Date Range are not migrated: "
}

export const GEN_ERR = {
    DUPLICATE_EXTNS: "extension(s) already in use by ACO",
    LIMITED_EXTNS : "Limited Extension configured for user(s) : "
}

const BLF_ERROR_MSG = {
    LIM_EXTN_NOT_SUPPORTED : "Configured Presence will not be migrated for the Limited Extension user(s): ",
    MSG_ONLY_NOT_SUPPORTED : "Configured Presence will not be migrated for the Message Only Extension user(s): "
}

const PARK_LOCATION = {
    PARK_LOCATION_EXTENSION_EXISTS: "Park Location extension(s) already in use by ACO : ",
    PARK_LOCATION_RESERVED_EXTENSION: "Park Location(s) with reserved extension : ",
    PARK_LOCATION_BTN_PRGM_NOT_CONFIGURABLE : "There is no API support to configure Park Locations under button programming currently, it must be configured manually to Park Location members in ACO.",
    PARK_LOCATION_EXT_EMPTY: "Call Park configured with empty extension number under users will not be migrated to ACO : ",
    SKIP_MESSAGE_ONLY_PARK_LOCATION: "Park Locations under message-only users will be skipped and following message-only users will not be configured as Park Location members : ",
    SKIP_LIMITED_EXTENSION_PARK_LOCATION: "Park Locations under limited extension users will be skipped and following limited extension users will not be configured as Park Location members : ",
    DUPLICATE_EXTENSION_PARK_LOCATION: "Following Park Locations configured with duplicate extensions in current migration will not be migrated : "
}

const SYSMON_MSG = {
    UNASSIGNED_PHONES_NOT_AVAILABLE: "Device MAC ID update will fail since target ACO system does not have sufficient unassigned licenses."
}

//Should be maintained as TASK QUEUE ORDER
const TASK_QUEUE_PRIORITY = [
    FEATURE_NAME.USERS,
    FEATURE_NAME.MESSAGE_ONLY,
    FEATURE_NAME.USER_CONTACTS,
    FEATURE_NAME.USER_BHOURS,
    FEATURE_NAME.USER_BLF,
    FEATURE_NAME.CALL_FORWARD,
    FEATURE_NAME.HUNT_GROUP,
    FEATURE_NAME.PAGING_GROUP,
    FEATURE_NAME.PARK_LOCATION,
    FEATURE_NAME.AUTO_ATTENDANT_PROMPTS,
    FEATURE_NAME.AUTO_ATTENDANT,
    FEATURE_NAME.COMPANY_RULES,
    FEATURE_NAME.USER_VM_WORKHOURS,
    FEATURE_NAME.USER_VM_AFTERHOURS,
    FEATURE_NAME.USER_VM_CUSTOMHOURS,
   // FEATURE_NAME.USER_UPDATERINGONMONITOREDCALLDATA,
    FEATURE_NAME.COMPLEX_IVR_PROMPTS,
    FEATURE_NAME.ZERO_DIAL,
    FEATURE_NAME.MAC_UPDATE,
    FEATURE_NAME.CALLPICKUP,
    FEATURE_NAME.POST_MIGRATION
]

const CUSTOM_STEERING_CODE_FORMATS = ['91XXXXXXXXXX', '1XXXXXXXXXX', '9XXXXXXXXXX', '81XXXXXXXXXX', '8XXXXXXXXXX'];
export const NAR_COUNTRIES_LIST = ["Anguilla", "Antigua and Barbuda", "Aruba", "Bahamas", "Barbados", "Belize", "Bermuda", "Virgin Islands, British", "Cayman Islands", "Costa Rica", "Cuba", "Dominica", "Dominican Republic", "El Salvador", "Venezuela", "Greenland", "Grenada", "Guadeloupe", "Guatemala", "Haiti", "Honduras", "Jamaica", "Martinique", "Mexico", "Montserrat", "Nicaragua", "Panama", "Colombia", "Saint Kitts and Nevis", "Saint Lucia", "Saint Pierre and Miquelon", "Saint Vincent and the Grenadines", "Sint Maarten", "Trinidad and Tobago", "Turks and Caicos Islands", "United States", "Virgin Islands, U.S."];
const ACO_SERVICE_EDITION_LIST = ['premium', 'enterprise', 'standard', 'ultimate'];
const EXISTING_PHONE = "existing phone";
const EXISTING_PHONE_LIMITED_EXTENSION = "existing phone limited extension";

//Templates
interface IPOUserData {
    US_Name:string,
	US_KName:string,
	US_Fullname:string,
	US_VoiceMailEmail:string,
	US_UniqueIdentity:string,
    US_WrapUpTime:number,
    US_Right:string,
	Extension:{
		US_Extension:string,
		EX_Type:string,
		EX_IsExtnCentralized:string,
		EX_CentralizedDDINumber:string,
        Ex_Location:string,
        Location_Timezone: string,
        Location_Name: string,
		US_UserCLI:string,
        EX_VoipFax:string,
        US_UserRightsTimeProfile: string		
	},
	ICR_Number:string,
    US_Locale:string,
    US_DialInSourceNumbers:string,
    US_AssignedPackage:string,	
	Phone: {
		EX_PhoneType:string,
		EX_Classification:string,
		US_SystemPhone:string,
	},
	US_ContactsList:{
	    Name:string,
		Number:string,
		SpeedDial:string
	}[],
	Ringing: {
		US_OCS:string,
		US_ICS:string,
		US_RBS:string
	},
	Forwarding: {
		US_ForwardOnBusy:string,
		US_ForwardOnNoAnswer:string,
		US_ForwardUnconditional:string,
		US_ForwardHuntGroup:string,
		US_ForwardNumber:string,
		US_ForwardBusyNumber:string,
	},
	DND: {
		US_DoNotDisturb:string,
		US_DoNotDisturbList:string
	},
	Absent: {
		US_AbsentMessage:string,
		US_AbsentSet:string,
		US_AbsentText:string
    },
    Twinning: {
        US_TwinningType:string,
        US_TwinMobileNumber:string,
        US_TwinTimeProfile:string
    },
    Button_Programming:{
        SC_ShortCode:string,
        SC_Telephone:string,
        SC_LineGroup:string,
        SC_Feature:string,
        SC_FeatureName:string,
        SC_Locale:string,
        SC_ForceAccountCode:string,
        SC_GUID:string,
        SC_ForceAuthCode:string                    
    }[]
    ,
    User_BLF :any,
    US_NoAnswerTime:number,
    Filename : string,
    Sitename:string,
    SiteId:string
};

interface tablePreviewData {
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: any,
    did: string,
    macId: string,
    extension: number,
    profileType: string,
    ipoPhoneModel: string,
    acoPhoneModel: string,
    extensionId: string,
    acoPhoneStatus: any,
    migrationReady: boolean,
    blfList: any,
    contactList:any,
    workHoursProfile: string,
    //User features data
    timeZoneId: string,
    timeZoneName: string,
    tzOffset: string,
    twinMob: string,
    twinProfile: string,
    twinType: string,
    vmGreetings: boolean,
    //ipo user name 
    ipoUserName: string,
    pageCapable: boolean,
    ringCount: number,
    isSelected: boolean,
    filename: string,
    //sysmon data
    isSysmonAssignment: boolean,
    displayPhoneModel: string,
    wrapUpTime: number
    //multi-site
    site_id:string,
    siteName:string,
    index: number
};
interface incomingNumbers
{
    isSelected: boolean,
    icrNumber:string,
    name:string,
    timeProfile:string,
    companyNumbers:string,
    //multi-site
    siteName:string,
}

interface AAICR
{
    name:string,
    phoneId:number,
    phoneNumber:string,
    timeProfile:string
}


interface tableTimeProfileData {
    name: string,
    calendar: boolean,
    recurrence: boolean,
    //multi-site
    siteName:string,
}
interface tableHuntGroupData{
    name: string,
    extension: number,
    totalMembers: number,
    overflowGroup: Array<string>,
    ringMode: string,
    isPagingEnabled: boolean,
    devices: Array<string>,
    wrapUpTime: number,
    siteName: string,
    isCallQueueMigrationEnabled: boolean,
    isRoutingMigrationEnabled: boolean
};

interface tableAutoAttendantData{    
    name: string,
    menuAvailability: boolean,
    ivrPrompt: boolean,
    companynumbers: Array<string>,
    //multi-site
    siteName:string,
};

interface tablePagingGroupData{
    name: string,
    extension: number,
    members: Array<string>
}

interface ExtensionMap{
    name: string,
    extn: number,
    extId: string,
    type: string
}

interface ACOExportUserData{
    extId: string,
    data:{
        contact:{
            firstName:string,
            lastName:string,
            email: string,
            emailAsLoginName:boolean
        },
        regionalSettings:{
            timezone:{
                id:string
            }
        },
        status:string,
        extensionNumber:number,
        site:{
            id:string,
          //  name:string
        }
    }
}

/*interface ACOExportFwdData{
    extId: string,
    data:{
        flipNumber:number,
        phoneNumber:string,
        label:string,
        type:string
    }
}*/
interface ACOExportNonTwinData{
    extId: string,
    ringCount: number
}

interface ACOExportHGUserData{
    extn: number,
    data:{
        contact:
            {
                firstName:string,
                email:string
            },
        extensionNumber:number,
        type: string ,
        site:{
            id:string
        }
    },
    membersData: {
        addedExtensionIds: any
    }
}

interface ACOExportPickupMembersData{
    extn:string,
    pickupMembers : any
}

interface ACOExportPGUserData{
    ipoExtn: number,
    data:{
        contact:
        {
            firstName:string
        },
        type: string ,
        site:{
            id:string
        }
    },
    membersData: {
        addedDeviceIds: any
    }
}

interface SteeringCodeData{
    Steering_Code: string,
    SC_Telephone: string,
    FileName:string
}

interface BCAData {
    Secretary_Extn:string,
    Secretary_Name:string,
    Secretary_Full_Name:string,
    Owners : {
        Owner_GUID:string,
        Ring_Delay:string,
    }[],
}

interface UserAppearanceData {
    User_Extn:string,
    User_Name:string,
    User_Full_Name:string,
    User_GUID:string,
    Appearances:number
}

interface delegatedGroupData {
    Owner_Extn:string,
    Owner_Name:string,
    Owner_FullName:string,
    Appearances:number,
    Delegates: {
        Delegate_Extn:string,
        Delegate_Name:string,
        Delegate_Ring_Delay:number
    }[],
}

interface parkLocationData {
    groupExtension:string,
    groupName:string,
    members:string[]
}

interface warningReports
{
   userData:{
           basicInfo:{
            errors: any,
            warnings: any,
         },
           advancedInfo:{
            warnings: string,
         },
        },
    systemData:{
          hgWarning:{
            warnings: string,
         },
          pgWarning:{
            warnings: string,
         },
         plWarning:{
            warnings: string,
         },
          aaWarning:{
            warnings: string,
         },
          tpWarning:{
            warnings: string,
         },
         icrWarning:{
            warnings: string,
         },
        }, 
}  
interface HGWarningData{
    missingHGAnnList:any,
    unsupportedHGAnnList:any,
    chainedAnnHGList:any,
    annUnCheckedHGList:any,
    hgVMMissingList:any,
    hgVMComplexList:any
}

export enum autoAssignName {
    Fname_Lname,
    Lname_Fname,
    Fname,
    Lname
}


export enum autoAssignPhone{
    PhoneType,
    UserType,
    SysmonType
}

export enum phoneStatus{
    unassigned,
    assigned,
}

export enum AutoAssignmentConfig{
    email,
    phoneAssignment,
    NameAssignment,
    Fname,
    Lname,
    All
}

enum TwinRule{
    default,
    TPForTwinNumberOnly,
    TPForPrimaryNumerOnly,
    TPForBoth
}

export enum SEVERITY{
    WARNING,
    ERROR, 
    INFO
}

export const PAGINATION_SIZE = 10;

const REJECT = {
    INVALID_NAME_ASSIGNMENT: "Invalid choice selected for name assignment",
    INVALID_PHONE_ASSIGNMENT_CHOICE: "Invalid choice selected for phone assignment",
    NO_TABLE_DATA: "Empty table data",
    INVALID_IPO_PHONE_MODEL: "Selected IP Office phone model unavailable",
    INVALID_ACO_PHONE_MODEL: "Selected ACO phone Type unavailable",
    INVALID_IPO_USER_TYPE: "Selected IP Office license type unavailable",
    PHONE_ASSIGNMENT_FAILED: "Unable to complete phone assignment",
    REMOVE_PHONE_INVALID_EXTENSION: "Extension unavailable to change phone model",
    ASSIGN_PHONE_INVALID_EXTENSION: "Extension unavailable to assign new phone model",
    ASSIGN_PHONE_INVALID_MODEL: "Selected new phone model unavailable",
    INCOMPLETE_PHONE_ASSIGNMENT: "Phone auto assignment partially completed"
};

const PHONE = {
    UNASSIGN: "unassign",
    UNASSIGNED: "unassigned",
    MESSAGE_ONLY : "message-only"
}

export var assignedExtnList:any = [];
export var assignedEmailList: any= [];
@Injectable({
    providedIn: 'root'
})
export class TablePreviewService {    

    private _tableUserList: tablePreviewData[] = [];
    private _tableTimeProfileList: tableTimeProfileData[] = [];
    private _tableHuntGroupList: tableHuntGroupData[] = [];
    private _tableAutoAttendantList: tableAutoAttendantData[] = []; 
    private _tableParkLocationList = [];
    private _parkLocDataStatus = new BehaviorSubject<string>(TRIGGER_STATUS.UNKNOWN);
    private _parkLocationExtEmptyUserList: Set<String>;
    private _parkLocationWarningsList: any = [];
    private _ipoUserList: IPOUserData[] = [];
    private _ipoUsersByPhone = [];
    private _ipoUsersByProfile = [];
    private _extnsMap: ExtensionMap[] = [];
    private _acoExportUserList: ACOExportUserData[] = [];
    private _acoExportNonTwinUserList: ACOExportNonTwinData[] = [];
    private _acoExportHGUserList: ACOExportHGUserData[] = [];
    private _acoExportPickupMemberList : ACOExportPickupMembersData[] = [];
    private _acoExportUserBHourList = [];
    private _acoExtnByPhoneGroup = [];
    private _pageCapableDeviceList = [];
    private _acoExportFwdList = [];
    private _cardId: string;
    private _timeZoneList = [];
    private _extnBLFList = [];
    private _extncontactList = [];
    private _extnPresenceTobeSet = [];
    private _taskChainList = [];
    private _acoExportPGUserList: ACOExportPGUserData[] = [];
    private _tableIncomingNumbersList: incomingNumbers[] = [];
    private _originaltableIncomingNumbersList = [];
    private _assignedEmailList = [];
    private _assignedExtnList = [];
    public _modifiedCompanyNumberAssignementCount;
    private _originalICRData;
    private _modifiedICRData;
    private _tableIncomingNumbersListCount ;
    private _zeroDialDestnation = [];
    public _comPhData;
    private collectiveCallWaiting = [];
    private emptyCollectiveCallWaiting = [];
    private pickupMemberswithExceededLimit = [];
    private cqMemberLimitExceeded = [];
    public _messageOnlyextnList = [];
    //DB data
    private _acoImportData: any = [];
    private _ipoCsvData: any = [];
    private _ipoTimeProfile: any = [];
    private _ipoHuntGroup: any = [];
    private _advncdUserInfoWarningsList: any = [];
    private _pagingGroupsWarningsList: any = [];
    private _huntGroupWarningsList: any = [];
    private _deletedUsersList: tablePreviewData[] = [];
    private _deletedICRList: incomingNumbers[] = [];
    private countryName;
    private _emailGroup: any;
    private _invalidEmailList: any = [];
    private messageOnlyWithBLF = [];
    private messageOnlywithCustomHours = [];
    public _notifications: any = [];
    private _tableDataInitialized = new BehaviorSubject<boolean>(false);
    private _isTableDataInitializedFlag = false;
    private _sysmonDataIntialized = new Subject<boolean>();
    public _isSysmonDataInitializedFlag = false;
    public isSysmonFileUploaded = false;
    private _isSysmonAutoAssigmentInProgress = false;
    private _isInternationalCallingEnabled = false;
    private _acoServiceEdition:any;
    private _greetingsDocStatus = new BehaviorSubject<string>(TRIGGER_STATUS.UNKNOWN);
    private _sysmonDocStatus = new BehaviorSubject<string>(TRIGGER_STATUS.UNKNOWN);
    private _cardClosureStatus = new Subject<boolean>();
    private _acoPhData:any = [];
    private _acoTimeZone:any;
    private _acoCountry:any;
    private _siteCountryMap;
    private _siteTimeZoneMap;
    private AAList= [];
    private steeringCodes: SteeringCodeData[] = [];
    private bcaConfigData: BCAData[] = [];
    private appearanceData: UserAppearancesConfig[] = [];
    public reservedExtensionsList = [];
    private _sysmonData:any;
    private _duplicateSysmonData:any;
    private _invalidSysmonData:any;
    private _deviceExtList:any;
    private _aaMenuData:any;
    private _vmGreetingsData:any;
    private _vmproAAParsedFlows:any;
    private _hgWarnObj:HGWarningData;
    private _invalidTimeProfileList:any = [];
    private _invalidTwinTimeProfileList = new Map();
    private invalidAnsRuleTimeProfilesList:any = []
    private _ringCountExceededExtensionmap = new Map();
    private _didNotConfiguredTwinList = [];
    private _zeroDialHGtoPgData;
    private _ipoUserHGSourceNumberList:any = [];
    private _aaMenuKeysData:any;
    public _messageOnlyExtns = [];
    public _userCount = [];
    private messageOnlyTwinning;
    private _cqMemberLimit;
    public _originalMsgOnlyExtns = [];
    private _zeroDialData = [];
    private _deletedUserZeroDialData = [];
    private _isMultiSiteMigration = false;
    private sites;
    private siteSpecificNotifications = new Map();
    private cqsiteSpecificNotifications = new Map();
    private pgsiteSpecificNotifications = new Map();
    private advInfositeSpecificNotifications = new Map();
    private aasiteSpecificNotifications = new Map();
    private tpsitespecificNotifications = new Map();
    private icrsiteSpecificNotifications = new Map();
    private basicInfoWarning = [];
    private basicInfoError = [];
    private mixedTimeProfileList;
    private _idx = 0;
    private userRestrictionData :any;
    // All write to DB to be done through Firestore service
    // All read for the session will be done through migrationdata service
    constructor(private firestoreService: FirestoreService,private fileUploader: FileUploadService,
        private logger:LogService,private ngzone: NgZone, private migrationDataService:MigrationDataService) {
        this._tableUserList = [];
        this._tableTimeProfileList = [];
        this._tableIncomingNumbersListCount = 0;
        this.firestoreService.getGreetingsDataAvlblMsg().subscribe((data)=>{
            this.updateTableData(data);
        });
        this.firestoreService.getSysmonDataAvlblMsg().subscribe((data) => {
            this.getTableDataInitializedObs().subscribe((res) => {
                if (this._isTableDataInitializedFlag && !this._isSysmonDataInitializedFlag) {
                    this.autoAssignSysmonPhoneModel(data)
                    .then(() => {
                        this.informSysmonInitiaization();
                    });
                } else {
                    this.informSysmonInitiaization();
                }
            })
        });
    }

    public setInvalidTimeProfileList(value: any) {
        this._invalidTimeProfileList = value;
    }

    get invalidTimeProfileList(): any {
        return this._invalidTimeProfileList;
    }

    get tableUserList(): tablePreviewData[] {
        return this._tableUserList;
    }

    set tableUserList(value: tablePreviewData[]) {
        this._tableUserList = value;
    }

    get tableTimeProfileList(): tableTimeProfileData[] {
        return this._tableTimeProfileList;
    }

    set tableTimeProfileList(value: tableTimeProfileData[]) {
        this._tableTimeProfileList = value;
    }

    get tableHuntGroupList(): tableHuntGroupData[] {
        return this._tableHuntGroupList;
    }

    set tableHuntGroupList(value: tableHuntGroupData[]) {
        this._tableHuntGroupList = value;
    }
    
    get tableAutoAttendantList(): tableAutoAttendantData[] {
        return this._tableAutoAttendantList;
    }

    set tableAutoAttendantList(value: tableAutoAttendantData[]) {
        this._tableAutoAttendantList = value;
    }

    get idx(): number {
        return this._idx;
    }

    set idx(value: number) {
        this._idx = value;
    }

    get tableParkLocationList(): parkLocationData[] {
        return this._tableParkLocationList;
    }

    set tableParkLocationList(value: parkLocationData[]) {
        this._tableParkLocationList = value;
    }

    public getParkLocDataStatus(): Observable<string> {
        return this._parkLocDataStatus.asObservable();
    }

    public sendParkLocDataStatus(data) {
        this._parkLocDataStatus.next(data);
    }

    get parkLocationExtEmptyUserList():any {
        return this._parkLocationExtEmptyUserList;
    }

    set parkLocationExtEmptyUserList(value:any) {
        this._parkLocationExtEmptyUserList = value;
    }

    get ipoUserList(): IPOUserData[] {
        return this._ipoUserList;
    }
    get acoPhData(): any{
        return this._acoPhData;
    }

    get steeringCodeData(): SteeringCodeData[] {
        return this.steeringCodes;
    }

    get BCAData(): BCAData[] {
        return this.bcaConfigData;
    }

    set ipoUserList(value: IPOUserData[]) {
        this._ipoUserList = value;
    }

    get acoExtnByPhoneGroup(): any {
        return this._acoExtnByPhoneGroup;
    }

    get ipoUsersByPhoneModel(): any {
        return this._ipoUsersByPhone;
    }

    get acoImportData():any {
        return this._acoImportData;
    }
    get ipoUsersByProfile(): any {
        return this._ipoUsersByProfile;
    }

    get AcoExportData(): ACOExportUserData[] {
        return this._acoExportUserList;
    }

    get AcoExportNonTwinUserList(): ACOExportNonTwinData[] {
        return this._acoExportNonTwinUserList;
    }

    get ACOExportFwdData(): any[]{// ACOExportFwdData[] {
        return this._acoExportFwdList;
    }

    get notificationsList(): any {
        return this._notifications;
    }

    public get parkLocationWarningsList(): any {
        return this._parkLocationWarningsList;
    }

    public get advncdUserInfoWarningsList(): any {
        return this._advncdUserInfoWarningsList;
    }

    get timeProfilesWarningsList(): any {
        return this.tpsitespecificNotifications;
    }

    get autoAttendantsWarningsList(): any{
        return this.aasiteSpecificNotifications;
    }

    public get icrAADestinationNotFoundWarningList(): any{
        return this.icrsiteSpecificNotifications;
    }

    get huntGroupWarningsList(): any{
        return this._huntGroupWarningsList;
    }

    get pagingGroupsWarningsList(): any{
        return this._pagingGroupsWarningsList;
    }

    get emailGroup(): any {
        return this._emailGroup;
    }

    get invalidEmailList(): any {
        return this._invalidEmailList;
    }

    get assignedEmailList(): any {
        return this._assignedEmailList;
    }

    get assignedExtnList(): any {
        return this._assignedExtnList;
    }

    public get incomingNumbers(): any {
        return this._tableIncomingNumbersList;
    }
    public set incomingNumbers(value: any){
        this._tableIncomingNumbersList = value;
    }
    public get incomingNumbersCount(): any {
        return this._tableIncomingNumbersListCount;
    }
    public set incomingNumbersCount(value: any){
        this._tableIncomingNumbersListCount= value;
    }
    public set parkLocationWarningsList(value: any) {
        this._parkLocationWarningsList = value;
    }
    public set advncdUserInfoWarningsList(value: any){
        this._advncdUserInfoWarningsList = value;
    }
    public set icrAADestinationNotFoundWarningList(value: any) {
        this.icrsiteSpecificNotifications = value;
    }
    public set messageOnlyList(value: any)
    {
        this._messageOnlyExtns = value;
    }
    public get  messageOnlyList(): any
    {
        return this._messageOnlyExtns;
    }
    public set originalmessageOnlyList(value: any)
    {
        this._originalMsgOnlyExtns = value;
    }
    public get  originalmessageOnlyList(): any
    {
        return this._originalMsgOnlyExtns;
    }
    public set userCount(value: any)
    {
        this._userCount = value;
    }
    public get  userCount(): any
    {
        return this._userCount;
    }
    public didNotConfiguredTwinList():any {
        return this._didNotConfiguredTwinList;
    }

    private getExtensionGroupByPhoneModel(){
        var extensionByPhone = { phoneModel: "", count:0, extensionList: []};
        return extensionByPhone;
    }

    private getExtensionGroupByProfile(){
        var extensionByProfile = { profileType: "", count:0, extensionList: []};
        return extensionByProfile;
    }

    private getExtnMapTemplate(){
        let extnDetail = { 
            extId: "",
            extn: 0,
            name: "",
            tzOffset: "",
            type: EXTNTYPE.USEREXTN
        }
        return extnDetail;
    }

    private getAcoExportDataTemplate(){
        var userExtn = { extId: "",
            data:{
                contact:{
                    firstName: "",
                    lastName: "",
                    email: "",
                    emailAsLoginName:true
                },
                regionalSettings:{
                    timezone:{
                        id:""
                    }
                },
                status: "Disabled",
                transition:{
                    sendWelcomeEmailsToUsers:false,
                    sendWelcomeEmail:false
                },
                extensionNumber: 0,
                site:{
                    id: ""
                }
            }
        }
        return userExtn;
    }

    private getAcoUserPersonalContactTemplate(){
        let userContact = { 
            extId: "",
            data:{
                records: [],
            }            
        } 
        return  userContact;
    } 

    private getACOExportPickupMembersData() {
        let pickupdata = {
            extn:"",
            pickupMembers: []
        }
    return pickupdata;
    }
    private getAcoUserHuntGroupTemplate(){
        let hgUser = {
            extn: 0,
            data:{
                contact:
                    {
                        firstName:"",
                        email: ""
                    },
                extensionNumber:0,
                type: "Department",
                site:{
                    id:""
                }
            },
            membersData: {
                addedExtensionIds:[]
            },
            uncheckedMembersData: {
                extensionIds:[]
            },
            site: {
                id:"",
                name:""
            }
        }
        return hgUser;
    }

    private getBLFDataTemplate(){
        let userBLF = {
            data:{
                id: "",
                extension:{
                    id: ""
                }
            }
        }
        return userBLF;
    }

    private getCallForwardTwinUserDataTemplate(){
        var callFwdData = { 
            extId: "",
            data:{
                "flipNumber":2,
                "phoneNumber":"",
                "label":"Twin",
                "type":""
            },
            twinRule: TwinRule.default,
            twinTProfileLabel : "",
            ringCount : DEFAULT_RING_COUNT
        }
        return callFwdData;
    }

    private getPagingGroupDataTemplate():ACOExportPGUserData{
        let pagingGroup = {
            ipoExtn: 0,
            data:{
                contact:
                    {
                        firstName:""
                    },
                extensionNumber:0,
                type: "PagingOnly",
                site:{
                    id:""
                }
            },
            membersData: {
                addedDeviceIds:[]
            }
        }
        return pagingGroup;
    }

    private getWarningReportTemplete():warningReports{
        let report = {
            userData:{
                basicInfo: {
                    errors: "",
                    warnings:"",
                 },
                advancedInfo:{
                    warnings: "",
                 },
             },
         systemData:{
               hgWarning:{
                warnings: "",
                },
               pgWarning:{
                warnings: "",
                },
               plWarning:{
                warnings: "",   
               },
               aaWarning:{
                warnings: "",
                },
               tpWarning:{
                warnings: "",
                },
               icrWarning:{
                warnings: "",
                },
             },  
        }
        return report;
    }
    private getHGWarnTemplate():HGWarningData {
        let obj = {
            missingHGAnnList:[],
            unsupportedHGAnnList:[],
            chainedAnnHGList:[],
            annUnCheckedHGList:[],
            hgVMMissingList:[],
            hgVMComplexList:[]
        }
        return obj;
    }

    private notificationTemplate(){
        let obj = {
            siteName : "",
            Notfications : []
        }
    }

    private fillPagingDevList(){
        this._pageCapableDeviceList = [];
        let pagingDev = this._acoImportData['paging_dev'];
        try{
            if(pagingDev != undefined)
                this._pageCapableDeviceList = JSON.parse(pagingDev);
        }catch(error){
            this.logger.error("Error while parsing ACO Import paging group data: ",error);
        }
        
    }

    private fillIPOUsersByPhoneAndProfile(){
        this.logger.info("fillIPOUsersByPhoneAndProfile");
        var phModelGroup: any;
        var ProfileGroup: any;
        this._ipoUsersByPhone = [];
        this._ipoUsersByProfile = [];

        for(let i=0;i<this._tableUserList.length;i++){
           if(!(this._messageOnlyExtns.includes(JSON.stringify(this._tableUserList[i].extension))))
           {
                if(this._tableUserList[i].acoPhoneStatus === phoneStatus.assigned){
                //this.logger.debug("Already assigned phone - ignore ", this._tableUserList[i].extension);
                    continue;
                }

            //this.logger.debug("Not assigned, extn ", this._tableUserList[i].extension);

            //Fill _ipoUsersByPhone
                phModelGroup = this._ipoUsersByPhone.find(entry => entry.phoneModel === this._tableUserList[i].ipoPhoneModel);
                if (phModelGroup) {
                    phModelGroup.extensionList.push(this._tableUserList[i].extension);
                    phModelGroup.count++;
                }
                else{
                    phModelGroup = this.getExtensionGroupByPhoneModel();
                    phModelGroup.extensionList.push(this._tableUserList[i].extension);
                    phModelGroup.phoneModel =  this._tableUserList[i].ipoPhoneModel;
                    phModelGroup.count++;
                    this._ipoUsersByPhone.push(phModelGroup);
                }

            //Fill _ipoUsersByProfile
                ProfileGroup = this._ipoUsersByProfile.find(entry => entry.profileType === this._tableUserList[i].profileType);
                if (ProfileGroup) {
                    ProfileGroup.extensionList.push(this._tableUserList[i].extension);
                    ProfileGroup.count++;
                }
                else{
                    ProfileGroup = this.getExtensionGroupByProfile();
                    ProfileGroup.extensionList.push(this._tableUserList[i].extension);
                    ProfileGroup.profileType =  this._tableUserList[i].profileType;
                    ProfileGroup.count++;
                    this._ipoUsersByProfile.push(ProfileGroup);
                }
            }
        }
        this.logger.debug("Updated list of ipo users by phone and profile");
/*         this.logger.debug("_ipoUsersByPhone: ", this._ipoUsersByPhone);
        this.logger.debug("_ipoUsersByProfile: ", this._ipoUsersByProfile); */
    }

    private fillACOUsersByPhone(){
        this.logger.info("fillACOUsersByPhone");
        var acoImportData = this._acoImportData;
        this._acoExtnByPhoneGroup = [];
        this._acoExtnByPhoneGroup = JSON.parse(acoImportData['extns_by_phone']);       

        this._tableUserList.forEach(element =>{
            if(element.phone !== "message-only")
           {
            if(element.acoPhoneStatus === phoneStatus.assigned){
               // this.logger.debug("fillACOUsersByPhone - assigned extension: ", element.extension);
                var matchPhoneGroup = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === element.acoPhoneModel);
                if(matchPhoneGroup){
                    var index = matchPhoneGroup.extensionList.indexOf(element.extensionId);
                    if (index !== NOT_FOUND){
                        //this.logger.debug("MATCH found in aco extension by phone group");
                        matchPhoneGroup.extensionList.splice(index, 1);
                        matchPhoneGroup.count--;
                    }
                }
                //this.updateLimExtnList(element);
                if (element.twinMob.length !== 0 && element.did.length === 0 && !this._didNotConfiguredTwinList.includes(element.extension)) {
                    this.pushDidNotConfiguredTwinList(element.siteName, element.extension);
                }
            }
         }
        else
         {
             if(element.twinMob.length !== 0)
             {
                this.messageOnlyTwinning.push(element.username);
                if(element.did.length !== 0 && !this._didNotConfiguredTwinList.includes(element.extension))
                {
                    this.pushDidNotConfiguredTwinList(element.siteName, element.extension);
                }
             }
         }
        })
    
        this.updateLimExtnData();
        this.updateMOEWarningData();
        //this.logger.debug("_acoExtnByPhoneGroup: ", this._acoExtnByPhoneGroup);
    }

    private pushDidNotConfiguredTwinList(siteName, extension)
    {
        let didNotConfiguredTwinEntry = {} as any;
        didNotConfiguredTwinEntry.siteName = siteName;
        didNotConfiguredTwinEntry.extension = extension;
        this._didNotConfiguredTwinList.push(didNotConfiguredTwinEntry);
    }

    public getCompanyPhNumbers()
    {
        var promise = new Promise((resolve, reject) => {   
        let tempArr = [];
        this.firestoreService.readComPhNumberData(this._cardId)
        .then((comPhData:any) => {
            this._comPhData= (comPhData && comPhData.data)?JSON.parse(comPhData.data):[];  
            this._comPhData.forEach(element => {
                tempArr.push(element.phoneNumber);
            });
            this._acoPhData = [...new Set(tempArr)]
            resolve();
        })
        .catch((error) => {
            this.logger.error(error);
            reject(error);
        });
    })
    return promise;
    }

    public async fillSteeringCodesData() {
        let tempArr = [];
        await this.firestoreService.readSteeringCodeData(this._cardId)
        .then((steeringCodes:any) => {
            let steeringCodearray = (steeringCodes && steeringCodes.data)?JSON.parse(steeringCodes.data):[];  
            steeringCodearray.forEach(element => {
                let SteeringCode = {
                    Steering_Code: element.Steering_Code,
                    SC_Telephone: element.SC_Telephone,
                    FileName: element.filename
                }
                //tempArr.push(element);
                tempArr.push(SteeringCode);
            });
            this.steeringCodes = [...new Set(tempArr)];
        })
    }

    private async prepareDLGData() {
        let bcaArray = [];
        let appearanceArray = [];
        let dlgGroupData: delegatedGroupData[] = [];

        await this.firestoreService.readBCAData(this._cardId)
        .then((bca:any) => {
            bcaArray = (bca && bca.data) ? JSON.parse(bca.data):[];
        });

        await this.firestoreService.readUserAppearanceData(this._cardId)
        .then((appearanceData:any) => {
            appearanceArray = (appearanceData && appearanceData.data) ? JSON.parse(appearanceData.data):[];
        });

        if ((bcaArray !== undefined) && (bcaArray.length > 0) && (appearanceArray !== undefined) && (appearanceArray.length > 0)) {
            
            appearanceArray.forEach((appearanceEntry) => {
                let dlgGroupEntry = null;
                bcaArray.forEach((bcaEntry) => {
                    let isOwner = bcaEntry.Owners.find(owner => owner.Owner_GUID == appearanceEntry.User_GUID);
                    if (isOwner) {
                        if (dlgGroupEntry === null) {
                            dlgGroupEntry = {
                                Owner_Extn: appearanceEntry.User_Extn,
                                Owner_Name: appearanceEntry.User_Name,
                                Owner_FullName: appearanceEntry.User_Full_Name,
                                Appearances: appearanceEntry.Appearances
                            }
                            dlgGroupEntry.Delegates = [];
                            
                            let delegatedGroup = {
                                Delegate_Extn: bcaEntry.Secretary_Extn,
                                Delegate_Name: bcaEntry.Secretary_Name,
                                Delegate_Ring_Delay: isOwner.Ring_Delay
                            }
                            dlgGroupEntry.Delegates.push(delegatedGroup);
                        } else {
                            let delegatedGroup = {
                                Delegate_Extn: bcaEntry.Secretary_Extn,
                                Delegate_Name: bcaEntry.Secretary_Name,
                                Delegate_Ring_Delay: isOwner.Ring_Delay
                            }
                            dlgGroupEntry.Delegates.push(delegatedGroup);
                        }
                    }
                });
                if (dlgGroupEntry !== null) {
                    dlgGroupData.push(dlgGroupEntry);
                }
            });
        } else {
            this.logger.debug("Bridged call appearance data is empty");
        }
        this.firestoreService.setDLGData(this._cardId, dlgGroupData)
        .then(() => {
            this.logger.debug("Delegated group data updated");
        })
    }

    private prepareParkLocationData() {
        let parkLocationExts = new Set();
        this._parkLocationExtEmptyUserList = new Set();
        this._tableParkLocationList = [];
        let duplicateExtnList = new Set();
        let resExtnList = new Set();
        this.sendParkLocDataStatus(false);
        this._parkLocationWarningsList = [];

        let skippedMessageOnlyUserExtns = new Map();
        let skippedLimitedExtUserExtns = new Map();

        var promise = new Promise((resolve, reject) => {
            this.downloadFileFromBucket(`Greetings/${this._cardId}/AcoExportCfg/userCallPark.json`)
                .then((doc: any) => {
                    let userCallParkData = doc !== null ? doc.data : [];
                    userCallParkData.map((entry) => {
                        if (entry !== null && entry !== undefined && entry.userExtension !== undefined && entry.callParks !== undefined && entry.callParks.length > 0) {
                            let matchedUser = this.tableUserList.find((user) => user.extension === parseInt(entry.userExtension));

                            //Check if user is a limited extension user - Limited extension users cannot be configured as park location members
                            let isLimitedExtn = false;
                            if (matchedUser && matchedUser.acoPhoneModel) {
                                isLimitedExtn = this.isLimitedExtn(matchedUser.acoPhoneModel);
                            }

                            //Skip Message-Only Users since they cannot be configured as park-location members
                            if (matchedUser && this.isUserNotDeleted(matchedUser.extension)) {
                                entry.callParks.map((callPark) => {
                                    if (matchedUser.phone !== "message-only" && !isLimitedExtn) {
                                        if (callPark.groupExtension !== undefined && callPark.groupExtension !== null && callPark.groupExtension.length > 0) {
                                            
                                            let groupExtension:any;
                                            if ((/^\d+$/.test(callPark.groupExtension))) {
                                                groupExtension = callPark.groupExtension;
                                            } else {
                                                let matchedUser = this._tableUserList.find((entry:any) => ((entry.username === callPark.groupExtension) || (entry.ipoUserName === callPark.groupExtension)));
                                                if (matchedUser) {
                                                    groupExtension = matchedUser.extension ? matchedUser.extension : "";
                                                }
                                            }
                                            
                                            if (parkLocationExts.has(groupExtension)) {
                                                let matchedRecord = this.tableParkLocationList.find((entry) => entry.groupExtension === groupExtension);
                                                matchedRecord.members.push(entry.userExtension);
                                                if ((matchedRecord.groupName === undefined || matchedRecord.groupName === null || matchedRecord.groupName.length === 0) &&
                                                    (callPark.groupName !== undefined && callPark.groupName !== null && callPark.groupName.length > 0)) {
                                                    matchedRecord.groupName = callPark.groupName;
                                                }
                                            } else {
                                                let parkLocationRecord: parkLocationData = {
                                                    groupExtension: groupExtension,
                                                    groupName: callPark.groupName,
                                                    members: []
                                                };
                                                parkLocationExts.add(groupExtension);
                                                parkLocationRecord.members.push(entry.userExtension);
                                                this._tableParkLocationList.push(parkLocationRecord);
                                            }
    
                                            //Handle existing extension in ACO
                                            if (this._assignedExtnList.includes(groupExtension)) {
                                                duplicateExtnList.add(groupExtension)
                                            }
    
                                            //Handle reserved numbers in ACO
                                            if (this.reservedExtensionsList.includes(parseInt(groupExtension))) {
                                                resExtnList.add(groupExtension);
                                            }
                                        } else {
                                            this._parkLocationExtEmptyUserList.add(entry.userExtension);
                                        }
                                    
                                    } else {
                                        //Filter out message-only users
                                        if (matchedUser.phone === "message-only") {
                                            if (skippedMessageOnlyUserExtns.has(callPark.groupExtension)) {
                                                let value = skippedMessageOnlyUserExtns.get(callPark.groupExtension);
                                                if (!value.includes(entry.userExtension)) {
                                                    value = [...value, entry.userExtension];
                                                    skippedMessageOnlyUserExtns.set(callPark.groupExtension, value);
                                                }
                                            } else {
                                                skippedMessageOnlyUserExtns.set(callPark.groupExtension, [entry.userExtension]);
                                            }
                                        }
                                        //Filter out limited extension users
                                        else if (isLimitedExtn) {
                                            if (skippedLimitedExtUserExtns.has(callPark.groupExtension)) {
                                                let value = skippedLimitedExtUserExtns.get(callPark.groupExtension);
                                                if (!value.includes(entry.userExtension)) {
                                                    value = [...value, entry.userExtension];
                                                    skippedLimitedExtUserExtns.set(callPark.groupExtension, value);
                                                }
                                            } else {
                                                skippedLimitedExtUserExtns.set(callPark.groupExtension, [entry.userExtension]);
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    });

                    //Collect HG extensions in current migration
                    let hgExtensions = this._tableHuntGroupList.map((entry:any) => {
                        if (entry !== undefined && entry.extension !== undefined){
                            return entry.extension;
                        }
                    });

                    //Collect user extensions in current migration
                    let userExtensions = this.tableUserList.map((entry:any) => {
                        if (entry!== undefined && entry.extension !== undefined) {
                            return entry.extension;
                        }
                    });

                    this._tableParkLocationList.map((entry) => {
                        //Remove duplicate members from list
                        entry.members = [...new Set(entry.members)];

                        //Configuring groupName to Park Locations where label is not configured
                        if (entry.groupName === undefined || entry.groupName === null || entry.groupName.length === 0) {
                            entry.groupName = "Park Location " + entry.groupExtension;
                        }
                    });

                    //Remove park location groups that will not be listed in the page(park location configured only under message-only users or limited extension users).
                    skippedMessageOnlyUserExtns.forEach((value, groupExtension) => {
                        if (!parkLocationExts.has(groupExtension)) {
                            skippedMessageOnlyUserExtns.delete(groupExtension);
                        }
                    });

                    //Remove park location groups that will not be listed in the page(park location configured only under message-only users or limited extension users).
                    skippedLimitedExtUserExtns.forEach((value, groupExtension) => {
                        if (!parkLocationExts.has(groupExtension)) {
                            skippedLimitedExtUserExtns.delete(groupExtension);
                        }
                    });

                    //Park Location - Generic warning
                    if (this._tableParkLocationList.length > 0) {
                        this.parkLocationWarningsList.push(PARK_LOCATION.PARK_LOCATION_BTN_PRGM_NOT_CONFIGURABLE);
                    }
                   
                    let currMigrationDupExtnList = [];
                    //Check if call parks are configured with a duplicate extension in the current migration
                    this._tableParkLocationList.map((parkLoc) => {
                        if (userExtensions.includes(parseInt(parkLoc.groupExtension)) || hgExtensions.includes(parseInt(parkLoc.groupExtension))) {
                            currMigrationDupExtnList.push(parkLoc.groupExtension);
                        }
                    });
                    if (currMigrationDupExtnList.length > 0) {
                        this._parkLocationWarningsList.push(PARK_LOCATION.DUPLICATE_EXTENSION_PARK_LOCATION + currMigrationDupExtnList.join(", "));
                    }

                    //Park Location - Extensions already in user warning
                    if (duplicateExtnList.size > 0) {
                        this._parkLocationWarningsList.push(PARK_LOCATION.PARK_LOCATION_EXTENSION_EXISTS + [...duplicateExtnList].join(", "));
                    }

                    //Park Location - Reserved extensions warning
                    if (resExtnList.size > 0) {
                        this._parkLocationWarningsList.push(PARK_LOCATION.PARK_LOCATION_RESERVED_EXTENSION + [...resExtnList].join(", "));
                    }

                    //Park Location - Empty extension warning
                    if (this._parkLocationExtEmptyUserList.size > 0) {
                        this.parkLocationWarningsList.push(PARK_LOCATION.PARK_LOCATION_EXT_EMPTY + [...this._parkLocationExtEmptyUserList].join(", "));
                    }

                    //Park Location - Skipping message only users warning
                    if (skippedMessageOnlyUserExtns.size > 0) {
                        let keyValues = "";
                        skippedMessageOnlyUserExtns.forEach((value, key) => {
                            keyValues = keyValues + key + "(" + value + "), ";
                        });
                        keyValues = keyValues.substring(0, (keyValues.length-2));
                        this.parkLocationWarningsList.push(PARK_LOCATION.SKIP_MESSAGE_ONLY_PARK_LOCATION + keyValues);
                    }

                    //Park Location - Skipping limited extensions users warning
                    if (skippedLimitedExtUserExtns.size > 0) {
                        let keyValues = "";
                        skippedLimitedExtUserExtns.forEach((value, key) => {
                            keyValues = keyValues + key + "(" + value + "), "; 
                        });
                        keyValues = keyValues.substring(0, (keyValues.length-2));
                        this._parkLocationWarningsList.push(PARK_LOCATION.SKIP_LIMITED_EXTENSION_PARK_LOCATION + keyValues);
                    }

                    //Updating task chain with park location feature
                    if (this._tableParkLocationList.length > 0) {
                        this.updateFeatureTaskChain(FEATURE_NAME.PARK_LOCATION);
                    }

                    this.fileUploader.uploadJsonFile(this._cardId, '/IpoCfg/parkLocation.json', { data: this.tableParkLocationList, duplicateExtnList: [...duplicateExtnList], 
                        reservedExtnList: [...resExtnList], emptyParkLocConfigUserList: [...this._parkLocationExtEmptyUserList], skippedMessageOnlyUserExtns: Array.from(skippedMessageOnlyUserExtns.entries()), 
                        skippedLimitedExtensionUserExtns: Array.from(skippedLimitedExtUserExtns.entries()) })
                    .then(() => {
                        this.logger.debug("park location data saved successfully");
                    })
                    .catch((error) => {
                        reject(error);
                    });

                    this.sendParkLocDataStatus(true);
                    resolve();

                })
                .catch((error) => {
                    this.logger.debug("Exception while extracting park location data : ", error);
                    this.sendParkLocDataStatus(true);
                    resolve();
                });
        })
        return promise;
    }

    //One more function to skip all limited extension users and adding warning for the same.

    private getDID(extensionId){
        var matchFound = false;
        var result="";
        var acoImportData = this._acoImportData;
        //this.logger.debug("acoImportData: ", acoImportData);
        var dl_data = JSON.parse(acoImportData.dl_data);   
        //this.logger.debug("getDID: dl_data: ", dl_data);
        if(dl_data !== ""){  
            var dlData = [];
            dlData = dl_data.ua_exts;
            var user = dlData.find(entry => entry.extensionID === extensionId);
            if(user !== undefined && user.DL[0] !== "" && user.DL[0].DID !== ""){
                result = user.DL[0].DID.phoneNumber;
                matchFound = true;
            }
        }

        if(!matchFound){
            var did = JSON.parse(acoImportData.did_data);  
            if(did !== ""){
                var didData = [];
                didData = did.ua_exts;
                var user = didData.find(entry => entry.extensionID === extensionId);;   
                if(user !== undefined && user.DID !== "" && user.DID[0] !== ""){
                    result = user.DID[0].phoneNumber;
                    matchFound = true;
                }
            }
        }
        return result;
    }

    private isDevicePageCapable(extensionId){
        let pgDevEntry = this._pageCapableDeviceList.find(entry => entry.extensionId === extensionId);
        if(pgDevEntry !== undefined)
            return true;
        return false;
    }

    public async initializeTableData(cardId){
        this.logger.debug("initializeTableData : "+this._cardId);
        if(this._cardId === cardId && this._isTableDataInitializedFlag){
            this.logger.debug("skipping initialization, as table data is already initialized");
            return Promise.resolve();
        }
        this._extnsMap = [];
        this._tableUserList = [];
        this._taskChainList = [];
        this._tableTimeProfileList = [];
        this._tableHuntGroupList = [];
        this._tableAutoAttendantList = [];
        this._tableParkLocationList = [];
        this._parkLocationExtEmptyUserList = new Set();
        this._parkLocationWarningsList = [];
        this._acoExportUserList = [];
        this._acoExportUserBHourList = [];
        this._acoExportFwdList = [];
        this._acoExportHGUserList = [];
        this._acoExportPickupMemberList = [];
        this._acoExportPGUserList = [];
        this._emailGroup = {};
        this._cardId = cardId;
        this._huntGroupWarningsList = [];
        this._pagingGroupsWarningsList = [];
        this._advncdUserInfoWarningsList = [];
        this._assignedExtnList = [];
        this._assignedEmailList =[];
        this._acoTimeZone = null;
        this._siteTimeZoneMap = new Map();
        this._siteCountryMap = new Map();
        let ExtnDetails = [];
        let mailDetails = [];
        this._didNotConfiguredTwinList = [];
        var extnData;
        this._tableIncomingNumbersListCount = 0;
        this._tableDataInitialized = new BehaviorSubject<boolean>(false);
        this._isTableDataInitializedFlag = false;
        this._sysmonDataIntialized = new Subject<boolean>();
        this._isSysmonDataInitializedFlag = false;
        this.isSysmonFileUploaded = false;
        this._deletedUsersList = [];
        this._ipoUserHGSourceNumberList = [];
        this._hgWarnObj = this.getHGWarnTemplate();
        this._aaMenuData = [];
        this._sysmonData = [];
        this._duplicateSysmonData = [];
        this._invalidSysmonData = [];
        this._deviceExtList = [];
        this._aaMenuKeysData = [];
        this.invalidAnsRuleTimeProfilesList = [];
        this.collectiveCallWaiting = [];
        this.emptyCollectiveCallWaiting = [];
        this.pickupMemberswithExceededLimit = [];
        this.cqMemberLimitExceeded = [];
        this._messageOnlyExtns = [];
        this.messageOnlyTwinning = [];
        this._originalMsgOnlyExtns = [];
        this._zeroDialData = [];
        this._isMultiSiteMigration = false;
        this.sites = [];
        this._deletedUserZeroDialData = [];
        //this._dtmfDestination = "";
        //this._dtmfExtension = "";
        this._vmGreetingsData = await this.downloadFileFromBucket(`Greetings/${cardId}/GreetingsCfg/userGreetings.json`);
        this._vmproAAParsedFlows = await this.downloadFileFromBucket(`Greetings/${this._cardId}/GreetingsCfg/vmproAAParsedFlows.json`);
        var promise = new Promise((resolve, reject) => {

            //Time profiles data needs to be extracted first, since some warnings needs to be built based on time profiles data
            this.downloadFileFromBucket(`Greetings/${cardId}/AcoExportCfg/timeProfiles.json`)
                .then((doc:any)=>{
                    this._ipoTimeProfile = (doc !== null)? doc.data: [] ;
                    var notifyMsg;
                     this.mixedTimeProfileList = [];
                    this._invalidTimeProfileList = (doc !== null) ? doc.invalidTimeProfiles : [];
                    //this.setInvalidTimeProfileList((doc !== null) ? doc.invalidTimeProfiles : []);
                    this._ipoTimeProfile.map(entry=>{
                        let timeProfEntry:tableTimeProfileData = {} as any;
                        timeProfEntry.siteName = entry.Sitename;
                        timeProfEntry.name = entry.name;
                        timeProfEntry.calendar =  entry.profiles.data.schedule.ranges?true:false;
                        timeProfEntry.recurrence = entry.profiles.data.schedule.weeklyRanges?true:false;
                        this._tableTimeProfileList.push(timeProfEntry);
                        if(timeProfEntry.calendar && timeProfEntry.recurrence){
                           this.mixedTimeProfileList.push(timeProfEntry.name) 
                        }
                    });  
                    this.logger.debug("completed Working Hours");
                    return;
                })
                .catch((error) => {
                    return;
                });

            this.firestoreService.readSysmonData(this._cardId)
                .then((sysmonData: any) => {
                    if (sysmonData !== undefined && sysmonData.trigger !== TRIGGER_STATUS.NOTYET) {
                        this.downloadFileFromBucket(`Greetings/${this._cardId}/IpoCfg/sysmon.json`)
                            .then((sysmonData: any) => {
                                if (sysmonData !== undefined) {
                                    this._sysmonData = sysmonData.data;
                                    this._invalidSysmonData = sysmonData.invaliUserEntries;
                                    this._duplicateSysmonData = sysmonData.duplicateUserEntries;
                                    if(this._sysmonData.length > 0) {
                                        this.isSysmonFileUploaded = true;
                                    }
                                }
                            }).catch(err => {
                                this.logger.debug("Sysmon file not found : ", err)
                                return;
                            });
                    }
                })
            
            //deviceExtList needs to be initialized irrespective of sysmon file is uploaded or not since mac Id needs to be assigned while auto assigning phone models. 
            this.firestoreService.fetchDeviceExtList(this._cardId)
            .then((deviceExtList:any) => {
                if (deviceExtList !== undefined && Object.keys(JSON.parse(deviceExtList.data)).length > 0) {
                    this._deviceExtList = JSON.parse(deviceExtList.data);
                }
            }).catch(err =>{
                this.logger.debug("Device extension list not found : ", err)
                return;
            });
            this.getCompanyPhNumbers();
            this.firestoreService.getAssignedExtensions(cardId)
            .then((doc:any) => {                
                extnData = doc.data;
                ExtnDetails  = JSON.parse(extnData);
                ExtnDetails.forEach(extnId => {
                    this._assignedExtnList.push(extnId.extensionNumber);
                    mailDetails.push(extnId.contact);
                })
                mailDetails.forEach(mail => {
                    this._assignedEmailList.push(mail.email);
                });
                
            })
            .catch((error) => {
                this.logger.error(error);
                reject(error);
            });
            this.firestoreService.getMessageOnlyData(cardId)
            .then((doc:any) => {                
               this._messageOnlyExtns = doc.extensions;
               this._originalMsgOnlyExtns = doc.extensions;
            })
            .catch((error) => {
                this.logger.error(error);
                reject(error);
            });
            
            let ignoredUsersList,partialUsersList;
            let aaComplexCallFlowList, isRootVmpFileFound = false, aaTimeoutList, conferenceMeetMeList;
            this.firestoreService.getFeaturePrerequisite(cardId)
            .then((result:any) => {
                if(typeof result !== 'undefined' && typeof result.timeZoneList !== 'undefined'
                && result.timeZoneList !== ''){
                    this._timeZoneList = JSON.parse(result.timeZoneList);
                }
                /* return this.firestoreService.readIPOCfgData(cardId); */
                return this.downloadFileFromBucket(`Greetings/${cardId}/IpoCfg/csv.json`);
            })
            .then((result:any) => {
                this._ipoUserList = result['IPOUsers'];
                this.logger.debug("IPO Users list length: ", this._ipoUserList.length);
                let userButtonProgrammingList = [];
                this._ipoUserList.map((entry:any) => {
                    if (entry.US_DialInSourceNumbers !== undefined && entry.US_DialInSourceNumbers !== ''){
                        let foundHSource = entry.US_DialInSourceNumbers.toString().split('|');
                        foundHSource = (foundHSource !== undefined)?foundHSource.find((el:any) => (el.toString().startsWith('H'))):undefined;
                        if (foundHSource !== undefined) this._ipoUserHGSourceNumberList.push(entry);
                    }
                    let action,username,buttonNumber,actionData,shortCodesList = [],key,featureMapping , migrationFlag;
                    username = entry.US_Name;
                    entry.Button_Programming.forEach (ButtonList =>{
                        let label = "";
                        key = ButtonList.SC_ShortCode.split("*DSS");
                        if(key.length >= 1 && key[1] !== undefined && key !== undefined)
                        {
                            buttonNumber = key[1];
                            if(ButtonList.SC_Telephone !== "")  
                            {
                                let splitData = ButtonList.SC_Telephone.split("/");
                                if(ButtonList.SC_FeatureName === "CW" && splitData[0] === "99")
                                {
                                    action = "Appearence";
                                    featureMapping = "Already Exists on ACO";
                                    actionData = splitData[1];
                                    label = splitData[2];
                                }
                                else if (ButtonList.SC_FeatureName === "CW" && splitData[0] === "9")
                                {
                                    action =  "Call Park";
                                    featureMapping = "";
                                    actionData = splitData[1];
                                    label = splitData[2];
                                }
                                else if (ButtonList.SC_FeatureName === "CW" && splitData[0] === "8")
                                {
                                    action =  "Call Forwarding All";
                                    featureMapping = "";
                                    label = "";
                                    actionData = splitData[1];
                                }
                                else
                                {
                                    action =  ButtonList.SC_FeatureName;
                                    featureMapping = "";
                                    actionData = splitData[0];
                                    label = splitData[1];
                                }
                            }
                            else
                            {
                                action =  ButtonList.SC_FeatureName;
                                actionData = "";
                                label = "";
                                featureMapping = "";
                            }
                            if(action === "Call Park" || action === "DialExtn")
                            {
                                if(buttonNumber !== "1" && buttonNumber !== "2" && buttonNumber !== "3")
                                    migrationFlag = "Yes";
                                else
                                    migrationFlag = "No"  
                            }                                
                            else
                                migrationFlag = "No"
                            if(action === "DialExtn")
                            {
                                action = "User";
                                label = ""
                            }
                        }
                        if(buttonNumber !== undefined)
                        shortCodesList.push(
                           {
                            "key" : buttonNumber,
                             "Label" : label,
                             "Action" : action,
                             "ActionData" : actionData,
                             "FeatureMapping" : featureMapping,
                             "MigrationStatus" : migrationFlag
                            }
                        )
                    })
                    userButtonProgrammingList.push(
                        {
                            "User" : username,
                            "ButtonProgrammingList" : shortCodesList,
                        }
                    ) 
                })
                this.fileUploader.uploadJsonFile(cardId ,'/IpoCfg/buttonProgramming.json', {data:userButtonProgrammingList ,totalData: userButtonProgrammingList.length});                
                this.downloadFileFromBucket(`Greetings/${this._cardId}/IpoCfg/parsed_userRestrictionData.json`)
                .then((doc:any) => {
                    this.userRestrictionData = doc.data;
                    let userRightsList = [];
                    if (this.userRestrictionData !== null && this.userRestrictionData !== undefined && this.userRestrictionData.length !== 0)
                {
                    this.userRestrictionData.forEach(element => {
                        let temp_list = [];
                        this._ipoUserList.forEach((ipoentry:any) => {
                        if(element.name === ipoentry.US_Right) 
                        temp_list.push(ipoentry.US_Name)    
                    });
                    let action,username,buttonNumber,actionData,label,shortCodesList = [],key;
                    username = element.name;
                    if (element.Button_Programming !== undefined) {
                    element.Button_Programming.forEach( ButtonList =>{
                       
                        key = ButtonList.SC_ShortCode.split("*DSS");
                        if(key.length >= 1 && key[1] !== undefined && key !== undefined)
                        {
                            buttonNumber = key[1];
                            if(ButtonList.SC_Telephone !== "")  
                            {
                                let splitData = ButtonList.SC_Telephone.split("/");
                                if(ButtonList.SC_FeatureName === "CW" && splitData[0] === "99")
                                {
                                    action = "Appearence";
                                    actionData = splitData[1];
                                    label = splitData[2];
                                }
                                else if (ButtonList.SC_FeatureName === "CW" && splitData[0] === "9")
                                {
                                    action =  "Call Park";
                                    actionData = splitData[1];
                                    label = splitData[2];
                                }
                                else if (ButtonList.SC_FeatureName === "CW" && splitData[0] === "8")
                                {
                                    action =  "Call Forwarding All";
                                    label = "";
                                    actionData = splitData[1];
                                }
                                else
                                {
                                    action =  ButtonList.SC_FeatureName;
                                    actionData = splitData[0];
                                    label = splitData[1];
                                }
                            }
                            else
                            {
                                action =  ButtonList.SC_FeatureName;
                                actionData = "";
                                label = "";
                            }
                            if(action === "DialExtn")
                            {
                                action = "User";
                                label = ""
                            }
                        }
                        if(buttonNumber !== undefined)
                        shortCodesList.push(
                           {
                             "key" : buttonNumber,
                             "Label" : label,
                             "keyType" : action,
                             "ActionData" : actionData
                            }
                        )
                    })
                   }
                    userRightsList.push(
                        {
                            "Name" : username,
                            "Members" : temp_list,
                            "ButtonProgrammingList" : shortCodesList,
                        }
                    ) 
                })
                } 
                this.fileUploader.uploadJsonFile(cardId, '/IpoCfg/userRestrictionData.json', {data: userRightsList, totalData:  userRightsList.length});                 
                return
                 })
                .catch((error) => {
                    this.logger.error(error);
                    return 
                });
                return (this.firestoreService.getAcoImportData(cardId));
            }).then((doc:any) => {
                if (doc !== undefined && doc.exists) {
                    this.logger.debug("ACO Import data");
                    this._acoImportData = doc.data();
                    return (this.firestoreService.readDeletedUsersCfgData(cardId)
                    .then((doc:any)=>{
                        this._deletedUsersList = (doc !== null)? JSON.parse(doc.data): [];
                        this._idx = (doc !== null)? doc.index: 0;
                       // this.logger.debug("Deleted users: ", JSON.stringify(this._deletedUsersList));
                        return;
                    })
                    .then(()=>{
                        return this.firestoreService.getSiteDetails(cardId)
                            .then((doc:any) => {
                                if (doc.data != null && JSON.parse(doc.data) !== undefined) {
                                    if (JSON.parse(doc.data) !== undefined && JSON.parse(doc.data).length > 0) {
                                        this._isMultiSiteMigration = true;  //set _isMultiSiteMigration to true
                                        this.sites = JSON.parse(doc.data);
                                        this.sites.forEach((site: any) => {
                                            //Collect site wise timezone details
                                            this._siteTimeZoneMap.set(site.siteId, site.siteTimeZone ? site.siteTimeZone : '');
                                            //Collect site wise country details
                                            this._siteCountryMap.set(site.siteId, site.homeCountry ? site.homeCountry : '');
                                            //create a map of site specific notification array
                                        });
                                    }

                                }
                            })
                    })
                    .then(()=>{
                        return this.firestoreService.getACOSummaryData(cardId)
                        .then((doc:any)=>{
                            this._acoTimeZone = (doc.data !== null && JSON.parse(doc.data).timezone !== undefined) ? JSON.parse(doc.data).timezone: null;
                            this._acoCountry = (doc.data !== null && JSON.parse(doc.data).country !== undefined) ? JSON.parse(doc.data).country: null;
                            this._cqMemberLimit = (doc.data !== null && JSON.parse(doc.data).maximumAgentsInSimultaneousCQ !== undefined) ? JSON.parse(doc.data).maximumAgentsInSimultaneousCQ: null;
                            return;
                        })
                        .catch((error)=>{
                            this.logger.error("Error while reading ACO summary data: "+ error);
                            return;
                        })
                    })
                    .then(() => {
                        return this.firestoreService.getCardDetails(this._cardId)
                        .then((curdata:any) => {
                            this.countryName = curdata.country;
                            this._acoServiceEdition = curdata.serviceEdition;
                        })
                    })
                    .then(() => {
                        this.reservedExtensionsList = [999,933];
                        var storage = firebase.storage();
                        var storageRef= storage.ref('reservedExtensions/reservedExtensions.json');
                        return storageRef.getDownloadURL().then((url)=>{
                            var xhr = new XMLHttpRequest();
                            xhr.onload = (event) => {
                                this.ngzone.run(() => {
                                    let result = JSON.parse(xhr.response);
                                    result.forEach(data=>{
                                        if(data.country == this.countryName)
                                        {
                                            data.extensions.forEach(element => {
                                                this.reservedExtensionsList.push(element);
                                            });                 
                                        }
                                    });
                                })
                            }
                            xhr.open('GET', url);
                            xhr.send();
                        }).catch((error)=>{
                            this.logger.error(error);
                            return;
                        });
                    })
                    .then(() =>{
                        this.firestoreService.getDTMFData(this._cardId)
                        .then((doc:any) =>{
                            this._zeroDialData = JSON.parse(doc.data);
                        })
                    })
                    .then(() => {
                        this.firestoreService.getDeletedUserDTMFData(this._cardId)
                            .then((doc: any) => {
                                this._deletedUserZeroDialData = JSON.parse(doc.data);
                            })
                    })
                    .then(()=> {
                        return (this.generateInitialTableUserList()
                        .then(() => {
                            //this.logger.debug("Initial table list: ", this._tableUserList);
                            this.fillIPOUsersByPhoneAndProfile();
                            this.fillACOUsersByPhone();
                            this.fillPagingDevList();
                            this.validateTableData();
                            this.fillSteeringCodesData();
                            this.prepareDLGData();
                            //this.prepareParkLocationData();
                            return;
                        })
                        .catch((error) => {
                            reject(error);
                        }));
                    })
                    .catch((error)=>{
                        this.logger.error(error);
                        reject(error);
                    }));
                }
                else{
                    reject();
                }
            }).then(()=>{
                return this.firestoreService.getVMProDocData(cardId)
                .then((data:any)=>{
                    if(data !== null && data !== undefined){
                        isRootVmpFileFound = (data.rootVmpFileFound !== undefined && data.rootVmpFileFound !== null && 
                            data.rootVmpFileFound !== "") ? data.rootVmpFileFound : false;
                        aaComplexCallFlowList = (isRootVmpFileFound && data.aaComplexCallFlowList !== undefined) ? JSON.parse(data.aaComplexCallFlowList):undefined;        
                        aaTimeoutList = (isRootVmpFileFound && data.aaTimeoutList !== undefined) ? JSON.parse(data.aaTimeoutList):undefined;
                        conferenceMeetMeList = (isRootVmpFileFound && data.conferenceMeetMeList !== undefined)? JSON.parse(data.conferenceMeetMeList):undefined
                        this.logger.debug("completed vmpro data : ");
                        if (!isRootVmpFileFound) { 
                            this.downloadFileFromBucket(`Greetings/${cardId}/IpoCfg/autoAttendant.json`)
                            .then((doc:any) => {
                               this._aaMenuKeysData = (doc !== null && doc !== undefined)? doc:[];
                               return
                            })
                           .catch((error) => {
                               this.logger.error(error);
                               return 
                           });
                        }
                        else this._aaMenuKeysData = this._vmproAAParsedFlows;
                    }
                    return;
                })
                .catch((error)=>{
                    this.logger.error(error);
                    return;
                })
            }).then(() =>{
                return this.firestoreService.getGreetingsData(cardId)
                .then((data:any)=>{
                    try{
                        ignoredUsersList = data.ignoredUsers !== undefined ? JSON.parse(data.ignoredUsers):undefined;
                        partialUsersList = data.partialUsers !== undefined ? JSON.parse(data.partialUsers):undefined;
                        /* missingHGAnnList = data.missingHGAnnList !== undefined ? JSON.parse(data.missingHGAnnList):undefined;
                        unsupportedHGAnnList = data.unsupportedHGAnnList !== undefined ? JSON.parse(data.unsupportedHGAnnList):undefined;
                        chainedAnnHGList = data.chainedAnnHGList !== undefined ? JSON.parse(data.chainedAnnHGList):undefined;
                        annUnCheckedHGList = data.annUnCheckedHGList !== undefined ? JSON.parse(data.annUnCheckedHGList):undefined 
                        hgVMMissingList = data.hgVMMissingList !== undefined ? JSON.parse(data.hgVMMissingList) : undefined;
                        hgVMComplexList = data.hgVMComplexList !== undefined ? JSON.parse(data.hgVMComplexList) : undefined; */
                        this._hgWarnObj.missingHGAnnList = data.missingHGAnnList !== undefined ? JSON.parse(data.missingHGAnnList):undefined;
                        this._hgWarnObj.unsupportedHGAnnList = data.unsupportedHGAnnList !== undefined ? JSON.parse(data.unsupportedHGAnnList):undefined;
                        this._hgWarnObj.chainedAnnHGList = data.chainedAnnHGList !== undefined ? JSON.parse(data.chainedAnnHGList):undefined;
                        this._hgWarnObj.annUnCheckedHGList = data.annUnCheckedHGList !== undefined ? JSON.parse(data.annUnCheckedHGList):undefined 
                        this._hgWarnObj.hgVMMissingList = data.hgVMMissingList !== undefined ? JSON.parse(data.hgVMMissingList) : undefined;
                        this._hgWarnObj.hgVMComplexList = data.hgVMComplexList !== undefined ? JSON.parse(data.hgVMComplexList) : undefined;
                        this.logger.debug("completed greetings data");
                        return;
                    }catch(error){
                        this.logger.debug(error);
                        return;
                    }
                }).catch((error)=>{
                    this.logger.debug(error);
                })
            }).then(() => {  
                return this.downloadFileFromBucket(`Greetings/${cardId}/IpoCfg/huntGroup.json`)
                .then((doc:any) => {
                    (doc !== null)?this._ipoHuntGroup=doc.data:this._ipoHuntGroup=[];
                        this.generateInitialHGnPGTableData().then(()=>{
                            this.updateHuntGroupWarnings();
                            this.updatePGNotificationMsg();
                            this.logger.debug("completed Call Queues");
                            this.prepareParkLocationData();
                            return;
                        }).catch((error)=>{
                            this.logger.debug(error);
                            return;
                        })
                    })
                .catch((error)=>{
                    this.logger.error(error);
                    return;
                   // reject(error);
                })     
            }).then(() => {
                return this.firestoreService.getCardDetails(cardId)
                    .then((doc: any) => {
                        this._isInternationalCallingEnabled = doc.internationalCallingEnabled;
                        return;
                    })
                    .catch((error) => {
                        this.logger.error(error);
                        return;
                    })
            }).then(() => {
                let notifyMsg;
                this.AAList = [];
               // this._tableIncomingNumbersList = [];
                this._tableAutoAttendantList = [];
                this.aasiteSpecificNotifications.forEach((value:[], key: string) =>{
                    this.aasiteSpecificNotifications.set(key,[])
                 })
                if((this._vmGreetingsData === undefined || this._vmGreetingsData.length === 0) ||
                    ignoredUsersList === undefined || ignoredUsersList === null ||
                    partialUsersList === undefined || partialUsersList === null){                      
                            this.advInfositeSpecificNotifications.get("Common Notifications").push(VM_ERROR_MSG.GREETINGS_MISSING);
                    }
                    this.updateAdvUserInfoTableData(this._vmGreetingsData,ignoredUsersList,partialUsersList);
                    if(!isRootVmpFileFound){
                        
                        let _ipoAutoAttendantList = [];
                        (this._aaMenuKeysData !== undefined && this._aaMenuKeysData !== null && Object.keys(this._aaMenuKeysData).length !== 0)? _ipoAutoAttendantList = this._aaMenuKeysData: _ipoAutoAttendantList=[];
                        if(_ipoAutoAttendantList !== undefined && _ipoAutoAttendantList.length > 0) {
                            _ipoAutoAttendantList.map(entry => {
                                let aaEntry: tableAutoAttendantData = {} as any;;
                                aaEntry.name = entry.AA_Name;
                                let menuLen = entry.AA_Actions.filter((entry => entry.Action == 'Not_defined')).length;
                                aaEntry.menuAvailability = (menuLen === IPO_MENU_KEYS) ? false : true;
                                aaEntry.siteName = (entry.siteName !== "")?entry.siteName:"MainSite";
                                const aaPromptFound = this._aaMenuData.filter ((entry) => (entry.AA_Name === aaEntry.name));
                                aaEntry.ivrPrompt = (aaPromptFound !== undefined)?true:false;
                                this._tableAutoAttendantList.push(aaEntry);
                                this.validateAAkeyInputs(entry);
                                this.updateFeatureTaskChain(FEATURE_NAME.AUTO_ATTENDANT);
                            })
                            this._tableAutoAttendantList.forEach(element => {
                                this.AAList.push(element.name)
                            })
                            this.logger.debug("completed Auto-Receptionists");
                            return
                        }
                    }else{
                        if(this._vmproAAParsedFlows !== undefined && this._vmproAAParsedFlows.length > 0){
                            this._vmproAAParsedFlows.forEach(element => {
                                let splkeys = [];
                                let numkeys = [];                                    
                                element.AA_Actions.forEach(data => {
                                    if(data.Key == "*" || data.Key == "#" || data.Key == "Fax")
                                        splkeys.push(data);
                                    else
                                        numkeys.push(data);
                                });
                                    numkeys.sort((n1,n2) => {
                                       return n1.Key - n2.Key;
                                    });
                                   numkeys = numkeys.concat(splkeys);
                                   element.AA_Actions = numkeys;   
                                });

                                this._vmproAAParsedFlows.map(entry => {
                                let aaEntry: tableAutoAttendantData = {} as any;
                                aaEntry.name = entry.AA_Name;
                                aaEntry.siteName = (entry.siteName !== "")?entry.siteName:"MainSite";
                                let menuLen = entry.AA_Actions.filter((entry => entry.Action == 'Not_defined')).length;
                                aaEntry.menuAvailability = (menuLen === entry.AA_Actions.length)?false:true;
                                const aaPromptFound = this._aaMenuData.filter ((entry) => (entry.AA_Name === aaEntry.name));
                                aaEntry.ivrPrompt = (aaPromptFound !== undefined)?true:false;
                                this._tableAutoAttendantList.push(aaEntry);
                                this.validateAAkeyInputs(entry) ;
                                this.updateFeatureTaskChain(FEATURE_NAME.AUTO_ATTENDANT);
                            })
                            this._tableAutoAttendantList.forEach(element => {
                                this.AAList.push(element.name)
                                 }) 
                            this.updateComplexCallFlowWarnings(aaComplexCallFlowList, aaTimeoutList, conferenceMeetMeList);
                            this.updateIPOSummaryDoc({No_of_AutoAttendants:this.tableAutoAttendantList.length});
                            this.icrDestinationWarnings();
                            this.logger.debug("completed Auto-Receptionists vmpro call flows");
                            return 
                        }
                    } 
                    
                             
                }).then(() => {
                    let notifMsg;
                    let stCodes = [];
                    if(this.steeringCodeData !== undefined && this.steeringCodeData !== null 
                        && this.steeringCodeData.length > 0){
                    this.advInfositeSpecificNotifications.forEach((value:[], key: string) =>{
                        this.steeringCodeData.forEach(function(stCodeData) {
                            if (!stCodes.includes(stCodeData.Steering_Code) && stCodeData.FileName === key) {
                                stCodes.push(stCodeData.Steering_Code);
                            }
                        });
                        if(stCodes.length !== 0){
                            notifMsg = TWIN_CONTACT_NUMBERS.STEERING_CODES_REMOVAL + stCodes.join(", ");
                            this.advInfositeSpecificNotifications.get(key).push(notifMsg);
                        }                 
                    })
                    }
                    //If invalid time profile is configured to a twin number, push extension Id into _invalidTwinTimeProfileList
                    this.advInfositeSpecificNotifications.forEach((value:[], key: string) =>{
                    for(let i=0;i<this._ipoUserList.length;i++){
                        if(this.isUserNotDeleted(parseInt(this._ipoUserList[i].Extension.US_Extension))){
                            if (typeof this._ipoUserList[i].Twinning !== 'undefined' && 
                                typeof this.ipoUserList[i].Twinning.US_TwinTimeProfile !== 'undefined' &&
                                this._invalidTimeProfileList !== undefined &&
                                this._invalidTimeProfileList.includes(this._ipoUserList[i].Twinning.US_TwinTimeProfile) && 
                                !this._invalidTwinTimeProfileList.get(key).includes(this._ipoUserList[i].Extension.US_Extension) && key === this._ipoUserList[i].Sitename) {
                                    this._invalidTwinTimeProfileList.get(key).push(this._ipoUserList[i].Extension.US_Extension);
                                }
                        }
                    }
               
                    //If _invalidTwinTimeProfileList has entries, notify warning on invalid time profiles configured to twin number.
                    this._invalidTwinTimeProfileList.forEach((value:[], key: string) =>{
                    if(value.length >0)  
                        this.advInfositeSpecificNotifications.get(key).push(TWIN_CONTACT_NUMBERS.TWIN_DATE_NOT_SELECTED_TIME_PROFILE + value);
                    })
                    this.advInfositeSpecificNotifications.forEach((value:[], key: string) =>{
                        let newValue = [...new Set(this.advInfositeSpecificNotifications.get(key))];
                        this.advInfositeSpecificNotifications.set(key,newValue);
                        })
                })
                this.advncdUserInfoWarningsList = this.advInfositeSpecificNotifications;
                }).then(() => {
                   return this.firestoreService.readAA_ICRData(cardId)
                    .then((doc:any) => {
                        if(this.AAList.length > 0)
                        {
                            let isUpdated = doc.isUpdated;
                            this._originaltableIncomingNumbersList = (doc !== null)? JSON.parse(doc.data): [];
                            this._tableIncomingNumbersList = [];
                            this._originaltableIncomingNumbersList.forEach(entry => {
                                entry.forEach(element => {
                                    if(this.AAList.includes(element.name))
                                    {
                                        let incomingNumberEntry: incomingNumbers ={} as any;
                                        incomingNumberEntry.isSelected = false;
                                        incomingNumberEntry.siteName =  (element.siteName !== "")?element.siteName:"MainSite";
                                        incomingNumberEntry.icrNumber=element.icrNumber;
                                        incomingNumberEntry.name = element.name;
                                        incomingNumberEntry.timeProfile = element.timeProfile;
                                        incomingNumberEntry.companyNumbers = element.phoneNumber;
                                        this._tableIncomingNumbersList.push(incomingNumberEntry);

                                        //Check if any Auto-Receptionists are configured with time profiles where date is not configured
                                        if (this._invalidTimeProfileList !== undefined && element.timeProfile !== 'undefined' && this._invalidTimeProfileList.includes(element.timeProfile) && 
                                        !this.invalidAnsRuleTimeProfilesList.includes(element.name)) {
                                            this.invalidAnsRuleTimeProfilesList.push(element.name);
                                        }
                                    }
                                });
                            });

                            if (this.invalidAnsRuleTimeProfilesList !== undefined && this.invalidAnsRuleTimeProfilesList.length > 0) {
                                this.aasiteSpecificNotifications.get("MainSite").push(AA_ERROR_MSG.ANS_RULE_DATE_NOT_SELECT_TIME_PROFILE + this.invalidAnsRuleTimeProfilesList.join(", "));
                            }

                            this._tableIncomingNumbersListCount = this._tableIncomingNumbersList.length;
                            this.setIncomingData();
                            this.firestoreService.updateAA_ICRData(this._cardId,this._originaltableIncomingNumbersList,isUpdated)
                            .then(() => {
                                this.logger.debug("Updated Incoming Numbers successfully");
                            })
                            this.getCompanyPhNumbers();
                            this.logger.debug("completed incoming Numbers");

                        }
                        this.icrDestinationWarnings()
                        .then(() => {this.logger.debug('updated ICR warnings')})
                        this._isTableDataInitializedFlag = true;
                        return resolve();
                        
                    })
                    .catch((error) => {
                        this._isTableDataInitializedFlag = true;
                        return resolve(); 
                    });
                    
                })
                    // .catch((error) => {
                    //     this.logger.error(error);
                    //        reject(error);
                    //    });
                })           
        return promise;
    }    
    
    public validateTableData(){
        //this.logger.debug("isMigrationDataValid : ", this._tableUserList);
        var notifyMsg;
        this._notifications = [];
        let tempNotifications = [];
        let genericNotifications = []; 
        var emptyList:tablePreviewData[];
        var uniqueEmail = [];
        //Errors
        this.siteSpecificNotifications.forEach((value:[], key: string) =>{
            tempNotifications = [];
            emptyList = this._tableUserList.filter(entry => entry.email === "" && key === entry.siteName);        
            if(emptyList.length){
                notifyMsg = emptyList.length + " Email(s) not configured";
                this.updateIPOSummaryDoc({No_Of_Users_Without_email:emptyList.length});
                tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
            }
    
            //ACOS-49:Validation for the email's in the iput csv is required
            this._tableUserList.filter(entry => (!this.validateEmailEntry(entry.email) && key === entry.siteName)).forEach(element => {
                notifyMsg = element.username +" has invalid email(s): " + element.email;
                tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
            });
    
            emptyList = this._tableUserList.filter(entry => (entry.firstName === "" && entry.lastName === "" && key === entry.siteName));
            if(emptyList.length){
                notifyMsg = "First and last name is not configured for " + emptyList.length + " user(s)";
                tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
            }
    
            emptyList = this._tableUserList.filter(entry => (entry.acoPhoneStatus === phoneStatus.unassigned) && key === entry.siteName);
            if(emptyList.length){
                notifyMsg = "PhoneType is not configured for " + emptyList.length + " user(s)";
                tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
            }
    
            emptyList = this._tableUserList.filter(entry => entry.phone === "message-only" && key === entry.siteName);
            if(emptyList !== undefined && emptyList.length){
                let extnList = [];
                emptyList.forEach(element => {
                    extnList.push(element.extension)
                });
                notifyMsg = "Users with Message Only extension(s): " + extnList.join( " , ");
                tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.INFO});
            }
           if(emptyList !== undefined && emptyList.length > 100){
                notifyMsg = "Maximum limit for message only extensions is set to 100 on ACO. Only the first 100 MOE users will be migrated."
                tempNotifications.push({msg: notifyMsg, severity: SEVERITY.WARNING});
            }
    
            if (this._didNotConfiguredTwinList.length > 0) {
                let extList = [];
                this._didNotConfiguredTwinList.forEach(element => {
                    if (key == element.siteName) {
                        extList.push(element.extension);
                    }
                });
                if (extList.length > 0) {
                    notifyMsg = TWIN_CONTACT_NUMBERS.DID_PHONES_NOT_ASSIGNED + extList.join(", ");
                    tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
                }
            }
    
            // Check for duplicate emails on RC
            let dupEmailList = [];
            this._tableUserList.filter(entry =>  
            {
                if(this._assignedEmailList.includes(entry.email) && key === entry.siteName)
                    dupEmailList.push(entry)
            });
            if(dupEmailList.length){
                notifyMsg =  "Email(s) already at use in ACO : "+ dupEmailList.map(entry=>entry.email).join( " , ");
                tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
            }
    
            this._tableUserList.filter(entry => (entry.email !== "") && key === entry.siteName).forEach(element => {
                if(uniqueEmail.indexOf(element.email) !== NOT_FOUND){
                    notifyMsg = "Remove duplicate email(s)  " + element.email;
                    let found = tempNotifications.find(entry=>{
                         return entry.msg === notifyMsg;
                    })
                    //To remove duplicate entries in the notifications
                    if(found === undefined)
                    tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR}); 
                }
                else{
                    uniqueEmail.push(element.email);
                }
            });
    
            //Warnings
            emptyList = this._tableUserList.filter(entry => (entry.firstName === "" && entry.lastName !== "" && key === entry.siteName));
            if(emptyList.length){
                notifyMsg = "First name is not configured for " + emptyList.length + " user(s)";
                tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
            }
    
            emptyList = this._tableUserList.filter(entry => (entry.firstName !== "" && entry.lastName === "" && key === entry.siteName));
            if(emptyList.length){
                notifyMsg = "Last name is not configured for " + emptyList.length + " user(s)";
                tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
            }
             // Check for duplicate ExtnId on RC 
             let duplicateExtnList = [];
             this._tableUserList.filter(entry =>  
                 {
                 if(this._assignedExtnList.includes(JSON.stringify(entry.extension)) && key === entry.siteName)
                 duplicateExtnList.push(entry.extension)
                 });
             if(duplicateExtnList.length){
                 notifyMsg =   (duplicateExtnList.length > 1 ?  [duplicateExtnList.slice(0,-1).join(', '), duplicateExtnList.slice(-1)[0]].join(' and ') 
                                                              : duplicateExtnList[0]);
                 notifyMsg = "User " + GEN_ERR.DUPLICATE_EXTNS + " : " + notifyMsg;
                 tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
                }
            // Validate reserved Extensions
            let resExtnList = [];
            this._tableUserList.filter(entry =>  
                {
                if(this.reservedExtensionsList.includes(entry.extension) && key === entry.siteName)
                resExtnList.push(entry.extension);
                });
                if(resExtnList.length){
                    notifyMsg = "Extension Number(s) " + resExtnList + " is/are reserved for special functions in  "+ this.countryName;
                    tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
                   }
              
            let invalidMacAssignmentList = [];
            if (this._sysmonData !== undefined && this._sysmonData.length > 0) {
                this._sysmonData.forEach((sysmonEntry) => {
                    let matchedUserEntry: any = this._tableUserList.filter(entry => entry.extension === parseInt(sysmonEntry.userExtension) && key == entry.siteName);
                    if (matchedUserEntry !== undefined && matchedUserEntry[0] !== undefined) {
                        if (matchedUserEntry[0].macId.toUpperCase() !== sysmonEntry.macId.toUpperCase() || !(matchedUserEntry[0].acoPhoneModel == sysmonEntry.phoneModel || ((matchedUserEntry[0].acoPhoneModel == "Existing Phone") || (matchedUserEntry[0].acoPhoneModel == "Existing phone")))) {
                            invalidMacAssignmentList.push(sysmonEntry.userExtension);
                        }
                    }
                });
            }
    
            if (invalidMacAssignmentList.length > 0) {
                notifyMsg = "Sysmon file recommended Serial Number(Device MAC address) and PhoneType  are not assigned to user extension number(s) due to unavailability or shortage of requested resources on ACO : " + invalidMacAssignmentList.join(" , ");
                let found = tempNotifications.find(entry => {
                    return (entry.msg === notifyMsg);
                });
                if (found === undefined) {
                    tempNotifications.push({ msg: notifyMsg, severity: SEVERITY.WARNING });
                }
            }
    
            let siteDuplicateSysmonData = [];
            if (this._duplicateSysmonData.length > 0) {
                this._duplicateSysmonData.forEach(entry => {
                    let matchedUser = this.tableUserList.find(user => user.extension === parseInt(entry.userExtension) && key == user.siteName);
                    if (matchedUser !== undefined) {
                        siteDuplicateSysmonData.push(entry);
                    }
                });
            }

            if (siteDuplicateSysmonData.length > 0) {
                notifyMsg = "Duplicate user entries found in uploaded sysmon file will be ignored for Serial Number(Device MAC address) update operations : " + siteDuplicateSysmonData.map(entry => (entry.userExtension + '-' + entry.userName + '-' + entry.macId + '-' + entry.phoneModel)).join(" , ");
                let found = tempNotifications.find(entry => {
                    return entry.msg == notifyMsg;
                })
                if (found === undefined) {
                    tempNotifications.push({ msg: notifyMsg, severity: SEVERITY.WARNING });
                }
            }

            let siteInvalidSysmonData = [];
            if (this._invalidSysmonData.length > 0) {
                this._invalidSysmonData.forEach(entry => {
                    let matchedUser = this.tableUserList.find(user => user.extension === parseInt(entry.userExtension) && key === user.siteName);
                    if (matchedUser !== undefined) {
                        siteInvalidSysmonData.push(entry);
                    }
                });
            }

            if (siteInvalidSysmonData.length > 0) {
                notifyMsg = "Entries in uploaded sysmon file is incomplete and is missing either User Name, Serial Number (Device MAC address) or PhoneType to be assigned for user extension number(s) and will be ignored for Serial Number (Device MAC address) update operations : " + siteInvalidSysmonData.map(entry => (entry.userExtension)).join(" , ");
                let found = tempNotifications.find(entry => {
                    return entry.msg == notifyMsg;
                });
                if (found === undefined) {
                    tempNotifications.push({ msg: notifyMsg, severity: SEVERITY.WARNING });
                }
            }
    
            let limExtnList = this._tableUserList.map(entry => {
                if(this.isLimitedExtn(entry.acoPhoneModel) && key === entry.siteName){
                    return entry.username;
                }
            });
            limExtnList = limExtnList.filter(entry=>entry);
            if(limExtnList.length){
                notifyMsg = GEN_ERR.LIMITED_EXTNS + limExtnList.join( " , ");
                tempNotifications.push({msg: notifyMsg,severity:  SEVERITY.INFO});
            }
            this.siteSpecificNotifications.set(key, tempNotifications);
        })
        if(this._zeroDialData !== undefined && this._zeroDialData.length !== 0)
        {
            this._zeroDialData.forEach(zData =>{
                if(this.reservedExtensionsList.includes(parseInt(zData.extnId)))
                {
                    notifyMsg = + zData.extnId + " is a Reserved Extesion,hence it cannot be migrated.Zero Dial destination will not be set. "+ this.countryName;
                    genericNotifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
                }  
            })  
        }
            if(this._deletedUserZeroDialData !== undefined && this._deletedUserZeroDialData.length !== 0)
            {  
                let delList = [];
                this._deletedUserZeroDialData.forEach(delData =>{
                    this._deletedUsersList.forEach((element) => {
                        if(element.extension.toString() === delData.extnId)
                        {
                            delList.push(delData.extnId)
                        }               
                    })
                })
                if(delList !== undefined && delList.length !== 0)
                {
                notifyMsg = "Extension number(s)"+ delList.toString() +" is set as a Zero Dialing extension. Deleting the extension number will disable Zero Dialing";
                    genericNotifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
                }
            }             
        
        if (this._deviceExtList === undefined || this._deviceExtList.length <= 0) {
            notifyMsg = SYSMON_MSG.UNASSIGNED_PHONES_NOT_AVAILABLE;
            genericNotifications.push({msg: notifyMsg, severity: SEVERITY.WARNING});
        }        
        this.acoExtnByPhoneGroup.forEach(element => {
            if(element.extensionList.length){
                notifyMsg = "ACO has " + element.extensionList.length + " " + element.phoneModel + " extension number(s) unassigned";
                genericNotifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING}); 
            }
        });
    let acoUnassignedExtnsCount = this._acoImportData.total;
        //ACOS-1504 check diff w.r.t to license. Message-only doesnt not require any license
        //diff should be done only for user which are not message-only. _messageOnlyextnList can also be used for length 
        let diff = acoUnassignedExtnsCount - (this._tableUserList.length - this._tableUserList.filter(user => user.phone === "message-only").length);
        if(diff < 0){
            notifyMsg = "You have exceeded the allowed users to be migrated by " + Math.abs(diff) + ". Remove users to proceed.";
            genericNotifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
        }

        let nonMessageOnlyUsersList = this._tableUserList.filter(entry => (entry.phone !== "message-only"));
        if(nonMessageOnlyUsersList.length === 0){
            let notifyMsg = "Cannot proceed with Migration. Users list for migration do not contain user extensions or contains only message-only users";
            genericNotifications.push({msg: notifyMsg,severity: SEVERITY.ERROR});
        }
        this.siteSpecificNotifications.set("Common Notifications", genericNotifications);
        this._notifications = this.siteSpecificNotifications;
    }

    private updateIPOSummaryDoc(data){
        if(this.migrationDataService.IPOSummaryData === undefined || this.migrationDataService.IPOSummaryData === null)
            this.migrationDataService.IPOSummaryData = {};
        if(this.migrationDataService.IPOSummaryData.IPOSummary === undefined || this.migrationDataService.IPOSummaryData.IPOSummary === null)
        {
            this.firestoreService.getIPOSummaryData(this._cardId)
            .then((result:any) => {          
                this.migrationDataService.IPOSummaryData = JSON.parse(result);
                let count = data.length;
                if(count > 0){
                    for(let key in data){
                        if(data[key] !== this.migrationDataService.IPOSummaryData[key]){
                            break;
                        }
                        count--;
                    }
                    if(count !== 0){
                        this.migrationDataService.IPOSummaryData.IPOSummary = {...this.migrationDataService.IPOSummaryData.IPOSummary,
                            ...data};
                        this.firestoreService.setIPOSummaryData(this._cardId, this.migrationDataService.IPOSummaryData)
                        .catch(err=>{
                            this.logger.error("Error saving IPOSummary data: "+err);
                        })
                    }
                }else{
                    this.logger.debug("IPO summary data is empty.");
                }
            })
        }else{
            this.migrationDataService.IPOSummaryData.IPOSummary = {...this.migrationDataService.IPOSummaryData.IPOSummary,
                                                                   ...data};
            this.firestoreService.setIPOSummaryData(this._cardId, this.migrationDataService.IPOSummaryData)
            .catch(err=>{
                this.logger.error("Error saving IPOSummary data: "+err);
            })
        } 
            
    }

    private updateOverflowGroups(){
        this.tableHuntGroupList.map((hgEntry)=>{
            hgEntry.overflowGroup = [];
            if(hgEntry.isPagingEnabled){
                hgEntry.overflowGroup =  [];
            }else{
                if(hgEntry.extension){
                    let ipoEntry = this._ipoHuntGroup.find(entry=>entry.Group.GR_Extension === hgEntry.extension.toString());            
                    let overflowTime = 0;
                    if(ipoEntry){
                        if(ipoEntry.Overflow && ipoEntry.Overflow.GR_OverflowTime)
                        overflowTime =  parseInt(ipoEntry.Overflow.GR_OverflowTime) || 0;
                        if (ipoEntry.Group !== undefined && 
                            ACO_SERVICE_EDITION_LIST.includes(this._acoServiceEdition.toLowerCase()) &&
                            ipoEntry.Overflow && ipoEntry.Overflow.GR_Overflow) {
                                hgEntry.overflowGroup = this.assignOverflowGroup(hgEntry, ipoEntry.Overflow.GR_Overflow);
                            }
                        }
                    }
                }
                                
            })
    }

    private updateHuntGroupWarnings(){
        let missingHGAnnList = this._hgWarnObj.missingHGAnnList,
         unsupportedHGAnnList = this._hgWarnObj.unsupportedHGAnnList,
         chainedAnnHGList = this._hgWarnObj.chainedAnnHGList,
         hgVMMissingList = this._hgWarnObj.hgVMMissingList,
         hgVMComplexList = this._hgWarnObj.hgVMComplexList;
        let notifyMsg="";
        this._huntGroupWarningsList = [];
        this.cqsiteSpecificNotifications.forEach((value:[], key: string) =>{
        let tempNotifications = []; 
        let commonNotifications = [];
        let hgNameList = this._tableHuntGroupList.filter((entry:any)=>{
            return !entry.isPagingEnabled;
        }).map((el)=>{return el.name});

        let invalidHgGroup = [], duplicateExtnList = [],resExtnList = [];
        this._tableHuntGroupList.filter((entry:any)=>{
            return !entry.isPagingEnabled;
        }).map((el) => {
            if ((el.extension === undefined || el.extension === null || !el.extension) && el.siteName ===  key){
                invalidHgGroup.push(el.name);
            }else {
                if(this._assignedExtnList.includes(el.extension.toString()) && el.siteName ===  key)
                    duplicateExtnList.push(el.extension);
                if(this.reservedExtensionsList.includes(el.extension) && el.siteName ===  key)
                    resExtnList.push(el.name+"("+el.extension+")");
            }
        })

        if (invalidHgGroup.length > 0) {
            notifyMsg = HG_ERROR_MSG.EXTENSION_MISSING + invalidHgGroup.join(", ");
            tempNotifications.push(notifyMsg);
        }

        // Check for duplicate ExtnId on RC
        if(duplicateExtnList.length){
            notifyMsg =   (duplicateExtnList.length > 1 ?  [duplicateExtnList.slice(0,-1).join(', '), duplicateExtnList.slice(-1)[0]].join(' and ') 
                                                          : duplicateExtnList[0]);
            notifyMsg = "Call Queues " + GEN_ERR.DUPLICATE_EXTNS + " : " + notifyMsg;
            tempNotifications.push(notifyMsg);
        }
        // Validate reserved Extensions
        if(resExtnList.length){
            notifyMsg = HG_ERROR_MSG.RESERVED_EXTN + resExtnList.join( " , ");
            tempNotifications.push(notifyMsg);
        }

        if (hgNameList.length > 100) {
            notifyMsg = HG_ERROR_MSG.CQ_COUNT_EXCEEDS_ACO_THRESHOLD;
            commonNotifications.push(notifyMsg);
        }

        if(missingHGAnnList !== undefined ){
            missingHGAnnList = missingHGAnnList.filter(entry=>entry);
            let missingHGAnnFinalList = missingHGAnnList.filter((entry:any)=>{
               if(hgNameList.includes(entry) && this.isHGMigrated(null,entry) && entry.siteName === key){
                   return entry;
               }
            });
            if(missingHGAnnFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.ANN_MISSING +  
                (missingHGAnnFinalList.length > 1 ? [missingHGAnnFinalList.slice(0, -1).join(', '), 
                                                    missingHGAnnFinalList.slice(-1)[0] ].join(' and ') 
                                                : missingHGAnnFinalList[0]);
                tempNotifications.push(notifyMsg)
            }
        }
        if(unsupportedHGAnnList !== undefined ){
            unsupportedHGAnnList = unsupportedHGAnnList.filter(entry=>entry);
            let unsupportedHGAnnFinalList = unsupportedHGAnnList.filter((entry:any)=>{
               if(hgNameList.includes(entry) && this.isHGMigrated(null,entry) && entry.siteName === key){
                   return entry;
               }
            });
            if(unsupportedHGAnnFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.UNSUPPORTED_CALL_FLOW +
                (unsupportedHGAnnFinalList.length > 1 ? [unsupportedHGAnnFinalList.slice(0, -1).join(', '), 
                                                        unsupportedHGAnnFinalList.slice(-1)[0] ].join(' and ') 
                                                : unsupportedHGAnnFinalList[0]);
                tempNotifications.push(notifyMsg)
            }
        }
        if(chainedAnnHGList !== undefined ){
            chainedAnnHGList = chainedAnnHGList.filter(entry=>entry);
            let chainedAnnHGFinalList = chainedAnnHGList.filter((entry:any)=>{
               if(hgNameList.includes(entry) && this.isHGMigrated(null,entry) && entry.siteName === key){
                   return entry;
               }
            });
            if(chainedAnnHGFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.MUL_ANN_FILES+  
                (chainedAnnHGFinalList.length > 1 ? [chainedAnnHGFinalList.slice(0, -1).join(', '), 
                                                    chainedAnnHGFinalList.slice(-1)[0] ].join(' and ') 
                                                : chainedAnnHGFinalList[0]);
                tempNotifications.push(notifyMsg)
            }
        }
        if(hgVMMissingList !== undefined ){
            hgVMMissingList = hgVMMissingList.filter(entry=>entry);
            let missingHGVMFinalList = hgVMMissingList.filter((entry:any)=>{
               if(hgNameList.includes(entry) && this.isHGMigrated(null,entry) && entry.siteName === key){
                   return entry;
               }
            });
            if(missingHGVMFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.VM_GREETING_MISSING +  
                (missingHGVMFinalList.length > 1 ? [missingHGVMFinalList.slice(0, -1).join(', '), 
                                                    missingHGVMFinalList.slice(-1)[0] ].join(' and ') 
                                                : missingHGVMFinalList[0]);
                tempNotifications.push(notifyMsg)
            }
        }
        if(hgVMComplexList !== undefined ){
            hgVMComplexList = hgVMComplexList.filter(entry=>entry);
            let complexHGVMFinalList = hgVMComplexList.filter((entry:any)=>{
               if(hgNameList.includes(entry) && this.isHGMigrated(null,entry) && entry.siteName === key){
                   return entry;
               }
            });
            if(complexHGVMFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.VM_COMPLEX_CALLFLOW +  
                (complexHGVMFinalList.length > 1 ? [complexHGVMFinalList.slice(0, -1).join(', '), 
                                                    complexHGVMFinalList.slice(-1)[0] ].join(' and ') 
                                                : complexHGVMFinalList[0]);
             tempNotifications.push(notifyMsg)
            }
        }

        if (this._ipoHuntGroup !== undefined && this._ipoUserHGSourceNumberList !== undefined && 
            this._ipoUserHGSourceNumberList.length > 0) {
            let userSourceList = [];
            this._ipoUserHGSourceNumberList.map((el: any) => {
                if (el !== null && el !== undefined && el.siteName === key) {
                    //Not checking if the HG is a reserved/empty extension/duplicate extension...If needed, can be done later
                    let matchFound = this._ipoHuntGroup.find((hgEntry: any) => ((el.US_DialInSourceNumbers.toString().includes(hgEntry.Group.GR_Name) || 
                                                                                el.US_DialInSourceNumbers.toString().includes(hgEntry.Group.GR_Extension)) && el.siteName === key))
                    if (matchFound !== undefined &&  this.isUserNotDeleted(el.Extension.US_Extension)) userSourceList.push(el.US_Name);
                }
            })
            if (userSourceList.length>0) {
                notifyMsg = HG_ERROR_MSG.HG_VM_USER_ACCESS +  
                (userSourceList.length > 1 ? [userSourceList.slice(0, -1).join(', '), 
                                              userSourceList.slice(-1)[0] ].join(' and ') : 
                                              userSourceList[0]);
                tempNotifications.push(notifyMsg)
            }
        }
   
        if (this._ipoHuntGroup !== undefined && this._ipoHuntGroup.length > 0){
            let ipoHGAnn2Unchecked = [];
            let annUnCheckedHGList =[];
            this._ipoHuntGroup.map((hgEntry:any) => {
                let isHGMigrated = this.isHGMigrated(hgEntry.Group.GR_Extension,null);
                if (hgEntry.Announcements.GR_FollowAnnouncements.toLowerCase() !== 'true' && hgNameList.includes(hgEntry.Group.GR_Name) && isHGMigrated && hgEntry.Sitename === key)
                  ipoHGAnn2Unchecked.push(hgEntry.Group.GR_Name);
                if (hgEntry.Announcements.GR_Announcements.toLowerCase() !== 'true' && hgNameList.includes(hgEntry.Group.GR_Name) && isHGMigrated && hgEntry.Sitename === key)
                  annUnCheckedHGList.push(hgEntry.Group.GR_Name);
                  
            })
            if(ipoHGAnn2Unchecked.length > 0){
                notifyMsg = HG_ERROR_MSG.ANN2_BOX_UNCHECKED +  
                (ipoHGAnn2Unchecked.length > 1 ? [ipoHGAnn2Unchecked.slice(0, -1).join(', '), 
                ipoHGAnn2Unchecked.slice(-1)[0] ].join(' and ') : 
                ipoHGAnn2Unchecked[0]);
                tempNotifications.push(notifyMsg);
            }
            
            if (annUnCheckedHGList !== undefined && annUnCheckedHGList.length > 0) {
                notifyMsg = HG_ERROR_MSG.ANN_BOX_UNCHECKED +
                    (annUnCheckedHGList.length > 1 ? [annUnCheckedHGList.slice(0, -1).join(', '),
                    annUnCheckedHGList.slice(-1)[0]].join(' and ')
                        : annUnCheckedHGList[0]);
                tempNotifications.push(notifyMsg)
            }
        }
     
        
        let limExtnWarnMsg = "", emptyHGList = [];
        //let _hgWithQueueLength = []; 
        let vmDisabledHuntGroups = [], vmHighPriorityHuntGroups = []; let hgWithRingGroup_unsupportedMembersCount = [];
        let _hgWithOverFlowMode = [];
        this.tableHuntGroupList.map((hgEntry)=>{
            if(!hgEntry.isPagingEnabled && this._ipoHuntGroup !== undefined && this.isHGMigrated(hgEntry.extension,null)  &&  hgEntry.siteName === key){
                let ipoHGEntry = this._ipoHuntGroup.find((entry) => { return entry.Group.GR_Extension === hgEntry.extension.toString() });
                let members = ipoHGEntry.Group.GR_Extensions;                
                
                if (members.length === 0) {
                    emptyHGList.push(hgEntry.name);
                } else {
                    //list of members with Limited Extensions
                    let limExtnMem  = [];
                    //list of members who are deleted
                    let deletedMemExtns = members.filter((memExtn: any) => {
                        memExtn = new RegExp("\\*[0-9]+").test(memExtn) ? memExtn.substring(1) : memExtn;
                        let isUserNotDeleted = this.isUserNotDeleted(memExtn);
                        //Push all the Limited Extension members to limExtnMem
                        let isLimitedExtn = this.tableUserList.find((entry)=>{
                            return (entry.extension.toString() === memExtn && this.isLimitedExtn(entry.acoPhoneModel));
                        })
                        if(isUserNotDeleted && isLimitedExtn)
                         limExtnMem.push(memExtn);
                        return !isUserNotDeleted;
                    })
                    if (deletedMemExtns.length === ipoHGEntry.Group.GR_Extensions.length) {
                        emptyHGList.push(hgEntry.name);
                    }else{                        
                        if(limExtnMem.length > 0){
                            limExtnWarnMsg += hgEntry.name + "("+ limExtnMem.join(",")+") , ";
                            //There are no members migrated 
                            let notMigratingMemLen = deletedMemExtns.length + limExtnMem.length;
                            if(notMigratingMemLen === members.length){
                                emptyHGList.push(hgEntry.name);
                            }
                        }
                    }
                    let removedMemLength = (deletedMemExtns.length + limExtnMem.length)
                    if((members.length - removedMemLength) > this._cqMemberLimit && ipoHGEntry.Group.GR_RingMode === "RingGroup" && ipoHGEntry.Group.GR_CallWaiting.toLowerCase() == "true"){
                        hgWithRingGroup_unsupportedMembersCount.push(ipoHGEntry.Group.GR_Name);
                    } 
                }

                /*Prioritizing voicemail before hunt group overflow i.e. 
                * If voicemail is enabled , Voicemail answer time is migrated to 'Maximum caller wait time in the queue'
                * If voicemail is disabled, hunt group overflow time is migrated to  'Maximum caller wait time in the queue'
                * If voicemail is disabled and hunt group overflow time is 0, then max_hold time(900s) is migrated to 'Maximum caller wait time in the queue'
                */
                //ACOS-1467 queue length set to No Limit , maxCallers will be set with 25 and holdTIme will be set to 900
                //Voicemail disabled, queuing limit => 0 ,Overflow time => 0
                // if(ipoHGEntry.Queue.GR_Queueing.toLowerCase() === "true" && ipoHGEntry.Queue.GR_QueueingLimit === '0' &&
                // ipoHGEntry.Group !== undefined && ipoHGEntry.Group.GR_Voicemail !== undefined && ipoHGEntry.Group.GR_Voicemail.toLowerCase() === 'false' && ipoHGEntry.Overflow && ipoHGEntry.Overflow.GR_OverflowTime === ""){
                //     _hgWithQueueLength.push(ipoHGEntry.Group.GR_Name);
                // }

                if(ACO_SERVICE_EDITION_LIST.includes(this._acoServiceEdition.toLowerCase())){
                    if (ipoHGEntry.Group !== undefined && ipoHGEntry.Group.GR_Voicemail !== undefined && 
                        (ipoHGEntry.Group.GR_Voicemail.toLowerCase() === 'false' ||
                            (ipoHGEntry.Group.GR_VoicemailTime !== undefined && parseInt(ipoHGEntry.Group.GR_VoicemailTime) > 0))) {
                        vmDisabledHuntGroups.push(ipoHGEntry.Group.GR_Name);                   
                    }else{
                        if(ipoHGEntry.Group !== undefined && ipoHGEntry.Group.GR_Voicemail !== undefined &&
                            ipoHGEntry.Group.GR_Voicemail.toLowerCase() === 'true' && ipoHGEntry.Group.GR_VoicemailTime !== undefined &&
                            parseInt(ipoHGEntry.Group.GR_Voicemail) > 0 && 
                            (ipoHGEntry.Overflow.GR_OverflowTime === "" || parseInt(ipoHGEntry.Overflow.GR_OverflowTime) > parseInt(ipoHGEntry.Group.GR_Voicemail))){
                                vmHighPriorityHuntGroups.push(ipoHGEntry.Group.GR_Name);
                        }
                    }
                }
                
            }
        })

        if(hgWithRingGroup_unsupportedMembersCount.length > 0){
            let warningText = HG_ERROR_MSG.HG_RINGGROUP_WITH_UNSUPPORTED_MEMBERS_COUNT+this._cqMemberLimit + " members: " + hgWithRingGroup_unsupportedMembersCount.join(' , ');
            tempNotifications.push(warningText); 
        }

        // if(_hgWithQueueLength.length > 0){
        //     let warningText = HG_ERROR_MSG.HG_QUEUE_LENGTH_NO_LIMIT + _hgWithQueueLength.join(' , ');
        //    tempNotifications.push(warningText); 
        // }       
        
        if(limExtnWarnMsg !== ""){  
            //Remove the trailing comma
            limExtnWarnMsg = limExtnWarnMsg.trim().substring(0,limExtnWarnMsg.length-2);
            limExtnWarnMsg = HG_ERROR_MSG.LIM_EXTN_UNSUPPORTED + limExtnWarnMsg;
            tempNotifications.push(limExtnWarnMsg);
        }
        if(emptyHGList.length > 0){
            notifyMsg = HG_ERROR_MSG.EMPTY_GROUP + emptyHGList.join( " , ");
            tempNotifications.push(notifyMsg);
        }
        let hgWithMessageOnly = [];
        let hgExtnList = [];
        let msgOnlyHGList = [];
        let pickupmemberCQlimit = [];
        let CQwithUserLimitExceeded = [];
        if(this._ipoHuntGroup !== undefined && this._ipoHuntGroup.length > 0)
        {
            this._ipoHuntGroup.forEach(element => {
            if(element.Group.GR_Extensions.length >0)
            {
                let tempextnData = [];
                let tempHgData = [];
                element.Group.GR_Extensions.forEach(extn => {
                   this._tableUserList.forEach(data =>{
                       if(JSON.stringify(data.extension) === extn && data.phone === "message-only" && data.siteName === key)
                            {
                                tempHgData.push(element.Group.GR_Name);
                                tempextnData.push(extn);
                            }
                   })
                });
                if(tempHgData.length > 0)
                {
                    hgWithMessageOnly = [...new Set(tempHgData)];
                    hgExtnList = [...new Set(tempextnData)];
                    if(hgExtnList.length > 0)
                    {
                        msgOnlyHGList.push({
                            "name" : hgWithMessageOnly,
                            "extnId" : hgExtnList
                    })
                    }
                }
            }
            });
            this.tableUserList.forEach(user =>{
                let count = 0;
                this._ipoHuntGroup.forEach(element => {
                    if(element.Group.GR_Extensions.length > 0 && element.Sitename === key)
                    {
                        if(element.Group.GR_Extensions.includes(JSON.stringify(user.extension)) && element.Group.GR_RingMode === "RingGroup" && element.Group.GR_CallWaiting.toLowerCase() == "true")
                            count ++;
                    }
                });
                if(count > 10 )
                    pickupmemberCQlimit.push(JSON.stringify(user.extension));
                })
            if(pickupmemberCQlimit.length > 0)
                {
                    this._ipoHuntGroup.forEach(element => {
                        let found = element.Group.GR_Extensions.some(value=> pickupmemberCQlimit.includes(value))
                        if(found)
                            CQwithUserLimitExceeded.push(element.Group.GR_Name);
                    })
                    if(CQwithUserLimitExceeded.length > 0)
                    notifyMsg = HG_ERROR_MSG.HG_PICKUP_MEMBER_CQ_LIMIT_EXCEEDED + CQwithUserLimitExceeded;
                    tempNotifications.push(notifyMsg);
                }          
        } 
        if(msgOnlyHGList.length > 0)
        {
            let msgOnlyHGString = ""
            msgOnlyHGList.forEach(data =>{
                if(hgNameList.includes(data.name[0]))
                {
                    msgOnlyHGString += data.name[0] + "(" + data.extnId.join(",")+") , ";
                }
            })
            if(msgOnlyHGString !== "")
            {
                msgOnlyHGString = msgOnlyHGString.trim().substring(0,msgOnlyHGString.length-2);
                notifyMsg = HG_ERROR_MSG.MSG_ONLY_UNSUPPORTED + msgOnlyHGString;
                tempNotifications.push(notifyMsg);
            }
        }
        
        if(this._zeroDialHGtoPgData && this._zeroDialHGtoPgData !== "")
        {
            notifyMsg = "Call Queue extension number(s) " + this._zeroDialHGtoPgData + " is set as Zero Dialing extension number(s).Converting to Paging Only will disable Zero Dialing.";
            tempNotifications.push(notifyMsg);
        }
        if (this._ipoHuntGroup !== undefined && this._ipoHuntGroup.length > 0) {
            this.collectiveCallWaiting = [];
            this.emptyCollectiveCallWaiting = [];
            this.pickupMemberswithExceededLimit = [];
            this.cqMemberLimitExceeded = [];
            this._ipoHuntGroup.forEach(element => {
                if (element.Group.GR_RingMode === "RingGroup" && element.Group.GR_CallWaiting.toLowerCase() == "true" && hgNameList.includes(element.Group.GR_Name) && this.isHGMigrated(element.Group.GR_Extension) && element.Sitename === key) {
                    this.collectiveCallWaiting.push(element.Group.GR_Name);
                    if(element.Group.GR_Extensions.length > PICKUP_MEMBER_LIMIT && element.Sitename === key)
                        this.pickupMemberswithExceededLimit.push(element.Group.GR_Name);
                    if(element.Group.GR_Extensions.length > this._cqMemberLimit && element.Sitename === key)
                        this.cqMemberLimitExceeded.push(element.Group.GR_Name);
                    this.tableHuntGroupList.forEach(updatedHG =>{
                        if (element.Group.GR_Name === updatedHG.name && updatedHG.totalMembers === 0)
                        this.emptyCollectiveCallWaiting.push(updatedHG.name);
                    });
                }               
            });
        }
        if (this.collectiveCallWaiting.length > 0) {
            notifyMsg = HG_ERROR_MSG.COLLECTIVE_CALLWAIT +
                (this.collectiveCallWaiting.length > 1 ? [this.collectiveCallWaiting.slice(0, -1).join(', '),
                this.collectiveCallWaiting.slice(-1)[0]].join(' and ') :
                    this.collectiveCallWaiting[0]);
            tempNotifications.push(notifyMsg);
        }
        if(this.emptyCollectiveCallWaiting.length > 0)
        {
            notifyMsg = HG_ERROR_MSG.EMPTY_MEMBERLIST+
                (this.emptyCollectiveCallWaiting.length > 1 ? [this.emptyCollectiveCallWaiting.slice(0, -1).join(', '),
                this.emptyCollectiveCallWaiting.slice(-1)[0]].join(' and ') :
                this.emptyCollectiveCallWaiting[0]);
            tempNotifications.push(notifyMsg);
        }
        if(this.pickupMemberswithExceededLimit.length > 0)
        {
            notifyMsg = HG_ERROR_MSG.PICKUP_MEMBERS_LIMIT_EXCEEDED +
            (this.pickupMemberswithExceededLimit.length > 1 ? [this.pickupMemberswithExceededLimit.slice(0, -1).join(', '),
            this.pickupMemberswithExceededLimit.slice(-1)[0]].join(' and ') :
                this.pickupMemberswithExceededLimit[0]);
        tempNotifications.push(notifyMsg);
        }
        if(this.cqMemberLimitExceeded.length > 0)
        {
            notifyMsg = HG_ERROR_MSG.PICKUP_MEMBERS_NOT_MIGRATED+
            (this.cqMemberLimitExceeded.length > 1 ? [this.cqMemberLimitExceeded.slice(0, -1).join(', '),
            this.cqMemberLimitExceeded.slice(-1)[0]].join(' and ') :
                this.cqMemberLimitExceeded[0]);
        tempNotifications.push(notifyMsg);
        }

        //If Voicemail Answer Time is greater than 900s,then push the HG name into htExceedingHGList
        let holdTimeExceedingHGList = [], MAX_HOLD_TIME = 900, MAX_CALLERS =  25;
        if(this._ipoHuntGroup !== undefined && this._ipoHuntGroup.length > 0){
            this._ipoHuntGroup.map((hgEntry)=>{
                if(hgEntry.Group !== undefined && hgEntry.Group.GR_VoicemailTime !== undefined && hgEntry.Group.GR_VoicemailTime > MAX_HOLD_TIME && this.isHGMigrated(hgEntry.Group.GR_Extension) && hgEntry.Sitename === key){
                    holdTimeExceedingHGList.push(hgEntry.Group.GR_Name);
                }
            })
        }
        if(holdTimeExceedingHGList.length > 0){
            tempNotifications.push(HG_ERROR_MSG.HOLD_TIME_EXCEEDED+ holdTimeExceedingHGList.join(", "));
        }

        let vmDisabledWaitTimeDefaultValue = [];
        let vmNOverflowDisabledGroups = [];// vmNoverflowDisabledGroups=> groups where vm => disabled, overflow=>disabled, overflowlist=>0
        //Overflow groups can be configured even when voicemail time is enabled now, commenting out the below check
        //if(vmDisabledHuntGroups.length > 0){
            let hgWithEmptyOverflowGrps = [];
            let hgWithSimultaneousRingType = [];
            let hgWithOverflowToggleDisabled = [];
            let maxOverflowTimeExceeded = [];
            let maxQueueLengthExceeded = [];
            if(this._acoServiceEdition === "standard"){
                tempNotifications.push(HG_ERROR_MSG.HG_OVERFLOW_FEATURE_NOT_SUPPORTED);
            }else{                 
                let pagingGroups = this.tableHuntGroupList.filter(entry=>entry.isPagingEnabled).map((entry)=>entry.name); 
                let reservedGroups = this.tableHuntGroupList.filter(entry=>this.reservedExtensionsList.includes(entry.extension)).map(entry=>entry.name);         
                for(let i=0;i<this.tableHuntGroupList.length;i++){
                    let hgEntry  = this.tableHuntGroupList[i];
                    if(hgEntry.isPagingEnabled === false){
                        notifyMsg = "";
                        let ipoEntry = this._ipoHuntGroup.find(entry=>hgEntry.extension.toString() == entry.Group.GR_Extension && entry.Sitename === key);
                        if(ipoEntry !== undefined){
                            //if(vmDisabledHuntGroups.includes(ipoEntry.Group.GR_Name)){
                                let overflowTime = 0;
                                if(ipoEntry.Overflow && ipoEntry.Overflow.GR_OverflowTime)
                                overflowTime =  parseInt(ipoEntry.Overflow.GR_OverflowTime) || 0;
                                
                                if(ipoEntry.Overflow &&  ipoEntry.Overflow.GR_Overflow && ipoEntry.Overflow.GR_Overflow.length > 0){
                                    
                                    if(overflowTime > MAX_HOLD_TIME)
                                        maxOverflowTimeExceeded.push(ipoEntry.Group.GR_Name);
                                    //notifyMsg += HG_ERROR_MSG.HG_OVERFLOW_WAIT_TIME_EXCEEDED;                         
                                    
                                    if(ipoEntry.Overflow.GR_OverflowMode === 'CALLANDGROUP' && ipoEntry.Overflow.GR_OverflowTime != ''){
                                        _hgWithOverFlowMode.push(ipoEntry.Group.GR_Name);
                                    }

                                    if (hgEntry.ringMode.trim() === "RingGroup") {
                                        hgWithSimultaneousRingType.push(hgEntry.name);
                                    }
                                    
                                    if(ipoEntry.Queue){
                                        let queueLimit = parseInt(ipoEntry.Queue.GR_QueueingLimit) || 0;
                                        if(queueLimit > MAX_CALLERS || queueLimit === 0){
                                            maxQueueLengthExceeded.push(ipoEntry.Group.GR_Name);
                                            //notifyMsg += "\n" + HG_ERROR_MSG.HG_OVERFLOW_MAX_CALLERS_EXCEEDED;
                                        }
                                    }  
                                    
                                    let skippedPagingGroups  = ipoEntry.Overflow.GR_Overflow.filter((entry)=>{
                                        return pagingGroups.includes(entry);
                                    });
                                    
                                    skippedPagingGroups = Array.from(new Set(skippedPagingGroups));
                                    if(skippedPagingGroups && skippedPagingGroups.length > 0){
                                        notifyMsg += "\n" + HG_ERROR_MSG.HG_OVERFLOW_PAGING_GRP_SKIPPED + skippedPagingGroups.join(",");
                                    }
                                    let skippedReservedExtensions = ipoEntry.Overflow.GR_Overflow.filter((entry)=>{
                                        return reservedGroups.includes(entry);
                                    });
                                    skippedReservedExtensions = Array.from(new Set(skippedReservedExtensions));
                                    if(skippedReservedExtensions && skippedReservedExtensions.length > 0){
                                        notifyMsg += "\n" + HG_ERROR_MSG.HG_OVERFLOW_RESERVED_EXTN_SKIPPED + skippedReservedExtensions.join(",") ;
                                    }
                                    
                                    let rejectedGroups = ipoEntry.Overflow.GR_Overflow.filter(entry=>{return !(hgEntry.overflowGroup.includes(entry)) && 
                                        !skippedPagingGroups.includes(entry) && !skippedReservedExtensions.includes(entry)});
                                        rejectedGroups = Array.from(new Set(rejectedGroups));
                                        if(rejectedGroups !== undefined && rejectedGroups.length > 0) {
                                            if (hgEntry.isCallQueueMigrationEnabled) {
                                                notifyMsg += "\n" + HG_ERROR_MSG.HG_OVERFLOW_REJECTED_GROUPS + ' ' + rejectedGroups.join(",");
                                            } else if (hgEntry.isRoutingMigrationEnabled) {
                                                notifyMsg += "\n" + HG_ERROR_MSG.HG_ROUTING_REJECTED_GROUPS + ' ' + rejectedGroups.join(",");
                                            }
                                        }

                                        notifyMsg = notifyMsg.trim();
                                        if(notifyMsg !== "") {
                                            notifyMsg = hgEntry.name +": " + notifyMsg;
                                            tempNotifications.push(notifyMsg);
                                        }
                                        if(hgEntry.overflowGroup === undefined || hgEntry.overflowGroup === null || 
                                            hgEntry.overflowGroup.length === 0)
                                        hgWithEmptyOverflowGrps.push(hgEntry.name);

                                        if(hgEntry.overflowGroup !== undefined && hgEntry.overflowGroup !== null && 
                                            hgEntry.overflowGroup.length > 0 && hgWithSimultaneousRingType.includes(hgEntry.name) &&
                                            !(ipoEntry.Group.GR_Voicemail !== undefined && ipoEntry.Group.GR_Voicemail.toLowerCase() === 'true' && 
                                            ipoEntry.Group.GR_VoicemailTime === "0")) {
                                                hgWithOverflowToggleDisabled.push(hgEntry.name);
                                        }

                                        if (vmDisabledHuntGroups.includes(hgEntry.name) && (hgEntry.isRoutingMigrationEnabled || hgEntry.isCallQueueMigrationEnabled)) {
                                            vmDisabledWaitTimeDefaultValue.push(hgEntry.name);
                                        }

                                        vmNOverflowDisabledGroups.push(ipoEntry.Group.GR_Name);
                                    }                       
                                //}
                            }
                    }
   
                }
                if(hgWithSimultaneousRingType.length > 0) {
                    notifyMsg = HG_ERROR_MSG.HG_OVERFLOW_REJECT_SIMULTANEOUS_RING_TYPE + hgWithSimultaneousRingType.join(", ");
                    tempNotifications.push(notifyMsg);
                }

                if(_hgWithOverFlowMode.length > 0){
                    notifyMsg = HG_ERROR_MSG.HG_OVERFLOW_MODE_UNSUPPORTED + _hgWithOverFlowMode.join(", ");
                    tempNotifications.push(notifyMsg);
                }
                if(hgWithEmptyOverflowGrps.length > 0){
                    notifyMsg = HG_ERROR_MSG.HG_OVERFLOW_EMPTY_GROUP + hgWithEmptyOverflowGrps.join(", ");
                    tempNotifications.push(notifyMsg);
                }
                if(vmHighPriorityHuntGroups.length > 0){
                    notifyMsg = HG_ERROR_MSG.VM_ENABLED_NO_OVERFLOW + vmHighPriorityHuntGroups.join(", "); 
                    tempNotifications.push(notifyMsg);
                } 
                if (hgWithOverflowToggleDisabled.length > 0) {
                    notifyMsg = HG_ERROR_MSG.HG_OVERFLOW_TOGGLE_DISABLED + hgWithOverflowToggleDisabled.join(", ");
                    tempNotifications.push(notifyMsg);
                }
                if (maxOverflowTimeExceeded.length > 0) {
                    notifyMsg = HG_ERROR_MSG.HG_OVERFLOW_WAIT_TIME_EXCEEDED + maxOverflowTimeExceeded.join(", ");
                    tempNotifications.push(notifyMsg);
                }
                if (maxQueueLengthExceeded.length > 0) {
                    notifyMsg = HG_ERROR_MSG.HG_OVERFLOW_MAX_CALLERS_EXCEEDED + maxQueueLengthExceeded.join(", ");
                    tempNotifications.push(notifyMsg);
                }
               /* if(_hgWithOverflowTimeDisabled.length > 0){
                    notifyMsg = HG_ERROR_MSG.HG_OVERFLOW_TIME_DISABLED + _hgWithOverflowTimeDisabled.join(",");
                    this.huntGroupWarningsList.push(notifyMsg);
                }   */            
            }
        //}        

        if (vmDisabledWaitTimeDefaultValue.length > 0) {
            if (this._acoServiceEdition.toLowerCase() === "standard") {
            tempNotifications.push((HG_ERROR_MSG.VM_DISABLED_WAITTIME_UPDATE.replace("SERVICE_EDITION", this._acoServiceEdition)) + vmDisabledWaitTimeDefaultValue.join(", "));
            }//if voicemail is disabled , either overflow is disabled or overflow time is set to 0, then show this warning
            //    else  if(vmDisabledWaitTimeDefaultValue.length > 0){            
            //         tempNotifications.push((HG_ERROR_MSG.VM_DISABLED_WAITTIME_UPDATE_OVERFLOW_SUPPORT.replace("SERVICE_EDITION", this._acoServiceEdition)) + vmDisabledWaitTimeDefaultValue.join(", "));
            //     }
        }
        this.cqsiteSpecificNotifications.set(key, tempNotifications);
        this.cqsiteSpecificNotifications.set("Common Notifications", commonNotifications);
    }) 
    this._huntGroupWarningsList = this.cqsiteSpecificNotifications;
    }

    private isHGMigrated(groupExtn,groupName=null){
        let isMigrated = true;
        if(groupExtn == null && groupName == null){
            this.logger.error("isHGMigrated() called with invalid arg types");
            isMigrated = false;
        }else{
            if(groupExtn !== null){ 
                    //if(typeof(groupExtn) === "string"){
                    groupExtn = (groupExtn !== undefined) ?parseInt(groupExtn):groupExtn;
                    //}               
                    if(groupExtn === undefined || groupExtn.toString() === "" || isNaN(groupExtn))
                        isMigrated = false
                    else if(this.reservedExtensionsList.includes(groupExtn)){
                        isMigrated = false;
                    }else if(this._assignedExtnList.includes(groupExtn.toString())){
                        isMigrated = false;
                    }
            }else if(groupName !== null){
                let usr = this.tableHuntGroupList.find(entry=>entry.name===groupName);
                if(usr!==undefined){
                    return this.isHGMigrated(usr.extension,null);
                }else{
                    this.logger.error("isHGMigrated() called with invalid call-queue name")
                   isMigrated = false;
                }
            }
        }

        return isMigrated ;
    }
    private updateAdvUserInfoTableData(userGreetingsData,ignoredUsers,partialUsers){

        let notifMsg;
        let stCodes = [];

        this._ringCountExceededExtensionmap.forEach((value:[], key: string) =>{
            if(value.length > 0){
             this.advInfositeSpecificNotifications.get(key).push(USER_BUSINESS_HOURS.RING_COUNT_EXCEEDS_THRESHOLD + value);
            }            
        })
        if(!(this._isInternationalCallingEnabled)) {
                let genericMsg = TWIN_CONTACT_NUMBERS.INTERNATIONAL_CALLING_DISABLED;
                this.advInfositeSpecificNotifications.get("Common Notifications").push(genericMsg);
        }
        //If _invalidTwinTimeProfileList has entries, notify warning on invalid time profiles configured to twin number.
        this._invalidTwinTimeProfileList.forEach((value:[], key: string) =>{
            if(value.length >0)  
                this.advInfositeSpecificNotifications.get(key).push(TWIN_CONTACT_NUMBERS.TWIN_DATE_NOT_SELECTED_TIME_PROFILE + value);
            })     

        if(this.steeringCodeData !== undefined && this.steeringCodeData !== null 
            && this.steeringCodeData.length > 0){
                this.advInfositeSpecificNotifications.forEach((value:[], key: string) =>{
                    this.steeringCodeData.forEach(function(stCodeData) {
                        if (!stCodes.includes(stCodeData.Steering_Code) && key === stCodeData.FileName) {
                            stCodes.push(stCodeData.Steering_Code);
                        }
                    }); 
            if(stCodes.length !== 0){
                notifMsg = TWIN_CONTACT_NUMBERS.STEERING_CODES_REMOVAL + stCodes.join(", ");
                this.advInfositeSpecificNotifications.get(key).push(notifMsg);
            }
        });
        }

        if(userGreetingsData !== undefined || ignoredUsers !== undefined || partialUsers !== undefined){
            this.advInfositeSpecificNotifications.forEach((value:[], key: string) =>{
                var usrsWOGreetings=[], usrsWithPartialGreetings=[],ignoredUsrsList=[];
                let notifyMsg, limExtnList =[];
                this.messageOnlyWithBLF = [];
            this._tableUserList.forEach(tableEntry=>{

                if(key == tableEntry.siteName)
                {
                let ipoUserName = tableEntry.ipoUserName;

                let isUploadSuccess = false, isUploadPartial = false,isUserIgnored = false;
                if(userGreetingsData !== undefined && Object.keys(userGreetingsData).length > 0){
                    isUploadSuccess = (userGreetingsData.filter((entry=> entry.Name === ipoUserName)).length) > 0 ? true : false;
                }
                tableEntry.vmGreetings = isUploadSuccess;   
                if(ignoredUsers !== undefined){
                    ignoredUsers = ignoredUsers.filter(user=>user);
                    isUserIgnored = (ignoredUsers.filter((entry=> entry === ipoUserName)).length)> 0 ? true : false;
                }
                if(partialUsers !== undefined){
                    partialUsers = partialUsers.filter(user=>user);
                    isUploadPartial = (partialUsers.filter((entry=> entry === ipoUserName)).length) > 0 ? true : false;
                }
                if(tableEntry)
                if(!isUploadSuccess){
                    if(isUploadPartial){
                        usrsWithPartialGreetings.push(tableEntry.username);
                    }else if(isUserIgnored){
                        ignoredUsrsList.push(tableEntry.username);
                    }else if (userGreetingsData !== undefined && Object.keys(userGreetingsData).length > 0){
                        usrsWOGreetings.push(tableEntry.username);
                    }
                }else{
                    if(this.isLimitedExtn(tableEntry.acoPhoneModel)){
                        limExtnList.push(tableEntry.username);
                    }
                }
            }
            });
            if(limExtnList.length > 0){
                notifyMsg = VM_ERROR_MSG.LIM_EXTN_UNSUPPORTED + limExtnList.join(" , ");
                this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
            }
            notifyMsg = "";
            if(usrsWOGreetings.length > 0){
                notifyMsg = VM_ERROR_MSG.GREETINGS_MISSING + " for ";
                notifyMsg += usrsWOGreetings.length > 1 ? [usrsWOGreetings.slice(0, -1).join(', '), 
                                                    usrsWOGreetings.slice(-1)[0]].join(' and '):usrsWOGreetings[0];
                this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
            }
            if(usrsWithPartialGreetings.length > 0){
                notifyMsg = VM_ERROR_MSG.PARTIAL_USERS + " for ";
                notifyMsg += usrsWithPartialGreetings.length > 1 ? [usrsWithPartialGreetings.slice(0, -1).join(', '), 
                        usrsWithPartialGreetings.slice(-1)[0]].join(' and '):usrsWithPartialGreetings[0];
                this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
            }
            if(ignoredUsrsList.length > 0){
                notifyMsg = VM_ERROR_MSG.IGNORED_USERS + " for ";
                notifyMsg += ignoredUsrsList.length > 1 ? [ignoredUsrsList.slice(0, -1).join(', '), 
                    ignoredUsrsList.slice(-1)[0]].join(' and '):ignoredUsrsList[0];
                this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
            }
        })    
        }else{
            this._tableUserList.forEach(tableEntry => tableEntry.vmGreetings = false);
            //Warnings list is already updated while initializing the table
        }
        this.advInfositeSpecificNotifications.forEach((value:[], key: string) =>{
		let newValue = [...new Set(this.advInfositeSpecificNotifications.get(key))];
        this.advInfositeSpecificNotifications.set(key,newValue);
        })
    
        this.advncdUserInfoWarningsList = this.advInfositeSpecificNotifications;
    }

    private updateComplexCallFlowWarnings(aaComplexCallFlowList, aaTimeoutList, conferenceMeetMeList) {
        if (aaComplexCallFlowList !== undefined && aaComplexCallFlowList.length > 0) {
            aaComplexCallFlowList = aaComplexCallFlowList.filter(entry => entry);
            if (aaComplexCallFlowList.length > 0) {
                let notifyMsg = AA_ERROR_MSG.COMPLEX_CALL_FLOW +
                    (aaComplexCallFlowList.length > 1 ? [aaComplexCallFlowList.slice(0, -1).join(', '),
                    aaComplexCallFlowList.slice(-1)[0]].join(' and ') : aaComplexCallFlowList[0]);
                this.aasiteSpecificNotifications.get("Common Notifications").push(notifyMsg);
            }
        }
        if (aaTimeoutList !== undefined && aaTimeoutList.length > 0) {
            aaTimeoutList = aaTimeoutList.filter(entry => entry);
            if (aaTimeoutList.length > 0) {
                let notifyMsg = AA_ERROR_MSG.TIMEOUT_PRESENT +
                    (aaTimeoutList.length > 1 ? [aaTimeoutList.slice(0, -1).join(', '),
                    aaTimeoutList.slice(-1)[0]].join(' and ') : aaTimeoutList[0]);
                this.aasiteSpecificNotifications.get("Common Notifications").push(notifyMsg);
            }
        }
        if (conferenceMeetMeList !== undefined && conferenceMeetMeList.length > 0) {
            conferenceMeetMeList = conferenceMeetMeList.filter(entry => entry);
            if (conferenceMeetMeList.length > 0) {
                let notifyMsg = AA_ERROR_MSG.CONFERENCE_MEET_ME +
                    (conferenceMeetMeList.length > 1 ? [conferenceMeetMeList.slice(0, -1).join(', '),
                    conferenceMeetMeList.slice(-1)[0]].join(' and ') : conferenceMeetMeList[0]);
                this.aasiteSpecificNotifications.get("Common Notifications").push(notifyMsg);
            }
        }
    }

    private async icrDestinationWarnings(){
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.readOriginalAA_ICRData(this._cardId)
                .then((doc: any) => {
                    let icrDoc = (doc !== null) ? JSON.parse(doc.data) : [];
                    if (icrDoc !== undefined && icrDoc.length > 0) {
                    this.icrsiteSpecificNotifications.forEach((value:[], key: string) =>{
                        let icrDestinationMissingList =  [];
                        this.icrsiteSpecificNotifications.set(key,[]);
                        icrDoc.forEach((icrEntry: any) => {
                            icrEntry.forEach((eachIcr: any) => {
                                if(eachIcr.siteName === key)
                                {
                                    if (this._tableAutoAttendantList.find((aaEntry: any) => (aaEntry.name === eachIcr.name)) === undefined) {
                                        if (icrDestinationMissingList.find((icr: any) => (icr === eachIcr.name)) === undefined) 
                                            icrDestinationMissingList.push(eachIcr.name);
                                        }
                                }
                            });
                        })   

                    if (icrDestinationMissingList.length > 0) {
                        let notifMsg = AA_ERROR_MSG.ICR_AA_NOT_FOUND +
                            (icrDestinationMissingList.length > 1 ? [icrDestinationMissingList.slice(0, -1).join(', '),
                            icrDestinationMissingList.slice(-1)[0]].join(' and ') : icrDestinationMissingList[0]);
                        this.icrsiteSpecificNotifications.get(key).push(notifMsg);
                    }
                })
                this.icrsiteSpecificNotifications.forEach((value:[], key: string) =>{
                
                })
            }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
            return promise;
        });
    }

    private validateAAkeyInputs(aaEntry){
        let  tempMsg:string="";
        let  faxMsg:string="";
        let  starAndHashMsg:string="";
        let  invalidDestMsg:string="";
        let invalidPhoneNumber:string = "";
        let dialByExtnWarn = [];
        for(let ipoActions of aaEntry.AA_Actions){
           if(ipoActions.Key == IPOMENU_KEYACTIONS.FAX.action && ipoActions.Action != IPOMENU_KEYACTIONS.NOT_DEFINED.action){
            faxMsg = "\n" + "\t" +ipoActions.Key + " - " +  IPOMENU_KEYACTIONS.FAX.description ;
            }
           else if(ipoActions.Key == IPOMENU_KEYACTIONS.STAR.action && ipoActions.Action != IPOMENU_KEYACTIONS.REPLAY_GREETING.action && ipoActions.Action != IPOMENU_KEYACTIONS.NOT_DEFINED.action){
            starAndHashMsg +="\n"+ "\t" +ipoActions.Key + " - " +  IPOMENU_KEYACTIONS.STAR.description ;
            }
            else if(ipoActions.Key == IPOMENU_KEYACTIONS.HASH.action && ipoActions.Action != IPOMENU_KEYACTIONS.REPLAY_GREETING.action && ipoActions.Action != IPOMENU_KEYACTIONS.NOT_DEFINED.action){
            starAndHashMsg +="\n"+ "\t" + ipoActions.Key + " - " +  IPOMENU_KEYACTIONS.HASH.description;
            }
            else if(ipoActions.Key != IPOMENU_KEYACTIONS.FAX.action && ipoActions.Key != IPOMENU_KEYACTIONS.STAR.action && ipoActions.Key != IPOMENU_KEYACTIONS.HASH.action)
            {
                if(ipoActions.Destination.trim() == "")
                 tempMsg += this.fetchInvalidDestinationWarnings(ipoActions);
                else{
                    let skipPhoneNumberValidation = false;
                    let extnNum = Number(ipoActions.Destination.trim());//parseInt(ipoActions.Destination.trim());
                    if(Number.isInteger(extnNum)){
                        if((!this.isUserNotDeleted(extnNum)|| (this.isHGExtn(extnNum) && this.checkIfHGExists(extnNum)))){
                            invalidDestMsg += ipoActions.Key + " - " + ipoActions.Action  + " <"+ ipoActions.Destination + "> , ";
                            skipPhoneNumberValidation = true;
                        }
                        if(!skipPhoneNumberValidation){
                            if(!this.isValidPhoneFormat(ipoActions.Destination.trim())){
                                invalidPhoneNumber += ipoActions.Key + " - " + ipoActions.Action  + " <"+ ipoActions.Destination +" > , ";
                            }
                        }
                    }
                }
                if (/^\d/.test(ipoActions.Action)) {
                    tempMsg += ipoActions.Action + " - " + AA_ERROR_MSG.UNKNOWN_ACTION + " , ";
                }
                if (ipoActions.Key.includes('?')) dialByExtnWarn.push(ipoActions.Key);
            }
        }
        var notifyKeyInputMismatchMsg:string="";
        invalidDestMsg = (invalidDestMsg !== "") ? AA_ERROR_MSG.INVALID_DESTINATION + " for "+ invalidDestMsg :"";
        invalidPhoneNumber = (invalidPhoneNumber !== "") ? AA_ERROR_MSG.INVALID_PHONE_NUMBER + " for Key Actions "+ invalidPhoneNumber :"";
        if(tempMsg.trim() != ""){
            //To remove the unnecessary comma at the end of the list
            if (tempMsg.endsWith(" , ")) {
                tempMsg = tempMsg.substring(0, tempMsg.length-2);
            }
            if(invalidDestMsg.endsWith(" , ")){
                invalidDestMsg = invalidDestMsg.substring(0, invalidDestMsg.length-2);
                invalidDestMsg = "\n\t"+invalidDestMsg;
            }
            if(invalidPhoneNumber.endsWith(" , ")){
                invalidPhoneNumber = invalidPhoneNumber.substring(0, invalidPhoneNumber.length-2);
                invalidPhoneNumber = "\n\t"+invalidPhoneNumber;
            }
            notifyKeyInputMismatchMsg = aaEntry.AA_Name + " : " + AA_ERROR_MSG.UNMATCHED_KEY_ACTIONS + " for " + 
            tempMsg+starAndHashMsg+faxMsg+invalidDestMsg+invalidPhoneNumber;
        }
        else if(tempMsg.trim() == "" && (faxMsg.trim() != "" || starAndHashMsg.trim() != "" || invalidDestMsg != "" || invalidPhoneNumber != "")){
            notifyKeyInputMismatchMsg =  starAndHashMsg+ faxMsg;
            notifyKeyInputMismatchMsg =  notifyKeyInputMismatchMsg.trim() !== ""? notifyKeyInputMismatchMsg : "";
            if (invalidDestMsg.trim() !== "" || invalidPhoneNumber.trim() !== "") notifyKeyInputMismatchMsg = aaEntry.AA_Name + " : "+ notifyKeyInputMismatchMsg  + invalidDestMsg + invalidPhoneNumber;
            else notifyKeyInputMismatchMsg = aaEntry.AA_Name + " : " + notifyKeyInputMismatchMsg;
        }
        else {
            if(aaEntry.AA_Actions === undefined || aaEntry.AA_Actions.length === 0) notifyKeyInputMismatchMsg = aaEntry.AA_Name + " : " + AA_ERROR_MSG.NO_KEYS_TO_MATCH;
            else notifyKeyInputMismatchMsg = aaEntry.AA_Name + " : ";
        }
        if(dialByExtnWarn !== undefined && dialByExtnWarn.length > 0){
            notifyKeyInputMismatchMsg = notifyKeyInputMismatchMsg.trim() !== ""? notifyKeyInputMismatchMsg : aaEntry.AA_Name;
            notifyKeyInputMismatchMsg += '\n\t' + dialByExtnWarn.toString() + " - " + AA_ERROR_MSG.DIAL_BY_EXTN_WARN ;
        }
        if(notifyKeyInputMismatchMsg != ""){    
             let {addAAPromptMissing, addAAPromptReRecord} = this.validateIVRPrompts(this._aaMenuData, aaEntry);
             if (addAAPromptMissing !== undefined && addAAPromptReRecord !== undefined){
                if (addAAPromptMissing === true)  notifyKeyInputMismatchMsg = (notifyKeyInputMismatchMsg.toString().endsWith(": "))? notifyKeyInputMismatchMsg + AA_ERROR_MSG.PROMPTS_MISSING : notifyKeyInputMismatchMsg + "\n\t" + AA_ERROR_MSG.PROMPTS_MISSING;
                if (addAAPromptReRecord == true) notifyKeyInputMismatchMsg = (notifyKeyInputMismatchMsg.toString().endsWith(": "))? notifyKeyInputMismatchMsg + AA_ERROR_MSG.PROMPT_TO_BE_RERECORD :notifyKeyInputMismatchMsg + "\n\t" + AA_ERROR_MSG.PROMPT_TO_BE_RERECORD;
             }
            this.aasiteSpecificNotifications.get(aaEntry.siteName).push(notifyKeyInputMismatchMsg);
        }
    }

    private validateIVRPrompts(aaMenuData, aaEntry){
       let aaPromptFound:any;
       let addAAPromptMissing = false;
       let addAAPromptReRecord = false;
       if (aaMenuData !== undefined && aaMenuData.length > 0 ){
           aaPromptFound = aaMenuData.filter ((entry) => (entry.AA_Name === aaEntry.AA_Name));
           if (aaPromptFound === undefined) {
               const alreadyAdded = this.aasiteSpecificNotifications.get(aaEntry.siteName).find((entry: any) => (entry.includes(aaEntry.name) && entry.includes(AA_ERROR_MSG.PROMPTS_MISSING)))
               if (alreadyAdded === undefined) addAAPromptMissing = true;
           }
           else {
               const alreadyAdded = this.aasiteSpecificNotifications.get(aaEntry.siteName).find((entry: any) => (entry.includes(aaEntry.name) && entry.includes(AA_ERROR_MSG.PROMPT_TO_BE_RERECORD)))
               if (alreadyAdded === undefined) addAAPromptReRecord = true;
           }
       }
       else {
           const alreadyAdded = this.aasiteSpecificNotifications.get(aaEntry.siteName).find((entry: any) => (entry.includes(aaEntry.name) && entry.includes(AA_ERROR_MSG.PROMPTS_MISSING)))
           if (alreadyAdded === undefined) addAAPromptMissing = true;
       }
       return {addAAPromptMissing, addAAPromptReRecord};
    }

    isValidPhoneFormat(extnNum){
        //function will check whether the destination is valid e.164 format.
        if(extnNum.replace(/^(\+)|\D/g, "$1").match(/^\+?[1-9]\d{1,14}$/)){
            return true;
        }else{
            //this.logger.debug("Invalid e.164 format: ",extnNum);
            return false;  
        }
    }

    private fetchInvalidDestinationWarnings(ipoActions) {
        let tempMsg:string="";
        switch(ipoActions.Action){

            case IPOMENU_KEYACTIONS.PARK_AND_PAGE.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.PARK_AND_PAGE.description + " , ";
                break;
            case IPOMENU_KEYACTIONS.DIALBYNUMBER.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.DIALBYNUMBER.description + " , " ;
                break;
            case IPOMENU_KEYACTIONS.REPLAY_GREETING.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.REPLAY_GREETING.description + " , " ;
                break;
            case IPOMENU_KEYACTIONS.CENTREX_TRANSFER.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.CENTREX_TRANSFER.description + " , ";
                break;
            case IPOMENU_KEYACTIONS.NORMALTRANSFER.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.NORMALTRANSFER.description + " , ";
                break;
            case IPOMENU_KEYACTIONS.TRANSFER_TO_AA.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.TRANSFER_TO_AA.description + " , ";
                break;
            case IPOMENU_KEYACTIONS.BLINDTRANSFER.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.BLINDTRANSFER.description + " , ";
                break;
            default:
                if (ipoActions.Action !== 'Not_defined' && !ipoActions.Key.toString().includes("?")  && 
                    ipoActions.Action !== IPOMENU_KEYACTIONS.DIALBYNAME.action){
                   tempMsg += ipoActions.Key + " - " + ipoActions.Action.toString() + " , ";
                }
                break;
        }
        return tempMsg;
    }

    public async autoAssignSysmonPhoneModel(data) {
        let promise = new Promise((resolve, reject) => {
            if (this._isSysmonAutoAssigmentInProgress) {
                this.logger.debug("Auto assignment is already in progress, skipping auto assignment now");
                resolve();
            }
            else {
                if (data.filePathName === "" && data.fileSize === 0) {
                    this.logger.debug("No sysmon file uploaded, skipping auto assignment of phones");
                    this._isSysmonDataInitializedFlag = true;
                    this.sendSysmonDocStatus(data.trigger);
                    resolve();
                }
                else if (data.trigger === TRIGGER_STATUS.DONE) {
                    this.logger.debug("Auto assignment of phone models as per uploaded sysmon file already done, skipping this step");
                    this._isSysmonDataInitializedFlag = true;
                    this.sendSysmonDocStatus(data.trigger);
                    resolve();
                }
                else {
                    this.updateFeatureTaskChain(FEATURE_NAME.MAC_UPDATE);
                    this._isSysmonAutoAssigmentInProgress = true;
                    this.downloadFileFromBucket(`Greetings/${this._cardId}/IpoCfg/sysmon.json`)
                        .then((sysmonData: any) => {
                            if (sysmonData !== undefined) {
                                this._sysmonData = sysmonData.data;
                                this._invalidSysmonData = sysmonData.invaliUserEntries;
                                this._duplicateSysmonData = sysmonData.duplicateUserEntries;
                                if (this._deviceExtList !== undefined && this._deviceExtList.length > 0) {
                                    var matchedUser: any;
                                    let ipoPhones: any, matchedProfile: any;

                                    for (let idx = 0; idx < this._sysmonData.length; idx++) {
                                        var acoExtensions = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === this._sysmonData[idx].phoneModel);
                                        matchedUser = this._tableUserList.filter(entry => entry.extension === parseInt(this._sysmonData[idx].userExtension));
                                        matchedUser = matchedUser[0];

                                        if (matchedUser !== undefined && matchedUser.phone === "unassigned" && matchedUser.acoPhoneStatus === 0) {
                                            let matchingDeviceEntry = this._deviceExtList.filter(entry => entry.model == this._sysmonData[idx].phoneModel && entry.macId.toLowerCase() == this._sysmonData[idx].macId.toLowerCase());
                                            //If sysmon file recommended J100 phone models is already present in ACO
                                            if (matchingDeviceEntry[0] !== undefined) {
                                                if (acoExtensions !== undefined && (acoExtensions.count > 0) && acoExtensions.extensionList.includes(matchingDeviceEntry[0].extensionId)) {
                                                    matchedUser.extensionId = matchingDeviceEntry[0].extensionId;
                                                    matchedUser.pageCapable = this.isDevicePageCapable(matchedUser.extensionId);
                                                    matchedUser.did = this.getDID(matchedUser.extensionId);
                                                    matchedUser.acoPhoneStatus = phoneStatus.assigned;
                                                    matchedUser.acoPhoneModel = matchingDeviceEntry[0].model;
                                                    matchedUser.phone = matchingDeviceEntry[0].model;
                                                    matchedUser.macId = matchingDeviceEntry[0].macId;
                                                    matchedUser.isSysmonAssignment = true;
                                                    matchedUser.displayPhoneModel = matchingDeviceEntry[0].model;
                                                    acoExtensions.extensionList = acoExtensions.extensionList.filter(entry => entry !== matchingDeviceEntry[0].extensionId);
                                                    acoExtensions.count--;

                                                    //Remove extension from _ipoUsersByProfile and _ipoUsersByPhone for the assigned extension and decrement the count
                                                    ipoPhones = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedUser.ipoPhoneModel);
                                                    if (ipoPhones !== undefined) {
                                                        const index = ipoPhones.extensionList.findIndex(extn => extn === matchedUser.extension);
                                                        ipoPhones.extensionList.splice(index, 1);
                                                        ipoPhones.count--;
                                                    }

                                                    matchedProfile = this._ipoUsersByProfile.find(entry => entry.profileType === matchedUser.profileType);
                                                    if (matchedProfile !== undefined) {
                                                        const index = matchedProfile.extensionList.findIndex(extn => extn === matchedUser.extension);
                                                        matchedProfile.extensionList.splice(index, 1);
                                                        matchedProfile.count--;
                                                    }

                                                    if (matchedUser.twinMob.length !== 0 && matchedUser.did.length === 0) {
                                                        this.pushDidNotConfiguredTwinList(matchedUser.siteName, matchedUser.extension);
                                                    }
                                                } else {
                                                    //Check for limited extension phone models if the extensionList does not include the matching extensionId 
                                                    var acoExtensions = this._acoExtnByPhoneGroup.find(entry => (entry.phoneModel.toLowerCase() === (this._sysmonData[idx].phoneModel.toLowerCase() + " limited extension")));
                                                    if (acoExtensions !== undefined && (acoExtensions.count > 0) && acoExtensions.extensionList.includes(matchingDeviceEntry[0].extensionId)) {
                                                        matchedUser.extensionId = matchingDeviceEntry[0].extensionId;
                                                        matchedUser.pageCapable = this.isDevicePageCapable(matchedUser.extensionId);
                                                        matchedUser.did = this.getDID(matchedUser.extensionId);
                                                        matchedUser.acoPhoneStatus = phoneStatus.assigned;
                                                        matchedUser.acoPhoneModel = acoExtensions.phoneModel;
                                                        matchedUser.phone = acoExtensions.phoneModel;
                                                        matchedUser.macId = matchingDeviceEntry[0].macId;
                                                        matchedUser.isSysmonAssignment = true;
                                                        matchedUser.displayPhoneModel = acoExtensions.phoneModel;
                                                        acoExtensions.extensionList = acoExtensions.extensionList.filter(entry => entry !== matchingDeviceEntry[0].extensionId);
                                                        acoExtensions.count--;

                                                        //Remove extension from _ipoUsersByProfile and _ipoUsersByPhone for the assigned extension and decrement the count
                                                        ipoPhones = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedUser.ipoPhoneModel);
                                                        if (ipoPhones !== undefined) {
                                                            const index = ipoPhones.extensionList.findIndex(extn => extn === matchedUser.extension);
                                                            ipoPhones.extensionList.splice(index, 1);
                                                            ipoPhones.count--;
                                                        }

                                                        matchedProfile = this._ipoUsersByProfile.find(entry => entry.profileType === matchedUser.profileType);
                                                        if (matchedProfile !== undefined) {
                                                            const index = matchedProfile.extensionList.findIndex(extn => extn === matchedUser.extension);
                                                            matchedProfile.extensionList.splice(index, 1);
                                                            matchedProfile.count--;
                                                        }

                                                        if (matchedUser.twinMob.length !== 0 && matchedUser.did.length === 0) {
                                                            this.pushDidNotConfiguredTwinList(matchedUser.siteName, matchedUser.extension);
                                                        }
                                                    }

                                                }
                                               // this.updateLimExtnList(matchedUser);
                                            }
                                            //If sysmon file recommended phone model is not present in ACO, assign Existing Phone to users
                                            else {
                                                var existingPhoneExtensions = this._acoExtnByPhoneGroup.find(entry => ((entry.phoneModel.toLowerCase() == EXISTING_PHONE)) && entry.count > 0);
                                                if (existingPhoneExtensions === undefined || existingPhoneExtensions.count < 1) {
                                                    var existingPhoneExtensions = this._acoExtnByPhoneGroup.find(entry => ((entry.phoneModel.toLowerCase() == EXISTING_PHONE_LIMITED_EXTENSION)) && entry.count > 0);
                                                }
                                                if (existingPhoneExtensions !== undefined) {
                                                    let extensionId = existingPhoneExtensions.extensionList.shift();
                                                    //assign an existing phone to user entry.
                                                    let existingPhone = this._deviceExtList.filter(entry => ((entry.model.toLowerCase() == EXISTING_PHONE)) && entry.extensionId === extensionId);
                                                    if (existingPhone[0] !== undefined) {
                                                        matchedUser.extensionId = existingPhone[0].extensionId;
                                                        matchedUser.pageCapable = this.isDevicePageCapable(matchedUser.extensionId);
                                                        matchedUser.did = this.getDID(matchedUser.extensionId);
                                                        matchedUser.acoPhoneStatus = phoneStatus.assigned;
                                                        matchedUser.acoPhoneModel = existingPhoneExtensions.phoneModel;
                                                        matchedUser.phone = existingPhoneExtensions.phoneModel;
                                                        matchedUser.macId = this._sysmonData[idx].macId;
                                                        matchedUser.isSysmonAssignment = true;
                                                        if (existingPhoneExtensions.phoneModel.toLowerCase().endsWith(("limited extension"))) {
                                                            matchedUser.displayPhoneModel = this._sysmonData[idx].phoneModel + " Limited Extension";
                                                        } else {
                                                            matchedUser.displayPhoneModel = this._sysmonData[idx].phoneModel;
                                                        }
                                                        existingPhoneExtensions.extensionList = existingPhoneExtensions.extensionList.filter(entry => entry !== existingPhone[0].extensionId);
                                                        existingPhoneExtensions.count--;

                                                        //Remove extension from _ipoUsersByProfile and _ipoUsersByPhone for the assigned extension and decrement the count
                                                        ipoPhones = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedUser.ipoPhoneModel);
                                                        if (ipoPhones !== undefined) {
                                                            const index = ipoPhones.extensionList.findIndex(extn => extn === matchedUser.extension);
                                                            ipoPhones.extensionList.splice(index, 1);
                                                            ipoPhones.count--;
                                                        }

                                                        matchedProfile = this._ipoUsersByProfile.find(entry => entry.profileType === matchedUser.profileType);
                                                        if (matchedProfile !== undefined) {
                                                            const index = matchedProfile.extensionList.findIndex(extn => extn === matchedUser.extension);
                                                            matchedProfile.extensionList.splice(index, 1);
                                                            matchedProfile.count--;
                                                        }

                                                        if (matchedUser.twinMob.length !== 0 && matchedUser.did.length === 0) {
                                                            this.pushDidNotConfiguredTwinList(matchedUser.siteName, matchedUser.extension);
                                                        }
                                                        //this.updateLimExtnList(matchedUser);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    this.validateTableData();
                                    this.updateHGnPGData();
                                    this.updateLimExtnData();
                                    this.updateMOEWarningData();
                                    this.firestoreService.updateSysmonTriggerStatus(this._cardId, TRIGGER_STATUS.DONE, data.filePathName, data.fileSize);
                                    this.sendSysmonDocStatus(data.trigger);
                                    this._isSysmonDataInitializedFlag = true;
                                    this._isSysmonAutoAssigmentInProgress = false;
                                    resolve();
                                } else {
                                    this.firestoreService.updateSysmonTriggerStatus(this._cardId, TRIGGER_STATUS.DONE, data.filePathName, data.fileSize);
                                    this.sendSysmonDocStatus(data.trigger);
                                    this._isSysmonAutoAssigmentInProgress = false;
                                    this._isSysmonDataInitializedFlag = true;
                                    resolve(`There are no devices in the ACO account`);
                                }
                            } else {
                                this.sendSysmonDocStatus(data.trigger);
                                this._isSysmonAutoAssigmentInProgress = false;
                                this._isSysmonDataInitializedFlag = true;
                                reject('Sysmon data fetched from cloud storage is undefined');
                            }
                        }).catch(err => {
                            this.sendSysmonDocStatus(data.trigger);
                            this._isSysmonAutoAssigmentInProgress = false;
                            this._isSysmonDataInitializedFlag = true;
                            reject(`Exception while auto assigning phone models as per sysmon file : ${err} `);
                        })
                }
            }
        });
        return promise;
    }

    public updateSysmonData() {
        this.logger.debug("updateSysmonData");
        let promise = new Promise((resolve, reject) => {
            this.firestoreService.getSysmonFileDetails(this._cardId).then((data: any) => {
                if (data !== undefined && data !== null && data.status === TRIGGER_STATUS.DONE) {
                    if (this._deviceExtList !== undefined && this._deviceExtList.length > 0) {
                        if (this._sysmonData !== undefined && this._sysmonData.length > 0) {
                            this.tableUserList.forEach((entry) => {
                                if (entry !== undefined && entry.isSysmonAssignment && ((entry.acoPhoneModel.toLowerCase() === EXISTING_PHONE) || 
                                (entry.acoPhoneModel.toLowerCase() === EXISTING_PHONE_LIMITED_EXTENSION)) && entry.macId !== undefined) {
                                    let idx = this._sysmonData.findIndex(sysmonEntry => parseInt(sysmonEntry.userExtension.trim()) === entry.extension);
                                    let matchedDeviceExtEntry: any = this._deviceExtList.filter(deviceExtEntry => deviceExtEntry.extensionId === entry.extensionId);
                                    if (idx !== -1 && matchedDeviceExtEntry !== undefined) {
                                        this._sysmonData[idx].canBeSynched = true;
                                        this._sysmonData[idx].extensionId = entry.extensionId;
                                        this._sysmonData[idx].deviceId = matchedDeviceExtEntry[0].deviceId;
                                    }
                                }
                                else if (entry !== undefined && entry.acoPhoneModel === "") {
                                    let idx = this._sysmonData.findIndex(sysmonEntry => parseInt(sysmonEntry.userExtension.trim()) === entry.extension);
                                    if (idx !== -1) {
                                        this._sysmonData[idx].canBeSynched = false;
                                        this._sysmonData[idx].extensionId = "";
                                        this._sysmonData[idx].deviceId = "";
                                    }
                                }
                            });

                            this.fileUploader.uploadJsonFile(this._cardId, '/IpoCfg/sysmon.json', { data: this._sysmonData, totalData: this._sysmonData.length, duplicateUserEntries: this._duplicateSysmonData, invaliUserEntries: this._invalidSysmonData })
                                .then(() => {
                                    this.logger.debug("updated sysmon data successfully");
                                    resolve();
                                });
                        } else {
                            this.logger.debug("Sysmon data is empty - sysmon file is not uploaded");
                            resolve();
                        }
                    } else {
                        this.logger.debug("device extension number(s) list in firestore is undefined or empty");
                        resolve();
                    }
                } else {
                    this.logger.debug("Sysmon trigger status is not DONE");
                    resolve();
                }
            }).catch(err => {
                this.logger.error("Failed to fetch sysmon file details from firestore : ", err);
                reject();
            });
        });
        return promise;
    }

    public async updateTableData(data){ 
        if(data){
            if(data.trigger == TRIGGER_STATUS.DONE){
                //ACOS-1063:Intermittent - Company numbers shown with zero count 
                //this._tableIncomingNumbersListCount = 0;
                const storageFiles:any = await this.getStorageBucketFilesList(`Greetings/${this._cardId}`, 'GreetingsCfg');
                const ipoCfgStorageFiles:any = await this.getStorageBucketFilesList(`Greetings/${this._cardId}`, 'IpoCfg');
                
                //download the latest greetings related files from bucket and update tableview internal arrays for use in InitializeTableData()
                if (storageFiles !== undefined && storageFiles.length > 0) {
                    if (data.aaPromptCount !== 0 && storageFiles.includes('aaMenuOptions.json')) this._aaMenuData = await this.downloadFileFromBucket(`Greetings/${this._cardId}/GreetingsCfg/aaMenuOptions.json`)
                    if (data.userVMCount !== 0 && storageFiles.includes('userGreetings.json')) this._vmGreetingsData = await this.downloadFileFromBucket(`Greetings/${this._cardId}/GreetingsCfg/userGreetings.json`);
                    if (data.vmproCallFlowCount !== 0 && storageFiles.includes('vmproAAParsedFlows.json')) this._vmproAAParsedFlows = await this.downloadFileFromBucket(`Greetings/${this._cardId}/GreetingsCfg/vmproAAParsedFlows.json`)
                    else if(ipoCfgStorageFiles !== undefined && Object.keys(ipoCfgStorageFiles).length > 0 && ipoCfgStorageFiles.includes('autoAttendant.js')) this._aaMenuKeysData = await this.downloadFileFromBucket(`Greetings/${this._cardId}/IpoCfg/autoAttendant.json`);
                }
                this.updateMOEWarningData();
                
                this.advInfositeSpecificNotifications.forEach((value:[], key: string) =>{
                    let updatedvalue = [];
                    updatedvalue =   this.advInfositeSpecificNotifications.get(key).filter((entry)=>{
                        return (entry.includes(TWIN_CONTACT_NUMBERS.LIM_EXTN_NOT_SUPPORTED) || entry.includes(BLF_ERROR_MSG.LIM_EXTN_NOT_SUPPORTED) ||
                        entry.includes(USER_BUSINESS_HOURS.LIM_EXTN_NOT_SUPPORTED));                       
                    })
                    this.advInfositeSpecificNotifications.set(key,updatedvalue)
                });
                this._advncdUserInfoWarningsList = this.advInfositeSpecificNotifications;
                this.invalidAnsRuleTimeProfilesList = [];
                this.updateAdvUserInfoTableData( this._vmGreetingsData,JSON.parse(data.ignoredUsers),JSON.parse(data.partialUsers));
                
                this._hgWarnObj = {
                    missingHGAnnList:data.missingHGAnnList !== undefined ? JSON.parse(data.missingHGAnnList): undefined,
                    unsupportedHGAnnList:data.unsupportedHGAnnList !== undefined ? JSON.parse(data.unsupportedHGAnnList):undefined,
                    chainedAnnHGList:data.chainedAnnHGList !== undefined ? JSON.parse(data.chainedAnnHGList):undefined,
                    annUnCheckedHGList:data.annUnCheckedHGList !== undefined ? JSON.parse(data.annUnCheckedHGList):undefined,
                    hgVMMissingList:data.hgVMMissingList !== undefined ? JSON.parse(data.hgVMMissingList) : undefined,
                    hgVMComplexList:data.hgVMComplexList !== undefined ? JSON.parse(data.hgVMComplexList) : undefined,

                }
                this.updateHuntGroupWarnings();
                // thi();
                
                this.firestoreService.getVMProDocData(this._cardId)
                .then((_vmproData: any)=>{
                    try{
                        if(data !== null && data !== undefined){
                            let isRootVmpFileFound = (_vmproData.rootVmpFileFound !== undefined && _vmproData.rootVmpFileFound !== null && 
                                _vmproData.rootVmpFileFound !== "") ? _vmproData.rootVmpFileFound : false;
                                if(isRootVmpFileFound){
                                    this.updateMOEWarningData();
                                    this._tableAutoAttendantList = [];
                                    this.aasiteSpecificNotifications.forEach((value:[], key: string) =>{
                                        this.aasiteSpecificNotifications.set(key, []);
                                    })
                                    this._aaMenuKeysData = this._vmproAAParsedFlows;
                                    let aaComplexCallFlowList = JSON.parse(_vmproData.aaComplexCallFlowList);
                                    let aaTimeoutList = JSON.parse(data.aaTimeoutList); 
                                    let conferenceMeetMeList = JSON.parse(_vmproData.conferenceMeetMeList);
                                    if (this._vmproAAParsedFlows !== undefined && this._vmproAAParsedFlows.length > 0) {
                                        this._vmproAAParsedFlows.map(entry => {
                                            let aaEntry: tableAutoAttendantData = {} as any;
                                            aaEntry.name = entry.AA_Name;
                                            aaEntry.siteName = (entry.siteName !== "")?entry.siteName:"MainSite";
                                            let menuLen = entry.AA_Actions.filter((entry => entry.Action == 'Not_defined')).length;
                                            aaEntry.menuAvailability = (menuLen === entry.AA_Actions.length) ? false : true;
                                            const aaPromptFound = this._aaMenuData.filter ((entry) => (entry.AA_Name === aaEntry.name));
                                            aaEntry.ivrPrompt = (aaPromptFound !== undefined)?true:false;
                                            this._tableAutoAttendantList.push(aaEntry);
                                            this.validateAAkeyInputs(entry);
                                            this.updateFeatureTaskChain(FEATURE_NAME.AUTO_ATTENDANT);
                                        });
                                        this.updateComplexCallFlowWarnings(aaComplexCallFlowList, aaTimeoutList, conferenceMeetMeList);
                                        this.updateIPOSummaryDoc({ No_of_AutoAttendants: this.tableAutoAttendantList.length });
                                        this.logger.debug('update Table data: completed vmpro')
                                    }
                                    return this.firestoreService.readAA_ICRData(this._cardId)
                                    .then((doc:any) => {
                                        
                                        this._tableAutoAttendantList.forEach(element => {
                                            this.AAList.push(element.name)
                                        })
                                        if(this.AAList.length > 0)
                                        {
                                            let isUpdated = doc.isUpdated;
                                            this._tableIncomingNumbersListCount = 0;
                                            this._originaltableIncomingNumbersList = (doc !== null)? JSON.parse(doc.data): [];
                                            this._tableIncomingNumbersList = [];
                                            this._originaltableIncomingNumbersList.forEach(entry => {
                                                entry.forEach(element => {
                                                    if(this.AAList.includes(element.name))
                                                    {
                                                        let incomingNumberEntry: incomingNumbers ={} as any;
                                                       // incomingNumberEntry.isEnabled = true;
                                                        incomingNumberEntry.icrNumber=element.icrNumber;
                                                        incomingNumberEntry.siteName =  (element.siteName !== "")?element.siteName:"MainSite";
                                                        incomingNumberEntry.name = element.name;
                                                        incomingNumberEntry.timeProfile = element.timeProfile;
                                                        incomingNumberEntry.companyNumbers = element.phoneNumber;
                                                        this._tableIncomingNumbersList.push(incomingNumberEntry);

                                                        //Check if any Auto-Receptionists are configured with time profiles where date is not configured
                                                        if (this._invalidTimeProfileList !== undefined && element.timeProfile !== 'undefined' && this._invalidTimeProfileList.includes(element.timeProfile) && 
                                                        !this.invalidAnsRuleTimeProfilesList.includes(element.name)) {
                                                            this.invalidAnsRuleTimeProfilesList.push(element.name);
                                                        }
                                                    }
                                                })
                                            });

                                            if (this.invalidAnsRuleTimeProfilesList !== undefined && this.invalidAnsRuleTimeProfilesList.length > 0) {
                                                this.autoAttendantsWarningsList.push(AA_ERROR_MSG.ANS_RULE_DATE_NOT_SELECT_TIME_PROFILE + this.invalidAnsRuleTimeProfilesList.join(", "));
                                            }

                                            this._tableIncomingNumbersListCount = this._tableIncomingNumbersList.length;   
                                            this.setIncomingData();                    
                                            this.firestoreService.updateAA_ICRData(this._cardId,this._originaltableIncomingNumbersList,isUpdated)
                                            .then(() => {
                                                this.logger.debug("Updated Incoming Numbers successfully");
                                            })
                                            this.getCompanyPhNumbers();
                                        }
                                        this.icrDestinationWarnings()
                                        .then(() => {this.logger.debug('update ICR destination warnings')})
                                        this.setWarningData();
                                    })
                                }
                                else if (!isRootVmpFileFound) {
                                    //500v2 processing
                                    let _ipoAutoAttendantList = [];
                                    (this._aaMenuKeysData !== undefined && this._aaMenuKeysData !== null && Object.keys(this._aaMenuKeysData).length > 0) ? _ipoAutoAttendantList = this._aaMenuKeysData : _ipoAutoAttendantList = [];
                                    if (_ipoAutoAttendantList !== undefined && Object.keys(_ipoAutoAttendantList).length > 0) {
                                        this._tableAutoAttendantList = [];
                                        this.AAList = [];
                                        this.aasiteSpecificNotifications.forEach((value:[], key: string) =>{
                                            this.aasiteSpecificNotifications.set(key,[]);
                                         })
                                        _ipoAutoAttendantList.map(entry => {
                                            let aaEntry: tableAutoAttendantData = {} as any;;
                                            aaEntry.name = entry.AA_Name;
                                            aaEntry.siteName = (entry.siteName !== "")?entry.siteName:"MainSite";
                                            let menuLen = entry.AA_Actions.filter((entry => entry.Action == 'Not_defined')).length;
                                            aaEntry.menuAvailability = (menuLen === IPO_MENU_KEYS) ? false : true;
                                            const aaPromptFound = this._aaMenuData.filter ((entry) => (entry.AA_Name === aaEntry.name));
                                            aaEntry.ivrPrompt = (aaPromptFound !== undefined)?true:false;
                                            this._tableAutoAttendantList.push(aaEntry);
                                            this.validateAAkeyInputs(entry);
                                            this.updateFeatureTaskChain(FEATURE_NAME.AUTO_ATTENDANT);
                                        })
                                        this._tableAutoAttendantList.forEach(element => {
                                            this.AAList.push(element.name)
                                        })
                                        this.logger.debug("UpdateTableData: completed Auto-Receptionists for 500v2");
                                    }
                                }
                                return;
                            }
                        }
                        catch(error){
                            this.logger.debug(error);
                            return;
                        }
                    })
                    .then(()=>{
                        return this.sendGreetingsDocStatus(data.trigger);
                    })
                }else
                {
                    this.sendGreetingsDocStatus(data.trigger);
                }
            }
        }

    public getGreetingsDocStatus(): Observable<string>{
        return this._greetingsDocStatus.asObservable();
    }

    public sendGreetingsDocStatus(data){
        this._greetingsDocStatus.next(data);
    }
    
    public getTableDataInitializedObs(): Observable<boolean>{
        return this._tableDataInitialized.asObservable();
    }

    public informTableInitialization(){
        this._tableDataInitialized.next(this._isTableDataInitializedFlag);
    }

    public getSysmonDocStatus(): Observable<string>{
        return this._sysmonDocStatus.asObservable();
    }

    public sendSysmonDocStatus(data){
        this._sysmonDocStatus.next(data);
    }

    getSysmonDataInitializedObs(): Observable<boolean>{
        return this._sysmonDataIntialized.asObservable();
    }

    public informSysmonInitiaization(){
        this._sysmonDataIntialized.next(this._isSysmonDataInitializedFlag);
    }

    public getCardClosureStatus(): Observable<boolean>{
        return this._cardClosureStatus.asObservable();
    }

    public sendCardClosureStatus(data){
        this._cardClosureStatus.next(data);
    }

    private isUserNotDeleted(extension){
        let extn = parseInt(extension);
        let found = this._deletedUsersList.find((entry:any)=>{
            return entry.extension === extn;
        })
        return found === undefined;
    }

    private checkIfHGExists(extn){
        let hgList = this.tableHuntGroupList.map((entry)=>{
            if(!entry.isPagingEnabled)
                return entry.extension;
        });
        hgList = hgList.filter(el=>el);
        let found = (hgList.find(entry=>entry===extn) !== undefined);
       // console.log("HGList: "+JSON.stringify(hgList)+"\nextn:"+extn+"\tfound: "+found !== undefined);
        return found;
    }

    private isHGExtn(extension){
        let found = this.tableHuntGroupList.find((entry)=>{
                return entry.extension === extension;
        }) !== undefined;
        //console.log("Is hunt group extension: "+ found);
        return found;
    }

    private generateInitialHGnPGTableData(){
        this.logger.info("generate initial call queues and paging groups table data");
        var promise = new Promise((resolve,reject)=>{
             this.firestoreService.readHGnPGTableData(this._cardId)
             .then((result:any)=>{
                 if(result !== ""){
                    this._tableHuntGroupList = JSON.parse(result);
                 }
                 if(Array.isArray(this._tableHuntGroupList) && !this._tableHuntGroupList.length && this._ipoHuntGroup !== undefined){
                        this._ipoHuntGroup.map(entry => {
                            let hGroupEntry: tableHuntGroupData = {} as any;
                            let ext;
                            if(entry.Group !== undefined)
                                ext = parseInt(entry.Group.GR_Extension);
                            if(ext !== undefined)
                              hGroupEntry.extension =  ext;
                            hGroupEntry.name = entry.Group.GR_Name;
                            hGroupEntry.siteName = entry.Sitename;
                            hGroupEntry.ringMode = entry.Group.GR_RingMode;
                            let overflowTime = 0;
                            if(entry.Overflow && entry.Overflow.GR_OverflowTime)
                                overflowTime =  parseInt(entry.Overflow.GR_OverflowTime) || 0;
                            if(entry.Group !== undefined && 
                                ACO_SERVICE_EDITION_LIST.includes(this._acoServiceEdition.toLowerCase()) &&
                                entry.Overflow) {
                                 hGroupEntry.overflowGroup = this.assignOverflowGroup(hGroupEntry, entry.Overflow.GR_Overflow,true);

                                //Configure overflow groups only when overflow groups are present
                                if (hGroupEntry.overflowGroup.length > 0) {
                                    //Overflow time is configured to a valid value
                                    if (entry.Overflow !== undefined && entry.Overflow.GR_OverflowTime !== undefined &&
                                        entry.Overflow.GR_OverflowTime !== "") {
                                        //Ring Mode is set to Simultaneous, configure overflow group under routing section.
                                        if (entry.Group.GR_RingMode.toLowerCase() === "ringgroup") {
                                            hGroupEntry.isCallQueueMigrationEnabled = false;
                                            hGroupEntry.isRoutingMigrationEnabled = true;

                                            //Voicemail time is set to a lower value than overflow time. Voicemail having higher precedence, overflow groups will not be configured.
                                            if (entry.Group !== undefined && entry.Group.GR_Voicemail.toLowerCase() === "true" && 
                                                entry.Group.GR_VoicemailTime !== undefined && parseInt(entry.Group.GR_VoicemailTime) > 0 &&
                                                parseInt(entry.Group.GR_VoicemailTime) < parseInt(entry.Overflow.GR_OverflowTime)) {
                                                    hGroupEntry.isRoutingMigrationEnabled = false;
                                            }
                                        //Ring mode is set to Sequential or Rotational, configure overflow group under overflow section using new API.
                                        } else {
                                            hGroupEntry.isCallQueueMigrationEnabled = true;
                                            hGroupEntry.isRoutingMigrationEnabled = false;

                                            //Voicemail time is set to a lower value than overflow time. Voicemail having higher precedence, overflow groups will not be configured.
                                            if (entry.Group !== undefined && entry.Group.GR_Voicemail.toLowerCase() === "true" &&
                                                entry.Group.GR_VoicemailTime !== undefined && parseInt(entry.Group.GR_VoicemailTime) > 0 &&
                                                parseInt(entry.Group.GR_VoicemailTime) < parseInt(entry.Overflow.GR_OverflowTime)) {
                                                    hGroupEntry.isCallQueueMigrationEnabled = false;
                                            }
                                        }
                                    //Overflow time is set to empty, i.e "", no overflow groups will be configured.
                                    } else {
                                        hGroupEntry.isCallQueueMigrationEnabled = false;
                                        hGroupEntry.isRoutingMigrationEnabled = false;
                                    }
                                }
                             //No Overflow groups are configured
                             }else{
                                 hGroupEntry.overflowGroup = [];
                                 hGroupEntry.isCallQueueMigrationEnabled = false;
                                 hGroupEntry.isRoutingMigrationEnabled = false;
                             }
                            //hGroupEntry.ringMode = entry.Group.GR_RingMode;
                            hGroupEntry.isPagingEnabled = entry.Paging;
                            let members = [];let devices = [];
                            if(entry.Group.GR_Extensions != undefined && entry.Group.GR_Extensions.length > 0)
                            {
                                members = entry.Group.GR_Extensions.map((extn:any)=>
                                    {
                                        //Include unchecked members in the list
                                        //extn = new RegExp("\\*[0-9]+").test(extn)?extn.substring(1):extn;
                                        let isLimitedExtn = this.tableUserList.find(el=>{
                                            return this.isLimitedExtn(el.acoPhoneModel)
                                        })
                                        if(this.isUserNotDeleted(extn) && !isLimitedExtn && !(this._messageOnlyExtns.includes(extn)))
                                         return extn;
                                        return null;
                                    });
                                //Exclude unchecked members from the devices list
                                devices =  entry.Group.GR_Extensions.filter((memExtn:any) => {
                                    return (
                                            this._tableUserList.find(el=>el.extension == memExtn && 
                                            el.pageCapable) !== undefined && !(this._messageOnlyExtns.includes(memExtn)));
                                });
                            } 
                            if(entry.Group.GR_RingMode === "RingGroup" && entry.Group.GR_CallWaiting.toLowerCase() == "true") 
                            {
                                this.collectiveCallWaiting.push(entry.Group.GR_Name)
                            }
                          
                            if(members !== undefined){
                                members = members.filter(entry=>!this.reservedExtensionsList.includes(parseInt(entry)));
                                hGroupEntry.totalMembers = members.length;                                 
                            }else{
                                hGroupEntry.totalMembers = 0;
                                //devices = [];
                            }

                            hGroupEntry.devices = devices||[];
                            hGroupEntry.siteName = entry.Sitename;
                            this._tableHuntGroupList.push(hGroupEntry);
                        })
                }
                 resolve();
             }).catch((error)=>{
                 reject(error);
             })
        })
        return promise;
    }

    private assignOverflowGroup(hgEntry, overflowGroupList,initialAssignment=false){
        let groupList = Array.from(new Set(overflowGroupList));;
        let nextGroup;
        let selectedOverflowGroups = [];
        let overflowGroupsCounter = 0;
        if(overflowGroupList && overflowGroupList.length > 0){
            while(groupList.length > 0 && overflowGroupsCounter < 3){
                nextGroup = groupList.shift();
                if(initialAssignment){
                    let selectedOverflowGroup = this._ipoHuntGroup.find((entry=>{ 
                        return entry.Group.GR_Name === nextGroup 
                        && hgEntry.extension !== parseInt(entry.Group.GR_Extension)     //A call queue cannot be added as overflow group to itself
                        &&  entry.Paging === false 
                        && entry.Group.GR_Extension && entry.Group.GR_Extension !== "" && 
                        !this.reservedExtensionsList.includes(parseInt(entry.Group.GR_Extension));
                    }))
                    if(selectedOverflowGroup !== undefined && selectedOverflowGroup.Group.GR_Extension){
                       //console.log("selected overflow group: "+selectedOverflowGroup.Group.GR_Name);
                        selectedOverflowGroups.push(selectedOverflowGroup.Group.GR_Name);
                        overflowGroupsCounter++;
                    }
                }else{
                    let selectedOverflowGroup = this._tableHuntGroupList.find(entry=>
                        { 
                            return entry.name.toString() === nextGroup
                            && entry.extension !== hgEntry.extension                //A call queue cannot be added as overflow group to itself
                            && !entry.isPagingEnabled 
                            && !this.reservedExtensionsList.includes(entry.extension);
                        });
                        if(selectedOverflowGroup !== undefined && selectedOverflowGroup.extension){
                           //console.log("selected overflow group: "+selectedOverflowGroup.name);
                           selectedOverflowGroups.push(selectedOverflowGroup.name);
                           overflowGroupsCounter++;
                        }
                    }
                }
        }
        return selectedOverflowGroups;
    }

    private getTableData(){
        var promise = new Promise((resolve, reject) => {
            let path = 'Greetings/'+ this._cardId;
            var storage = firebase.storage();
            var storageRef = storage.ref(path);
            var listRef = storageRef.child('IpoCfg');
            listRef.listAll()
            .then((res) => {
                let files = [];
                res.items.forEach((itemRef) => {
                    console.log("list : ",itemRef.name)
                    files.push(itemRef.name)
                });
                let tempData = [];
                if(files.includes("tableData.json")){
                    this.downloadFileFromBucket(`Greetings/${this._cardId}/IpoCfg/tableData.json`)
                    .then((result:any) => {
                        if(Object.keys(result).length !== 0){
                            tempData = result;
                        }
                        resolve(tempData)
                    })
                }else{
                    resolve(tempData);
                }
            }).catch((error) => {
                console.log("unexpected error:",error);
                reject();
            });
        });
        return promise;
    }

    //Configure ACO's default main-site timezone to user
    private configureDefaultAcoTimeZone(tempTableUser) {
        tempTableUser.timeZoneId = this._acoTimeZone.id;
        tempTableUser.timeZoneName = this._acoTimeZone.description;
        if (this._acoTimeZone.bias !== 'undefined') {
            tempTableUser.timeZoneName += " " + this.getGMTTime(this._acoTimeZone.bias);
            tempTableUser.tzOffset = this._acoTimeZone.bias;
        }
        return tempTableUser;
    }

    //Configure site specific timezone to user
    private configureSiteConfiguredTimeZone(tempTableUser, ipoUser) {
        let matchingSiteTimezone = this._siteTimeZoneMap.get(ipoUser.SiteId);
        if (matchingSiteTimezone !== undefined && matchingSiteTimezone !== null && matchingSiteTimezone.id !== undefined) {
            tempTableUser.timeZoneId = matchingSiteTimezone.id
            tempTableUser.timeZoneName = matchingSiteTimezone.description;
            if (matchingSiteTimezone.bias !== 'undefined') {
                tempTableUser.timeZoneName += " " + this.getGMTTime(matchingSiteTimezone.bias);
                tempTableUser.tzOffset = matchingSiteTimezone.bias;
            }
        }
        // matchingSiteTimezone is undefined, hence configure user with ACO's default main-site timezone
        else {
            tempTableUser = this.configureDefaultAcoTimeZone(tempTableUser);
        }
        return tempTableUser;
    }

    private async generateInitialTableUserList(resetFromIPOCfg = false){
        this.siteSpecificNotifications.clear();
        this.cqsiteSpecificNotifications.clear();
        this.pgsiteSpecificNotifications.clear();
        this.advInfositeSpecificNotifications.clear();
        this._ringCountExceededExtensionmap.clear();
        this._invalidTwinTimeProfileList.clear();
        this.aasiteSpecificNotifications.clear();
        this.tpsitespecificNotifications.clear()
        this.icrsiteSpecificNotifications.clear();
        this.siteSpecificNotifications.set("Common Notifications",[]);
        this.pgsiteSpecificNotifications.set("Common Notifications", []);
        this.cqsiteSpecificNotifications.set("Common Notifications",[]);
        this.advInfositeSpecificNotifications.set("Common Notifications",[]);
        this._invalidTwinTimeProfileList.set("Common Notifications",[]); 
        this._ringCountExceededExtensionmap.set("Common Notifications",[]);
        this._invalidTwinTimeProfileList.set("Common Notifications",[]);
        this.aasiteSpecificNotifications.set("Common Notifications",[]);
        this.tpsitespecificNotifications.set("Common Notifications",[]);
        this.icrsiteSpecificNotifications.set("Common Notifications",[]);
        this.aasiteSpecificNotifications.set("MainSite",[]);
        for(let i=0;i<this._ipoUserList.length;i++){
            
            if(!this.siteSpecificNotifications.has(this._ipoUserList[i].Sitename))
                this.siteSpecificNotifications.set(this._ipoUserList[i].Sitename,[]);                                 
            if(!this.cqsiteSpecificNotifications.has(this._ipoUserList[i].Sitename))
                this.cqsiteSpecificNotifications.set(this._ipoUserList[i].Sitename,[]);                                  
           // if(!this.pgsiteSpecificNotifications.has(this._ipoUserList[i].Sitename))                
            //    this.pgsiteSpecificNotifications.set(this._ipoUserList[i].Sitename,[]); 
            if(!this.advInfositeSpecificNotifications.has(this._ipoUserList[i].Sitename))                
                this.advInfositeSpecificNotifications.set(this._ipoUserList[i].Sitename,[]);
            if(!this._ringCountExceededExtensionmap.has(this._ipoUserList[i].Sitename))  
                this._ringCountExceededExtensionmap.set(this._ipoUserList[i].Sitename,[]); 
            if(!this._invalidTwinTimeProfileList.has(this._ipoUserList[i].Sitename))  
                this._invalidTwinTimeProfileList.set(this._ipoUserList[i].Sitename,[]); 
            if(!this.aasiteSpecificNotifications.has(this._ipoUserList[i].Sitename))  
                this.aasiteSpecificNotifications.set(this._ipoUserList[i].Sitename,[]);  
            if(!this.tpsitespecificNotifications.has(this._ipoUserList[i].Sitename))  
                this.tpsitespecificNotifications.set(this._ipoUserList[i].Sitename,[]); 
            if(!this.icrsiteSpecificNotifications.has(this._ipoUserList[i].Sitename))  
                this.icrsiteSpecificNotifications.set(this._ipoUserList[i].Sitename,[]);                                             
        }
       // 
       //  
        this.logger.info("generateInitialTableUserList");
        var promise = new Promise((resolve, reject) => {
            //this.firestoreService.readTableData(this._cardId)4
            this.getTableData()
            .then((result:any) => {
                /* if(result !== ""){
                    this._tableUserList = JSON.parse(result);
                } */
                this._tableUserList = result;
                //this.logger.debug("Table data from DB: ", this._tableUserList);
                if((Array.isArray(this._tableUserList) && !this._tableUserList.length) || resetFromIPOCfg){
                    if(resetFromIPOCfg){
                        this._tableUserList=[];
                    }
                   
                    for(let i=0;i<this._ipoUserList.length;i++){
                        if(this.isUserNotDeleted(parseInt(this._ipoUserList[i].Extension.US_Extension))){
                            let tempTableUser: tablePreviewData = {} as any;
                            tempTableUser.filename = this._ipoUserList[i].Filename;
                            tempTableUser.siteName = this._ipoUserList[i].Sitename;
                            tempTableUser.site_id = this._ipoUserList[i].SiteId;
                            tempTableUser.isSelected = false;
                            tempTableUser.blfList = this._ipoUserList[i].User_BLF.filter((entry:any)=>{
                                return this.isUserNotDeleted(parseInt(entry.US_Extension));
                            });                            
                            tempTableUser.contactList=this._ipoUserList[i].US_ContactsList.filter((entry:any)=>{
                                return this.isUserNotDeleted(parseInt(entry.Number));
                            })
                            tempTableUser.username = this._ipoUserList[i].US_Fullname;
                            tempTableUser.ipoUserName = this._ipoUserList[i].US_Name;
                            if(tempTableUser.username === ""){
                                tempTableUser.username = this._ipoUserList[i].US_Name;
                            }
                            //this.logger.debug("US_Extension",i, ", extension: ", this._ipoUserList[i].Extension.US_Extension);
                            tempTableUser.extension = parseInt(this._ipoUserList[i].Extension.US_Extension);
                            tempTableUser.email = this._ipoUserList[i].US_VoiceMailEmail || this._ipoUserList[i].US_UniqueIdentity || "";
                            if(this._messageOnlyExtns.includes(JSON.stringify(tempTableUser.extension)))
                            {
                                tempTableUser.phone = PHONE.MESSAGE_ONLY;
                                tempTableUser.displayPhoneModel = PHONE.MESSAGE_ONLY;
                                tempTableUser.acoPhoneStatus = phoneStatus.assigned;
                                tempTableUser.did = "";
                            }
                            else
                            {
                                tempTableUser.phone = PHONE.UNASSIGNED;
                                tempTableUser.displayPhoneModel = PHONE.UNASSIGNED;
                                tempTableUser.acoPhoneStatus = phoneStatus.unassigned;
                            }
                            
                            tempTableUser.pageCapable = false;
                            tempTableUser.migrationReady = false;
                            tempTableUser.profileType = this._ipoUserList[i].US_AssignedPackage;
                            tempTableUser.wrapUpTime = this._ipoUserList[i].US_WrapUpTime ? this._ipoUserList[i].US_WrapUpTime : 0;
                            tempTableUser.macId = "";
                            if(this.ipoUserList[i].Phone.EX_PhoneType !== null &&
                                this.ipoUserList[i].Phone.EX_PhoneType !== undefined &&
                                this.ipoUserList[i].Phone.EX_PhoneType !== "" &&
                                this.ipoUserList[i].Phone.EX_PhoneType !== "unknownSip" &&
                                this.ipoUserList[i].Phone.EX_PhoneType !== "unknownVoip"){
                                    tempTableUser.ipoPhoneModel = this.ipoUserList[i].Phone.EX_PhoneType;
                                }
                                else{
                                    tempTableUser.ipoPhoneModel = PHONE_MODEL.SOFTPHONE;
                                }

                                tempTableUser.tzOffset = '';
                                if(typeof this._ipoUserList[i].Extension.Location_Timezone === 'undefined' ||
                                this._ipoUserList[i].Extension.Location_Timezone === null){
                                    if (this._isMultiSiteMigration) {
                                        if(this._siteTimeZoneMap !== null && this._siteTimeZoneMap.size > 0 && this._ipoUserList[i].SiteId !== undefined && 
                                            this._ipoUserList[i].SiteId !== null && this.ipoUserList[i].SiteId !== "") {
                                            //user timezone is undefined & user belongs to a specifc site - Configure site specific timezone to user
                                            tempTableUser = this.configureSiteConfiguredTimeZone(tempTableUser, this._ipoUserList[i]);
                                        } else if (this._acoTimeZone !== null && this._acoTimeZone !== undefined) {
                                            //user timezone is undefined & user's siteId info is null - configuring to default ACO main-site timezone to user
                                            tempTableUser = this.configureDefaultAcoTimeZone(tempTableUser);
                                        }
                                    } else if (this._acoTimeZone !== null && this._acoTimeZone !== undefined) {
                                        //user timezone is undefiend - Single site migration - configure default ACO main-site timezone to user
                                        tempTableUser = this.configureDefaultAcoTimeZone(tempTableUser);
                                    }
                                    else{
                                        tempTableUser.timeZoneId = NOT_FOUND;
                                    }
                                }
                                else{
                                    let timeZone = this._ipoUserList[i].Extension.Location_Timezone;
                                    let matchTimeZone = this._timeZoneList.find(entry => (timeZone.includes(entry.description)));
                                    tempTableUser.timeZoneName = this._ipoUserList[i].Extension.Location_Name + " " 
                                    + timeZone.split(" ")[0];
                                    if(matchTimeZone !== undefined){
                                        //User time zone is defined & valid matching timezone is found
                                        tempTableUser.timeZoneId = matchTimeZone.id;
                                        tempTableUser.tzOffset = matchTimeZone.bias;
                                    }
                                    else {
                                        if (this._isMultiSiteMigration) {
                                            if (this._siteTimeZoneMap !== null && this._siteTimeZoneMap.size > 0 && 
                                                this._ipoUserList[i].SiteId !== undefined && this._ipoUserList[i].SiteId !== null && this.ipoUserList[i].SiteId !== "") {
                                                //User timezone is defined but not valid - Multisite migration & user site Id is valid - configuring to site specific timezone
                                                tempTableUser = this.configureSiteConfiguredTimeZone(tempTableUser, this._ipoUserList[i]);
                                            }
                                            else if (this._acoTimeZone !== null && this._acoTimeZone !== undefined) {
                                                //user timezone is defined but not valid - Multisite migration but user site Id is null/invalid - configuring to default ACO main-site timezone
                                                tempTableUser = this.configureDefaultAcoTimeZone(tempTableUser);
                                                
                                            }
                                        }
                                        else if (this._acoTimeZone !== null && this._acoTimeZone !== undefined) {
                                            //User timezone is defined but not valid - Single site migration - configure default ACO main-site timezone to user
                                            tempTableUser = this.configureDefaultAcoTimeZone(tempTableUser);
                                        }
                                        else {
                                            tempTableUser.timeZoneId = NOT_FOUND;
                                        }
                                    }
                                }
                                
                                tempTableUser.firstName = "";
                                tempTableUser.lastName = "";
                                tempTableUser.workHoursProfile = (typeof this._ipoUserList[i].Extension.US_UserRightsTimeProfile == 'undefined')?
                                "":this._ipoUserList[i].Extension.US_UserRightsTimeProfile;
                                
                                //Twinning data
                                tempTableUser.twinMob = "";
                                tempTableUser.twinProfile = "";
                                //ACOS-294: Migration of "No Answer Timeout" from IP Office Configuration
                                //If NoAnswerTime is not defined , then default ringCount should be 3
                                let noAnswerTimeOut = this._ipoUserList[i].US_NoAnswerTime;
                                if(noAnswerTimeOut && noAnswerTimeOut != 0){
                                    this.advInfositeSpecificNotifications.forEach((value:[], key: string) =>{
                                    tempTableUser.ringCount = Math.ceil(noAnswerTimeOut / ACO_RING_COUNT_UNIT) > ACO_RING_COUNT_MAX_LIMIT ? ACO_RING_COUNT_MAX_LIMIT : Math.ceil(noAnswerTimeOut / ACO_RING_COUNT_UNIT);
                                    if (Math.ceil(noAnswerTimeOut / ACO_RING_COUNT_UNIT) > ACO_RING_COUNT_MAX_LIMIT && key === this._ipoUserList[i].Sitename) {
                                        this._ringCountExceededExtensionmap.get(key).push(this._ipoUserList[i].Extension.US_Extension);
                                    }
                                })
                                }else{
                                    tempTableUser.ringCount = DEFAULT_RING_COUNT;
                                }

                                //Consider all entries having a valid twin mobile number for migration
                                if(typeof this._ipoUserList[i].Twinning !== 'undefined' && 
                                typeof this._ipoUserList[i].Twinning.US_TwinMobileNumber !== 'undefined'){
                                    tempTableUser.twinMob = this._ipoUserList[i].Twinning.US_TwinMobileNumber;
                                    tempTableUser.twinProfile = this._ipoUserList[i].Twinning.US_TwinTimeProfile;
                                    tempTableUser.twinType = this.ipoUserList[i].Twinning.US_TwinningType;
                                }
                                this._tableUserList.push(tempTableUser);
                            }
                        }   
                        this.advInfositeSpecificNotifications.forEach((value:[], key: string) =>{                 
                            if (this._ringCountExceededExtensionmap.get(key).length > 0)  
                            this.advInfositeSpecificNotifications.get(key).push(USER_BUSINESS_HOURS.RING_COUNT_EXCEEDS_THRESHOLD + this._ringCountExceededExtensionmap.get(key))
                        })
                    this._userCount = this._tableUserList.filter(entry => !(this._messageOnlyExtns.includes(JSON.stringify(entry.extension))));
                    }
                resolve();
                
            })
            .catch(error => {
                this.logger.debug(error);
                reject(error);
            })
            
        });
        if (this._vmGreetingsData === undefined || Object.keys(this._vmGreetingsData).length === 0){
            this._vmGreetingsData = [];
                this.advInfositeSpecificNotifications.get("Common Notifications").push(VM_ERROR_MSG.GREETINGS_MISSING);
        }
        this.tpsitespecificNotifications.forEach((value:[], key: string) =>{
            let tempmixedTimeProfileList = [];
            let tempinvalidTimeProfileList = [];
            if(this._ipoTimeProfile !== undefined && this._ipoTimeProfile.length > 0)
            {
            this._ipoTimeProfile.forEach(element => {                                    
            if (this.mixedTimeProfileList.length > 0 && this.mixedTimeProfileList.includes(element.name) && key === element.Sitename) {
                tempmixedTimeProfileList.push(element.name);
            }
        
        if (this._invalidTimeProfileList.length > 0 && this._invalidTimeProfileList.includes(element.name) && key === element.Sitename) {                 
               tempinvalidTimeProfileList.push(element.name);
            }                 
        })
        }
        if(tempmixedTimeProfileList.length > 0)
        {
            let notifyMsg = tempmixedTimeProfileList.join(", ") + " - Working Hours has both specific date range and weekly schedule.";
            this.tpsitespecificNotifications.get(key).push(notifyMsg);
        }
        if(tempinvalidTimeProfileList.length > 0)
        {
            let Msg = TIME_PROFILE.DATE_NOT_SELECTED_TIME_PROFILE + tempinvalidTimeProfileList.join(", ");
            this.tpsitespecificNotifications.get(key).push(Msg);
        }
       
        })
        return promise;
    }
    getGMTTime(bias: any) {
        let absBias = Math.abs(bias);
        let tempBiasStr = (bias < 0 ? '-': '+')+('0'+ Math.floor(absBias / 60)).slice(-2) +":"+('0'+(absBias %60)).slice(-2);
        return "(GMT"+  tempBiasStr +")";
    }


    public autoAssignFirstAndLastName(choice: autoAssignName){
        this.logger.info("autoAssignFirstAndLastName: ", choice);
        var promise = new Promise((resolve, reject) => {            
            switch (choice){
                case autoAssignName.Fname_Lname:
                {
                    for(var i=0;i<this._tableUserList.length;i++){ 
                        var ipoUser = this._ipoUserList.find((entry:any)=>this._tableUserList[i].extension === parseInt(entry.Extension.US_Extension));
                        if(ipoUser !== undefined){
                            let fname = " " ,lname = " ", nameArray = [];
                            if(ipoUser.US_Fullname != null && ipoUser.US_Fullname !== ""){
                                nameArray = ipoUser.US_Fullname.trim().replace(/  +/g, ' ').split(" ");  
                            }
							else if(ipoUser.US_Name != null) {
                                nameArray = ipoUser.US_Name.trim().replace(/  +/g, ' ').split(" ");
                            }
                            
                            if(nameArray.length !== 0){
                                lname = nameArray.pop();
                                fname = nameArray.join(" ").trim();     
                                if(fname === "") {
                                    fname = lname;
                                    lname = " ";
                                }
                            }
                            this._tableUserList[i].firstName = fname;
                            this._tableUserList[i].lastName = lname;           
                        }
                    }
                    this.validateTableData();
                    resolve();
                    break;
                }
                case autoAssignName.Lname_Fname:
                {
                    for(var i=0;i<this._tableUserList.length;i++){
                        var ipoUser = this._ipoUserList.find((entry:any)=>this._tableUserList[i].extension === parseInt(entry.Extension.US_Extension));
                        if(ipoUser !== undefined){
                            let fname = " " ,lname = " ", nameArray = [];
                            if(ipoUser.US_Fullname != null && ipoUser.US_Fullname !== ""){
                                nameArray = ipoUser.US_Fullname.trim().replace(/  +/g, ' ').split(" ");
                            }
							else if(ipoUser.US_Name != null) {
								nameArray = ipoUser.US_Name.trim().replace(/  +/g, ' ').split(" ");
                            }
                            
                            if(nameArray.length !== 0){
                                fname = nameArray.pop();
                                lname = nameArray.join(" ").trim();   
                                if(lname === "") {
                                    lname = fname;
                                    fname = " ";
                                }
                            }
                            this._tableUserList[i].firstName = fname;
                            this._tableUserList[i].lastName = lname;           
                        }
                    }
                    this.validateTableData();
                    resolve();
                    break;
                }
                case autoAssignName.Fname:
                {
                    for(var i=0;i<this._tableUserList.length;i++){
                        var ipoUser = this._ipoUserList.find((entry:any)=>this._tableUserList[i].extension === parseInt(entry.Extension.US_Extension));
                        if(ipoUser !== undefined){
                            let fname = " " ,lname = " ", nameArray = [];
                            if(ipoUser.US_Fullname != null && ipoUser.US_Fullname !== ""){
                                nameArray = ipoUser.US_Fullname.split(" ");       
                            }
							else if(ipoUser.US_Name != null) {
								nameArray = ipoUser.US_Name.split(" ");
                            }         
                            
                            if(nameArray.length !== 0){
                                lname = nameArray.pop();
                                fname = nameArray.join(" ").trim();    
                                if(fname === "") {
                                    fname = lname;
                                    lname = "";
                                }
                            }
                            this._tableUserList[i].firstName = fname;          
                        }
                    }
                    this.validateTableData();
                    resolve();
                    break;
                }
                case autoAssignName.Lname:
                {
                    for(var i=0;i<this._tableUserList.length;i++){
                        var ipoUser = this._ipoUserList.find((entry:any)=>this._tableUserList[i].extension === parseInt(entry.Extension.US_Extension));
                        if(ipoUser !== undefined){
                            let fname = " " ,lname = " ", nameArray = [];
                            if(ipoUser.US_Fullname != null && ipoUser.US_Fullname !== ""){
                                nameArray = ipoUser.US_Fullname.split(" ");          
                            }
                            else if(ipoUser.US_Name != null) {
                                nameArray = ipoUser.US_Name.split(" ");
                            }
                            
                            if(nameArray.length !== 0){
                                lname = nameArray.pop();
                                fname = nameArray.join(" ").trim();    
                                if(fname === "") {
                                    fname = lname;
                                    lname = "";
                                }
                            }
                            this._tableUserList[i].lastName = lname;           
                        }
                    }
                    this.validateTableData();
                    resolve();
                    break;
                }
                default:
                {
                    this.logger.error("Invalid choice passed: ", choice);
                    reject({reason: REJECT.INVALID_NAME_ASSIGNMENT});
                }
            }
        });

        return promise;
    }

    public autoAssignPhone(choice: autoAssignPhone, ipoChoice, acoChoice, mapCount){
        this.logger.info("autoAssignPhone: ", choice);
        var promise = new Promise((resolve, reject) => {
            switch (choice){
                case autoAssignPhone.PhoneType:
                {
                    var idx = 0; var extn;
                    var matchedUser: any, matchedProfile: any;
                    var acoExtensions = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === acoChoice);
                    var ipoPhones = this._ipoUsersByPhone.find(entry => entry.phoneModel === ipoChoice);
                    if(ipoPhones === undefined){
                        this.logger.error("No matching phones in ipo phone model list");
                        reject({reason: REJECT.INVALID_IPO_PHONE_MODEL});
                    }

                    if(acoExtensions === undefined){
                        this.logger.error("No matching phones in aco phone type list");
                        reject({reason: REJECT.INVALID_ACO_PHONE_MODEL});
                    }
                    
                    var assignmentIncomplete = false;

                    for(var idx=0; idx < mapCount; idx++){
                        extn = ipoPhones.extensionList.shift();
                        matchedUser = this._tableUserList.find(entry => (entry.ipoPhoneModel === ipoChoice && entry.acoPhoneStatus === phoneStatus.unassigned && entry.extension === extn));
                        if(matchedUser !== undefined){
                            matchedUser.extensionId = acoExtensions.extensionList.shift();
                            let matchedDeviceExtEntry = this._deviceExtList.find(entry => (matchedUser.extensionId === entry.extensionId));
                            matchedUser.pageCapable = this.isDevicePageCapable(matchedUser.extensionId);
                            //this.updatePGWarningList(matchedUser);
                            matchedUser.did = this.getDID(matchedUser.extensionId);
                            matchedUser.acoPhoneStatus = phoneStatus.assigned;
                            matchedUser.acoPhoneModel = acoChoice;
                            matchedUser.phone = acoChoice
                            let sysmonEntry = this._sysmonData.find((entry) => parseInt(entry.userExtension) === matchedUser.extension);
                            if (sysmonEntry !== undefined && ((acoChoice.toLowerCase() === EXISTING_PHONE) || (acoChoice.toLowerCase() === EXISTING_PHONE_LIMITED_EXTENSION))) {
                                matchedUser.displayPhoneModel = acoChoice.toLowerCase() === EXISTING_PHONE ? sysmonEntry.phoneModel : sysmonEntry.phoneModel + " Limited Extension";
                                matchedUser.macId = sysmonEntry.macId;
                                matchedUser.isSysmonAssignment = true;
                            } else {
                                matchedUser.displayPhoneModel = acoChoice;
                                matchedUser.macId = matchedDeviceExtEntry !== undefined ? matchedDeviceExtEntry.macId : "";
                                matchedUser.isSysmonAssignment = false;
                            }
                            acoExtensions.count--;
                            ipoPhones.count--;
                            matchedProfile = this._ipoUsersByProfile.find(entry => entry.profileType === matchedUser.profileType);
                            if(matchedProfile !== undefined){
                                const index = matchedProfile.extensionList.findIndex(extn => extn === matchedUser.extension);
                                matchedProfile.extensionList.splice(index, 1);
                                matchedProfile.count--;
                            }
                            if (matchedUser.twinMob.length !== 0 && matchedUser.did.length === 0) {
                                this.pushDidNotConfiguredTwinList(matchedUser.siteName, matchedUser.extension);
                            }
                           // this.updateLimExtnList(matchedUser);
                        }
                        else {
                            //this.logger.warn("Incomplete assignment");
                            assignmentIncomplete = true;
                        }
                    }                   
                    this.validateTableData();
                    this.updateHGnPGData();
                    this.updateLimExtnData();
                    this.updateMOEWarningData();
                    if(assignmentIncomplete){
                        this.logger.warn("Incomplete assignment");
                        reject({result: REJECT.INCOMPLETE_PHONE_ASSIGNMENT});
                    }
                    resolve();
                }
                break;            
                case autoAssignPhone.UserType:
                {
                    var idx = 0; var extn;
                    var matchedUser: any, matchedProfile: any;
                    var acoExtensions = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === acoChoice);
                    var ipoPhones = this._ipoUsersByProfile.find(entry => entry.profileType === ipoChoice);
                    if(ipoPhones === undefined){
                        this.logger.error("No matching user license type exists");
                        reject({reason: REJECT.INVALID_IPO_USER_TYPE});
                    }

                    if(acoExtensions === undefined){
                        this.logger.error("No matching phones in aco phone type list");
                        reject({reason: REJECT.INVALID_ACO_PHONE_MODEL});
                    }

                    var assignmentIncomplete = false;
                    for(var idx=0; idx < mapCount; idx++){
                        extn = ipoPhones.extensionList.shift();
                        matchedProfile = this._tableUserList.find(entry => (entry.profileType === ipoChoice && entry.acoPhoneStatus === phoneStatus.unassigned && entry.extension === extn));
                        if(matchedProfile !== undefined){
                            matchedProfile.extensionId = acoExtensions.extensionList.shift();
                            let matchedDeviceExtEntry = this._deviceExtList.find(entry => (matchedProfile.extensionId === entry.extensionId));
                            matchedProfile.pageCapable = this.isDevicePageCapable(matchedProfile.extensionId);
                           // this.updatePGWarningList(matchedProfile);
                            matchedProfile.did = this.getDID(matchedProfile.extensionId);
                            matchedProfile.acoPhoneStatus = phoneStatus.assigned;
                            matchedProfile.acoPhoneModel = acoChoice;
                            matchedProfile.phone = acoChoice;
                            let sysmonEntry = this._sysmonData.find((entry) => (parseInt(entry.userExtension) === matchedProfile.extension));
                            if (sysmonEntry !== undefined && ((acoChoice.toLowerCase() === EXISTING_PHONE) || (acoChoice.toLowerCase() === EXISTING_PHONE_LIMITED_EXTENSION))) {
                                matchedProfile.displayPhoneModel = acoChoice.toLowerCase() === EXISTING_PHONE ? sysmonEntry.phoneModel : sysmonEntry.phoneModel + " Limited Extension";
                                matchedProfile.macId = sysmonEntry.macId;
                                matchedProfile.isSysmonAssignment = true;
                            } else {
                                matchedProfile.displayPhoneModel = acoChoice;
                                matchedProfile.macId = matchedDeviceExtEntry !== undefined ? matchedDeviceExtEntry.macId : "";
                                matchedProfile.isSysmonAssignment = false;
                            }
                            acoExtensions.count--;
                            ipoPhones.count--;
                            matchedUser = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedProfile.ipoPhoneModel);
                            if(matchedUser !== undefined){
                                const index = matchedUser.extensionList.findIndex(extn => extn === matchedProfile.extension);
                                matchedUser.extensionList.splice(index, 1);
                                matchedUser.count--;
                            }
                            else {
                                assignmentIncomplete = true;
                            }
                            if (matchedProfile.twinMob.length !== 0 && matchedProfile.did.length === 0) {
                                this.pushDidNotConfiguredTwinList(matchedProfile.siteName, matchedProfile.extension);
                            }
                           // this.updateLimExtnList(matchedUser);
                        }
                    }

                    this.validateTableData();
                    this.updateHGnPGData();
                    this.updateLimExtnData();
                    this.updateMOEWarningData();
                    if(assignmentIncomplete){
                        this.logger.warn("Incomplete assignment");
                        reject({result: REJECT.INCOMPLETE_PHONE_ASSIGNMENT});
                    }
                    resolve();
                }
                break;
                default:
                {
                    this.logger.debug("Invalid choice passed: ", choice);
                    reject({result: REJECT.INVALID_PHONE_ASSIGNMENT_CHOICE});
                }
        }
        });

        return promise;
    }

 
    public clearConfiguration(autoAssignmentconfig:AutoAssignmentConfig[]){
        var clearAll = autoAssignmentconfig.find(element => (element == AutoAssignmentConfig.All));
        if(clearAll !== undefined){
            autoAssignmentconfig = [AutoAssignmentConfig.All];
        } 
        var promise = new Promise((resolve, reject) => {
            autoAssignmentconfig.forEach(element => {
                switch(element)
                {
                    case AutoAssignmentConfig.email:
                    {
                        this._emailGroup = '';
                        this._invalidEmailList = [];
                        this._tableUserList.forEach(entry => entry.email="");
                        this.validateTableData();
                        resolve();
                        break;
                    }
                    case AutoAssignmentConfig.NameAssignment:
                    {
                        this._tableUserList.forEach(entry => { 
                            entry.firstName = "";
                            entry.lastName = ""
                        });
                        this.validateTableData();
                        resolve();
                        break;
                    }
                    case AutoAssignmentConfig.phoneAssignment:
                    {
                        this._tableUserList.forEach(entry => {
                            if(!(this._messageOnlyExtns.includes(JSON.stringify(entry.extension))))
                            {
                                this.removeAssignedPhone(entry.extension, true)
                                .catch((error) => {
                                    this.logger.error("Error while removing assigned phones");
                                })
                            }
                            else{                               
                                this.reAssignMessageOnly(entry.extension,true)
                            }
                        })
                        this.didNotConfiguredTwinList().length = 0;
                        this.validateTableData();
                        //this.updateHGnPGData();
                        resolve();
                        break;
                    }
                    case AutoAssignmentConfig.All:
                    {
                        this._emailGroup = [];
                        this._invalidEmailList = [];
                        this._tableUserList = [];
                        this._acoExtnByPhoneGroup = [];
                        this._ipoUsersByPhone = [];
                        this._ipoUsersByProfile = [];
                        this.didNotConfiguredTwinList().length = 0;
                        this.generateInitialTableUserList(true)
                        .then(() => {
                             this.fillIPOUsersByPhoneAndProfile();
                             this.fillACOUsersByPhone();
                             this.validateTableData();
                             this.updateHGnPGData();
                             this.updateLimExtnData();
                             this.updateMOEWarningData();
                             resolve();
                        })
                        .catch(error => {});
                        break;
                    }
                    default:
                        this.logger.error("Invalid choice for clearConfiguration: ", element);
                        break;
                }
            });
        });

        return promise; 
    }

    updateLimExtnData(){
        //console.debug("Limited extn data updated");
            this.updateBLFDataForLimExtn();
            this.updateTwinWarningsForLimExtn();    
            this.updateUserHoursWarningsForLimExtn();
            this.updateVMGreetingsWarningsForLimExtn();    
    }

    updateMOEWarningData()
    {
        this.updateUserHoursWarningsForMOE();
        this.updateBLFDataForMOE();
        this.updateTwinWarningsForMOE();
    }

    updateVMGreetingsWarningsForLimExtn(){
        this.advInfositeSpecificNotifications.forEach((value:any[], key: string) =>{
        value = value.filter(entry => !entry.includes(VM_ERROR_MSG.LIM_EXTN_UNSUPPORTED));
        if(value !== undefined)
            this.advInfositeSpecificNotifications.set(key,value);
        let limExtnList = this.tableUserList.map((entry)=>{
            if(entry.vmGreetings && this.isLimitedExtn(entry.acoPhoneModel))
            return entry.username;
        })
        limExtnList=[...new Set(limExtnList.filter(el=>el))];
        if(limExtnList.length > 0){
            let LEList = [];
            this.tableUserList.forEach(tableUser =>{
                limExtnList.forEach(list => {
                    if(tableUser.siteName === key && tableUser.username === list)
                            LEList.push(list);
                });
            })
            if(LEList.length > 0 && LEList !== undefined)
            {
                let notifyMsg = VM_ERROR_MSG.LIM_EXTN_UNSUPPORTED + LEList.join(" , ");          
                this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
            }
        }
    })
    }
    updateUserHoursWarningsForLimExtn(){
        this.advInfositeSpecificNotifications.forEach((value:any[], key: string) =>{
        value = value.filter(entry => !entry.includes( USER_BUSINESS_HOURS.LIM_EXTN_NOT_SUPPORTED));
        if(value !== undefined)
            this.advInfositeSpecificNotifications.set(key,value);
        let userList = this.tableUserList.map((entry)=>{
           if(this.isLimitedExtn(entry.acoPhoneModel) && entry.timeZoneName !== undefined && entry.siteName === key)
            return entry.username;
        });
        userList = [...new Set(userList.filter(entry=>entry))];
        if(Array.isArray(userList) && userList.length > 0){
            let notifyMsg = USER_BUSINESS_HOURS.LIM_EXTN_NOT_SUPPORTED + userList.join(" , ");
            this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
        }
    })        
    }
    updateUserHoursWarningsForMOE(){
        this.advInfositeSpecificNotifications.forEach((value:any[], key: string) =>{
            value = value.filter(entry => !entry.includes(USER_BUSINESS_HOURS. MSG_ONLY_NOT_SUPPORTED));
            if(value !== undefined)
                this.advInfositeSpecificNotifications.set(key,value);
            let userList = [];
            userList = this.tableUserList.map((entry)=>{
           if(entry.phone === "message-only" && entry.siteName === key)
            return entry.username;
        });

            userList = [...new Set(userList.filter(entry=>entry))];
            if(Array.isArray(userList) && userList.length > 0){
                let notifyMsg = USER_BUSINESS_HOURS. MSG_ONLY_NOT_SUPPORTED + userList.join(" , ");
                this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
            }         
        })                     
    }
    updateBLFDataForLimExtn(){
        this.advInfositeSpecificNotifications.forEach((value:any[], key: string) =>{
        value = value.filter(entry => !entry.includes(BLF_ERROR_MSG.LIM_EXTN_NOT_SUPPORTED));
        if(value !== undefined)
            this.advInfositeSpecificNotifications.set(key,value);
        let notifyMsg = "";
        let leUserList = this.tableUserList.map((entry)=>{
            if(this.isLimitedExtn(entry.acoPhoneModel)){
                if(entry.blfList !== undefined && entry.blfList.length > 0 && entry.siteName === key)
                     return entry.username;
            }
        })
        if(Array.isArray(leUserList)){            
            leUserList =[...new Set( leUserList.filter(entry=>entry))];
            if(leUserList.length>0){
                notifyMsg = BLF_ERROR_MSG.LIM_EXTN_NOT_SUPPORTED + leUserList.join(" , ");
                this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
            }
        } 
        })        
    }

     updateBLFDataForMOE(){
        this.advInfositeSpecificNotifications.forEach((value:any[], key: string) =>{
        value = value.filter(entry => !entry.includes(BLF_ERROR_MSG.MSG_ONLY_NOT_SUPPORTED));
        if(value !== undefined)
            this.advInfositeSpecificNotifications.set(key,value);
        let notifyMsg = "";
        let UserList = this.tableUserList.map((entry)=>{
            if(entry.phone === "message-only" && entry.siteName === key){
                if(entry.blfList !== undefined && entry.blfList.length > 0)
                     return entry.username;
            }
        })
        if(Array.isArray(UserList)){            
            UserList = [...new Set(UserList.filter(entry=>entry))];
            if(UserList.length>0){
                notifyMsg = BLF_ERROR_MSG.MSG_ONLY_NOT_SUPPORTED + UserList.join(" , ");
                this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
            }
        } 
        })       
    }

    updateTwinWarningsForLimExtn(){
        this.advInfositeSpecificNotifications.forEach((value:any[], key: string) =>{
        value = value.filter(entry => !entry.includes(TWIN_CONTACT_NUMBERS.LIM_EXTN_NOT_SUPPORTED));
        if(value !== undefined)
            this.advInfositeSpecificNotifications.set(key,value); 
        let notifyMsg = "";
        let limExtnTwinList = [];
        this.tableUserList.map((tableEntry:tablePreviewData)=>{
            if(this.isLimitedExtn(tableEntry.acoPhoneModel) && tableEntry.twinMob.length > 0 && !this._didNotConfiguredTwinList.includes(tableEntry.extension) && tableEntry.siteName === key){
                limExtnTwinList.push(tableEntry.username);
            }
        });
        limExtnTwinList = [...new Set(limExtnTwinList)];
        if(Array.isArray(limExtnTwinList) && limExtnTwinList.length > 0){
            notifyMsg = TWIN_CONTACT_NUMBERS.LIM_EXTN_NOT_SUPPORTED + limExtnTwinList.join (" , ");
            this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
        }
    })
    }

    updateTwinWarningsForMOE(){
        this.advInfositeSpecificNotifications.forEach((value:any[], key: string) =>{
        value = value.filter(entry => !entry.includes(TWIN_CONTACT_NUMBERS.MSG_ONLY_NOT_SUPPORTED));
        if(value !== undefined)
            this.advInfositeSpecificNotifications.set(key,value);  
        let notifyMsg = "";
        let MOETwinList = [];
        this.tableUserList.map((tableEntry)=>{
            if(tableEntry.phone === "message-only" && tableEntry.twinMob.length > 0 && !this._didNotConfiguredTwinList.includes(tableEntry.extension) && tableEntry.siteName === key){
                MOETwinList.push(tableEntry.username);
            }
        });
        MOETwinList = [...new Set(MOETwinList)];
        if(Array.isArray(MOETwinList) && MOETwinList.length > 0){
            notifyMsg = TWIN_CONTACT_NUMBERS.MSG_ONLY_NOT_SUPPORTED + MOETwinList.join (" , ");
            this.advInfositeSpecificNotifications.get(key).push(notifyMsg);
        }
    })
    }
    updateHGnPGData(user = null) {
        try {
            if(user === null){
                this.tableHuntGroupList.map((tableEntry)=>{
                    if (this._ipoHuntGroup !== undefined && !isNaN(tableEntry.extension)) {
                        let ipoHGEntry = this._ipoHuntGroup.find(entry => (tableEntry.extension && (entry.Group && entry.Group.GR_Extension === tableEntry.extension.toString())));
                        let members = [];
                        if (ipoHGEntry && ipoHGEntry.Group) {
                        let devices = ipoHGEntry.Group.GR_Extensions.map(extn => {
                            // extn = new RegExp("\\*[0-9]+").test(extn)?extn.substring(1):extn;                        
                            let usr = this.tableUserList.find((usr) => {
                                return usr.extension.toString() === extn ;
                            });
                            if (usr !== undefined) {
                                if(!this.isLimitedExtn(usr.acoPhoneModel))
                                    members.push(usr.extension);
                                if(usr.pageCapable)
                                    return extn;
                            }
                            return null;
                        });
                        tableEntry.totalMembers = members.length;
                        tableEntry.devices = devices.filter(entry => entry);
                    }
                    }
                })
            }else{            
                this._tableHuntGroupList.map((tableEntry:tableHuntGroupData)=>{
                    if (this._ipoHuntGroup !== undefined  && !isNaN(tableEntry.extension)) {
                        let usrExtn = user.extension.toString();
                        //IPOffice config entry of hunt group has *extn for unchecked members whereas tableHuntGroupData devices have 
                        //only extn even for unchecked members. This needs to be handled carefully
                        let hgEntry = this._ipoHuntGroup.find((entry) => {
                            if (tableEntry.extension && entry.Group) {
                                return entry.Group.GR_Extension === tableEntry.extension.toString() &&
                                (entry.Group.GR_Extensions.includes(usrExtn));//|| entry.Group.GR_Extensions.includes("*"+usrExtn)
                            } 
                        })
                        if (hgEntry !== undefined) {
                            if (user.pageCapable) {
                                tableEntry.devices.push(user.extension.toString());
                            } else {
                                tableEntry.devices = tableEntry.devices.filter((el) => el != user.extension.toString());
                            }
                            let members = [];
                            if(hgEntry.Group)
                                members = hgEntry.Group.GR_Extensions;
                            members = members.filter((mem)=>{
                                //Include the unchecked members as well in the total count for hunt groups
                                mem = new RegExp("\\*[0-9]+").test(mem)?mem.substring(1):mem;
                                let found = this.tableUserList.find((userEntry)=>{
                                    return userEntry.extension.toString() === mem && this.isUserNotDeleted(mem) &&
                                    !this.isLimitedExtn(userEntry.acoPhoneModel)
                                })
                                return found !== undefined;
                            });
                            tableEntry.totalMembers = members.length;
                        }
                    }
                })
            }
            this.updateHuntGroupWarnings();
            this.updatePGNotificationMsg();
            
        } catch (error) {
            this.logger.error("Update Hunt group and paging group data: "+error);
            throw error;
        }
    }

    public removeUsersFromTable(delUsrList){
        var promise = new Promise((resolve,reject)=>{
            let newlyDelUsers = this._tableUserList.filter((entry:any) => {return delUsrList.includes(entry.extension)});
            this._idx++;
            newlyDelUsers.forEach((user:any)=>{
                user.isSelected = false;
                user.index = this._idx;
            });
            this._deletedUsersList.push(...newlyDelUsers);
            this.firestoreService.setDeletedUsersCfgData(this._cardId,this._deletedUsersList, this._idx).then(()=>{
                this._tableUserList = this._tableUserList.filter(entry => (!delUsrList.includes(entry.extension)));
                if(this._zeroDialData !== undefined && this._zeroDialData.length !== 0) {
                    this._deletedUserZeroDialData = this._zeroDialData.filter(entry => (delUsrList.includes(entry.extnId ? JSON.parse(entry.extnId) : "")))
                    this._zeroDialData = this._zeroDialData.filter(entry => (!delUsrList.includes(entry.extnId ? JSON.parse(entry.extnId) : "")))
                    this.firestoreService.setDTMFData(this._cardId,this._zeroDialData);
                    this.firestoreService.setDeletedUserDTMFData(this._cardId, this._deletedUserZeroDialData);
                }
                this.fillIPOUsersByPhoneAndProfile();
                this.updateCardDetailsWithUsersCount(this._cardId,this._tableUserList.length)
                .catch((error)=>{
                    this.logger.error("Error updating the users count while deleting users: "+ error);
                })
                this.removeUsersFromHG();
                this.removeUsersFromBLFData();
                this.removeUsersFromContactsList();
                this.removeUsersFromParkLocation();
                this.saveTableData(this._cardId)
                .then(()=>{
                    resolve();
                })
                .catch((error)=>{
                    this.logger.error(error);
                    //TODO: Revert the deletion
                    resolve();
                })                
            })
        })
        return promise;
    }

    removeUsersFromBLFData(){
        this._tableUserList.forEach((entry:any)=>{
            if(entry.blfList.length !=0){
                entry.blfList = entry.blfList.filter((blfData:any)=>{
                    return this.isUserNotDeleted(blfData.US_Extension);
                })
            }
        })
        
    }

    removeUsersFromContactsList(){
        this._tableUserList.forEach((entry:any)=>{
            if(entry.contactList.length !=0){
                entry.contactList = entry.contactList.filter((contact:any)=>{
                    return this.isUserNotDeleted(contact.Number);
                })
            }
        })
    }

    removeUsersFromHG(){
        this._tableHuntGroupList.forEach((entry:any)=>{
            if (this._ipoHuntGroup !== undefined) {
                let hgEntry = this._ipoHuntGroup.find((el: any) => el.Group.GR_Extension === entry.extension.toString());
                if (hgEntry !== undefined) {
                    let members = hgEntry.Group.GR_Extensions.filter((extn: any) => this.isUserNotDeleted(extn));
                    if(hgEntry.Group.GR_Name == "Paging"){
                        console.log("Members: "+JSON.stringify(members));
                    }
                    entry.totalMembers = members.length;
                    entry.devices = members.filter((extn) => {
                        return this.tableUserList.find((user) => { return user.extension.toString() === extn && user.pageCapable });
                    })
                }
            }
        });
        this.updateOverflowGroups();
        this.updatePGNotificationMsg();  
        this.updateHuntGroupWarnings();
    }

    removeUsersFromParkLocation(){
        this._tableParkLocationList.forEach((parkLocation:any) => {
        if (parkLocation && parkLocation.members && parkLocation.members.length != 0) {
            parkLocation.members = parkLocation.members.filter((member:any)=>{
                return this.isUserNotDeleted(member)
            });
        }
        });
    }

    public removeICRsFromTable(delICRList){
        console.log("Remove ICRs from table data");
        var promise = new Promise((resolve,reject)=>{
            this._deletedICRList.push(...delICRList);
            this.firestoreService.setDeletedUsersCfgData(this._cardId,this._deletedICRList).then(()=>{
                this._tableIncomingNumbersList = this._tableIncomingNumbersList.filter(entry => {
                    return delICRList.find(el=>el.name === entry.name && el.icrNumber === entry.icrNumber) === undefined;
                });
                
                for( let i = 0 ; i < this._originaltableIncomingNumbersList.length ; i ++)
                {
                    this._originaltableIncomingNumbersList[i].forEach(element => {
                        if(delICRList.find(entry=>entry.name === element.name && entry.icrNumber === element.icrNumber) !== undefined)
                        {
                            let index = this._originaltableIncomingNumbersList[i].indexOf(element);
                            if (index > -1)
                            this._originaltableIncomingNumbersList[i].splice(index,1);
                        }
                    })                  
                }
                this._originaltableIncomingNumbersList = this._originaltableIncomingNumbersList.filter(el=>el&& el.length>0);
                this._tableIncomingNumbersListCount = this._tableIncomingNumbersList.length;
                this.firestoreService.updateAA_ICRData(this._cardId, this._originaltableIncomingNumbersList ,true)
                .then(() => {
                    this.logger.debug("icr table data saved successful");
                    resolve();
                })                
            });                           
        });        
        return promise;
    }

    undoDelUsers() {
        var promise = new Promise((resolve, reject) => {
            let undoUserList = this._deletedUsersList.filter((extn: any) => extn.index === this._idx);
            if (undoUserList !== undefined) {
                this._tableUserList = this._tableUserList.concat(undoUserList);
                var undoUsersLen = undoUserList.length;
                for (var i = 0; i < undoUsersLen; i++) {
                    this._deletedUsersList.pop();
                }
                this._idx--;
                this.firestoreService.setDeletedUsersCfgData(this._cardId, this._deletedUsersList, this._idx).then(() => {
                    this.updateDTMFData(this._cardId);
                    this.fillIPOUsersByPhoneAndProfile();
                    this.updateCardDetailsWithUsersCount(this._cardId, this._tableUserList.length)
                        .catch((error) => {
                            this.logger.error("Error updating the users count while deleting users: " + error);
                        })
                    this.removeUsersFromHG();
                    this.updateUsersFromBLFDataContactList();
                    this.removeUsersFromParkLocation();
                    this.saveTableData(this._cardId)
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            this.logger.error(error);
                            //TODO: Revert the chnages
                            resolve();
                        })
                })
            }
        });
        return promise;
    }

    updateUsersFromBLFDataContactList() {
        this._tableUserList.forEach((entry: any) => {
            if (this._ipoUserList !== undefined) {
                let userEntry = this._ipoUserList.find((el: any) => el.Extension.US_Extension === entry.extension.toString());
                if (userEntry !== undefined) {
                    entry.blfList = userEntry.User_BLF.filter((entry: any) => {
                        return this.isUserNotDeleted(parseInt(entry.US_Extension));
                    });
                    entry.contactList = userEntry.US_ContactsList.filter((entry: any) => {
                        return this.isUserNotDeleted(parseInt(entry.Number));
                    });
                }
            }
        })
    }

    updateDTMFData(cardId) {
        this._tableUserList.forEach((entry: any) => {
            if (this._deletedUserZeroDialData !== undefined && this._deletedUserZeroDialData.length !== 0) {
                let zeroDialEntry = this._deletedUserZeroDialData.find((el: any) => el.extnId === entry.extension.toString());
                if (zeroDialEntry !== undefined) {
                    this._zeroDialData = this._deletedUserZeroDialData.filter(entry => (this.isUserNotDeleted(parseInt(entry.extnId))));
                    this._deletedUserZeroDialData = this._deletedUserZeroDialData.filter(entry => (this._zeroDialData.includes(entry.extnId ? JSON.parse(entry.extnId) : "")))
                    this.firestoreService.setDTMFData(cardId, this._zeroDialData);
                    this.firestoreService.setDeletedUserDTMFData(cardId, this._deletedUserZeroDialData);
                }
            }
        });
    }

    changeHGRole(hgUserExtnList:any){
        //console.debug("change huntgroup role:"+hgUserExtnList.toString());
        var promise = new Promise((resolve,reject)=>{
            for (let hgUser of hgUserExtnList){
                let idx = this.tableHuntGroupList.findIndex(el=>{
                    if(hgUser.extension === "" || isNaN(hgUser.extension))
                    return el.name === hgUser.name;
                    else
                    return el.extension === hgUser.extension;
                });
                this.tableHuntGroupList[idx].isPagingEnabled = ! this.tableHuntGroupList[idx].isPagingEnabled;  
                if(this._zeroDialData.length !== 0 && this._zeroDialData !== undefined)
                {
                    this._zeroDialData.forEach(data =>{
                    if(this.tableHuntGroupList[idx].name === data.name)
                    {
                        if(this.tableHuntGroupList[idx].isPagingEnabled)
                        {
                            this._zeroDialHGtoPgData = "";
                            data.isPaging = "yes";
                        }
                            
                        else      
                            {
                                this._zeroDialHGtoPgData = this.tableHuntGroupList[idx].name;
                                data.isPaging = "no";
                        }
                    }
                    })
                    this.firestoreService.setDTMFData(this._cardId,this._zeroDialData);
                }              
            }
            this.updateOverflowGroups();
            this.updatePGNotificationMsg();  
            this.updateHuntGroupWarnings();         
            resolve();
        });
        return promise;        
        
    }

    changeOverflowMigrationFlags(hgUserExtnList:any, flag){
        var promise = new Promise((resolve,reject)=>{
            for (let hgUser of hgUserExtnList){
                let idx = this.tableHuntGroupList.findIndex(el=>{
                    if(hgUser.extension === "" || isNaN(hgUser.extension))
                    return el.name === hgUser.name;
                    else
                    return el.extension === hgUser.extension;
                });
                let matchedHGEntry = this._ipoHuntGroup.find(entry => {
                    if (entry && entry.Group && entry.Group.GR_Extension && 
                        parseInt(entry.Group.GR_Extension) === this.tableHuntGroupList[idx].extension) {
                            return entry;
                    }
                })
                //Toggles can be enabled/disabled only when overflow groups are configured.
                if (this.tableHuntGroupList[idx].overflowGroup.length > 0){

                    //Toggles can be enabled/disabled only when one of the toggle is enabled.
                    if (this.tableHuntGroupList[idx].ringMode !== "RingGroup" && 
                    (this.tableHuntGroupList[idx].isCallQueueMigrationEnabled === true || this.tableHuntGroupList[idx].isRoutingMigrationEnabled === true)) {
                        this.tableHuntGroupList[idx].isCallQueueMigrationEnabled = ! this.tableHuntGroupList[idx].isCallQueueMigrationEnabled;
                        this.tableHuntGroupList[idx].isRoutingMigrationEnabled = ! this.tableHuntGroupList[idx].isRoutingMigrationEnabled;
                    } else if (this.tableHuntGroupList[idx].ringMode === "RingGroup" && matchedHGEntry.Group.GR_Voicemail !== undefined && 
                        matchedHGEntry.Group.GR_Voicemail.toLowerCase() === "true" && matchedHGEntry.Group.GR_VoicemailTime !== undefined &&
                        matchedHGEntry.Group.GR_VoicemailTime === "0") {
                            this.tableHuntGroupList[idx].isCallQueueMigrationEnabled = ! this.tableHuntGroupList[idx].isCallQueueMigrationEnabled;
                            this.tableHuntGroupList[idx].isRoutingMigrationEnabled = ! this.tableHuntGroupList[idx].isRoutingMigrationEnabled;
                    }
                }                             
            }
            this.updatePGNotificationMsg();  
            this.updateHuntGroupWarnings();         
            resolve();
        });
        return promise;        
        
    }

    updateCardDetailsWithUsersCount(cardId,usersCount){
        this.logger.debug("updateCardDetailsWithUsersCount: "+usersCount);
        let data = {totalIpoUsers : usersCount};
        return this.firestoreService.setMTUserCardData(cardId,null,null,data);     
    }
    public reAssignMessageOnly(extension, skipValidation = false)
    {
        var promise = new Promise((resolve, reject) => {
        var matchedTableUser = this._tableUserList.find(entry => (entry.acoPhoneStatus === phoneStatus.assigned && entry.extension === extension));   
        if(matchedTableUser === undefined){
            matchedTableUser = this._tableUserList.find(entry => (entry.acoPhoneStatus === phoneStatus.unassigned && entry.extension === extension));   
            if(matchedTableUser === undefined)
                reject({result: REJECT.REMOVE_PHONE_INVALID_EXTENSION});
            else
            {
                delete matchedTableUser.extensionId;
                delete matchedTableUser.acoPhoneModel;
                delete matchedTableUser.isSysmonAssignment;
                matchedTableUser.did = "";
                matchedTableUser.phone = PHONE.MESSAGE_ONLY;
                matchedTableUser.acoPhoneStatus = phoneStatus.assigned;
                matchedTableUser.pageCapable = false;
                matchedTableUser.macId = "";
                matchedTableUser.displayPhoneModel = PHONE.MESSAGE_ONLY;

                let matchedProfile = this._ipoUsersByProfile.find(entry => entry.profileType === matchedTableUser.profileType);
                if(matchedProfile !== undefined)
                {
                    let index = matchedProfile.extensionList.findIndex(extn => extn === matchedTableUser.extension);
                    if(index !== undefined)
                    {
                        if(index !== -1)
                        {                       
                            matchedProfile.extensionList.splice(index, 1);
                            matchedProfile.count--;
                        }
                    }
                }
                let matchedUser = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedTableUser.ipoPhoneModel);
            if(matchedUser !== undefined)
            {
                let idx = matchedUser.extensionList.findIndex(extn => extn === matchedTableUser.extension);
                if(idx !== undefined)
                {
                    if(idx !== -1)
                    {
                        matchedUser.extensionList.splice(idx, 1);
                        matchedUser.count--;
                    }
                }
            }
            }
        }
        else{
        //Re-evaluate _acoExtnByPhoneGroup
            var acoPhModelGroup = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === matchedTableUser.acoPhoneModel);
            if (acoPhModelGroup) {
                acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                acoPhModelGroup.count++;
            }
         /*   else
            {
                acoPhModelGroup = this.getExtensionGroupByPhoneModel();
                acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                acoPhModelGroup.phoneModel =  matchedTableUser.acoPhoneModel;
                acoPhModelGroup.count++;
                this._acoExtnByPhoneGroup.push(acoPhModelGroup);
            }*/
            delete matchedTableUser.extensionId;
            delete matchedTableUser.acoPhoneModel;
            delete matchedTableUser.isSysmonAssignment;
            matchedTableUser.did = "";
            matchedTableUser.phone = PHONE.MESSAGE_ONLY;
            matchedTableUser.acoPhoneStatus = phoneStatus.assigned;
            matchedTableUser.pageCapable = false;
            matchedTableUser.macId = "";
            matchedTableUser.displayPhoneModel = PHONE.MESSAGE_ONLY;
        }
        if(!skipValidation){
            this.validateTableData();
        }
        this.updateHGnPGData(matchedTableUser);
        this.updateLimExtnData();
        this.updateMOEWarningData(); 
        resolve();       
        });
        return promise;
    }
    public removeAssignedPhone(extension, skipValidation = false){
        //.logger.debug("removeAssignedPhone");
        var promise = new Promise((resolve, reject) => {
            var matchedTableUser = this._tableUserList.find(entry => (entry.acoPhoneStatus === phoneStatus.assigned && entry.extension === extension));
            
            if(matchedTableUser === undefined){
                //this.logger.error("Unable to locate extension in table user list, remove phone assignment failed");
                reject({result: REJECT.REMOVE_PHONE_INVALID_EXTENSION})
            }
            //Re-evaluate _acoExtnByPhoneGroup
            var acoPhModelGroup = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === matchedTableUser.acoPhoneModel);
            if (acoPhModelGroup) {
                acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                acoPhModelGroup.count++;
            }
            else{
                acoPhModelGroup = this.getExtensionGroupByPhoneModel();
                acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                acoPhModelGroup.phoneModel =  matchedTableUser.acoPhoneModel;
                acoPhModelGroup.count++;
                this._acoExtnByPhoneGroup.push(acoPhModelGroup);
            }
            
            //Re-evaluate _ipoUsersByPhone
            var phModelGroup = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedTableUser.ipoPhoneModel);
            if (phModelGroup) {
                phModelGroup.extensionList.push(matchedTableUser.extension);
                phModelGroup.count++;
            }
            else{
                phModelGroup = this.getExtensionGroupByPhoneModel();
                phModelGroup.extensionList.push(matchedTableUser.extension);
                phModelGroup.phoneModel =  matchedTableUser.ipoPhoneModel;
                phModelGroup.count++;
                this._ipoUsersByPhone.push(phModelGroup);
            }      
            
            //Re-evaluate _ipoUsersByProfile
            var ProfileGroup = this._ipoUsersByProfile.find(entry => entry.profileType === matchedTableUser.profileType);
            if (ProfileGroup) {
                ProfileGroup.extensionList.push(matchedTableUser.extension);
                ProfileGroup.count++;
            }
            else{
                ProfileGroup = this.getExtensionGroupByProfile();
                ProfileGroup.extensionList.push(matchedTableUser.extension);
                ProfileGroup.profileType =  matchedTableUser.profileType;
                ProfileGroup.count++;
                this._ipoUsersByProfile.push(ProfileGroup);
            }     
            
            matchedTableUser.extensionId = "";
            matchedTableUser.acoPhoneModel = "";
            matchedTableUser.did = "";
            matchedTableUser.phone = PHONE.UNASSIGNED;
            matchedTableUser.acoPhoneStatus = phoneStatus.unassigned;
            matchedTableUser.pageCapable = false;
            matchedTableUser.macId = "";
            matchedTableUser.isSysmonAssignment = false;
            matchedTableUser.displayPhoneModel = PHONE.UNASSIGNED;
            //this.updatePGWarningList(matchedTableUser);            
            if(!skipValidation){
                this.validateTableData();
            }
            //this.updateLimExtnList(matchedTableUser);
            this.updateHGnPGData(matchedTableUser);
            this.updateLimExtnData();
            this.updateMOEWarningData();
            resolve();
        });

        return promise;
    }

    public assignNewPhone(extension, acoChoice){
        var promise = new Promise((resolve, reject) => {
            var matchedUser: any, matchedProfile: any,index,idx;
            var acoExtensions = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === acoChoice);
            var matchedTableUser = this._tableUserList.find(entry => entry.extension === extension);

            if(acoExtensions === undefined){
                this.logger.error("Selected ACO phone type unavailable, phone assignment failed");
                reject({result: REJECT.ASSIGN_PHONE_INVALID_MODEL});
            }

            if(matchedTableUser === undefined){
                this.logger.error("Extensio unavailable for phone assignment, phone assignment failed");
                reject({result: REJECT.ASSIGN_PHONE_INVALID_EXTENSION});
            }

            var extensionId = acoExtensions.extensionList.shift();
            let matchedDeviceExtEntry = this._deviceExtList.find(entry => (extensionId === entry.extensionId));
            matchedTableUser.extensionId = extensionId;
            matchedTableUser.pageCapable = this.isDevicePageCapable(extensionId);
            //this.updatePGWarningList(matchedTableUser);
            matchedTableUser.did = this.getDID(matchedTableUser.extensionId);
            matchedTableUser.acoPhoneStatus = phoneStatus.assigned;
            matchedTableUser.phone = acoChoice;
            matchedTableUser.acoPhoneModel = acoChoice;
            let sysmonEntry = this._sysmonData.find((sysmonEntry) => (parseInt(sysmonEntry.userExtension) === matchedTableUser.extension));
            if (sysmonEntry !== undefined && ((acoChoice.toLowerCase() === EXISTING_PHONE) || (acoChoice.toLowerCase() === EXISTING_PHONE_LIMITED_EXTENSION))) {
                matchedTableUser.displayPhoneModel = acoChoice.toLowerCase() === EXISTING_PHONE ? sysmonEntry.phoneModel : sysmonEntry.phoneModel + " Limited Extension";
                matchedTableUser.macId = sysmonEntry.macId;
                matchedTableUser.isSysmonAssignment = true;
            } else {
                matchedTableUser.displayPhoneModel = acoChoice;
                matchedTableUser.macId = matchedDeviceExtEntry !== undefined ? matchedDeviceExtEntry.macId : "";
                matchedTableUser.isSysmonAssignment = false;
            }
            acoExtensions.count--;
            
            matchedProfile = this._ipoUsersByProfile.find(entry => entry.profileType === matchedTableUser.profileType);
            if(matchedProfile !== undefined)
            {
                index = matchedProfile.extensionList.findIndex(extn => extn === matchedTableUser.extension);
                if(index !== undefined)
                {
                    if(index !== -1)
                    {                       
                        matchedProfile.extensionList.splice(index, 1);
                        matchedProfile.count--;
                    }
                }
            }
            matchedUser = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedTableUser.ipoPhoneModel);
            if(matchedUser !== undefined)
            {
                idx = matchedUser.extensionList.findIndex(extn => extn === matchedTableUser.extension);
                if(idx !== undefined)
                {
                    if(idx !== -1)
                    {
                        matchedUser.extensionList.splice(idx, 1);
                        matchedUser.count--;
                    }
                }
            }
            if (matchedTableUser.twinMob.length !== 0 && matchedTableUser.did.length === 0) {
                this.pushDidNotConfiguredTwinList(matchedTableUser.siteName, matchedTableUser.extension);
            }
            //this.updateLimExtnList(matchedTableUser);
            this.validateTableData();
            this.updateHGnPGData(matchedTableUser);
            this.updateLimExtnData();
            this.updateMOEWarningData();
            resolve();
        });

        return promise;
    }


    public removeAutoAssignedPhones(ipoChoice, acoChoice, mapCount){
        this.logger.debug("removeAutoAssignedPhones - ipoChoice: ", ipoChoice, " acoChoice: ", acoChoice, " mapCount: ", mapCount);
        var promise = new Promise((resolve, reject) => {

            var matchedUsers = this._tableUserList.filter(entry => (entry.ipoPhoneModel === ipoChoice 
                && entry.acoPhoneModel === acoChoice
                && entry.acoPhoneStatus === phoneStatus.assigned));
            
            if(matchedUsers.length === 0){
                matchedUsers = this._tableUserList.filter(entry => (entry.profileType === ipoChoice 
                    && entry.acoPhoneModel === acoChoice
                    && entry.acoPhoneStatus === phoneStatus.assigned));
            }

            if(matchedUsers.length === 0){
                this.logger.error("Unable to match auto phone assignments length");
                mapCount = matchedUsers.length;
            }
            
            for(var idx=0; idx<mapCount; idx++) {
                var matchedTableUser = this._tableUserList.find(entry => (entry.extension === matchedUsers[idx].extension));
        
                if(matchedTableUser === undefined){
                    this.logger.error("Unable to locate extension number(s) in table user list, remove phone assignment failed: ", matchedUsers[idx].extension);
                    continue;
                }
                //Re-evaluate _acoExtnByPhoneGroup
                var acoPhModelGroup = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === matchedTableUser.acoPhoneModel);
                if (acoPhModelGroup) {
                    acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                    acoPhModelGroup.count++;
                }
                else{
                    acoPhModelGroup = this.getExtensionGroupByPhoneModel();
                    acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                    acoPhModelGroup.phoneModel =  matchedTableUser.acoPhoneModel;
                    acoPhModelGroup.count++;
                    this._acoExtnByPhoneGroup.push(acoPhModelGroup);
                }
                
                //Re-evaluate _ipoUsersByPhone
                var phModelGroup = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedTableUser.ipoPhoneModel);
                if (phModelGroup) {
                    phModelGroup.extensionList.push(matchedTableUser.extension);
                    phModelGroup.count++;
                }
                else{
                    phModelGroup = this.getExtensionGroupByPhoneModel();
                    phModelGroup.extensionList.push(matchedTableUser.extension);
                    phModelGroup.phoneModel =  matchedTableUser.ipoPhoneModel;
                    phModelGroup.count++;
                    this._ipoUsersByPhone.push(phModelGroup);
                }      
                
                //Re-evaluate _ipoUsersByProfile
                var ProfileGroup = this._ipoUsersByProfile.find(entry => entry.profileType === matchedTableUser.profileType);
                if (ProfileGroup) {
                    ProfileGroup.extensionList.push(matchedTableUser.extension);
                    ProfileGroup.count++;
                }
                else{
                    ProfileGroup = this.getExtensionGroupByProfile();
                    ProfileGroup.extensionList.push(matchedTableUser.extension);
                    ProfileGroup.profileType =  matchedTableUser.profileType;
                    ProfileGroup.count++;
                    this._ipoUsersByProfile.push(ProfileGroup);
                }     
                
                matchedTableUser.extensionId = "";
                matchedTableUser.acoPhoneModel = "";
                matchedTableUser.did = "";
                matchedTableUser.phone = PHONE.UNASSIGNED;
                matchedTableUser.macId = "";
                matchedTableUser.acoPhoneStatus = phoneStatus.unassigned;
                matchedTableUser.displayPhoneModel = PHONE.UNASSIGNED;
                matchedTableUser.pageCapable = false;
               // this.updatePGWarningList(matchedTableUser);
               //this.updateLimExtnList(matchedTableUser);
            }
            
            this.updateHGnPGData();
            this.updateLimExtnData();
            this.validateTableData();
            this.updateMOEWarningData();
            resolve();
        });

        return promise;
    }

    public validateEmailEntry(value){
        var result = false;
        if(value === ''){
            return true;
        }
        result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value);
        return result && tldjs.tldExists(value);
    }
    
    public addDummyEmailIds(domainStr) {
        let domain = domainStr.split("@")[1].replace(/[,;]*$/g, "");
        this.logger.debug("Add dummy email ids: " + domain);
        this.tableUserList.forEach((user)=>{
            if(user.email !== undefined && user.email !== null && user.email === ""){
                user.email = user.extension + "@"+ domain ;
                //this.logger.debug("New user email for user "+user.extension+" "+user.email);
            }
        });
    }
    //Outlook, Gmail formats 
    public autoAssignEmail(data,validateMail){
        this.logger.debug("autoAssignEmail");
            var result;
            if(data.indexOf(';') !== NOT_FOUND){
                var emailwithNameList = data.split(';');
                result = this.splitEmailAndName(emailwithNameList);
            }
            else if(data.indexOf(',') !== NOT_FOUND){
                var emailwithNameList = data.split(',');
                result = this.splitEmailAndName(emailwithNameList);
            }
            else{
                this.logger.warn("Unmatched delimiter");
                var emailwithNameList:any = [];
                emailwithNameList.push(data);
                result = this.splitEmailAndName(emailwithNameList);
            }
            if(validateMail){
                this._invalidEmailList = [];
                if(this.checkIfMailsAreValid(result)){
                    this._emailGroup = result;
                    return true;
                }else{
                    return false;
                }
            }else{
                this._emailGroup = result;
                return true;
            }
        //this.logger.debug("autoAssignEmail - result: ", result);
    }

    private checkIfMailsAreValid(data){
        var isValid = true;
        for(var key in data){
            if(!this.validateEmailEntry(key))
            {
             isValid = false;
             this._invalidEmailList.push(key);
            } 
        }
        return isValid;
    }

    private splitEmailAndName(emailwithNameList){
        var result = {};
        if(Array.isArray(emailwithNameList)){
            emailwithNameList.forEach(entry => {
                if(entry.indexOf('<') !== NOT_FOUND){
                    var emailId = entry.substring(
                        entry.lastIndexOf("<") + 1, 
                        entry.lastIndexOf(">")
                    );
                    var name = entry.substring(0, 
                        entry.indexOf("<")
                    );
                    result[emailId] = name;
                }
                else {
                //if(entry.indexOf('@') !== NOT_FOUND){
                    let email = entry.trim();
                    result[email] = email;
                }
            });
        }
        else{
            result[emailwithNameList] = emailwithNameList;
        }
        return result;
    }

    isEmailIdUnique=(value)=>{
       if(!value)
        return false;
        if(this._tableUserList.filter(entry => (value === entry.email)).length > 1)
         return false;
        return true;
    }

    private updatePGNotificationMsg(){
            let tempNotifications = []; 

                let pgGroup:tableHuntGroupData[]  = this._tableHuntGroupList.filter(el=>{
                    return el.isPagingEnabled;
                });

                let invalidPgGroup = [], duplicateExtnList = [],resExtnList = [], msgOnlyPGList = [], pgzerodialData = [];
                let emptyPgList = [];       
                pgGroup.map((pgGroupEntry)=>{
                    let pgExtnList = [];                  
                    let tempextnData = [];
                    let tempPgData = [];
                    let pgWithMessageOnly =[];

                    if(this._ipoHuntGroup !== undefined && this._ipoHuntGroup.length > 0 )
                    {
                    this._ipoHuntGroup.forEach((data) =>{
                    if(data.Group.GR_Name === pgGroupEntry.name)
                    {
                        data.Group.GR_Extensions.forEach(extn => {
                            this._tableUserList.forEach(element =>{
                            if(JSON.stringify(element.extension) === extn && element.phone === "message-only")
                            {
                                tempPgData.push(data.Group.GR_Name);
                                tempextnData.push(extn);
                            }
                            })                         
                        });
                        if(tempPgData.length > 0)
                        {
                            pgWithMessageOnly = [...new Set(tempPgData)];
                            pgExtnList = [...new Set(tempextnData)];
                            msgOnlyPGList.push({
                                "name" : pgWithMessageOnly,
                                "extnId" : pgExtnList
                            })
                        }
                    }
                    })
                    }
                    if (pgGroupEntry.extension === undefined || pgGroupEntry.extension === null || !pgGroupEntry.extension){
                        invalidPgGroup.push(pgGroupEntry.name);
                        return;
                    }else{
                        if(this._assignedExtnList.includes(pgGroupEntry.extension.toString())){
                            duplicateExtnList.push(pgGroupEntry.extension);
                            return;
                        }
                        if(this.reservedExtensionsList.includes(pgGroupEntry.extension)){
                            resExtnList.push(pgGroupEntry.name + "("+pgGroupEntry.extension+")");
                            return;
                        }  
                    }    
                    let ipoHGEntry =this._ipoHuntGroup.find((entry)=>{return entry.Group.GR_Extension === pgGroupEntry.extension.toString()});               
                    let members = ipoHGEntry.Group.GR_Extensions;
                    //list of members who are deleted
                    let deletedMemExtns = members.filter((memExtn:any) => {
                        memExtn = new RegExp("\\*[0-9]+").test(memExtn)?memExtn.substring(1):memExtn;
                        let isUserNotDeleted = this.isUserNotDeleted(memExtn);                        
                        return !isUserNotDeleted;
                    })
                    let name = ipoHGEntry.Group.GR_Name;                  
                    if(deletedMemExtns.length === ipoHGEntry.Group.GR_Extensions.length ||  ipoHGEntry.Group.GR_Extensions.length == 0){
                        emptyPgList.push(name);
                        return;
                    }
                                        
                    //this._pagingGroupsWarningsList = this._pagingGroupsWarningsList.filter(entry=>!entry.includes(pGroupEntry.name));
                    if(pgGroupEntry.devices.length != ipoHGEntry.Group.GR_Extensions.length){
                        let missingMem = members.map(el => {
                            //Exclude unchecked members
                            //el = (new RegExp('^\\*').test(el))?el.substring(1):el;
                            if((pgGroupEntry.devices.indexOf(el) <0) && (deletedMemExtns.indexOf(el) === -1) && 
                                !(new RegExp('^\\*').test(el))){
                                return el;
                            }
                            return null;
                        });
                        missingMem = missingMem.filter(entry=>entry);                        
                        if(missingMem.length > 0){
                            let notifyMsg = name +" : ";
                            if(missingMem.length > 1){
                                let extnsListStr = [missingMem.slice(0, -1).join(', '), missingMem.slice(-1)[0]].join(' and ');
                                notifyMsg += PG_ERROR_MSG.MULTIPLE_EXTNS_NOT_ASSIGNED + extnsListStr ;
                            }else{
                                notifyMsg += PG_ERROR_MSG.SINGLE_EXTN_NOT_ASSIGNED + missingMem[0];
                            }
                            tempNotifications.push(notifyMsg);
                        }
                    }
                    let uncheckedMemList = members.map(entry=>{
                        if(new RegExp("\\*[0-9]+").test(entry))
                         return entry.substring(1);
                        return null;
                    })
                    if(uncheckedMemList !== undefined || uncheckedMemList !== null){
                        uncheckedMemList = uncheckedMemList.filter(entry=>entry);
                        let count = uncheckedMemList.length;
                        if(uncheckedMemList && count  > 0){
                            let index = tempNotifications.findIndex(entry=>entry.includes(name));
                            let finalWarnMsg;
                            let notifyMsg =  PG_ERROR_MSG.UNCHECKED_MEM_LIST + ((count>1)?[uncheckedMemList.slice(0,count-1).join(', '),uncheckedMemList.slice(-1)].join(' and '):
                            uncheckedMemList.slice(-1));
                            if(index !== -1){
                                finalWarnMsg = tempNotifications[index]+"\n " + notifyMsg;
                                tempNotifications[index] = finalWarnMsg;
                            }else{
                                finalWarnMsg = name +" : " + notifyMsg;
                                tempNotifications.push(finalWarnMsg);
                            }
                        }
                    }
                    let membersCount = pgGroupEntry.devices.length;
                    if(membersCount > PAGE_DEV_LIMIT){
                        let notifyMsg = PG_ERROR_MSG.LIMIT_RCHD ;
                        if((membersCount - PAGE_DEV_LIMIT)>1){
                            let extnsListStr = [pgGroupEntry.devices.slice(PAGE_DEV_LIMIT,-1).join(', '),pgGroupEntry.devices.slice(-1)].join(' and ');
                            notifyMsg += "members "+ extnsListStr ;
                        }else{
                            notifyMsg += "member "+ pgGroupEntry.devices.slice(-1);
                        }                        
                        let index = tempNotifications.findIndex(entry=>entry.includes(name));
                        let finalWarnMsg;
                        if(index !== -1){
                            finalWarnMsg = tempNotifications[index]+"\n " + notifyMsg;
                            tempNotifications[index] = finalWarnMsg;
                        }else{
                            finalWarnMsg = name +" : " + notifyMsg;
                            tempNotifications.push(finalWarnMsg);
                        }
                    }else if(membersCount === 0){
                        emptyPgList.push(name);
                    }

                    if ( this._aaMenuKeysData !== undefined && this._aaMenuKeysData.length > 0 ){
                        let notifyMsg = PG_ERROR_MSG.AA_KEYS_FOUND;
                        let pgGroupEntryFoundInAAKeys = [];
                        this._aaMenuKeysData.map((aaEntry:any) => {
                            const aaActionKey = aaEntry.AA_Actions.find((eachKey) =>
                                                (eachKey.Destination.toString() === pgGroupEntry.extension.toString() || 
                                                eachKey.Destination.toString() === pgGroupEntry.name.toString()))
                            if (aaActionKey !== undefined) {
                                const alreadyAdded = pgGroupEntryFoundInAAKeys.find((element:any) => (element === aaEntry.AA_Name))
                                if (alreadyAdded === undefined) pgGroupEntryFoundInAAKeys.push(aaEntry.AA_Name);
                            }
                        })
                        if (pgGroupEntryFoundInAAKeys !== undefined && pgGroupEntryFoundInAAKeys.length > 0) {
                            notifyMsg += (pgGroupEntryFoundInAAKeys.length > 1 ? 
                                          [pgGroupEntryFoundInAAKeys.slice(0, -1).join(', '), 
                                          pgGroupEntryFoundInAAKeys.slice(-1)[0] ].join(' and ') 
                                          : pgGroupEntryFoundInAAKeys[0]) + "-" + pgGroupEntry.name;
                                          tempNotifications.push(notifyMsg)
                        }
                    }
                    this._zeroDialData.forEach(data =>{
                        if (data.name === pgGroupEntry.name)
                        {
                            pgzerodialData.push(data.name);
                        }
                    })

                })
                
                let notifyMsg;
                if (invalidPgGroup.length > 0) {
                    notifyMsg = PG_ERROR_MSG.EXTENSION_MISSING + invalidPgGroup.join(", ");
                    tempNotifications.push(notifyMsg);
                }
                if(pgzerodialData.length > 0)
                {
                    notifyMsg = "Zero Dialing cannot be set to Paging Only on ACO for : " + pgzerodialData.join(", ");
                    tempNotifications.push(notifyMsg);
                }
                //Check for Message only members
                if(msgOnlyPGList.length > 0)
                    {
                        let msgOnlyPGString = ""
                        msgOnlyPGList.forEach(data =>{
                            msgOnlyPGString += data.name[0] + "(" + data.extnId.join(",")+") , ";
                        })
                        msgOnlyPGString = msgOnlyPGString.trim().substring(0,msgOnlyPGString.length-2);
                        let notifyMsg = PG_ERROR_MSG.MSG_ONLY_UNSUPPORTED + msgOnlyPGString;
                        tempNotifications.push(notifyMsg);
                    }
                // Check for duplicate ExtnId on RC
                if(duplicateExtnList.length){
                    notifyMsg =   (duplicateExtnList.length > 1 ?  [duplicateExtnList.slice(0,-1).join(', '), duplicateExtnList.slice(-1)[0]].join(' and ') 
                                                          : duplicateExtnList[0]);
                    notifyMsg = "Paging-group " + GEN_ERR.DUPLICATE_EXTNS + " : " + notifyMsg;
                    tempNotifications.push(notifyMsg);
                }
                // Validate reserved Extensions
                if(resExtnList.length){
                    notifyMsg = PG_ERROR_MSG.RESERVED_EXTN + resExtnList.join(" , ");
                    tempNotifications.push(notifyMsg);
                }

                //Empty paging groups warning
                if(emptyPgList.length > 0){
                    notifyMsg = PG_ERROR_MSG.EMPTY_GROUP + emptyPgList.join(" , ");
                    tempNotifications.push(notifyMsg);
                }
              
                this.pgsiteSpecificNotifications.set("Common Notifications", tempNotifications);
           
            this._pagingGroupsWarningsList = this.pgsiteSpecificNotifications;
    }

    private removeCustomSteeringCodesByCountry(phoneNumber, tableUser) {
        if (this._acoCountry !== undefined && this._acoCountry !== null) {
            if (NAR_COUNTRIES_LIST.includes(this._acoCountry) && phoneNumber !== undefined &&
            phoneNumber.length !== 10 && phoneNumber === tableUser.twinMob) {
                phoneNumber = this.removeCustomSteeringCodes(phoneNumber);
            }
        }
        return phoneNumber;
    }

    private generateAcoExportData(acoTimeProfiles){
        this.logger.info("generate aco export data");

        let timeProfiles;
        if(acoTimeProfiles && acoTimeProfiles.data !== ''){
            timeProfiles = acoTimeProfiles.data;
        }
        var promise = new Promise((resolve, reject) => {
            this._extnsMap = [];
            this._acoExportUserList = [];
            this._extnBLFList = [];
            this._extncontactList = [];
            this._acoExportUserBHourList = [];
            this._acoExportFwdList = [];
            this._acoExportNonTwinUserList = [];
            this._acoExportHGUserList = [];
            this._acoExportPickupMemberList = [];
            this._acoExportPGUserList = [];
            let blfPresenceData = [];
            this._zeroDialDestnation = [];
            this._messageOnlyextnList = [];
            
            for(var idx=0; idx<this._tableUserList.length; idx++){
                let user = this.getAcoExportDataTemplate();
                user['extId'] = this._tableUserList[idx].extensionId;
                user['data'].contact['firstName'] =  this._tableUserList[idx].firstName;
                user['data'].contact['lastName'] =  this._tableUserList[idx].lastName;
                user['data'].contact['email'] =  this._tableUserList[idx].email;
                user['data'].contact['emailAsLoginName'] =  true;
                user['data'].status =  'Disabled';
                user['data'].extensionNumber =  this._tableUserList[idx].extension;
                user['data'].site['id'] =  this._tableUserList[idx].site_id;
                //Enable Users migrations task by default
                this.updateFeatureTaskChain(FEATURE_NAME.USERS);

                if(this._tableUserList[idx].timeZoneId !== NOT_FOUND){
                    user['data'].regionalSettings.timezone['id'] = this._tableUserList[idx].timeZoneId;
                }
                else{
                    //Remove regional settings if IP Office CSV data does not contain it
                    delete user['data'].regionalSettings.timezone.id;
                    delete user['data'].regionalSettings.timezone;
                    delete user['data'].regionalSettings;
                }
                this._acoExportUserList.push(user);

                let isUsrExtnLimited = this.isLimitedExtn(this._tableUserList[idx].acoPhoneModel);

                let extnMapEntry:any = this.getExtnMapTemplate();
                extnMapEntry['name'] = this._tableUserList[idx].ipoUserName;
                extnMapEntry['extId'] = this._tableUserList[idx].extensionId;
                extnMapEntry['extn'] = this._tableUserList[idx].extension;
                extnMapEntry['tzOffset'] = this._tableUserList[idx].tzOffset;
                extnMapEntry['siteName'] = this._tableUserList[idx].siteName;
                if(isUsrExtnLimited)
                 extnMapEntry['type'] = EXTNTYPE.LIMITEDEXTN;
                this._extnsMap.push(extnMapEntry);
                
                //USER BUSINESS HOURS
                //Exclude adding the user to business hours list if it is a Limited Extension

                if(!isUsrExtnLimited && timeProfiles && this._tableUserList[idx].phone !== "message-only"){
                    let timeLists = timeProfiles.find(entry => entry.name === this._tableUserList[idx].workHoursProfile);
                    if(timeLists && timeLists.profiles.data && timeLists.profiles.data.schedule && timeLists.profiles.data.schedule.weeklyRanges){

                        let tempTimeProfile = timeLists.profiles.data;
                        if (tempTimeProfile.schedule.ranges) {
                            delete tempTimeProfile.schedule.ranges;
                        }
                        
                        this._acoExportUserBHourList.push({
                            extId: this._tableUserList[idx].extensionId,
                            data: tempTimeProfile
                        });                        
                        this.updateFeatureTaskChain(FEATURE_NAME.USER_BHOURS);
                    }
                }
               // }

                //BLF FEATURE
                if(this._tableUserList[idx].blfList.length !=0 && !isUsrExtnLimited ){
                    let blfExtnList = [];
                    if(this._tableUserList[idx].phone !== "message-only")
                    {
                        this._tableUserList[idx].blfList.forEach(blfData => {
                        let blfExtnId= this._tableUserList.find(entry => entry.extension === parseInt(blfData.US_Extension));
                        if (blfExtnId && blfExtnId.extensionId !== undefined && (blfData.BLF_Key !== "1" && blfData.BLF_Key !== "2" && blfData.BLF_Key !== "3" && 
                            parseInt(blfData.US_Extension) !== this._tableUserList[idx].extension)) {                                
                            blfPresenceData.push(blfExtnId.extensionId);
                            let duplicateBLFData = blfExtnList.find(entry => entry.extension.id === blfExtnId.extensionId.toString());
                            if(!duplicateBLFData){
                                blfExtnList.push({
                                    "id": blfData.BLF_Key,
                                    "extension": {
                                        "id": blfExtnId.extensionId.toString()
                                    }
                                });
                            }else{
                                this.logger.debug(`Duplicate BLFData found- Extension:${this._tableUserList[idx].extension} key: ${blfData.BLF_Key} BLFUser ${blfData.US_Extension}`);
                                }  
                            }
                        });
                        this._extnBLFList.push({
                            "extnId" : this._tableUserList[idx].extensionId,
                            "data": blfExtnList
                        });
                        this.updateFeatureTaskChain(FEATURE_NAME.USER_BLF);   
                    }    

                }
                //MESSAGE-ONLY DATA
               if(this._messageOnlyExtns.length > 0)
                {
                    if(this._tableUserList[idx].phone === "message-only")
                    {
                        /* this._messageOnlyextnList.push({
                            "extnId" : this._tableUserList[idx].extension,
                            "name": this._tableUserList[idx].username,
                            "email": this.tableUserList[idx].email,
                        }); */

                        const msgOnlyUserFOund = this._acoExportUserList.find(user => user.data.extensionNumber === this._tableUserList[idx].extension)
                        if(msgOnlyUserFOund){
                            let clonedUser = JSON.parse(JSON.stringify(msgOnlyUserFOund));
                            clonedUser.data["type"] = "Voicemail";
                            /* delete clonedUser.extId; */
                            delete clonedUser['data'].regionalSettings.timezone.id;
                            delete clonedUser['data'].regionalSettings.timezone;
                            delete clonedUser['data'].regionalSettings;
                            delete clonedUser['data'].contact['emailAsLoginName'];
                            delete clonedUser['data'].status;
                            clonedUser['data'].contact['firstName'] = clonedUser['data'].contact['firstName'].trim() !== "" ? (clonedUser['data'].contact['firstName'] + " " + clonedUser['data'].contact['lastName']).trim() : clonedUser['data'].contact['lastName'];
                            delete clonedUser['data'].contact['lastName'];
                            this._messageOnlyextnList.push(clonedUser.data);
                        }
                        this.updateFeatureTaskChain(FEATURE_NAME.MESSAGE_ONLY);
                    }
                }
                /*{
                    this.migrationDataService.ZeroDialData.forEach(ipoDTMF => {
                        let dtmfExtension = ipoDTMF.destination.split(" ");
                        //let dtmfUserName = ipoDTMF.substr(ipoDTMF.indexOf(' ')).trim(); 
                        if(dtmfExtension[0] && JSON.stringify(this._tableUserList[idx].extension) === dtmfExtension[0])
                        {
                        this._zeroDialDestnation.push({
                                "name" : this._tableUserList[idx].username,
                                "extnId": JSON.stringify(this._tableUserList[idx].extension),
                                "siteId" : ipoDTMF.siteId
                            });
                        }                        
                        if(dtmfExtension[0])
                        {
                        this._tableHuntGroupList.forEach((element) =>{
                            if(element.name === ipoDTMF.destination)
                                {
                                    this._zeroDialDestnation.push({
                                        "name" : element.name,
                                        "extnId": JSON.stringify(element.extension),
                                        "siteId" : ipoDTMF.siteId
                                    });
                                }
                            }) 
                        } 
                        if(dtmfExtension[0] = "") 
                        this._zeroDialDestnation.push({
                            "name" : "",
                            "extnId": "",
                            "siteId" : ipoDTMF.siteId
                        });

                    });
                    this.updateFeatureTaskChain(FEATURE_NAME.ZERO_DIAL);
                }*/

                // let hgNameList = this._tableHuntGroupList.filter((entry:any)=>{
                //     return !entry.isPagingEnabled;
                // }).map((el)=>{return el.name});
                //PERSONAL CONTACTS FEATURE                  
                if(this._tableUserList[idx].contactList != 0)
                {
                    let userContact = this.getAcoUserPersonalContactTemplate();                           
                    userContact['extId'] = this._tableUserList[idx].extensionId;
                    let personalContactslist = [];
                    this._tableUserList[idx].contactList.map(contactsEntry => {
                        if (contactsEntry.Name != null && contactsEntry.Name.length > 0 &&
                            contactsEntry.Number != null && contactsEntry.Number.length > 0) {
                                personalContactslist.push({
                                    firstName : contactsEntry.Name,
                                    //businessPhone :contactsEntry.Number
                                    businessPhone : this.removeSteeringCodes(contactsEntry.Number , this._tableUserList[idx].filename)
                                });
                            }
                    })
                    if (personalContactslist.length > 0) {
                        userContact.data['records'] = personalContactslist;
                        this._extncontactList.push(userContact);
                        this.updateFeatureTaskChain(FEATURE_NAME.USER_CONTACTS);
                    }
                }
                if(this._messageOnlyExtns.length > 0)
                    this.updateFeatureTaskChain(FEATURE_NAME.USER_CONTACTS);
                //Consider Twinning migration only if assigned phone has a phone number and twinning is enabled
                //If it is a Limited Extension,its twin number will not be migrated
                if(!isUsrExtnLimited){
                    if(this._tableUserList[idx].twinMob !== "" && this._tableUserList[idx].did !== ""){
                        let mobileUser = this.getCallForwardTwinUserDataTemplate();
                        mobileUser['extId'] = this._tableUserList[idx].extensionId;
                        let phoneNumber = this.removeSteeringCodes(this._tableUserList[idx].twinMob , this._tableUserList[idx].filename);
                        if (phoneNumber !== undefined) {
                            let phoneNumberDigits = phoneNumber.replace(/[^a-zA-Z0-9]/g, '');
                            
                            phoneNumber = (phoneNumberDigits !== undefined && phoneNumberDigits.length === 10) ? phoneNumber : this._tableUserList[idx].twinMob;
                            

                            //If twin mobile number does not match any steering codes, if they are from NAR region countries 
                            //and if they are present in these formats: '91XXXXXXXXXX', '1XXXXXXXXXX', '9XXXXXXXXXX', '81XXXXXXXXXX', '8XXXXXXXXXX', 
                            //then the preceeding digits needs to be trimmed.
                            if (this._isMultiSiteMigration) {
                                //For multisite migration, check if site's country is from NAR region
                                if (this._siteCountryMap !== undefined && this._siteCountryMap !== null && this._siteCountryMap.size > 0 && 
                                    this._tableUserList[idx].site_id !== undefined && this._tableUserList[idx].site_id !== null) {
                                        let matchedCountry = this._siteCountryMap.get(this._tableUserList[idx].site_id);
                                        if (matchedCountry !== undefined && matchedCountry != null) {
                                            if (NAR_COUNTRIES_LIST.includes(matchedCountry) && phoneNumber !== undefined &&
                                            phoneNumber.length !== 10 && phoneNumber === this._tableUserList[idx].twinMob) {
                                                phoneNumber = this.removeCustomSteeringCodes(phoneNumber);
                                            }
                                        } else {
                                        //Multisite migration - matching country is undefined - defaulting to ACO's country to remove steering codes
                                        phoneNumber = this.removeCustomSteeringCodesByCountry(phoneNumber, this._tableUserList[idx]);
                                    }
                                } else {
                                    //Multisite migration - Site Id is null or undefined - defaulting to ACO's country to remove steering codes
                                    phoneNumber = this.removeCustomSteeringCodesByCountry(phoneNumber, this._tableUserList[idx]);
                                }
                            } else {
                                //Single site migration - defaulting to ACO's country to remove steering codes
                                phoneNumber = this.removeCustomSteeringCodesByCountry(phoneNumber, this._tableUserList[idx]);
                            }
                            phoneNumber = (phoneNumber !== undefined && phoneNumber.length === 10) ? phoneNumber : this._tableUserList[idx].twinMob;
                            mobileUser['data'].phoneNumber = phoneNumber;
                        } else {
                            mobileUser['data'].phoneNumber = this._tableUserList[idx].twinMob;
                        }
                        mobileUser['twinRule'] = this.updateMigrationTwinRule(this._tableUserList[idx]);
                        mobileUser['twinTProfileLabel'] = this._tableUserList[idx].twinProfile;
                        mobileUser['ringCount'] = this._tableUserList[idx].ringCount;
                        mobileUser['data'].type = this._tableUserList[idx].twinType;
                        this._acoExportFwdList.push(mobileUser);
                        this.updateFeatureTaskChain(FEATURE_NAME.CALL_FORWARD);
                        
                    }else{
                        let user = {} as any;
                        user['extId'] = this._tableUserList[idx].extensionId;
                        
                        user['ringCount'] =  this._tableUserList[idx].ringCount || DEFAULT_RING_COUNT;
                        user['phone'] = this._tableUserList[idx].did !== "" ? true:false;
                        this._acoExportFwdList.push(user);
                        this.updateFeatureTaskChain(FEATURE_NAME.CALL_FORWARD);
                    }
                }
            }
            this.firestoreService.setMessageOnlyData(this._cardId,this._messageOnlyextnList)
            this._extnPresenceTobeSet = [...new Set(blfPresenceData)];
           //this._ringOnMonitoredCall = [...new Set(ringData)];
            if (this._ipoHuntGroup !== undefined) {
                this._ipoHuntGroup.map(hgEntry => {
                    let hGTableEntry = this.tableHuntGroupList.find((entry) => {
                        if (entry && entry.extension){
                            return (entry.extension && (entry.extension.toString() === hgEntry.Group.GR_Extension));
                        }
                    })
                    if (hGTableEntry !== undefined && hGTableEntry.extension !== undefined && hGTableEntry.extension !== null &&
                        hGTableEntry.extension) {
                        let isPagingEnabled = hGTableEntry.isPagingEnabled;
                        if (!isPagingEnabled) {
                            let hgUser = this.getAcoUserHuntGroupTemplate();
                            let pickupdata = this.getACOExportPickupMembersData();
                            hgUser['extn'] = hgEntry.Group.GR_Extension;
                            hgUser['data']['extensionNumber'] = hgEntry.Group.GR_Extension;
                            hgUser['data'].contact['firstName'] = hgEntry.Group.GR_Name;
                            if (hgEntry.Group.GR_VoicemailEmail != "") {
                                hgUser['data'].contact['email'] = hgEntry.Group.GR_VoicemailEmail;
                            }
                            else {
                                hgUser['data'].contact['email'] = 'hgUser' + Math.floor((Math.random() * 1000)) + '@example.com';
                            }
                            hgUser['data'].site['id'] = hgEntry.SiteId;
                            hgUser['data'].site['name'] = hgEntry.Sitename;

                            let extnMapEntry: any = this.getExtnMapTemplate();
                            extnMapEntry['name'] = hgEntry.Group.GR_Name;
                            extnMapEntry['extId'] = "";
                            extnMapEntry['extn'] = hgEntry.Group.GR_Extension;
                            extnMapEntry['type'] = EXTNTYPE.HUNTGROUPEXTN;
                            extnMapEntry['siteName'] = hgEntry.Sitename;
                            this._extnsMap.push(extnMapEntry);

                            let members = hgEntry.Group.GR_Extensions;
                            members.map(extnEntry => {
                                let isUnchecked = false;
                                if (new RegExp("\\*[0-9]+").test(extnEntry)) {
                                    extnEntry = extnEntry.substring(1);
                                    isUnchecked = true;
                                }
                                let memExtn = parseInt(extnEntry);
                                if (memExtn) {
                                    let userExtn = this._tableUserList.find(entry => { return memExtn === entry.extension });
                                    //Ignore if it is Limited Extension
                                    if (userExtn && userExtn.phone !== "message-only" && !this.isLimitedExtn(userExtn.acoPhoneModel)) {
                                        hgUser['membersData'].addedExtensionIds.push(userExtn.extensionId);
                                        if (isUnchecked === true)
                                            hgUser['uncheckedMembersData'].extensionIds.push(userExtn.extensionId);
                                        if(hgEntry.Group.GR_RingMode === "RingGroup" && hgEntry.Group.GR_CallWaiting.toLowerCase() == "true")
                                        {
                                            pickupdata['extn'] = hgEntry.Group.GR_Extension;
                                            pickupdata['pickupMembers'].push(userExtn.extension);
                                        }
                                    }
                                }
                            })
                            this._acoExportHGUserList.push(hgUser);
                            this.updateFeatureTaskChain(FEATURE_NAME.HUNT_GROUP);
                            if(pickupdata['extn'] !== "" && pickupdata['pickupMembers'].length > 0  && pickupdata['pickupMembers'].length <= this._cqMemberLimit)
                                this._acoExportPickupMemberList.push(pickupdata);
                        } else {
                            let pgGroup = this.getPagingGroupDataTemplate();
                            pgGroup['ipoExtn'] = hgEntry.Group.GR_Extension;
                            pgGroup['data']['extensionNumber'] = hgEntry.Group.GR_Extension;
                            pgGroup['data'].contact['firstName'] = hgEntry.Group.GR_Name;
                            pgGroup['data'].site['id'] = hgEntry.SiteId;
                            let members = hgEntry.Group.GR_Extensions;
                            members.map(extnEntry => {
                                if (pgGroup['membersData'].addedDeviceIds.length < PAGE_DEV_LIMIT) {
                                    let user = this._tableUserList.find(entry =>
                                        parseInt(extnEntry) === entry.extension);
                                        //ignore if not page capable
                                    if (user != undefined && user.pageCapable) {
                                        let dev = this._pageCapableDeviceList.find(entry => entry.extensionId === user.extensionId);
                                        if (dev)
                                            pgGroup['membersData'].addedDeviceIds.push(dev.deviceId);
                                    }
                                }
                            })
                            this._acoExportPGUserList.push(pgGroup);
                        }
                    } else {
                        this.logger.debug("Cannot find the huntgroup in the table data");
                    }

                })
            }

            if(this._acoExportHGUserList.length < 1){
                this.removeFeatureFromTaskChain(FEATURE_NAME.HUNT_GROUP);
            }
         //   if(this._acoExportPickupMemberList.length > 0)
         //       this.updateFeatureTaskChain(FEATURE_NAME.CALLPICKUP);
            if(this._acoExportPGUserList.length > 0)
            {    
                this.updateFeatureTaskChain(FEATURE_NAME.PAGING_GROUP);
            }else{
                this.removeFeatureFromTaskChain(FEATURE_NAME.PAGING_GROUP);
            }
             //ZERO DIALING FEATURE
               
             if(this._zeroDialData.length !== 0 && this._taskChainList.includes(FEATURE_NAME.AUTO_ATTENDANT) && this._taskChainList.includes(FEATURE_NAME.COMPANY_RULES))
             {
                let zeroDialCount = this._zeroDialData.length;
                if(this._zeroDialData !== undefined)
                this._zeroDialData.forEach((data) =>{
                    if(data.isPaging === "yes")
                    {
                        zeroDialCount --;
                    }
                })  
                if(zeroDialCount > 0)
                    this.updateFeatureTaskChain(FEATURE_NAME.ZERO_DIAL);
                else
                    this.removeFeatureFromTaskChain(FEATURE_NAME.ZERO_DIAL);
             }
             else
             {
                if(this._taskChainList.includes(FEATURE_NAME.ZERO_DIAL))
                    this.removeFeatureFromTaskChain(FEATURE_NAME.ZERO_DIAL);
             }
            

            this._tableParkLocationList.map((entry:any)=>{
                let extnMapEntry: any = this.getExtnMapTemplate();
                extnMapEntry['name'] = entry.groupName;
                extnMapEntry['extId'] = "";
                extnMapEntry['extn'] = entry.groupExtension;
                extnMapEntry['type'] = EXTNTYPE.PARKLOCEXTN;
                extnMapEntry['siteName'] = "";
                this._extnsMap.push(extnMapEntry);
            })
            
            
                resolve();
        });

        return promise;
    }

    private updateFeatureTaskChain(featureName){ 
        let featureFound  = this._taskChainList.find((entry:any) =>  entry == featureName);
        if(!featureFound){
            this._taskChainList.push(featureName);
        }
    }
    private removeFeatureFromTaskChain(featureName){
        this._taskChainList = this._taskChainList.filter((entry:any) =>  entry !== featureName);
    }

    private prioritizeTaskQueue(){
        let newTaskChain = [];
        TASK_QUEUE_PRIORITY.filter((entry:any) => {
            let featureFound = this._taskChainList.find((featureName:any) => featureName === entry);
            if(featureFound){
                newTaskChain.push(featureFound);
            }
        })
        this._taskChainList = newTaskChain;
    }

    private updateMigrationTwinRule(tableUser){
        let _twinRule:TwinRule;
        if(tableUser.twinProfile === tableUser.workHoursProfile){
            _twinRule = TwinRule.default;
        }
        else if(tableUser.twinProfile !== "" && tableUser.workHoursProfile == ""){
            _twinRule = TwinRule.TPForTwinNumberOnly;
        }
        else if(tableUser.twinProfile == "" && tableUser.workHoursProfile !== ""){
            _twinRule = TwinRule.TPForPrimaryNumerOnly;
        }
        else{
            _twinRule = TwinRule.TPForBoth;
        }
        this.logger.info("updateMigrationTwinRule: result = ", _twinRule);
        return _twinRule;
    }

    public setACOMigrationData(cardId){
        this.logger.info("setACOMigrationData");
        let userVM = false;
        var promise = new Promise((resolve, reject) => {
            /* let found = this._notifications.find(entry => (
                entry.severity === SEVERITY.ERROR));    
            if(!found){ */
                //this.firestoreService.readTimeProfilesData(cardId).
                this.firestoreService.getIPOSummaryData(cardId)
                .then((result:any) => {          
                    this.migrationDataService.IPOSummaryData = JSON.parse(result);
                })
                this.downloadFileFromBucket(`Greetings/${cardId}/AcoExportCfg/timeProfiles.json`)
                .then((acoTimeProfiles) => {
                    return this.generateAcoExportData(acoTimeProfiles);
                })
                .then(() => {
                    return this.firestoreService.getGreetingsData(cardId)
                    .then((data:any)=>{
                        if(data){
                            if(data.userVMCount !== undefined && data.userVMCount > 0){
                                userVM = true;
                                this.updateFeatureTaskChain(FEATURE_NAME.USER_VM_WORKHOURS);
                                if(this._acoExportUserBHourList.length){
                                    this.updateFeatureTaskChain(FEATURE_NAME.USER_VM_AFTERHOURS);
                                }
                                if(this._acoExportFwdList.length){
                                    this.updateFeatureTaskChain(FEATURE_NAME.USER_VM_CUSTOMHOURS);
                                }
                            }
                            if (data.aaPromptCount !== undefined && data.aaPromptCount > 0){
                                this.updateFeatureTaskChain(FEATURE_NAME.AUTO_ATTENDANT_PROMPTS);
                            }
                            let complexIvrPromptList = JSON.parse(data.aaComplexPromptsList);
                            if (complexIvrPromptList !== undefined && complexIvrPromptList.length > 0){
                                this.updateFeatureTaskChain(FEATURE_NAME.COMPLEX_IVR_PROMPTS);
                            }
                        }
                    }).catch((error)=>{
                        this.logger.debug(error);
                    });
                }).then(() => {
                    this.logger.debug("updating UserCfgData");
                    return (this.fileUploader.uploadJsonFile(cardId , '/AcoExportCfg/userCfgData.json' ,{data:this._acoExportUserList , totalData:this._acoExportUserList.length}));
                }).then(() => {
                    this.logger.debug("UserCfgData updated successfully");
                    return (this.firestoreService.getTasksTemplate(cardId));
                }).then((tasks) =>{
                    this.updateFeatureTaskChain(FEATURE_NAME.POST_MIGRATION);
                    this.prioritizeTaskQueue();
                    tasks[TASK_NAME.CHAIN].data = this._taskChainList;
                    tasks[TASK_NAME.FINAL_TASK_CHAIN].data = [];
                    tasks[TASK_NAME.USER_ASSIGN].data = this._acoExportUserList;
                    tasks[TASK_NAME.MESSAGE_ONLY].data = this._messageOnlyextnList;
                    tasks[TASK_NAME.USER_CONTACTS].data = this._extncontactList;
                    tasks[TASK_NAME.USER_BUSINESS_HOURS].data = this._acoExportUserBHourList;
                    tasks[TASK_NAME.USER_CALLFORWARD].data = this._acoExportFwdList;
                    tasks[TASK_NAME.USER_BLF_ENABLEPRESENCE].data = this._extnPresenceTobeSet;
                    tasks[TASK_NAME.USER_UPDATEBLFDATA].data = this._extnBLFList;
                    tasks[TASK_NAME.HUNT_GROUP_USERDATA].data = this._acoExportHGUserList;
                    tasks[TASK_NAME.EXTENSIONS_MAP].data = this._extnsMap;
                    tasks[TASK_NAME.PAGING_GROUP_USERDATA].data = this._acoExportPGUserList;
                    tasks[TASK_NAME.PARK_LOCATION_DATA].data = this._tableParkLocationList;
                    //tasks[TASK_NAME.UPLOAD_ZERO_DIAL].data = this._zeroDialDestnation;
                    tasks[TASK_NAME.MAC_UPDATE_FOR_EXTENSIONS].data = this._sysmonData;
                   // tasks[TASK_NAME.USER_UPDATERINGONMONITOREDCALLDATA].data = this._ringOnMonitoredCall;
                   tasks[TASK_NAME.CALL_PICKUP].data = this._acoExportPickupMemberList;
                    return (this.firestoreService.setACOMigrationData(tasks , cardId))
                }).then(() => {
                    this.updateStatsSummary(cardId);
                    this.logger.debug("setACOMigrationData task is completed");
                    resolve();
                }).catch(error => {
                    this.logger.error(error);
                    reject();
                }); 
            /* }
            else{
                this.logger.error("Unable to trigger aco export data as errors exist. See error notification badge");
                reject();
            } */
            
        });
        return promise;
    }

    public saveTableData(cardId){
        this.logger.info("saveTableData");
        this.updateMOEWarningData();
        var promise = new Promise((resolve, reject) => {
            //this.firestoreService.setTableData(cardId, this._tableUserList)
            this.fileUploader.uploadJsonFile(cardId , '/IpoCfg/tableData.json' ,this._tableUserList)
            .then(() => {
                this.logger.debug("table data saved successful");
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });
        return promise;
    }

    public async saveParkLocationData(cardId) {
        this.logger.info("saveParkLocationData");
        var promise = new Promise((resolve, reject) => {
            this.prepareParkLocationData().then(() => {
                        this.logger.debug("park location data prepared and saved successfully");
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
        });
        return promise;
    }

    public updateHGnPGWrapUpTime() {
        this.logger.info("update call queues and paging group wrapUpTime");
        var promise = new Promise((resolve, reject) => {
            this._tableHuntGroupList.map((hGroupEntry:any) => {
                let matchedIpoHGGroup = this._ipoHuntGroup.find((hgGroup) => (hGroupEntry.extension && (hgGroup.Group.GR_Extension === hGroupEntry.extension.toString())));
                if (matchedIpoHGGroup !== undefined && matchedIpoHGGroup.Group.GR_Extensions !== undefined && matchedIpoHGGroup.Group.GR_Extensions.length > 0) {
                    let members = matchedIpoHGGroup.Group.GR_Extensions.map((extn:any) => {
                        //Include unchecked members in the list
                        extn = new RegExp("\\*[0-9]+").test(extn)?extn.substring(1):extn;
                        //TODO: Need to include check so that the user is not assigned a Limited Extension
                        let isLimitedExtn = this.tableUserList.find((entry)=>{
                            return entry.extension == extn && this.isLimitedExtn(entry.acoPhoneModel);
                        });
                        if(this.isUserNotDeleted(extn) && !(this.reservedExtensionsList.includes(parseInt(extn))) &&
                        isLimitedExtn == undefined)
                         return extn;
                        return null;
                    });
                    
                    //Default wrapUpTime for CallQueues
                    let wrapUpTime = 15;
                    let wrapUpTimes = [];

                    if (members !== undefined && members.length > 0) {
                        //Fetch the highest wrapUpTime among the HG members.
                        for (let idx = 0; idx < members.length; idx++) {
                            if (members[idx] !== undefined && members[idx] !== null) {
                                let user = this._tableUserList.find(user => user.extension === parseInt(members[idx]));
                                if (user !== undefined && user.wrapUpTime !== undefined) {
                                    wrapUpTimes.push(user.wrapUpTime);
                                }
                            }
                        }

                        if (wrapUpTimes.length > 0) {
                            wrapUpTime = Math.max(...wrapUpTimes);
                        }

                        //If wrapUpTime is less than 10, then assign wrapUpTime to 0 
                        if (wrapUpTime < 10) {
                            wrapUpTime = 0;
                        } else if (wrapUpTime > 300) {
                            wrapUpTime = 300;
                        }
                        //If wrapUpTime is greater than 10 and is not a multiple of 5, assign the nearest multiple of 5 to it.
                        else if ((wrapUpTime%5 !== 0)) {
                            if (wrapUpTime%5 < 3) {
                                wrapUpTime = wrapUpTime - (wrapUpTime%5);
                            } else {
                                wrapUpTime = Math.ceil(wrapUpTime/5)*5;
                            }
                        }
                        members = members.filter(entry=>entry);
                        hGroupEntry.totalMembers = members.length; 
                    } else {
                        hGroupEntry.totalMembers = 0;
                    }            
                    hGroupEntry.wrapUpTime = wrapUpTime;
                }
            });
            resolve();
        });
        return promise;
    }

    public saveHGnPGTableData(cardId){
        this.logger.info("save call queues and paging group table data");
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.setHGnPGTableData(cardId, this._tableHuntGroupList)
            .then(() => {
                this.logger.debug("call queues and paging group table data saved successful");
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });
        return promise;
    }

    public saveCompanyData(cardId) {
        this.logger.info("saveCompanyData");
        this._modifiedCompanyNumberAssignementCount = 0;
        let i = 0;
        var isUpdated = 'false';
        this._originalICRData = [];
        this._modifiedICRData = [];
        if (this._tableIncomingNumbersList !== undefined && this._tableIncomingNumbersList.length > 0) {
            this._originaltableIncomingNumbersList.forEach(element => {
                element.forEach(entry => {
                    if (this.AAList.includes(entry.name) && this._tableIncomingNumbersList[i] !== undefined) {
                        if (entry.phoneNumber !== this._tableIncomingNumbersList[i].companyNumbers) {
                            entry.phoneNumber = this._tableIncomingNumbersList[i].companyNumbers;
                        }
                        i++;
                    }

                });
            });
        }
        this.setIncomingData();
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.updateAA_ICRData(cardId, this._originaltableIncomingNumbersList, isUpdated)
                .then(() => {
                    this.logger.debug("table data saved successful");
                    this.firestoreService.readAA_ICRData(cardId)
                        .then((doc: any) => {
                            let UpdatedData = (doc !== null) ? JSON.parse(doc.data) : [];
                            UpdatedData.forEach(entry => {
                                entry.forEach(element => {
                                    this._modifiedICRData.push(element.phoneNumber)
                                });
                            });
                            this.firestoreService.readOriginalAA_ICRData(cardId)
                                .then((doc: any) => {
                                    let OriginalData = (doc !== null) ? JSON.parse(doc.data) : [];
                                    OriginalData.forEach(entry => {
                                        entry.forEach(element => {
                                            this._originalICRData.push(element.phoneNumber)
                                        });
                                    });
                                    for (let i = 0; i < this._originalICRData.length; i++) {
                                        if (this._originalICRData[i] != this._modifiedICRData[i])
                                            this._modifiedCompanyNumberAssignementCount++;
                                    }
                                    if (this._modifiedCompanyNumberAssignementCount > 0)
                                        isUpdated = 'true';
                                    else
                                        isUpdated = 'false';
                                    this.firestoreService.updateAA_ICRData(cardId, this._originaltableIncomingNumbersList, isUpdated)
                                        .then(() => {
                                            this.logger.debug("table data saved successful");
                                            resolve();
                                        })
                                })
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
        })
        return promise;
    }

    public resetTableData(cardId){
        this.logger.info("resetTableData");
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.setTableData(cardId, [])
            .then(() => {
                this.logger.debug("table data reset successfully");
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });

        return promise;
    }

    private updateStatsSummary(cardId){
        this.logger.debug("updateStatsSummary");
        let ivrPrompts = this._tableAutoAttendantList.filter(entry => entry.ivrPrompt == true).length;
        let userVMs = this._tableUserList.filter(entry => entry.vmGreetings == true).length;
        let pagingGroups = this._tableHuntGroupList.filter((entry)=>{return entry.isPagingEnabled}).length;
        let huntGroups = this._tableHuntGroupList.length - pagingGroups;
        let _stats = {
            totalAutoAttendants: this._tableAutoAttendantList.length,
            totalIVRprompts: ivrPrompts,
            totalPagingGroups: pagingGroups,
            totalHuntGroups: huntGroups,
            totalUserVoiceMails: userVMs
        }
        this.firestoreService.setMTUserCardStats(cardId, _stats);
    }

    public fetchrefreshData(cardId)
    {
        let extnDetails = [];
        let mailDetails = [];
        this._assignedExtnList = [];
        this._assignedEmailList = [];
        var extnData;
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.fetchDeviceExtList(cardId)
                .then((deviceExtList: any) => { 
                    if (deviceExtList !== undefined && Object.keys(JSON.parse(deviceExtList.data)).length > 0) {
                        this._deviceExtList = JSON.parse(deviceExtList.data);
                    }
                    this.firestoreService.getAssignedExtensions(cardId)
                        .then((doc: any) => {
                            extnData = doc.data;
                            extnDetails = JSON.parse(extnData);
                            extnDetails.forEach(extnId => {
                                this._assignedExtnList.push(extnId.extensionNumber);
                                mailDetails.push(extnId.contact);
                            })
                            mailDetails.forEach(mail => {
                                this._assignedEmailList.push(mail.email);
                            });
                            this.updateHuntGroupWarnings();
                            this.updatePGNotificationMsg();
                            this.firestoreService.getFeaturePrerequisite(cardId)
                                .then((result: any) => {
                                    return (this.firestoreService.getAcoImportData(cardId));
                                })
                                .then((doc: any) => {
                                    if (doc !== undefined && doc.exists) {
                                        this.logger.debug("ACO Import data");
                                        this._acoImportData = doc.data();
                                        if (this._acoImportData.total) {
                                            this.fillIPOUsersByPhoneAndProfile();
                                            this.fillACOUsersByPhone();
                                            resolve();
                                            return;
                                        }
                                    }
                                    else {
                                        reject();
                                    }
                                })

                        })
                })
                .catch((error) => {
                    this.logger.error(error);
                    reject(error);
                });
    }) 

    return promise;
    
    }

    private setIncomingData()
    {
        for( let i = 0 ; i < this._originaltableIncomingNumbersList.length ; i ++)
        {
            this._originaltableIncomingNumbersList[i].forEach(element => {
                if(!this.AAList.includes(element.name))
                {
                    let index = this._originaltableIncomingNumbersList[i].indexOf(element);
                    if (index > -1)
                        this._originaltableIncomingNumbersList[i].splice(index,1);
                }
             })
            this._originaltableIncomingNumbersList = this._originaltableIncomingNumbersList.filter(el=>el&& el.length>0);
             if(this._originaltableIncomingNumbersList.length > 0){
                 this.updateFeatureTaskChain(FEATURE_NAME.COMPANY_RULES);
             }
        }                     
    }

    public removeCustomSteeringCodes(telephoneNumber) {
        let phoneNumber = telephoneNumber;
        let customteeringCodeFormats = CUSTOM_STEERING_CODE_FORMATS;
        for(let i = 0; i < customteeringCodeFormats.length; i++) {
            //Extract digits before xxxx pattern
            let digits = customteeringCodeFormats[i].substring(0, customteeringCodeFormats[i].indexOf("X"));
            let trimmedPhoneNumber = phoneNumber.substring(customteeringCodeFormats[i].indexOf("X"), phoneNumber.length);
            if (digits !== undefined && phoneNumber.startsWith(digits) && 
            trimmedPhoneNumber !== undefined && trimmedPhoneNumber.length === 10) {
                return trimmedPhoneNumber;
            }
        }
        return phoneNumber;
    }
  
    public removeSteeringCodes(telephoneNumber:any , csvFilename:any, steeringData?:any) {
        let phoneNumber = telephoneNumber;
        //let steeringCodes = this.steeringCodeData;
        let steeringCodes = [];
        if (csvFilename !== null) steeringCodes = this.steeringCodeData.filter((steeringCode:any) => steeringCode.FileName === csvFilename)
        else if (steeringData !== undefined && steeringData.length > 0) steeringCodes = steeringData; 
        for(let i=0; i < steeringCodes.length; i++) {
            let lengthOfX: any;
            let stCodes:any = [];

            //If steering code does not contain N and is equal to telephoneNumber, then replace telephoneNumber with SC_Telepone value.
            if((/^[0-9].*$/.test(steeringCodes[i].Steering_Code)) && !(/[a-zA-Z]/g.test(steeringCodes[i].Steering_Code)) && 
                steeringCodes[i].Steering_Code.replace(/[^a-zA-Z0-9]/g, '') === telephoneNumber) {
                      return steeringCodes[i].SC_Telephone; 
            }

            let digitsInSCTelephone = this.fetchDigitsInSCTelephone(steeringCodes[i].SC_Telephone.replace(/[^a-zA-Z0-9]/g, ''));

            //Extract all combinations of steering Codes when optional part such as [8]1N is present
            stCodes = this.extractAndPopulateSteeringCodeSet(steeringCodes[i].Steering_Code, stCodes);
            
            //Check for pattern xxx... and remove the same from steering code
            stCodes.forEach((stCode, index) => {
                if(stCode.match(/x+/)) {
                    lengthOfX = stCode.match(/x+/).join("").length;
                    stCodes[index] = stCode.substring(0, stCode.indexOf("x")); 
                }
            });

            stCodes = this.extractSteeringCodeDigits(stCodes);
            stCodes = this.removeScTelephoneDigits(digitsInSCTelephone, stCodes, steeringCodes[i]); 

            let steeringCodeRemoved: any;
            [steeringCodeRemoved, phoneNumber] = this.removeSteeringCode(phoneNumber, stCodes);
            [steeringCodeRemoved, phoneNumber] = this.validatePhoneNumber(steeringCodeRemoved, phoneNumber, lengthOfX, digitsInSCTelephone, telephoneNumber);
            if (steeringCodeRemoved) {
               break;
            }
        }
        return phoneNumber;
    }

    private fetchDigitsInSCTelephone(scTelephone) {
        if (scTelephone.includes("N") && scTelephone.indexOf("N") > 0 
            && /^[0-9].*$/.test(scTelephone.substring(0, scTelephone.indexOf("N")))) {
            return scTelephone.substring(0, scTelephone.indexOf("N"));
        } else {
            return "";
        }
    }

    private extractAndPopulateSteeringCodeSet(steeringCode, stCodes) {
        if (steeringCode.includes("[") && steeringCode.includes("]") 
        && steeringCode.substring(steeringCode.indexOf("["), steeringCode.indexOf("]")).length >0) {

            let initialMandatoryStcode = steeringCode.substring(0, steeringCode.indexOf("["));
            let optionalPart = steeringCode.substring(steeringCode.indexOf("[") + 1, steeringCode.indexOf("]"));
            let finalMandatoryStCode = steeringCode.substring(steeringCode.indexOf("]") + 1);

            stCodes.push(initialMandatoryStcode.concat(optionalPart).concat(finalMandatoryStCode).replace(/[^a-zA-Z0-9]/g, ''));
            stCodes.push(initialMandatoryStcode.concat(finalMandatoryStCode).replace(/[^a-zA-Z0-9]/g, ''));
        } else {
            stCodes.push(steeringCode.replace(/[^a-zA-Z0-9]/g, ''));
        }
        return stCodes; 
    }

    private extractSteeringCodeDigits(stCodes) {
        stCodes.forEach((stCode, index) => {
            if (stCode !== undefined && stCode.length > 0 && stCode.match(/\d+/g) !== null ) {
                stCodes[index] = stCode.match(/\d+/g).join("");
            }
        });
        return stCodes;
    }

    //remove digits present in scTelephone field from the steeringCodes if present at the end of it
    private removeScTelephoneDigits(scTelephoneDigits, stCodes, entry) {
        stCodes.forEach((stCode, index) => {
            if (scTelephoneDigits.length > 0 && entry.SC_Telephone.length < entry.Steering_Code.length 
                && stCode.includes(scTelephoneDigits) && stCode.endsWith(scTelephoneDigits)) {
                //replace the digits present in scTelephoneDigits at the end of steeringCode with empty string
                stCodes[index] = stCode.replace(new RegExp(scTelephoneDigits+'$'), "");
            }
        });
        return stCodes;
    }

    private removeSteeringCode(phoneNumber, stCodes) {
        let steeringCodeRemoved = false;
        for(let stCode of stCodes) {
            if (stCode != null && stCode.length > 0 && phoneNumber.startsWith(stCode)) {
                phoneNumber = phoneNumber.substring(0 + stCode.length);
                steeringCodeRemoved = true;
                break;
            }
        }
        return [steeringCodeRemoved, phoneNumber];
    }

    private validatePhoneNumber(steeringCodeRemoved, phoneNumber, lengthOfX, digitsInSCTelephone, telephoneNumber) {
        //if pattern xxx* is present, then lengthOfX pattern must match the length of phoneNumber, 
        //else reinitialize the phoneNumber to original telephoneNumber
        if (steeringCodeRemoved && lengthOfX !== undefined && lengthOfX > 0) {
            if (digitsInSCTelephone && digitsInSCTelephone !== undefined 
                && digitsInSCTelephone.length > 0 && phoneNumber.startsWith(digitsInSCTelephone) 
                && phoneNumber.substring(digitsInSCTelephone.length).length !== lengthOfX) {
                    phoneNumber = telephoneNumber;
                    steeringCodeRemoved = false;
            } else if (digitsInSCTelephone && digitsInSCTelephone !== undefined 
                && digitsInSCTelephone.length > 0 && !phoneNumber.startsWith(digitsInSCTelephone)) {
                    phoneNumber = telephoneNumber;
                    steeringCodeRemoved = false;
            } else if ((digitsInSCTelephone === undefined || digitsInSCTelephone.length <=0) && phoneNumber.length !== lengthOfX) {
                phoneNumber = telephoneNumber;
                steeringCodeRemoved = false;
            }
        }
        return [steeringCodeRemoved, phoneNumber];
    }

    private downloadFileFromBucket(fileName:any){
        var promise = new Promise((resolve, reject) => {
            try {
                var storage = firebase.storage();
                var storageRef = storage.ref(fileName);
                storageRef.getDownloadURL().then((url) => {
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = 'json';
                    xhr.onload = (event) => {
                        if (xhr.status != 200) reject();
                        else resolve(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.send();
                    xhr.onabort = () => {
                        this.logger.error("Request to fetch document is aborted");
                        reject();
                    }
                    xhr.onerror = () => {
                        this.logger.error("Error while processing document request");
                        reject();
                    }
                })
                .catch((err) => { 
                    this.logger.debug('Error getting document', err)
                    reject();
                })
            } catch (err) { 
                this.logger.debug('Error, could not execute request', err);  
                reject();
            }
        })
        return promise;
    }

    private isLimitedExtn(acoPhoneModel: string) {
        var reg = new RegExp(".* "+LIMITED_EXTENSION+"$","i");
        return reg.test(acoPhoneModel);
    }
    
    private getStorageBucketFilesList(mainPath: any, subPath: any) {
        var promise = new Promise((resolve, reject) => {
            try {
                var storage = firebase.storage();
                var storageRef = storage.ref(mainPath);
                var listRef = storageRef.child(subPath);
                listRef.listAll()
                    .then((res) => {
                        let files = [];
                        res.items.forEach((itemRef) => {
                            files.push(itemRef.name)
                        });
                        resolve(files);
                    }).catch((error) => {
                        console.log("unexpected error:", error);
                        reject();
                    });
            } catch (err) {
                this.logger.debug('Error, could not execute request');
                reject();
            }
        });
        return promise;
    }
  
    public async setWarningData()
    {
        let basicInfoNotifications = [];
        let cqNotifications = [];
        this.basicInfoWarning = [];
        this.basicInfoError = [];
        let warningList, errorList,warningkey,errorKey;
        basicInfoNotifications = Array.from(this._notifications);
        basicInfoNotifications.forEach(element => {
            warningkey = [];
            errorKey = [];
            warningList = [];
            errorList = [];
            element[1].forEach(warning => {
                if(warning.severity === 0)
                warningList.push(warning.msg);
                if(warning.severity === 1)
                errorList.push(warning.msg);
            });
            warningkey.push(element[0],warningList);
            errorKey.push(element[0],errorList)
            this.basicInfoWarning.push(warningkey);
            this.basicInfoError.push(errorKey);
        });
     /*   var advInfoWarnings= this.advncdUserInfoWarningsList.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })  */    
        let warningReportsEntry = this.getWarningReportTemplete();
        warningReportsEntry.userData.basicInfo.errors =JSON.stringify(this.basicInfoError);
        warningReportsEntry.userData.basicInfo.warnings = JSON.stringify(this.basicInfoWarning);
        warningReportsEntry.userData.advancedInfo.warnings = JSON.stringify(Array.from(this.advncdUserInfoWarningsList));
        warningReportsEntry.systemData.aaWarning.warnings = JSON.stringify(Array.from(this.autoAttendantsWarningsList));
        warningReportsEntry.systemData.hgWarning.warnings = JSON.stringify(Array.from(this.huntGroupWarningsList));
        warningReportsEntry.systemData.pgWarning.warnings = JSON.stringify(Array.from(this.pagingGroupsWarningsList));
        warningReportsEntry.systemData.plWarning.warnings = JSON.stringify(this.parkLocationWarningsList);
        warningReportsEntry.systemData.tpWarning.warnings = JSON.stringify(Array.from(this.timeProfilesWarningsList));
        warningReportsEntry.systemData.icrWarning.warnings = JSON.stringify(Array.from(this.icrAADestinationNotFoundWarningList));
        await this.firestoreService.setNotificationData(this._cardId,null,warningReportsEntry);
    }    

}


