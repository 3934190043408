// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCJNcuwGuYI8H9-SEWD8DLq5rvY4IgEeX4",
    authDomain: "vignaraj-playground.firebaseapp.com",
    databaseURL: "https://vignaraj-playground.firebaseio.com",
    projectId: "vignaraj-playground",
    storageBucket: "vignaraj-playground.appspot.com",
    messagingSenderId: "442340770447",
    appId: "1:442340770447:web:277830b8f02f42bbf066fa",
    measurementId: "G-0WXZJ7D2Z0"
  },
  client_id : "442340770447-b9l7th4gsh3jl1d58tgvhv7ghianurbo.apps.googleusercontent.com" ,
  cookie_policy: 'single_host_origin',
  scope: 'profile email',
  well_known_endpoint: 'https://storage.googleapis.com/vignaraj-playground.appspot.com/.well-known/mt',
  bucket_name: 'vignaraj-playground.appspot.com',
  avaya_domain: 'avaya.com',
  app_version: '0.13.6.6'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
