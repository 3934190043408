import { Component, OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FirestoreService ,MIGRATION_STAGE , FEATURE_NAME , TASK_QUEUE_PRIORITY, CLEAR_ACO_TASK_QUEUE_PRIORITY, PROCESS} from 'src/app/shared/services/firestore/firestore.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { Subscription } from 'rxjs';
import { FireAuthService } from 'src/app/shared/services/firebaseAuth/fire-auth.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import * as firebase from 'firebase/app';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { saveAs } from 'file-saver';
import { PreConfigDownloadService } from '../../../shared/services/preConfigDownload/preConfigDownload.service';
import { ALIGN, DownloadNotificationService, FONT_CLASS, FONT_STYLES, getLineContent, getTextContent, lineStruct, setTextContPr, textStruct } from '../../../shared/services/downloadNotifications/downloadNotifications.service';
import { TablePreviewService} from './../../../shared/services/tablePreview/table-preview.service';
const tldjs = require('tldjs');
var RESULT = {
    NOTAVAILABLE: -1,
    SUCCESSFUL: 0,
    FAILURE: 1,
    ABORTED: 2,
    TASK_ABORTED: 3,
    NOTAPPLICABLE: 4,
    SKIPPED: 5
}

const TRIGGER_VALUE = {
    START : "start",
    RETRY : "retry"
}

const MIGRATION_SUMMARY_STRINGS = {
    INITIAL : "Please click 'Start Migration' to start.",
    INPROGRESS : "Migration in progress...",
    SUCCESSFUL : "Migration Successful.",
    FAILURE : "Migration Failed.",
    FAILURE_RETRY : "Migration Failed. Click 'Retry' to try again.",
    MORE_OPTIONS : "(Click Here for more details)",
    STATUS: "Status :",
    START_TIME: "Start Time :",
    END_TIME : "End Time :",
    DURATION : "Duration :",
    TOTAL_USERS : "Total Users :",
    SUCCESSFUL_USERS : "Successful Users :",
    FAILURE_USERS : "Failure Users :",
    FAILURE_USER_DATA_GENERIC : "Connection Error",
    FAILURE_USER_DATA_ACOERROR : "acoerror",
    COMPLETED_BY_USER : "Migration is completed manually.", 
    TASK_ABORTED: "Oops! Something went wrong. Retry or if error persist, please contact Administrator",
    ERRORS_FOUND_IN_TABLE: "Resolve errors to Start Migration",
    PRE_CONFIG_TOOLTIP: "Click to download Pre-config",
    PRE_CONFIG_TOOLTIP_ERROR: "User Data contents are missing",
    CALL_QUEUES_UNCHECKED_IVR_CHECKED: "Call Queues feature is not selected. Any Call queue destinations with IVR Menu keys will not be migrated.",
    IVR_UNCHECKED_COMPANY_RULES_CHECKED: "IVR Menu and Keys feature is not selected. Company number and Zero Dial destinations mapped with IVR Menu and Keys will not be migrated.",
    COMPANY_NUMBERS_CHECKED_IVR_UNCHECKED: "Company number destinations are dependent on IVR Menu, selecting this also enables the IVR Menu and Keys feature for migration.",
    ZERO_DIAL_CHECKED_IVR_UNCHECKED: "Zero Dial destinations are dependent on IVR Menu and Company Numbers, selecting this also enables the 'IVR Menu and Keys' and 'Company Numbers' features for migration.",
    ZERO_DIAL_CHECKED_COMPANYRULES_UNCHECKED : "Zero Dial destinations are dependent on Company Numbers, unchecking Company numbers will also uncheck ZeroDial",
}

const DELETION_SUMMARY_STRINGS = {
    INITIAL : "Please click 'Clear ACO Config' to start.",
    INPROGRESS : "Clearing ACO Configuration in progress...",
    SUCCESSFUL : "Clear ACO Configuration Successful.",
    FAILURE : "Clear ACO Configuration Failed",
    TASK_ABORTED: "Oops! Something went wrong. Retry or if error persist, please contact Administrator",
    START_TIME: "Start Time :",
    END_TIME : "End Time :",
    DURATION : "Duration :",
    FAILURE_USER_DATA_GENERIC : "Connection Error",
    FAILURE_USER_DATA_ACOERROR : "acoerror",
}

//Strings to display in UI
const FEATURES_LIST = {
    USERS: "Users",
    USER_BHOURS: "User Working Hours",
    USER_CONTACTS: "Contacts",
    USER_BLF: "Presence",
    CALL_FORWARD: "Twinning",
    HUNT_GROUP: "Call Queues",
    PAGING_GROUP: "Paging Only",
    PARK_LOCATION: "Park Locations",
    AUTO_ATTENDANT_PROMPTS: "IVR Prompts",
    AUTO_ATTENDANT: "IVR Menus and Keys",
    COMPANY_RULES:"Company Numbers",
    COMPLEX_IVR_PROMPTS:"Not Used Prompts",
    ZERO_DIAL:"ZeroDial",
    MAC_UPDATE: "MacUpdate",
    USER_VM_GREETINGS: "User VM Greetings",
    POST_MIGRATION: "PostMigration",
    CLEAR_COMPANY_RULES: "ClearCompanyRules",
    CLEAR_AUTO_ATTENDANT: "ClearAutoAttendant",
    CLEAR_AUTO_ATTENDANT_PROMPTS: "ClearAutoAttendantPrompts",
    CLEAR_COMPLEX_IVR_PROMPTS:"ClearComplexIvrPrompts",  
    CLEAR_HUNT_GROUP: "ClearHuntGroups",
    CLEAR_PAGING_GROUP: "ClearPagingGroups",
    MOVE_ASSIGNED_USERS_TO_MAINSITE:"moveAssignedUsertoMainSite",
    CLEAR_ASSIGNED_USERS: "ClearAssignedUsers",
    //POST_DELETION:"PostDeletion"
}


export enum phoneStatus{
    unassigned,
    assigned
}

/* export enum FeatureListEnum{
    users,
    usersContact,
    UsersBusinessHours,
    userBlf,
    userTwinning,
    huntGroup,
    pagingGroup,
    autoAttendant,
    autoAttendantPrompts,
    complexIvrPrompts,
    userVmGreetings,
    zeroDial
} */

@Component({
  selector: 'app-start-migration',
  templateUrl: './start-migration.component.html',
  styleUrls: ['./start-migration.component.css']
})
export class StartMigrationComponent implements OnInit, OnDestroy {
    
    subscription: Subscription;
    delSubscription: Subscription;
    private id:any;
    public isMigrationStarted = false;    
    public migrationStatus = -1;
    public operationProgress = 0; 
    public migrationProgress = 0;
    public deletionProgress = 0;   
    private readonly TIMER_INTERVAL=90000;
    private readonly MINUTE=60000;
    private authStateChange: Subscription;
    public status = RESULT;
    public migration_strings = MIGRATION_SUMMARY_STRINGS;
    public migrationInfo:string;
    public migrationSummaryData:any;    
    public showMigrationSummary = false;
    public showFailureData = false;
    public failureUserData:any = [];
    public isMarkAsComplete: false;
    public reportData:any = [];
    public basicInfoError:any = [];
    public basicInfoWarnings:any = [];
    public advInfoWarnings :any = [];
    public huntGroupWarnings:any = [];
    public pagingGroupWarnings:any = [];
    public parkLocationWarnings:any = [];
    public aaWarnings:any = [];
    public tpWarnings:any = [];
    public icrWarnings:any = [];
    public systemSummaryWarning:any = [];
    public isDeletionStarted = false;
    public deletionStatus = -1;
    public currRunOperation = PROCESS.MIGRATION;
    public deletion_strings = DELETION_SUMMARY_STRINGS;
    public deletionInfo:string;
    public deletionSummaryData:any;
    public showDeletionSummary = false;

    private statusCheckTimer:any = null;
    private isTaskInQueue:any;
    readonly MAX_CHECK_ITERATION = 3;
    private iterationCount: number;
    private isOnline = false;
    public companyName: string;
    public featureDocData = [];
    public migrationStatusDocData= null;
    public deletionFeatureDocData = [];
    public deletionStatusDocData = null; 

    public isMorrisonProject = false;
    private extensionMap = [];
    public errorsFound = false;
    public reservedExtensionsList = [];
    public starMigrationTooltipText = "";
    public startDeletionTooltipText = "";
    public preConfigToolTip = MIGRATION_SUMMARY_STRINGS.PRE_CONFIG_TOOLTIP;

    selectedFeatureList = [];
    public featureList = FEATURES_LIST;
    public showFeatureList = false;
    selectedClearConfigFeatureList = [];
    //taskChain is the data from csv
    public taskChain = [];
    public featureName = FEATURE_NAME;
    //finalTaskChainData is the data from feature selected list
    public finalTaskChainData = [];

    public clearACOTaskChain = [];

    //notAvailableInCSV is based on the data from CSV
    //this will be read only for this UI component and editable based on task chain obtained from table component
    public featureListValue = {
        USERS: {name: "Users",value:true, notAvailableInCSV:false},
        USER_BHOURS: {name: "UserContacts",value:false, notAvailableInCSV:true},
        USER_CONTACTS: {value:false, notAvailableInCSV:true},
        USER_BLF: {value:false, notAvailableInCSV:true},
        CALL_FORWARD: {value:false, notAvailableInCSV:true},
        HUNT_GROUP: {value:false, notAvailableInCSV:true},
        PAGING_GROUP: {value:false, notAvailableInCSV:true},
        PARK_LOCATION: {value:false, notAvailableInCSV:true},
        AUTO_ATTENDANT_PROMPTS: {value:false, notAvailableInCSV:true},
        AUTO_ATTENDANT: {value:false, notAvailableInCSV:true},
        COMPANY_RULES:{value:false, notAvailableInCSV:true},
        COMPLEX_IVR_PROMPTS:{value:false, notAvailableInCSV:true},
        ZERO_DIAL:{value:false, notAvailableInCSV:true},
        MAC_UPDATE:{value:false, notAvailableInCSV:true},
        USER_VM_GREETINGS: {value:false, notAvailableInCSV:true},
    }

  constructor(private router: Router , public zone: NgZone, 
    private migrationDataService:MigrationDataService,
    private firestoreService: FirestoreService, public _Activatedroute:ActivatedRoute,
    private overlay: OverlayService ,private fireauthService:FireAuthService,
    private cdRef: ChangeDetectorRef, private logger:LogService,
    private session:SessionHandlerService,private downloadNotificationService:DownloadNotificationService,
    private preConfigDownloadService:PreConfigDownloadService,private tablePreviewService:TablePreviewService)
  { 
    this.isTaskInQueue = false;
    this.iterationCount = 0;
    this.isOnline = false;
    this.id = this.session.cardId;  
    this.logger.debug("StartMigrationComponent constructor: ", this.id)
    this.companyName = this.session.companyName;
    if(this.id == null){
        this.logger.warn("CardId does not exist - route to dashboard");
        this.zone.run(() => { this.router.navigate(['/home/migration']); });
        return;
    }
    
    this.authStateChange = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
        this.authStateChange.unsubscribe();
        if(data !== null){
            this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
            this.getLastRunOperation()
            .then((opVal:string)=>{
                this.currRunOperation = opVal;                
                if(this.currRunOperation == PROCESS.DELETION){
                    this.preFetchClearACOConfigData();
                }else{
                    //need to call this to download notification and other data
                    this.preFetchAcoExportData(); 
                }                             
            });  
        }else{
        this.zone.run(() => { this.router.navigate(['/']); });
        } 
    });
    this.fireauthService.authStateChangeListener();
  }

    downloadFileFromBucket(fileName:any){
        var promise = new Promise((resolve, reject) => {
            try {
                var storage = firebase.storage();
                var storageRef = storage.ref(fileName);
                storageRef.getDownloadURL().then((url) => {
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = 'json';
                    xhr.onload = (event) => {
                        if (xhr.status != 200) reject();
                        else resolve(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.send();
                    xhr.onabort = () => {
                        this.logger.error("Request to fetch document is aborted");
                        reject();
                    }
                    xhr.onerror = () => {
                        this.logger.error("Error while processing document request");
                        reject();
                    }
                })
                .catch((err) => { 
                    this.logger.debug('Error getting document', err)
                    reject();
                })
            } catch (err) { 
                this.logger.debug('Error, could not execute request', err);  
                reject();
            }
        })
        return promise;
    }

    public validateEmailEntry(value){
        var result = false;
        if(value === ''){
            return true;
        }
        result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value);
        return result && tldjs.tldExists(value);
    }

    downloadExcel(){
        this.preConfigDownloadService.downloadExcel(this.companyName,this.id);
    }   

    preFetchClearACOConfigData(){
        this.logger.debug("prefetchClearACOConfigData:");
        this.deletionInfo = DELETION_SUMMARY_STRINGS.INITIAL;//this will never be displayed
        return this.firestoreService.readTrigger(this.id,PROCESS.DELETION)
        .then((result:any)=>{
            this.overlay.closeSpinner();
            if(result.trigger !== 'notyet'){
                this.logger.debug("Deletion is started already");
                this.isDeletionStarted = true;
                this.deletionInfo = DELETION_SUMMARY_STRINGS.INPROGRESS; 
                this.getCardDetails();              
            }else{
                this.firestoreService.getUserCardDetails(this.id)
                .then((result:any) => {
                    if(result.type !== undefined && result.type === 'morrison'){
                        this.isMorrisonProject = true;
                    }
                }).catch(()=>{})
            }
           // this.overlay.closeSpinner();            
        })
        .catch((error) => {
            this.overlay.closeSpinner();
            this.logger.error(error);
        });
    }
    
    preFetchAcoExportData(){
        this.logger.debug("preFetchAcoExportData");
        //this.firestoreService.updateStage(this.id, MIGRATION_STAGE.DATAMIGRATION).catch();
        this.migrationInfo = MIGRATION_SUMMARY_STRINGS.INITIAL;
        this.firestoreService.getNotificationData(this.id)
        .then((doc:any)=>{
            let reportData = doc.configSummaryNotifications;
            let basicInfoError = JSON.parse(reportData.userData.basicInfo.errors);
            basicInfoError.forEach(element => {
                if(element[1].length !== 0){
                    this.errorsFound = true;
                    this.starMigrationTooltipText = MIGRATION_SUMMARY_STRINGS.ERRORS_FOUND_IN_TABLE;
                } 
            });
            return this.firestoreService.readTrigger(this.id,PROCESS.MIGRATION);
        }).then((result:any) => {
            if(result.trigger !== 'notyet'){
                this.overlay.closeSpinner();
                this.logger.debug("Migration completed already");
                this.isMigrationStarted = true;
                this.migrationInfo = MIGRATION_SUMMARY_STRINGS.INPROGRESS;
                this.getCardDetails(); 
            }else{
                this.firestoreService.getUserCardDetails(this.id)
                .then((result:any) => {
                    if(result.type !== undefined && result.type === 'morrison'){
                        this.isMorrisonProject = true;
                    }
                    return this.firestoreService.getTaskChain(this.id , true, PROCESS.MIGRATION)
                }).then((result:any) => {
                    //this.taskChain = JSON.parse(result.data);
                    this.finalTaskChainData= JSON.parse(result.data);
                    return this.firestoreService.getTaskChain(this.id , false, PROCESS.MIGRATION)               
                }).then(async (taskChainData:any) =>{
                    this.taskChain= JSON.parse(taskChainData.data);
                    if( !Array.isArray(this.finalTaskChainData) || this.finalTaskChainData.length === 0){
                        //finaltask is empty so consider original taskchain from csv
                        //this.taskChain= JSON.parse(taskChainData.data);
                        this.finalTaskChainData = JSON.parse(JSON.stringify(this.taskChain));
                        await this.assignFeatureCheckBoxProperties(this.taskChain); 
                    }else{
                        //this.taskChain = this.finalTaskChainData;
                        //enable and check all the feature list which was selected previously
                        await this.assignFeatureCheckBoxProperties(this.finalTaskChainData);
                        //remaining features from csv should be enabled and unchecked so that user can select again
                        var uniqueTaskChainList = JSON.parse(taskChainData.data).filter((val) => {
                            return this.finalTaskChainData.indexOf(val) === -1;
                        });
                        await this.uncheckAndEnableTaskChainFromCSV(uniqueTaskChainList);
                    }
                    //ACOS-1509 if migration is triggered before taskchain is filtered, 
                    //migration is getting stuck at 2% since taskchain data is empty
                    this.overlay.closeSpinner();
                }).catch(()=>{
                    this.overlay.closeSpinner();
                })
            }
        }).catch((error) => {
            this.overlay.closeSpinner();
            this.logger.error(error);
        });
    }

    resetFeatureSelectUI(){
        this.featureListValue.USER_CONTACTS.value = false;
        this.featureListValue.USER_BHOURS.value = false;
        this.featureListValue.USER_BLF.value = false;
        this.featureListValue.USER_VM_GREETINGS.value = false;
        this.featureListValue.CALL_FORWARD.value = false;
        this.featureListValue.HUNT_GROUP.value = false;
        this.featureListValue.PAGING_GROUP.value = false;
        this.featureListValue.PARK_LOCATION.value = false;
        this.featureListValue.AUTO_ATTENDANT_PROMPTS.value = false;
        this.featureListValue.AUTO_ATTENDANT.value = false;
        this.featureListValue.COMPANY_RULES.value = false;
        this.featureListValue.COMPLEX_IVR_PROMPTS.value = false;
        this.featureListValue.ZERO_DIAL.value = false;
        this.featureListValue.MAC_UPDATE.value = false;
    }

    //features which are available in csv and not selected previous should be shown to users 
    //users can check or uncheck this feature and will not be disabled
    async uncheckAndEnableTaskChainFromCSV(taskChainFromCSV){
        this.logger.debug("uncheckAndEnableTaskChainFromCSV taskchain:",taskChainFromCSV);
        taskChainFromCSV.forEach((featureName)=> {
            switch (featureName) {
                case FEATURE_NAME.USER_CONTACTS:
                    this.featureListValue.USER_CONTACTS.value = false;
                    this.featureListValue.USER_CONTACTS.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.USER_BHOURS:
                    this.featureListValue.USER_BHOURS.value = false;
                    this.featureListValue.USER_BHOURS.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.USER_BLF:
                    this.featureListValue.USER_BLF.value = false;
                    this.featureListValue.USER_BLF.notAvailableInCSV = false;
                break;
                //for UserVmGreetings
                case FEATURE_NAME.USER_VM_AFTERHOURS:
                case FEATURE_NAME.USER_VM_WORKHOURS: 
                case FEATURE_NAME.USER_VM_CUSTOMHOURS:  
                    this.featureListValue.USER_VM_GREETINGS.value = false;
                    this.featureListValue.USER_VM_GREETINGS.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.CALL_FORWARD:
                    this.featureListValue.CALL_FORWARD.value = false;
                    this.featureListValue.CALL_FORWARD.notAvailableInCSV = false;                          
                break;
                case FEATURE_NAME.HUNT_GROUP:
                    this.featureListValue.HUNT_GROUP.value = false;
                    this.featureListValue.HUNT_GROUP.notAvailableInCSV = false;                          
                break;
                case FEATURE_NAME.PAGING_GROUP:
                    this.featureListValue.PAGING_GROUP.value = false;
                    this.featureListValue.PAGING_GROUP.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.PARK_LOCATION:
                    this.featureListValue.PARK_LOCATION.value = false;
                    this.featureListValue.PARK_LOCATION.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.AUTO_ATTENDANT_PROMPTS:
                    this.featureListValue.AUTO_ATTENDANT_PROMPTS.value = false;
                    this.featureListValue.AUTO_ATTENDANT_PROMPTS.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.AUTO_ATTENDANT:
                    this.featureListValue.AUTO_ATTENDANT.value = false;
                    this.featureListValue.AUTO_ATTENDANT.notAvailableInCSV = false;
                    break;
                case FEATURE_NAME.COMPANY_RULES:
                    this.featureListValue.COMPANY_RULES.value = false;
                    this.featureListValue.COMPANY_RULES.notAvailableInCSV = false;
                    break;
                case FEATURE_NAME.COMPLEX_IVR_PROMPTS:
                    this.featureListValue.COMPLEX_IVR_PROMPTS.value = false;
                    this.featureListValue.COMPLEX_IVR_PROMPTS.notAvailableInCSV = false;                          
                break;               
                case FEATURE_NAME.ZERO_DIAL:
                    this.featureListValue.ZERO_DIAL.value = false;
                    this.featureListValue.ZERO_DIAL.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.MAC_UPDATE:
                    this.featureListValue.MAC_UPDATE.value = false;
                    this.featureListValue.MAC_UPDATE.notAvailableInCSV = false;
                break;
                default:
                this.logger.debug("feature not found in csv taskchain list for cardId", this.id);
            }
        })
        return;
    }

    async assignFeatureCheckBoxProperties(taskChain){
        this.logger.debug("assignFeatureCheckBoxProperties");
        //deep cloning taskchain data so that original data is not tampered 
        //any modification done in UI checkbox will be captured in selectedFeatureList
        this.selectedFeatureList = JSON.parse(JSON.stringify(taskChain));
        taskChain.forEach((featureName)=> {
            switch (featureName) {
                case FEATURE_NAME.USER_CONTACTS:
                    this.featureListValue.USER_CONTACTS.value = true;
                    this.featureListValue.USER_CONTACTS.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.USER_BHOURS:
                    this.featureListValue.USER_BHOURS.value = true;
                    this.featureListValue.USER_BHOURS.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.USER_BLF:
                    this.featureListValue.USER_BLF.value = true;
                    this.featureListValue.USER_BLF.notAvailableInCSV = false;
                break;
                //for UserVmGreetings
                case FEATURE_NAME.USER_VM_AFTERHOURS:
                case FEATURE_NAME.USER_VM_WORKHOURS: 
                case FEATURE_NAME.USER_VM_CUSTOMHOURS: 
                    this.featureListValue.USER_VM_GREETINGS.value = true;
                    this.featureListValue.USER_VM_GREETINGS.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.CALL_FORWARD:
                    this.featureListValue.CALL_FORWARD.value = true;
                    this.featureListValue.CALL_FORWARD.notAvailableInCSV = false;                          
                break;
                case FEATURE_NAME.HUNT_GROUP:
                    this.featureListValue.HUNT_GROUP.value = true;
                    this.featureListValue.HUNT_GROUP.notAvailableInCSV = false;                          
                break;
                case FEATURE_NAME.PAGING_GROUP:
                    this.featureListValue.PAGING_GROUP.value = true;
                    this.featureListValue.PAGING_GROUP.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.PARK_LOCATION:
                    this.featureListValue.PARK_LOCATION.value = true;
                    this.featureListValue.PARK_LOCATION.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.AUTO_ATTENDANT_PROMPTS:
                    this.featureListValue.AUTO_ATTENDANT_PROMPTS.value = true;
                    this.featureListValue.AUTO_ATTENDANT_PROMPTS.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.AUTO_ATTENDANT:
                    this.featureListValue.AUTO_ATTENDANT.value = true;
                    this.featureListValue.AUTO_ATTENDANT.notAvailableInCSV = false;
                    break;
                case FEATURE_NAME.COMPLEX_IVR_PROMPTS:
                    this.featureListValue.COMPLEX_IVR_PROMPTS.value = true;
                    this.featureListValue.COMPLEX_IVR_PROMPTS.notAvailableInCSV = false;                          
                break;
                case FEATURE_NAME.ZERO_DIAL:
                    this.featureListValue.ZERO_DIAL.value = true;
                    this.featureListValue.ZERO_DIAL.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.MAC_UPDATE:
                    this.featureListValue.MAC_UPDATE.value = true;
                    this.featureListValue.MAC_UPDATE.notAvailableInCSV = false;
                break;
                case FEATURE_NAME.COMPANY_RULES:
                    this.featureListValue.COMPANY_RULES.value = true;
                    this.featureListValue.COMPANY_RULES.notAvailableInCSV = false;
                    break;
                default:
                this.logger.debug("feature not found in list for cardId", this.id);
            }
        })
        return;
    }

  getCardDetails(){
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.firestoreService.getUserCardDetails(this.id)
    .then((result:any) => {
        this.logger.debug("getUserCardDetails successful:",this.id);
        this.isMarkAsComplete = result.markAsCompleted; 
        if(result.type !== undefined && result.type === 'morrison'){
            this.isMorrisonProject = true;
        }
        //migration progress is updated as 100% since it is complete by user manually by "markAsComplete"
        if(this.isMarkAsComplete){
            this.migrationProgress = 100;
        }
        this.subscribeMigrationStatus();
        this.firestoreService.checkACOMigrationStatus(this.id);
        if(this.currRunOperation === PROCESS.DELETION){
            this.subscribeDeletionStatus();
            this.firestoreService.checkACODeletionStatus(this.id);
        } 
        return;
    })
    .then( () => {
        this.checkNetworkConn$().subscribe(isOnline => { 
            this.isOnline = isOnline;
            this.logger.debug("Network Connected: ", isOnline);
            if(this.isOnline){
                this.isTaskInQueue = false;
                if(this.currRunOperation === PROCESS.DELETION)
                    this.doDeletionStatusCheck();
                else
                    this.doMigrationStatusCheck();
            }
        });
    })
    .catch((error) => {
        this.overlay.closeSpinner();
        this.logger.error(error);
    });
  }

  ngOnInit(): void {
  }

  navigateBack(){
    if(!this.isMigrationStarted){
        if(!this.isMorrisonProject){
            this.zone.run(() => { this.router.navigate(['/home/migration/config-summary/upload-greetings-files']); });
        }else{
            this.zone.run(() => { this.router.navigate(['/home/migration/morrison-summary']); });
        }
    }else{
        this.logger.error("Back Navigation Failed. Migration has started")
    }     
  }
  
  clearStatusCheckTimer(){
      if(this.statusCheckTimer != null){
        clearTimeout(this.statusCheckTimer);
        this.statusCheckTimer = null;
      }
  }

  checkNetworkConn$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

    closeMigrationActivity(){
        this.session.alertModal(this.onClose.bind(this));
    }

    onClose() {
        this.logger.debug("Start Migration - close current Migration Activity");
        this.migrationDataService.clearData();
        this.zone.run(() => { this.router.navigate(['/home/migration']);});
    }

    ifDisplayDelInfo(){
        return this.currRunOperation === PROCESS.DELETION;
        //return (this.migrationStatus === RESULT.FAILURE || this.migrationStatus === RESULT.SUCCESSFUL);
    }
    startDeletion(triggerValue){
        this.logger.debug("startDeletion with trigger: %s for card : %s:" , triggerValue ,this.id);
        //TODO : stage of card could be updated with deletion if there is a requirement
        if(!this.isDeletionStarted){
            this.isDeletionStarted = !this.isDeletionStarted;
            this.deletionInfo = DELETION_SUMMARY_STRINGS.INPROGRESS;
            this.logger.debug("Start Deletion for :",this.id);
            this.showDeletionSummary = false;
            this.isTaskInQueue = false;
            this.iterationCount = 0;
            this.deletionProgress = 0;
            if(triggerValue === TRIGGER_VALUE.START){
                this.currRunOperation = PROCESS.DELETION;
                this.buildClearACOTaskChain()
                .then(()=>{
                    this.prioritizeClearACOTaskQueue();
                    return  this.firestoreService.setTaskChain(this.id, this.selectedClearConfigFeatureList, false,PROCESS.DELETION);
                })                
                .then(() =>{
                    this.setTriggerForClearACOConfig(triggerValue);
                    this.setLastRunOperationForACOExport(PROCESS.DELETION);
                    this.statusCheckTimer = setTimeout(() => { 
                        this.doDeletionStatusCheck();
                    }, this.MINUTE);
                    this.cdRef.detectChanges();
                }).catch((error) => {
                    this.logger.error(error);
                });
            }
        }
    }

    startMigration(triggerValue){
        this.logger.debug("startMigration with trigger: %s for card : %s:" , triggerValue ,this.id);
        this.firestoreService.updateStage(this.id, MIGRATION_STAGE.COMPLETE).catch();
        if(!this.isMigrationStarted){ 
            this.migrationInfo = MIGRATION_SUMMARY_STRINGS.INPROGRESS;    
            this.isMigrationStarted = !this.isMigrationStarted;
            this.logger.debug("Start Migration for :" , this.id);
            this.showMigrationSummary = false;
            this.isTaskInQueue = false;
            this.iterationCount = 0;
            this.deletionStatus = -1;
            if(triggerValue === TRIGGER_VALUE.START){
                var skippedFeatureList = this.taskChain.filter((val) => {
                    return this.selectedFeatureList.indexOf(val) === -1;
                });
                this.logger.debug("skipped feature list: ",skippedFeatureList)
                this.firestoreService.updateSkippedFeatureStatus(skippedFeatureList , this.id , this.status.SKIPPED)
                .then(() =>{
                    return this.firestoreService.setTaskChain(this.id, this.selectedFeatureList, false,PROCESS.MIGRATION);
                }).then(() =>{
                    this.setTriggerForACOExport(triggerValue);
                    this.setLastRunOperationForACOExport(PROCESS.MIGRATION);
                    this.statusCheckTimer = setTimeout(() => { 
                        this.doMigrationStatusCheck();
                    }, this.MINUTE);
                }).catch((error) => {
                    this.logger.error(error);
                });
            }else if(triggerValue === TRIGGER_VALUE.RETRY){
                this.firestoreService.resetACOExportStatus(this.id)
                .then(() => {
                    this.logger.debug("ACO Export status is updated for retry :" , this.id );
                    this.migrationProgress = 0;
                    this.setTriggerForACOExport(triggerValue);
                    this.setLastRunOperationForACOExport(PROCESS.MIGRATION);
                    this.clearStatusCheckTimer();
                    this.statusCheckTimer = setTimeout(() => { 
                        this.doMigrationStatusCheck();
                    }, this.MINUTE);
                })
                .catch((error) => {
                    this.logger.error(error);
                });
            }
        }
    }


    setTriggerForACOExport(triggerValue){
        this.logger.info("setTriggerForACOExport :",this.id);
        this.firestoreService.setACOTrigger(this.id , triggerValue,PROCESS.MIGRATION)
        .then(() => {
            this.logger.debug("Migration for card ID %s triggered Succefully" , this.id );
            this.subscribeMigrationStatus();
            this.firestoreService.checkACOMigrationStatus(this.id);
        })
        .catch((error) => {
            this.logger.error(error);
        });
    }

    setTriggerForClearACOConfig(triggerValue){
        this.logger.info("setTriggerForClearACOConfig :",this.id);
        this.firestoreService.setACOTrigger(this.id , triggerValue,PROCESS.DELETION)
        .then(() => {
            this.logger.debug("Deletion for card ID %s triggered Succefully" , this.id );
            this.subscribeDeletionStatus();
            this.firestoreService.checkACODeletionStatus(this.id);
        })
        .catch((error) => {
            this.logger.error(error);
        });
    }

    setLastRunOperationForACOExport(value){
        this.logger.info("setLastRunOperationForACOExport :",this.id);
        this.firestoreService.setACOExportLastRunOperation(this.id , value)
        .then(() => {
            this.logger.debug("Last run operationfor card ID %s set succefully" , this.id );
        })
        .catch((error) => {
            this.logger.error(error);
        });
    }

    getLastRunOperation(){
        this.logger.info("getLastRunOperation :", this.id);
        let promise = new Promise((resolve,reject)=>{
            this.firestoreService.getACOExportLastRunOperation(this.id)
            .then((lastRunOperation:any)=>{
                if(lastRunOperation && lastRunOperation !== "")
                    resolve(lastRunOperation);
                //default operation is assumed to be migration
                resolve(PROCESS.MIGRATION);
            })
            .catch((error)=>{
                this.logger.error("Error in getting the last run operation.");
                resolve(PROCESS.MIGRATION);
            })
        })
        return promise;        
    }
    

    subscribeMigrationStatus(){
        this.subscription = this.firestoreService.getMessage().subscribe((result) => {
            if(result.progress > this.migrationProgress ){
                this.migrationProgress = result.progress;
                this.logger.debug("migration progress :", this.migrationProgress);
            }
            this.migrationStatus = result.status;
            this.logger.debug("Migration status update -  progress: ", result.progress, " status: ", this.migrationStatus);
            if(this.migrationStatus === RESULT.SUCCESSFUL){
                this.logger.debug("MIGRATION SUCCESSFUL");
                this.subscription.unsubscribe();
                this.modifyMigrationStatusDocData(result);
                if(!this.isMorrisonProject){
                    this.getSummary();
                }else{
                    this.showMigrationSummary =true;
                }
            }
            else if(this.migrationStatus !== RESULT.NOTAVAILABLE && this.migrationStatus !== RESULT.SUCCESSFUL){
                this.logger.debug("Migration failed");
                this.isMigrationStarted = false;
                this.subscription.unsubscribe();
                this.modifyMigrationStatusDocData(result);
                if(!this.isMorrisonProject){
                    this.getSummary();
                }else{
                    this.showMigrationSummary =true;
                }
            }
            else{
                this.logger.debug("Migration result not available yet");
            }
            this.cdRef.detectChanges();
            this.overlay.closeSpinner();
        });
    }
     subscribeDeletionStatus(){
        this.delSubscription = this.firestoreService.getDeletionProgMessage().subscribe((result) => {
            if(result.progress > this.deletionProgress ){
                this.deletionProgress = result.progress;
                this.logger.debug("deletion progress :", this.deletionProgress);
            }
            this.deletionStatus = result.status;
            this.logger.debug("Deletion status update -  progress: ", result.progress, " status: ", this.deletionStatus);
            if(this.deletionStatus === RESULT.SUCCESSFUL){
                this.logger.debug("DELETION SUCCESSFUL");
               // this.isDeletionStarted = false;
                this.delSubscription.unsubscribe();
                this.modifyDeletionStatusDocData(result);
                if(!this.isMorrisonProject){
                    this.getDeletionSummary();
                }else{
                    this.showDeletionSummary = true;
                }
            }
            else if(this.deletionStatus !== RESULT.NOTAVAILABLE && this.deletionStatus !== RESULT.SUCCESSFUL){
                this.logger.debug("Deletion failed");
                this.isDeletionStarted = false;
                this.delSubscription.unsubscribe();
                this.modifyDeletionStatusDocData(result);
                if(!this.isMorrisonProject){
                    this.getDeletionSummary();
                }else{
                    this.showDeletionSummary =true;
                }
            }
            else{
                this.logger.debug("Deletion result not available yet");
            }
            this.cdRef.detectChanges();
            this.overlay.closeSpinner();
        });
    }

    modifyMigrationStatusDocData(data){
        if (data) {
            let tempData = data;
            let startTime = moment(tempData.startTime.toDate())
            let endTime = moment(tempData.endTime.toDate())
            let Migrationduration = moment.duration(endTime.diff(startTime));
            tempData['duration'] = Math.round(Migrationduration.asMinutes()) === 0 ? 1 : Math.round(Migrationduration.asMinutes());
            tempData.startTime = tempData.startTime.toDate();
            tempData.endTime = tempData.endTime.toDate();
            this.migrationStatusDocData = tempData;
        }
    }

    modifyDeletionStatusDocData(data){
        if (data) {
            let tempData = data;
            let startTime = moment(tempData.startTime.toDate())
            let endTime = moment(tempData.endTime.toDate())
            let deletionDuration = moment.duration(endTime.diff(startTime));
            tempData['duration'] = Math.round(deletionDuration.asMinutes()) === 0 ? 1 : Math.round(deletionDuration.asMinutes());
            tempData.startTime = tempData.startTime.toDate();
            tempData.endTime = tempData.endTime.toDate();
            this.deletionStatusDocData = tempData;
        }
    }

    getSummary(){
        this.logger.debug("getSummary :",this.id);
        //this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.firestoreService.getExtensionMap(this.id)
        .then((extensionDoc:any)=>{
            this.extensionMap = JSON.parse(extensionDoc.data);
            return this.firestoreService.getCardSummary(this.id)
        }).then((result:any) => {
            this.showMigrationSummary =true;
            let summaryDoc = [];
            this.migrationSummaryData = [];
            result.forEach(doc => {
                if(doc.id === "summary"){
                    summaryDoc = doc;
                }
            });
            this.buildSummaryData(summaryDoc , result );
        }).catch((error) => {
            this.overlay.closeSpinner();
            this.logger.error("fetching card's migration summary failed :",this.id);
        })
    }

    getDeletionSummary(){
        this.logger.debug("getDeletionSummary :",this.id);
        this.firestoreService.getExtensionMap(this.id)
        .then((extensionDoc:any)=>{
            this.extensionMap = JSON.parse(extensionDoc.data);
            this.showDeletionSummary = true;        
            this.buildClearACOConfigSummaryData();
            
        })
    }

    /* migration status check flow 

    */
    doMigrationStatusCheck(){
        this.logger.debug("doMigrationStatusCheck");
        this.clearStatusCheckTimer();
        this.statusCheckTimer = setTimeout(() => { 
            this.handleStatusCheck();
        }, this.TIMER_INTERVAL);
    }

    doDeletionStatusCheck(){
        this.logger.debug("doDeletionStatusCheck");
        //use the same timer for both migration and deletion
        this.clearStatusCheckTimer();
        this.statusCheckTimer = setTimeout(() => { 
            this.handleDeletionStatusCheck();
        }, this.TIMER_INTERVAL);
    }

    buildClearACOConfigSummaryData(){
        this.logger.debug("buildClearACOConfigSummaryData :",this.id);            
        this.getDeletionReportData();
        this.cdRef.detectChanges();
        this.overlay.closeSpinner();
        
    }

    buildSummaryData(summaryDoc , resultDocs){
        this.logger.debug("buildSummaryData :",this.id);
        this.migrationSummaryData = summaryDoc;
        resultDocs.forEach(doc => {
            if(doc.id === this.migrationSummaryData.lastRunDocId){
                this.migrationSummaryData['failure'] = doc.failure;
                this.migrationSummaryData['failureData'] = doc.failureData;
            }
        });
        if(this.migrationStatus !== RESULT.NOTAVAILABLE && this.migrationStatus !== RESULT.SUCCESSFUL
            && this.migrationStatus !== RESULT.TASK_ABORTED){
            this.buildFailureUserData();
        }

        this.migrationSummaryData['startTime'] = this.migrationSummaryData.started.toDate();
        if(typeof this.migrationSummaryData.retryStarted !== 'undefined' &&
            this.migrationSummaryData.retryStarted !== ''){
            this.migrationSummaryData['startTime'] = this.migrationSummaryData.retryStarted.toDate();
        }

        this.migrationSummaryData['endTime'] = this.migrationSummaryData.finished.toDate();
        if(typeof this.migrationSummaryData.retryFinished !== 'undefined' &&
            this.migrationSummaryData.retryFinished !== ''){
            this.migrationSummaryData['endTime'] = this.migrationSummaryData.retryFinished.toDate();
        }

        let startTime = moment(this.migrationSummaryData.startTime)
        let endTime = moment(this.migrationSummaryData.endTime)
        let Migrationduration = moment.duration(endTime.diff(startTime));
        this.migrationSummaryData['duration'] = Math.round(Migrationduration.asMinutes());
        this.getMigrationReportData();
        this.cdRef.detectChanges();
        this.overlay.closeSpinner();
        
    }

    buildFailureUserData(){
        if(this.migrationSummaryData.failureData !== undefined){
            this.logger.debug("buildFailureUserData :: failureData :",this.migrationSummaryData.failureData);
            this.migrationSummaryData.failureData = JSON.parse(this.migrationSummaryData.failureData);
            if(!Array.isArray(this.migrationSummaryData.failureData) || this.migrationSummaryData.failureData.length === 0){
                this.logger.debug("buildFailureUserData :: empty failure data");
                return;
            }
           
            this.failureUserData = [];
            this.migrationSummaryData.failureData.forEach(userError => {
                if(userError.error !== undefined && userError.error.name === MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_ACOERROR 
                    && (userError.error.data.httpStatusCode >= 400) 
                    && (userError.error.data.httpStatusCode != 429)
                    && (userError.error.data.httpStatusCode < 500)){

                    let siteName = "";
                    let matchedFound = this.extensionMap.find(x=>x.extn === parseInt(userError.extn));
                    if(matchedFound){
                        siteName = (matchedFound.siteName && matchedFound.siteName !== "" && matchedFound.siteName.length > 0) ? matchedFound.siteName : "MainSite";
                    }
                    //put specific error message from error.data.message
                    this.failureUserData.push({
                        'extn': userError.extn,
                        'site': siteName, 
                        "message":userError.error.data.message
                    })
                }else if(userError.errors !== undefined ){
                    let extn = "";
                    let siteName = "";
                    let matchedFound = this.extensionMap.find(x=>x.extId === parseInt(userError.extensionId));
                    if(matchedFound){
                        extn = matchedFound.extn;
                        siteName = (matchedFound.siteName && matchedFound.siteName !== "" && matchedFound.siteName.length > 0) ? matchedFound.siteName : "MainSite";
                    }
                    this.failureUserData.push({
                        'extn': extn,
                        'site': siteName,
                        "message":userError.errors[0].message
                    })
                }else{
                    let siteName = "";
                    let matchedFound = this.extensionMap.find(x=>x.extn === parseInt(userError.extn));
                    if(matchedFound){
                        siteName = (matchedFound.siteName && matchedFound.siteName !== "" && matchedFound.siteName.length > 0) ? matchedFound.siteName : "MainSite";
                    }
                    //put generic error - Connection Error
                    this.failureUserData.push({
                        'extn': userError.extn,
                        'site': siteName,
                        "message":MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC
                    })
                }
            });
        }
        this.overlay.closeSpinner();
    }

    ngOnDestroy() {
        this.logger.debug("ngOnDestroy");
        if(this.subscription !== undefined && this.subscription !== null){
            this.subscription.unsubscribe();
        }
        if(this.delSubscription !== undefined && this.delSubscription !== null){
            this.delSubscription.unsubscribe();
        }
        this.clearStatusCheckTimer();
    }

    retryMigration(){
        this.logger.debug("retryMigration")
        this.startMigration("retry");
    }


    displayBackArrow(){
        let flag = !this.isMigrationStarted && !this.isMarkAsComplete && !this.ifDisplayDelInfo();
        return flag;
    }

    displayLoginPortalBtn(){
        let flag = this.migrationStatus === RESULT.SUCCESSFUL || this.deletionStatus === RESULT.FAILURE || this.deletionStatus === RESULT.SUCCESSFUL;
        return flag;
    }

    //same as displayRetryBtn, but added a separate func to just change the logic whenever required
    displayMarkAsCompleteBtn(){
        let flag = this.migrationStatus !== RESULT.NOTAVAILABLE && this.migrationStatus !== RESULT.SUCCESSFUL && !this.isMarkAsComplete && !this.ifDisplayDelInfo();
        return flag;
    }

    displayRetryBtn(){
        let flag = this.migrationStatus !== RESULT.NOTAVAILABLE && this.migrationStatus !== RESULT.SUCCESSFUL && !this.isMarkAsComplete && !this.ifDisplayDelInfo();
        return flag;
    }

    displaySelect(){
        let flag = !this.isMigrationStarted && this.migrationStatus == RESULT.NOTAVAILABLE && !this.isMorrisonProject && !this.ifDisplayDelInfo();
        return flag;
    }

    displayStartBtn(){
        return this.migrationStatus ===  RESULT.NOTAVAILABLE && !this.ifDisplayDelInfo();
    }

    displayDeleteBtn(){
        return (this.migrationStatus === RESULT.SUCCESSFUL || this.migrationStatus === RESULT.FAILURE) && this.deletionStatus === RESULT.NOTAVAILABLE;
    }

    close(){
        this.logger.debug("close")
        this.closeMigrationActivity();
    }

    closeErrorWarningBlock(){
        this.logger.info("closeErrorWarningBlock");
        this.showFailureData = false;
        this.cdRef.detectChanges();
    }

    viewFailureUserData(){
        this.logger.info("viewFailureUserData");
        if(this.failureUserData.length !== 0){
            this.showFailureData = true;
            this.cdRef.detectChanges();
        }else{
            this.logger.warn("Migration user failure data is empty")
        }
    }

    handleDeletionStatusCheck(toUpdate = false) {
        this.logger.debug("handleDeletionStatusCheck");
        if(this.isOnline && this.isDeletionStarted && 
            this.deletionStatus === RESULT.NOTAVAILABLE && !this.isTaskInQueue){

            if(toUpdate){
                this.logger.warn("Deletion task elapsed estimated duration, marking as failed");
            }
            
            firebase.auth().currentUser.getIdToken(true)
            .then((idToken) => {
                let passArgs = {
                    cardId: this.id,   
                    updateStatus: toUpdate, 
                    processName: PROCESS.DELETION,        
                    idToken
                };

                //Same function is called to check if there is an active task
                var statusCheck = firebase.functions().httpsCallable('MigrationStatusCheck');
                statusCheck(passArgs).then((res: any) => {
                    // Read result of the Cloud Function.
                    this.logger.debug('Received result -' + JSON.stringify(res.data));          
                    this.isTaskInQueue = res.data.activeTask;
                    this.iterationCount++;
                    if(this.iterationCount == this.MAX_CHECK_ITERATION){
                        this.logger.warn("Max iteration check reached, update as failed");
                        this.clearStatusCheckTimer();
                        this.statusCheckTimer = setTimeout(() => { 
                            this.handleDeletionStatusCheck(true);
                        }, this.TIMER_INTERVAL);
                    }
                    else if(!toUpdate){
                            this.logger.debug("Status check iteration: ", this.iterationCount);
                            this.clearStatusCheckTimer();
                            this.statusCheckTimer = setTimeout(() => { 
                                this.handleDeletionStatusCheck();
                            }, this.TIMER_INTERVAL);
                    }
                    else{
                        this.logger.debug("Deletion status marked as failed by audit"); 
                    }
                })
                .catch((error) => {
                    var message = error.message;
                    this.logger.error("deletionStatusCheck failed: ", message);
                });
            })
            .catch((error) => {
                this.logger.error("handleDeletionStatusCheck failed: ", error);
            });
        }
        else if(this.isOnline && this.isDeletionStarted && 
            this.deletionStatus === RESULT.NOTAVAILABLE && this.isTaskInQueue){
            this.logger.debug("Task in queue already, reset status check"); 
            this.iterationCount = 0;
            this.clearStatusCheckTimer();
            this.isTaskInQueue = false;
            this.statusCheckTimer = setTimeout(() => { 
                this.handleDeletionStatusCheck();
            }, this.TIMER_INTERVAL);
        }
        else{
            this.iterationCount = 0;
            this.isTaskInQueue = false;
            this.clearStatusCheckTimer();
            this.logger.debug("Deletion status check complete already"); 
        }
    }

    handleStatusCheck(toUpdate = false) {
        this.logger.debug("handleStatusCheck");
        if(this.isOnline && this.isMigrationStarted && 
            this.migrationStatus === RESULT.NOTAVAILABLE && !this.isTaskInQueue){

            if(toUpdate){
                this.logger.warn("Migration task elapsed estimated duration, marking as failed");
            }
            
            firebase.auth().currentUser.getIdToken(true)
            .then((idToken) => {
                let passArgs = {
                    cardId: this.id,   
                    updateStatus: toUpdate,         
                    idToken
                };

                var statusCheck = firebase.functions().httpsCallable('migrationStatusCheck');
                statusCheck(passArgs).then((res: any) => {
                    // Read result of the Cloud Function.
                    this.logger.debug('Received result -' + JSON.stringify(res.data));          
                    this.isTaskInQueue = res.data.activeTask;
                    this.iterationCount++;
                    if(this.iterationCount == this.MAX_CHECK_ITERATION){
                        this.logger.warn("Max iteration check reached, update as failed");
                        this.clearStatusCheckTimer();
                        this.statusCheckTimer = setTimeout(() => { 
                            this.handleStatusCheck(true);
                        }, this.TIMER_INTERVAL);
                    }
                    else if(!toUpdate){
                            this.logger.debug("Status check iteration: ", this.iterationCount);
                            this.clearStatusCheckTimer();
                            this.statusCheckTimer = setTimeout(() => { 
                                this.handleStatusCheck();
                            }, this.TIMER_INTERVAL);
                    }
                    else{
                        this.logger.debug("Migration status marked as failed by audit"); 
                    }
                })
                .catch((error) => {
                    var message = error.message;
                    this.logger.error("migrationStatusCheck failed: ", message);
                });
            })
            .catch((error) => {
                this.logger.error("handleStatusCheck failed: ", error);
            });
        }
        else if(this.isOnline && this.isMigrationStarted && 
            this.migrationStatus === RESULT.NOTAVAILABLE && this.isTaskInQueue){
            this.logger.debug("Task in queue already, reset status check"); 
            this.iterationCount = 0;
            this.clearStatusCheckTimer();
            this.isTaskInQueue = false;
            this.statusCheckTimer = setTimeout(() => { 
                this.handleStatusCheck();
            }, this.TIMER_INTERVAL);
        }
        else{
            this.iterationCount = 0;
            this.isTaskInQueue = false;
            this.clearStatusCheckTimer();
            this.logger.debug("Migration status check complete already"); 
        }
    }

    loginToACOPortal(){
        this.logger.debug("loginToACOPortal");
        let acoPortalWindow =  window.open('https://service.cloudoffice.avaya.com/','_blank');
    }

    markAsComplete(){
        this.logger.debug("markAsComplete");
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.firestoreService.updateMarkAsComplete(this.id)
        .then((res) =>{
          //TODO:
          //update the status in ACOExport status , progress to 100%   OR
          //in startMigration page , check for markascomplete field and update progress directly 
          return this.firestoreService.updateMarkAsCompleteToAnalytics(this.id);
        }).then((res) =>{
            //this.overlay.closeSpinner();
            this.preFetchAcoExportData();
        }).catch((error) =>{
          this.overlay.closeSpinner();
          this.logger.error("Upadating markascomplete failed: ",this.id)     
        })
    }

    timeupCallbackfunc(ol){
        //this.logger.debug("Time up");
        ol.showSpinner = false;
        ol.closeSpinner();
    }


    getDeletionReportData(){
        this.logger.debug("getDeletionnReportData :"); 
        this.firestoreService.getDeletionFeatureStatusDoc(this.id)
        .then((doc:any)=>{
           // console.log("deletion report: "+ JSON.parse(doc.data));
            this.deletionFeatureDocData = [];
            let tempFeatureDocData = [];
            tempFeatureDocData= JSON.parse(doc.data);
            tempFeatureDocData.forEach(feature => {
                let failedErrorData = [];
                switch(feature.status){
                    case RESULT.ABORTED:
                        feature['status'] = "Aborted";
                        break;
                    case RESULT.FAILURE:
                        feature['status'] = "Failed";
                        break;
                    case RESULT.SUCCESSFUL:
                        feature['status'] = "Success";
                        break;
                    case RESULT.NOTAPPLICABLE:
                        feature['status'] = "Not Applicable"
                        break;
                    case RESULT.SKIPPED:
                            feature['status'] = "Not Selected"
                            break;
                    default:
                        feature['status'] = "Not Available"
                }

                if(Array.isArray(feature.failedDesc) && feature.failedDesc.length !== 0){
                    feature.failedDesc.map((errorItem) =>{
                        let errorString;let errorMsg = errorItem.error.data.message;
                        if(errorMsg == undefined || errorMsg === ""){
                            errorMsg = errorItem.error.data.httpMsg;
                        }
                        if(errorItem.error.name === DELETION_SUMMARY_STRINGS.FAILURE_USER_DATA_ACOERROR 
                            && (errorItem.error.data.httpStatusCode >= 400)
                            && (errorItem.error.data.httpStatusCode !== 429)
                            && (errorItem.error.data.httpStatusCode < 500)){
                                if(errorItem.extn){
                                    errorString = 'Extension : '+ errorItem.extn + ', Fail reason : ' + errorMsg ;
                                }else{
                                    let matchedFound = null;
                                    if(errorItem.extId && errorItem.extId !== "")
                                        matchedFound = this.extensionMap.find(x=>x.extId === errorItem.extId);
                                    else{
                                        matchedFound = this.extensionMap.find(x=>x.name === errorItem.name);
                                    }
                                    
                                    if(matchedFound){
                                        errorString = 'Extension : '+ matchedFound.extn + ', Fail reason : ' + errorMsg;
                                    }else if(errorItem.name){
                                        errorString = errorItem.name +' - Fail reason : ' + errorMsg;
                                    }else{
                                        errorString = 'Fail reason : ' + errorMsg;
                                    }
                                }
                        }else{
                            if(errorItem.extn){
                                errorString ='Extension : '+ errorItem.extn + ', Fail reason : ' + DELETION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                            }else{
                                let matchedFound = null;
                                if(errorItem.extId && errorItem.extId !== "")
                                        matchedFound = this.extensionMap.find(x=>x.extId === errorItem.extId);
                                else{
                                    matchedFound = this.extensionMap.find(x=>x.name === errorItem.name);
                                }
                                if(matchedFound){
                                    errorString ='Extension : '+ matchedFound.extn + ', Fail reason : ' + DELETION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                }else if(errorItem.name){
                                    errorString = errorItem.name +' - Fail reason : ' + errorMsg;
                                }else{
                                    errorString = 'Fail reason : ' + DELETION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                }
                            }
                        }
                        failedErrorData.push(errorString);
                    }) 
                }
                feature.failedDesc = failedErrorData;
                this.deletionFeatureDocData.push(feature);
            });
            this.cdRef.detectChanges();
            this.overlay.closeSpinner();
        })
        .catch((error)=>{
            this.logger.error("get deletion report: Error while fetching document from firestore :",error);
        });
    }

    getMigrationReportData(){
        this.logger.debug("getMigrationReportData :", this.failureUserData);
        //this.failureUserData = [];
        let migrationReportData = "";
        /* if(this.migrationSummaryData.length !== 0){
            if(this.migrationSummaryData.failure !== 0 && this.failureUserData.length !== 0){
                this.failureUserData.forEach(item => {
                    migrationReportData = 'Extension : '+ item.extn+ ' Fail reason : '+item.message;
                    this.failureUserData.push(migrationReportData);
                });
            }
        } */

        this.firestoreService.getFeatureStatusDoc(this.id)
        .then((doc:any)=>{
            this.featureDocData = [];
            let tempFeatureDocData = [];
            tempFeatureDocData= JSON.parse(doc.data);
            tempFeatureDocData.forEach(feature => {
                let failedErrorData = [];
                switch(feature.status){
                    case RESULT.ABORTED:
                        feature['status'] = "Aborted";
                        break;
                    case RESULT.FAILURE:
                        feature['status'] = "Failed";
                        break;
                    case RESULT.SUCCESSFUL:
                        feature['status'] = "Success";
                        break;
                    case RESULT.NOTAPPLICABLE:
                        feature['status'] = "Not Applicable"
                        break;
                    case RESULT.SKIPPED:
                            feature['status'] = "Not Selected"
                            break;
                    default:
                        feature['status'] = "Not Available"
                }

                if(Array.isArray(feature.failedDesc) && feature.failedDesc.length !== 0){
                    feature.failedDesc.map((errorItem) =>{
                        let errorString;
                        if(errorItem.error.name === MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_ACOERROR 
                            && (errorItem.error.data.httpStatusCode >= 400)
                            && (errorItem.error.data.httpStatusCode !== 429)
                            && (errorItem.error.data.httpStatusCode < 500)){
                                if(errorItem.extn){
                                    let matchedFound = this.extensionMap.find(x=>x.extn === errorItem.extn);
                                    if (matchedFound) {
                                        let siteName =  (matchedFound.siteName && matchedFound.siteName !== "" && matchedFound.siteName.length > 0) ? matchedFound.siteName : "MainSite";
                                        errorString = 'Extension : '+ errorItem.extn + ' , Site : ' + siteName + ', Fail reason : ' + errorItem.error.data.message;
                                    } else {
                                        errorString = 'Extension : '+ errorItem.extn + ', Fail reason : ' + errorItem.error.data.message;
                                    }
                                } else if (feature.name.trim().toLowerCase().includes('auto-receptionists')) {
                                    errorString = errorItem.name + ', Site : ' + 'MainSite' + ', Fail reason : ' + errorItem.error.data.message; 
                                }
                                else{
                                    let matchedFound = this.extensionMap.find(x=>x.extId === errorItem.extId);
                                    if(matchedFound){
                                        let siteName = (matchedFound.siteName && matchedFound.siteName !== "" && matchedFound.siteName.length > 0) ? matchedFound.siteName : "MainSite";
                                        errorString = 'Extension : '+ matchedFound.extn + ' , Site : ' + siteName + ', Fail reason : ' + errorItem.error.data.message;
                                    }else if(errorItem.name){
                                        let matchedFound = this.extensionMap.find(x=>x.name === errorItem.name);
                                        if (matchedFound) {
                                            let siteName = (matchedFound.siteName && matchedFound.siteName !== "" && matchedFound.siteName.length > 0) ? matchedFound.siteName : "MainSite";
                                            errorString = errorItem.name  + ', Site : ' + siteName + ' - Fail reason : ' + errorItem.error.data.message;
                                        } else {
                                            errorString = errorItem.name  + ' - Fail reason : ' + errorItem.error.data.message;
                                        }
                                    }else{
                                        errorString = 'Fail reason : ' + errorItem.error.data.message;
                                    }
                                }
                        }else{
                            if(errorItem.extn){
                                let matchedFound = this.extensionMap.find(x=>x.extn === errorItem.extn);
                                if (matchedFound) {
                                    let siteName = (matchedFound.siteName && matchedFound.siteName !== "" && matchedFound.siteName.length > 0) ? matchedFound.siteName : "MainSite";
                                    errorString ='Extension : '+ errorItem.extn + ' , Site : ' + siteName + ', Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                } else {
                                    errorString ='Extension : '+ errorItem.extn + ', Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                }
                            } else if (feature.name.trim().toLowerCase().includes('auto-receptionists')) {
                                errorString = errorItem.name + ', Site : ' + 'MainSite' + ', Fail reason : ' + errorItem.error.data.message; 
                            }
                            else{
                                let matchedFound = this.extensionMap.find(x=>x.extId === errorItem.extId);
                                if(matchedFound){
                                    let siteName = (matchedFound.siteName && matchedFound.siteName !== "" && matchedFound.siteName.length > 0) ? matchedFound.siteName : "MainSite";
                                    errorString ='Extension : '+ matchedFound.extn + ' , Site : ' + siteName + ', Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                }else if(errorItem.name){
                                    let matchedFound = this.extensionMap.find(x=>x.name === errorItem.name);
                                    if (matchedFound) {
                                        let siteName = (matchedFound.siteName && matchedFound.siteName !== "" && matchedFound.siteName.length > 0) ? matchedFound.siteName : "MainSite";
                                        errorString = errorItem.name + ', Site : ' + siteName + ' - Fail reason : ' + errorItem.error.data.message;
                                    } else {
                                        errorString = errorItem.name +' - Fail reason : ' + errorItem.error.data.message;
                                    }
                                }else{
                                    errorString = 'Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                }
                            }
                        }
                        failedErrorData.push(errorString);
                    }) 
                }
                feature.failedDesc = failedErrorData;
                this.featureDocData.push(feature);
            });
            this.cdRef.detectChanges();
            this.overlay.closeSpinner();
        })
        .catch((error)=>{
            this.logger.error("error while fetching document from firestore :",error);
        });
    }

    triggerDownloadReport() {
        this.logger.debug("triggerNotificationReport");
        if(this.isMorrisonProject){
            this.downloadCSVFile();
        }else{
            let compiledReportData = this.buildMigrationReportData();
            this.downloadNotificationService.generatePDF("migrationStatusReport",this.companyName,compiledReportData);
        }
    }

    triggerDownloadDelReport() {
        this.logger.debug("triggerDownloadDelReport");
        if(this.isMorrisonProject){
            //TODO :Morrison case  needs to be handled
            //this.downloadCSVFile();
        }else{
            let compiledReportData = this.buildDeletionReportData();
            this.downloadNotificationService.generatePDF("deletionStatusReport",this.companyName,compiledReportData);
        }
    }

    triggerNotificationDownloadReport() {
        
        this.reportData = [];
        this.logger.debug("triggerDownloadReport");    
        this.firestoreService.getNotificationData(this.id)
        .then((doc:any)=>{
            let compiledReportData = this.buildNotificationsReportData(doc);
            this.downloadNotificationService.generatePDF("notificationsReport",this.companyName,compiledReportData);
        }).catch((error)=>{
            this.logger.error("download report failed :",error);
        })

    }

    addData(data,fontClass){
        let temp = getTextContent();
        temp.text.push(data);
        temp.fontClass = fontClass;
        return setTextContPr(temp);
    }

    buildMigrationReportData(){
        this.logger.debug("build migration report data");
        let finalData:any=[];
        let temp:textStruct, line:lineStruct;
        //==========================================================================================
        temp = this.addData("Migration Report",FONT_CLASS.MAIN_TITLE);
        finalData.push(temp);       
        //==========================================================================================
        temp = getTextContent();
        //Basic migration details like Company name , migration status , migration end time 
        temp.text.push("Company Name : "+this.companyName);
        if(this.migrationStatus === this.status.SUCCESSFUL){
            temp.text.push("Migration Status : "+ this.migration_strings.SUCCESSFUL);
        }else{
            temp.text.push("Migration Status : "+this.migration_strings.FAILURE);
        }
        temp.text.push("Migration Completed on : "+this.migrationStatusDocData.endTime);
        temp.fontClass = FONT_CLASS.MAINCONTENT;
        finalData.push(temp);
        //==========================================================================================
        line = getLineContent();
        finalData.push(line);
        //==========================================================================================
        //lists all the feature executed for this migration 
        temp = this.addData("Migration Summary",FONT_CLASS.TITLE);
        finalData.push(temp);
        //------------------------------------------------------------------------------------------
        //Adding Assign Users feature as separate div since its not part of feature status Doc
        temp = getTextContent();
        temp.align = ALIGN.CENTER;
        if(this.migrationSummaryData.total  > 0 && this.migrationSummaryData.failure === 0){
            temp.text.push("Assign Users : Successful");
        }else{
            //migrationSummaryData.total > 0 && migrationSummaryData.failure > 0
            temp.text.push("Assign Users : Failed");
        }
        //All data is available in feature status Doc
        //use i+1 for storing the feature name and status as temp.text[0] has assign users feature status
        for(let i=0;i<this.featureDocData.length;i++){
            temp.text[i+1] = this.featureDocData[i].name + " : "+this.featureDocData[i].status;  
        }
        finalData.push(temp);
        //==========================================================================================
        line = getLineContent();
        finalData.push(line);
        //==========================================================================================
        temp = this.addData("AssignUsers",FONT_CLASS.HEADING);
        temp.style = FONT_STYLES.NORMAL;
        finalData.push(temp);
        //==========================================================================================
        temp = getTextContent();
        temp.fontClass = FONT_CLASS.MAINCONTENT;
        if(this.migrationSummaryData.total > 0 && this.migrationSummaryData.failure === 0){
            temp.text.push("Status : Success");
        }else{
            //migrationSummaryData.total > 0 && migrationSummaryData.failure > 0
            temp.text.push("Status : Failed");
        }
        
        let messageOnlyTotalUsers = 0;
        let featureFound = this.featureDocData.find(feature => feature.name.toString() === "Create MessageOnly Users");
        if (featureFound) messageOnlyTotalUsers = featureFound.total;
        //ACOS-1580 production dashboard count issue.
        temp.text.push("Total : "+ this.migrationSummaryData.total);
        temp.text.push("Successfully migrated : "+ (this.migrationSummaryData.total - this.migrationSummaryData.failure));
        temp.text.push("Failed to migrate : "+ this.migrationSummaryData.failure);
        if(this.failureUserData.length !== 0){
            temp.text.push("Failures");
            for(let i=0;i<this.failureUserData.length;i++){

                temp.text.push("Extension :"+this.failureUserData[i].extn+ " , Site : "+ this.failureUserData[i].site+ " , Fail reason : "+this.failureUserData[i].message);
            }

        }
        finalData.push(temp);
        //==========================================================================================
        line = getLineContent();
        finalData.push(line);
        //==========================================================================================
        for(let featureItem of this.featureDocData){
            temp = this.addData(featureItem.name,FONT_CLASS.HEADING);
            temp.style = FONT_STYLES.NORMAL;
            finalData.push(temp);

            temp = getTextContent();
            temp.fontClass = FONT_CLASS.MAINCONTENT;
            temp.text.push("Status : "+ featureItem.status);
            temp.text.push("Total : "+featureItem.total);
            temp.text.push("Successfully migrated : "+(featureItem.total - featureItem.totalFailed));
            temp.text.push("Failed to migrate : "+featureItem.totalFailed);
            if(featureItem.failedDesc.length !== 0){
                temp.text.push("Failures");
                for(let item of featureItem.failedDesc){
                    temp.text.push(item);
                }
            }
            finalData.push(temp);
            //==========================================================================================
            line = getLineContent();
            finalData.push(line);
            //==========================================================================================
        }        
        return finalData;
    }

    buildDeletionReportData(){
        this.logger.debug("build deletion report data");
        let finalData:any=[];
        let temp:textStruct, line:lineStruct;
        //==========================================================================================
        temp = this.addData("Deletion Report",FONT_CLASS.MAIN_TITLE);
        finalData.push(temp);       
        //==========================================================================================
        temp = getTextContent();
        //Basic migration details like Company name , migration status , migration end time 
        temp.text.push("Company Name : "+this.companyName);
        if(this.deletionStatus === this.status.SUCCESSFUL){
            temp.text.push("Deletion Status : "+ this.deletion_strings.SUCCESSFUL);
        }else{
            temp.text.push("Deletion Status : "+this.deletion_strings.FAILURE);
        }
        temp.text.push("Deletion Completed on : "+this.deletionStatusDocData.endTime);
        temp.fontClass = FONT_CLASS.MAINCONTENT;
        finalData.push(temp);
        //==========================================================================================
        line = getLineContent();
        finalData.push(line);
        //==========================================================================================
        //lists all the feature executed for this deletion        
        temp = this.addData("Deletion Summary",FONT_CLASS.TITLE);
        finalData.push(temp);
        //------------------------------------------------------------------------------------------
        temp = getTextContent();
        temp.align = ALIGN.CENTER;
        //All data is available in feature status Doc
        for(let i=0;i<this.deletionFeatureDocData.length;i++){
            temp.text[i] = this.deletionFeatureDocData[i].name + " : "+this.deletionFeatureDocData[i].status;  
        }
        finalData.push(temp);
        //==========================================================================================
        line = getLineContent();
        finalData.push(line);
        //==========================================================================================
        temp = getTextContent();
          for(let featureItem of this.deletionFeatureDocData){
            temp = this.addData(featureItem.name,FONT_CLASS.HEADING);
            temp.style = FONT_STYLES.NORMAL;
            finalData.push(temp);

            temp = getTextContent();
            temp.fontClass = FONT_CLASS.MAINCONTENT;
            temp.text.push("Status : "+ featureItem.status);
            temp.text.push("Total : "+featureItem.total);
            temp.text.push("Successfully deleted : "+(featureItem.total - featureItem.totalFailed));
            temp.text.push("Failed to delete : "+featureItem.totalFailed);
            if(featureItem.failedDesc.length !== 0){
                temp.text.push("Failures");
                for(let item of featureItem.failedDesc){
                    temp.text.push(item);
                }
            }
            finalData.push(temp);
            //==========================================================================================
            line = getLineContent();
            finalData.push(line);
            //==========================================================================================
        }        
        return finalData;
    }
    
    buildNotificationsReportData(data){
        let reportData = data.configSummaryNotifications;  
        let systemSummaryWarning = JSON.parse(data.systemSummaryNotifications);
        let basicInfoError = (reportData.userData.basicInfo.errors !== undefined)?JSON.parse(reportData.userData.basicInfo.errors):[];
        let basicInfoWarnings = (reportData.userData.basicInfo.warnings !== undefined)?JSON.parse(reportData.userData.basicInfo.warnings):[];
        let advInfoWarnings = (reportData.userData.advancedInfo.warnings !== undefined)?JSON.parse(reportData.userData.advancedInfo.warnings):[];
        let hgWarnings = (reportData.systemData.hgWarning !== undefined)?JSON.parse(reportData.systemData.hgWarning.warnings):[];
        let pgWarnings = (reportData.systemData.pgWarning !== undefined)?JSON.parse(reportData.systemData.pgWarning.warnings):[];
        let plWarnings = (reportData.systemData.plWarning !== undefined)?JSON.parse(reportData.systemData.plWarning.warnings):[];
        let tpWarnings =(reportData.systemData.tpWarning !== undefined)?JSON.parse(reportData.systemData.tpWarning.warnings):[];
        let aaWarnings = (reportData.systemData.aaWarning !== undefined)?JSON.parse(reportData.systemData.aaWarning.warnings):[];
        let icrWarnings = (reportData.systemData.icrWarning !== undefined)?JSON.parse(reportData.systemData.icrWarning.warnings):[];
        let features = [
          { 
            name: "Call Queues",
            warnings:hgWarnings
          },
          {
            name:"Paging Only",
            warnings:pgWarnings
          },
          {
            name:"Park Locations",
            warnings:plWarnings
          },
          {
            name:"Working Hours",
            warnings:tpWarnings
          },
          {
            name:"Auto-Receptionists",
            warnings:aaWarnings
          },
          {
            name:"Company Numbers",
            warnings:icrWarnings
          }
        ];  
        let finalData:any=[];
        if(data !== undefined && data != null){
            let temp:textStruct, line:lineStruct;
            //==========================================================================================
            temp = this.addData("Notifications Report",FONT_CLASS.MAIN_TITLE);
            finalData.push(temp);       
            //==========================================================================================
            temp = getTextContent();
            temp.text.push("Company Name : "+this.companyName);
            temp.text.push("Report Downloaded on : "+this.migrationStatusDocData.endTime);
            temp.fontClass = FONT_CLASS.MAINCONTENT;
            finalData.push(temp);
            //==========================================================================================
            line = getLineContent();
            finalData.push(line);
            //==========================================================================================
            temp = this.addData("System Summary Warnings",FONT_CLASS.TITLE);
            finalData.push(temp);
            //------------------------------------------------------------------------------------------
            temp = getTextContent();
            temp.x = 20;        
            for(let i=0;i<systemSummaryWarning.length;i++){
                temp.text[i] = String.fromCharCode(0x2022)+" "+systemSummaryWarning[i];
            }
            finalData.push(temp);
            //==========================================================================================
            line = getLineContent();
            finalData.push(line);        
            //==========================================================================================
            temp = this.addData("User Data Notifications",FONT_CLASS.TITLE);
            finalData.push(temp);
            //==========================================================================================
            if(basicInfoError.length > 0 || basicInfoWarnings.length > 0){
                temp = this.addData("Basic Information",FONT_CLASS.HEADING);
                finalData.push(temp);
                //----------------------------------------------------------------------------------------
                if(basicInfoError.length > 0){
                  temp = this.addData("Errors",FONT_CLASS.ERROR);
                  finalData.push(temp);
                  //------------------------------------------------------------------------------------------
                  for(let i=0;i<basicInfoError.length;i++){
                    if(basicInfoError[i][0] !== "")
                      temp = this.addData(basicInfoError[i][0],FONT_CLASS.HEADING);
                    else 
                      temp = this.addData("Main-Site",FONT_CLASS.HEADING);
                      if(basicInfoError[i][1].length > 0)
                      {
                    finalData.push(temp);
                    temp = getTextContent();
                    temp.x = 20;
                    for(let j = 0; j < basicInfoError[i][1].length ; j++)
                    {
                      temp.text[j] = String.fromCharCode(0x2022)+" "+basicInfoError[i][1][j];
                    }
                    finalData.push(temp);
                  }
                  }
                }        
                //------------------------------------------------------------------------------------------
                if(basicInfoWarnings.length > 0){
                  temp = this.addData("Warnings",FONT_CLASS.WARNING);
                  finalData.push(temp);
                  //------------------------------------------------------------------------------------------
                  for(let i=0;i<basicInfoWarnings.length;i++){
                    if(basicInfoWarnings[i][0] !== "")
                    temp = this.addData(basicInfoWarnings[i][0],FONT_CLASS.HEADING);
                  else 
                    temp = this.addData("Main-Site",FONT_CLASS.HEADING);
                    if(basicInfoWarnings[i][1].length > 0)
                    {
                    finalData.push(temp);
                    temp = getTextContent();
                    temp.x = 20;
                    for(let j = 0; j < basicInfoWarnings[i][1].length ; j++)
                    {
                      temp.text[j] = String.fromCharCode(0x2022)+" "+basicInfoWarnings[i][1][j];
                    }
                    finalData.push(temp);
                  }
                  }
                }            
                //==========================================================================================
              }
            line = getLineContent();
            finalData.push(line);
            //==========================================================================================
            temp = this.addData("Advanced Information",FONT_CLASS.HEADING);
            finalData.push(temp);
            //------------------------------------------------------------------------------------------
            temp = this.addData("Warnings",FONT_CLASS.WARNING);
            finalData.push(temp);
            //------------------------------------------------------------------------------------------
            for(let i=0;i<advInfoWarnings.length;i++){
              if(advInfoWarnings[i][0] !== "")
              temp = this.addData(advInfoWarnings[i][0],FONT_CLASS.HEADING);
            else 
              temp = this.addData("Main-Site",FONT_CLASS.HEADING);
              if(advInfoWarnings[i][1].length > 0)
              finalData.push(temp);
              temp = getTextContent();
              temp.x = 20;
              for(let j = 0; j < advInfoWarnings[i][1].length ; j++)
              {
                temp.text[j] = String.fromCharCode(0x2022)+" "+advInfoWarnings[i][1][j];
              }
              finalData.push(temp);
            }
            //==========================================================================================
            line = getLineContent();
            finalData.push(line);
            //==========================================================================================
            temp = this.addData("System Data Warnings",FONT_CLASS.TITLE);
            finalData.push(temp);
            //==========================================================================================
            for(let i=0;i<features.length;i++){
                if(features[i].warnings.length > 0) {
                    if (features[i].name !== "Park Locations")
                  {
                    temp = this.addData(features[i].name,FONT_CLASS.HEADING);
                    finalData.push(temp);
                    //------------------------------------------------------------------------------------------
                    temp = this.addData("Warnings",FONT_CLASS.WARNING);
                    finalData.push(temp);
                    //------------------------------------------------------------------------------------------
                    for(let k = 0;features[i].warnings[k]; k ++)
                    {
                    if(features[i].warnings[k][0] !== "")
                      temp = this.addData(features[i].warnings[k][0],FONT_CLASS.HEADING);
                    else
                      temp = this.addData("Main-Site",FONT_CLASS.HEADING);
                    if(features[i].warnings[k][1].length > 0)
                      {
                        finalData.push(temp);
                        temp = getTextContent();
                        temp.x = 20;
                        for(let j=0;j<features[i].warnings[k][1].length;j++){
                          temp.text[j] = String.fromCharCode(0x2022)+" "+features[i].warnings[k][1][j];
                        }
                        finalData.push(temp);
                      }
                      else continue;
                    }
                  }
                  else
                  {
                  temp = this.addData(features[i].name,FONT_CLASS.HEADING);
                  finalData.push(temp);
                  //------------------------------------------------------------------------------------------
                  temp = this.addData("Warnings",FONT_CLASS.WARNING);
                  finalData.push(temp);
                  //------------------------------------------------------------------------------------------
                  temp = getTextContent();
                  temp.x = 20;
                  for(let j=0;j<features[i].warnings.length;j++){
                    temp.text[j] = String.fromCharCode(0x2022)+" "+features[i].warnings[j];
                  }
                  finalData.push(temp);
                }
                  //==========================================================================================
                  line = getLineContent();
                  finalData.push(line);
                  //==========================================================================================
                }
              }
            }
        return finalData;
    }
   
    downloadReport(){
        this.logger.debug("downloadReport");
        let migrationReportData = "";
        if(this.migrationSummaryData.length !== 0){
            if(this.migrationSummaryData.failure !== 0 && this.failureUserData.length !== 0){
                this.failureUserData.forEach(item => {
                    migrationReportData = migrationReportData + 'Extension : '+ item.extn+ ' Site : ' + item.site + ' Fail reason : '+item.message+'\n';
                });
            }
        }

        this.firestoreService.getFeatureStatusDoc(this.id)
        .then((doc:any)=>{
            var featureDocData = JSON.parse(doc.data);
            if(Array.isArray(featureDocData) && featureDocData.length !== 0){
                featureDocData.map((feature) =>{
                    if(Array.isArray(feature.failedDesc) && feature.failedDesc.length !== 0){
                        feature.failedDesc.map((errorItem) =>{
                            let tempData;
                            if(errorItem.error.name === MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_ACOERROR 
                                && (errorItem.error.data.httpStatusCode >= 400)
                                && (errorItem.error.data.httpStatusCode !== 429)
                                && (errorItem.error.data.httpStatusCode < 500)){
                                    if(errorItem.extn){
                                        tempData = 'Extension : '+ errorItem.extn + ' Fail reason : ' + errorItem.error.data.message;
                                    }else{
                                        tempData = 'Extension : '+ errorItem.extId + ' Fail reason : ' + errorItem.error.data.message;
                                    }
                            }else{
                                if(errorItem.extn){
                                    if(errorItem.userAssignFailure){
                                        tempData ='Extension : '+ errorItem.extn + ' Fail reason : User Migration failed for this user';
                                    }else{
                                        tempData ='Extension : '+ errorItem.extn + ' Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                    }
                                }else{
                                    if(errorItem.userAssignFailure){
                                        tempData ='Extension : '+ errorItem.extId + ' Fail reason : User Migration failed for this user';
                                    }else{
                                        tempData ='Extension : '+ errorItem.extId + ' Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                    }
                                }
                            }
                        })  
                    }
                })   
            }
        }).catch((error)=>{
            this.logger.error("download report failed :",error);
        })
    }

    public async downloadCSVFile() {
        this.logger.info("downloadCSVFile");
        let csv :any= [];
        this.firestoreService.readDoc(this.id)
        .then((curdata:any) => {
            let row = [ "AccessCode", curdata.accesscode].join(",");
            csv += row + "\r\n";
            row = [ "Extension", "Status"].join(",");
            csv += row + "\r\n";
            return this.firestoreService.readUnconditionalForwardingResDataDoc(this.id);
        }).then(async (curdata:any) => {  
            
            //get extn list from db 
            if(curdata !== null){
                this.logger.debug("readUnconditionalForwardingResDataDoc success "+curdata.data);
                let unconditionalFowardingResData = JSON.parse(curdata.data);  
                if(unconditionalFowardingResData.length !== 0){
                    let csvData = await this.buildCSVContent(unconditionalFowardingResData);
                    csv += csvData;
                } 
            }
            return this.firestoreService.readTextToSpeechResDataDoc(this.id);  
        }).then(async (curdata:any) => {
            if(curdata !== null){
                this.logger.debug("readUnconditionalForwardingResDataDoc success "+curdata.data);
                let textToSpeechResData = JSON.parse(curdata.data);  
                if(textToSpeechResData.length !== 0){
                    csv += "HQUsers\r\n"
                    let csvData = await this.buildCSVContent(textToSpeechResData);
                    csv += csvData;
                }
            }
           
            const csvAsBlob = new Blob([csv], { type: 'text/csv' });
            const fileNameToSaveAs = this.companyName+'.csv';
            var url = window.URL.createObjectURL(csvAsBlob);
            saveAs(csvAsBlob, fileNameToSaveAs);
            this.overlay.closeSpinner();
        }).catch((err) => {
            this.logger.debug("getAssignedExtensionList failed: "+err);
            this.overlay.closeSpinner();
        });
      }

    async buildCSVContent(data){
        let csv:any = [];
        data.forEach(element => {
            let status = ""
            if(element.status === RESULT.SUCCESSFUL){
                status = "Success";
            }else{
                status = "Failed";
            }
            let row = [ element.extn, status].join(",");
            csv += row + "\r\n";
        });
        return csv;
    }

    async showFeature(){
        this.logger.debug("showFeatureList");
        this.showFeatureList = !this.showFeatureList;
        await this.assignFeatureCheckBoxProperties(this.finalTaskChainData);
        this.cdRef.detectChanges();
        document.getElementById("selectFeatureList").setAttribute("style","height:35vh!important;width:32vw;");
    }

    buildClearACOTaskChain(){
        let promise = new Promise((resolve,reject)=>{
            this.firestoreService.getSiteMap(this.id)
            .then((sitesData:any)=>{
                let siteMap = JSON.parse(sitesData.data);
                if(siteMap.length > 0){
                    this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_MULTI_SITES)
                }
                return this.firestoreService.getTaskChain(this.id , false,PROCESS.MIGRATION)
            })            
            .then((taskChainData:any)=>{
                let migrationTaskChain = JSON.parse(taskChainData.data);
                migrationTaskChain.forEach((task)=>{
                    switch(task){
                        case FEATURE_NAME.COMPANY_RULES:
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_COMPANY_RULES);
                            break;
                        case FEATURE_NAME.AUTO_ATTENDANT:
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_AUTO_ATTENDANT);
                            break;
                        case FEATURE_NAME.AUTO_ATTENDANT_PROMPTS:
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_AUTO_ATTENDANT_PROMPTS);
                            break;
                        case FEATURE_NAME.COMPLEX_IVR_PROMPTS:
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_COMPLEX_IVR_PROMPTS);
                            break;
                        case FEATURE_NAME.PARK_LOCATION:
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_PARK_LOCATIONS);
                            break;
                        case FEATURE_NAME.HUNT_GROUP:
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_PICKUP_MEMBERS);
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_HUNT_GROUP);
                            break;
                        case FEATURE_NAME.PAGING_GROUP:
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_PAGING_GROUP);
                            break;
                        case FEATURE_NAME.USERS:
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.MOVE_ASSIGNED_USERS_TO_MAINSITE);
                            this.selectedClearConfigFeatureList.push(FEATURE_NAME.CLEAR_ASSIGNED_USERS);
                            break;
                        default:{
                            //DO NOTHING
                        }
                    }
                })
              //  this.selectedClearConfigFeatureList.push(FEATURE_NAME.POST_DELETION);
                resolve();
            })
            .catch((error)=>{
                reject();
            })
        })
        return promise;
    }

    selectFeatures(checked , option){
        if(checked){
            if(option === FEATURE_NAME.USER_VM_WORKHOURS){
                if(this.taskChain.indexOf(FEATURE_NAME.USER_VM_AFTERHOURS) !== -1){
                    this.selectedFeatureList.push(FEATURE_NAME.USER_VM_AFTERHOURS);
                }
                if(this.taskChain.indexOf(FEATURE_NAME.USER_VM_CUSTOMHOURS) !== -1){
                    this.selectedFeatureList.push(FEATURE_NAME.USER_VM_CUSTOMHOURS);
                }
                if(this.taskChain.indexOf(FEATURE_NAME.USER_VM_WORKHOURS) !== -1){
                    this.selectedFeatureList.push(FEATURE_NAME.USER_VM_WORKHOURS);
                }  
            }if(option === FEATURE_NAME.COMPANY_RULES && !(this.selectedFeatureList.includes(FEATURE_NAME.AUTO_ATTENDANT))){
                this.logger.debug(MIGRATION_SUMMARY_STRINGS.COMPANY_NUMBERS_CHECKED_IVR_UNCHECKED);
                this.session.alertModal(this.onCompanyRulesCheckedWhenAutoAttendantsUnCheckedConfirmed.bind(this) , MIGRATION_SUMMARY_STRINGS.COMPANY_NUMBERS_CHECKED_IVR_UNCHECKED
                ,"Ok","Cancel",this.onCompanyRulesCheckedWhenAutoAttendantsUnCheckedCancel.bind(this));
            }
            else if(option === FEATURE_NAME.ZERO_DIAL && !(this.selectedFeatureList.includes(FEATURE_NAME.AUTO_ATTENDANT)) && !(this.selectedFeatureList.includes(FEATURE_NAME.COMPANY_RULES))){
                this.logger.debug(MIGRATION_SUMMARY_STRINGS.ZERO_DIAL_CHECKED_IVR_UNCHECKED);
                this.session.alertModal(this.onCompanyRulesAndZeroDialCheckedWhenAutoAttendantsUnCheckedConfirmed.bind(this) , MIGRATION_SUMMARY_STRINGS.ZERO_DIAL_CHECKED_IVR_UNCHECKED
                ,"Ok","Cancel",this.onCompanyRulesCheckedWhenAutoAttendantsUnCheckedCancel.bind(this));
            }
            else{
                this.selectedFeatureList.push(option);
            }
        }else{
            if(option != FEATURE_NAME.HUNT_GROUP){
                if(option === FEATURE_NAME.AUTO_ATTENDANT && (this.selectedFeatureList.includes(FEATURE_NAME.COMPANY_RULES))  && (this.selectedFeatureList.includes(FEATURE_NAME.ZERO_DIAL))){
                    this.logger.debug(MIGRATION_SUMMARY_STRINGS.IVR_UNCHECKED_COMPANY_RULES_CHECKED);

                    this.session.alertModal(this.onAutoAttendantsUnCheckedWhenCompanyRulesCheckedConfirmed.bind(this) , MIGRATION_SUMMARY_STRINGS.IVR_UNCHECKED_COMPANY_RULES_CHECKED
                    ,"Ok","Cancel",this.onAutoAttendantsUnCheckedWhenCompanyRulesCheckedCancel.bind(this));
                }  
                else if(option === FEATURE_NAME.COMPANY_RULES && (this.selectedFeatureList.includes(FEATURE_NAME.ZERO_DIAL))){
                    this.logger.debug(MIGRATION_SUMMARY_STRINGS.ZERO_DIAL_CHECKED_COMPANYRULES_UNCHECKED)
                    this.session.alertModal(this.onCompanyRulesUnCheckedWhenZeroDialCheckedConfirmed.bind(this) , MIGRATION_SUMMARY_STRINGS.ZERO_DIAL_CHECKED_COMPANYRULES_UNCHECKED
                    ,"Ok","Cancel",this.onCompanyRulesUnCheckedWhenZeroDialCheckedCancel.bind(this));
                }              
                else{
                    if(this.selectedFeatureList.indexOf(option) !== -1){
                        this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(option) , 1);
                    }
                    if(option === FEATURE_NAME.USER_VM_WORKHOURS){
                        if(this.selectedFeatureList.indexOf(FEATURE_NAME.USER_VM_AFTERHOURS) !== -1){
                            this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(FEATURE_NAME.USER_VM_AFTERHOURS) , 1);
                        }
                        if(this.selectedFeatureList.indexOf(FEATURE_NAME.USER_VM_CUSTOMHOURS) !== -1){
                            this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(FEATURE_NAME.USER_VM_CUSTOMHOURS) , 1);
                        }
                    }
                }                                
            }else{
                if(this.selectedFeatureList.includes(FEATURE_NAME.AUTO_ATTENDANT)){
                    //show warning for unselecting HG when IVR is selected
                    this.logger.debug(MIGRATION_SUMMARY_STRINGS.CALL_QUEUES_UNCHECKED_IVR_CHECKED);
                    this.session.alertModal(this.onCallQueuesUnCheckedWhenIVRCheckedConfirmed.bind(this) , MIGRATION_SUMMARY_STRINGS.CALL_QUEUES_UNCHECKED_IVR_CHECKED
                    ,"Ok","Cancel",this.onCallQueuesUnCheckedWhenIVRCheckedCancel.bind(this));
                }
                else{
                    if(this.selectedFeatureList.indexOf(option) !== -1){
                        this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(option) , 1);
                    }
                }
            }
        }
        this.logger.debug("features selected ",this.selectedFeatureList);
    }

    //dummy callBack since that is mandatory callback 
    onCallQueuesUnCheckedWhenIVRCheckedConfirmed(){
        this.logger.debug("onCallQueuesUnCheckedWhenIVRCheckedConfirmed");
        if(this.selectedFeatureList.indexOf(FEATURE_NAME.HUNT_GROUP) !== -1){
            this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(FEATURE_NAME.HUNT_GROUP) , 1);
        }
    }

    onCallQueuesUnCheckedWhenIVRCheckedCancel(){
        //when user clicks Cancel in warning popup, HG feature should be reverted to checked status
        this.logger.debug("revert back HG feature to checked status")
        this.featureListValue.HUNT_GROUP.value = true;
    }

    onAutoAttendantsUnCheckedWhenCompanyRulesCheckedConfirmed(){
        this.logger.debug("onAutoAttendantsUnCheckedWhenCompanyRulesCheckedConfirmed");
        if(this.selectedFeatureList.indexOf(FEATURE_NAME.COMPANY_RULES) !== -1){
            this.featureListValue.COMPANY_RULES.value = false;
            this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(FEATURE_NAME.COMPANY_RULES) , 1);
            
        }
        if(this.selectedFeatureList.indexOf(FEATURE_NAME.ZERO_DIAL) !== -1){
            this.featureListValue.ZERO_DIAL.value = false;
            this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(FEATURE_NAME.ZERO_DIAL) , 1);
        }
        if(this.selectedFeatureList.indexOf(FEATURE_NAME.AUTO_ATTENDANT) !== -1){
            this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(FEATURE_NAME.AUTO_ATTENDANT) , 1);
        }
    }
    onAutoAttendantsUnCheckedWhenCompanyRulesCheckedCancel(){
        this.logger.debug("revert back Auto-Attendant feature to checked status")
        this.featureListValue.AUTO_ATTENDANT.value = true;
    }
    onCompanyRulesUnCheckedWhenZeroDialCheckedConfirmed(){
        this.logger.debug("onCompanyRulesUnCheckedWhenZeroDialCheckedConfirmed");
        if(this.selectedFeatureList.indexOf(FEATURE_NAME.ZERO_DIAL) !== -1){
            this.featureListValue.ZERO_DIAL.value = false;
            this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(FEATURE_NAME.ZERO_DIAL) , 1);
        }
        if(this.selectedFeatureList.indexOf(FEATURE_NAME.COMPANY_RULES) !== -1){
            this.selectedFeatureList.splice(this.selectedFeatureList.indexOf(FEATURE_NAME.COMPANY_RULES) , 1);
        }
    }
    onCompanyRulesUnCheckedWhenZeroDialCheckedCancel(){
        this.logger.debug("revert back Auto-Attendant feature to checked status")
        this.featureListValue.COMPANY_RULES.value = true;
    }

    onCompanyRulesCheckedWhenAutoAttendantsUnCheckedConfirmed(){
        this.logger.debug("onCompanyRulesCheckedWhenAutoAttendantsUnCheckedConfirmed");
        this.featureListValue.COMPANY_RULES.value = true;
        this.selectedFeatureList.push(FEATURE_NAME.COMPANY_RULES);
        this.featureListValue.AUTO_ATTENDANT.value = true;
        this.selectedFeatureList.push(FEATURE_NAME.AUTO_ATTENDANT);
    }

    onCompanyRulesAndZeroDialCheckedWhenAutoAttendantsUnCheckedConfirmed(){
        this.logger.debug("onCompanyRulesAndZeroDialCheckedWhenAutoAttendantsUnCheckedConfirmed");
        this.featureListValue.COMPANY_RULES.value = true;
        this.selectedFeatureList.push(FEATURE_NAME.ZERO_DIAL);
        this.featureListValue.AUTO_ATTENDANT.value = true;
        this.selectedFeatureList.push(FEATURE_NAME.AUTO_ATTENDANT);
    }
    onCompanyRulesCheckedWhenAutoAttendantsUnCheckedCancel(){
        this.logger.debug("onCompanyRulesCheckedWhenAutoAttendantsUnCheckedCancel");
        this.featureListValue.COMPANY_RULES.value = false;
    }

    cancelFeaturePopup(){
        this.logger.debug("cancelFeaturePopup");
        this.showFeatureList = false;
        this.resetFeatureSelectUI();
        //this.assignFeatureCheckBoxProperties(this.selectedFeatureList);
    }

    featuresSelected(){
        this.logger.debug("featuresSelected");
        this.showFeatureList = false;
        this.prioritizeTaskQueue();
    }

    private prioritizeTaskQueue(){
        let newTaskChain = [];
        TASK_QUEUE_PRIORITY.filter((entry:any) => {
            let featureFound = this.selectedFeatureList.find((featureName:any) => featureName === entry);
            if(featureFound){
                newTaskChain.push(featureFound);
            }
        })
        this.selectedFeatureList = JSON.parse(JSON.stringify(newTaskChain));
        this.finalTaskChainData = JSON.parse(JSON.stringify(newTaskChain)); 	
        this.logger.debug(`Old task chain is replaced with new feature list selected. Old TaskChain: ${this.taskChain}`);
        this.logger.debug(`New TaskChain: ${this.selectedFeatureList}`);
        this.firestoreService.setTaskChain(this.id,this.selectedFeatureList , true,PROCESS.MIGRATION)
        .then((res) =>{
            this.logger.debug("final taskchain is set succesfully");
        }).catch(()=>{

        })
        //push the selected feature list to finalTaskChain doc
    }

    private prioritizeClearACOTaskQueue(){
        let newTaskChain = [];
        CLEAR_ACO_TASK_QUEUE_PRIORITY.filter((entry:any) => {
            let featureFound = this.selectedClearConfigFeatureList.find((featureName:any) => featureName === entry);
            if(featureFound){
                newTaskChain.push(featureFound);
            }
        })
        this.selectedClearConfigFeatureList = JSON.parse(JSON.stringify(newTaskChain));
       // this.finalClearACOTaskChainData = JSON.parse(JSON.stringify(newTaskChain)); 	
        this.logger.debug(`Old task chain is replaced with new feature list selected. Old TaskChain: ${this.clearACOTaskChain}`);
        this.logger.debug(`New TaskChain: ${this.selectedClearConfigFeatureList}`);
        //TODO : If select feature is added for deletion, push the selected feature list to finalTaskChain doc        
        
    }
}


